import React from 'react'
import PropTypes from 'prop-types'

import Hint from 'components/Hint'

import style from '../../ResourceProviderCreator/ResourceProviderCreator.styl'

export class CredentialsFormOauth2 extends React.Component {
  constructor () {
    super()

    this._onValueChange = this._onValueChange.bind(this)

    this.state = {
      grant_type: 'password',
      token_url: null,
      client_id: null,
      client_secret: null,
      username: null,
      password: null,
      token_expiry: null
    }
  }

  componentWillMount () {
    this.props.onCredentialsChange(this.state)
  }

  transformAndSendCredentials () {
    const expiryField = this.state.expiry_type_field || this.state.expiry_type

    const tokenExpiry = this.state.expiry_type === 'issued_at'
      ? { [`${this.state.expiry_type}_field`]: expiryField, expires_in: parseInt(this.state.ttl) }
      : { [`${this.state.expiry_type}_field`]: expiryField }

    var creds = {
      grant_type: this.state.grant_type,
      token_url: this.state.token_url,
      client_id: this.state.client_id,
      client_secret: this.state.client_secret,
      username: this.state.username,
      password: this.state.password,
      token_expiry: tokenExpiry
    }

    this.props.onCredentialsChange(creds)
  }

  _onValueChange (valueName, { target }) {
    this.setState((prevState, props) => {
      return {[valueName]: target.value}
    }, () => {
      this.transformAndSendCredentials()
    })
  }

  renderIssuedAt () {
    if (this.state.expiry_type === 'issued_at') {
      return (
        <div className={style.formField}>
          <label>
            Token TTL (required for'issued_at' expiry type)
            <Hint className={style.hint}>
              Duration (TTL) for the access token.
            </Hint>
          </label>
          <input
            required={!this.props.updating} name='ttl' type='number'
            value={this.state.ttl || ''}
            onChange={this._onValueChange.bind(null, 'ttl')} />
        </div>
      )
    }
  }

  render () {
    return (
      <div>
        <div className={style.description}>
          <p>
            This resource provider type accesses an API using the OAuth2 "password" grant type.
          </p>
          <p>
            A URL that supplies access tokens is required, as well as a client ID. Client secret is optional. Username and password are required.
          </p>
          <p>
            The token expiry type must be one of 'expires_in', 'expires_at' or 'issued_at'. Optionally supply the field name that the OAuth2 provider
            uses to supply token expiry data. If 'issued_at' is used, a token TTL must be supplied.
          </p>
        </div>

        <div className={style.formField}>
          <label>
            OAuth2 provider URL
            <Hint className={style.hint}>
              The OAuth2 provider URL, from which access tokens will be retrieved.
            </Hint>
          </label>
          <input
            required name='token url' type='text'
            value={this.state.token_url || ''}
            onChange={this._onValueChange.bind(null, 'token_url')} />
        </div>

        <div className={style.formField}>
          <label>
            OAuth2 client ID
            <Hint className={style.hint}>
              Client ID for OAuth2 authentication
            </Hint>
          </label>
          <input
            required name='client_id' type='text'
            value={this.state.client_id || ''}
            onChange={this._onValueChange.bind(null, 'client_id')} />
        </div>

        <div className={style.formField}>
          <label>
            OAuth2 client secret (optional)
            <Hint className={style.hint}>
              Client secret for OAuth2 authentication
            </Hint>
          </label>
          <input
            name='client_secret' type='text'
            value={this.state.client_secret || ''}
            onChange={this._onValueChange.bind(null, 'client_secret')} />
        </div>

        <div className={style.formField}>
          <label>
            Username
            <Hint className={style.hint}>
              The username to use when obtaining access tokens.
            </Hint>
          </label>
          <input
            required name='username' type='text'
            value={this.state.username || ''}
            onChange={this._onValueChange.bind(null, 'username')} />
        </div>

        <div className={style.formField}>
          <label>
            Password
            <Hint className={style.hint}>
              The password to use when obtaining access tokens.
            </Hint>
          </label>
          <input
            required name='password' type='text'
            value={this.state.password || ''}
            onChange={this._onValueChange.bind(null, 'password')} />
        </div>

        <div className={style.formField}>
          <label>
            Token expiry type
            <Hint className={style.hint}>
              Token expiry type (one of 'expires_in', 'expires_at', 'issued_at').
            </Hint>
          </label>
          <input
            required name='expiry_type' type='text'
            pattern='^(expires_in|expires_at|issued_at)$'
            value={this.state.expiry_type || ''}
            onChange={this._onValueChange.bind(null, 'expiry_type')} />
        </div>

        <div className={style.formField}>
          <label>
            Expiry type field (optional)
            <Hint className={style.hint}>
              Field to be used in the access token for token expiry calculations. Defaults to value of --expiry-type if not specified.
            </Hint>
          </label>
          <input
            name='expiry_type_field' type='text'
            value={this.state.expiry_type_field || ''}
            onChange={this._onValueChange.bind(null, 'expiry_type_field')} />
        </div>

        {this.renderIssuedAt()}
      </div>
    )
  }
}

CredentialsFormOauth2.propTypes = {
  onCredentialsChange: PropTypes.func.isRequired
}

export default CredentialsFormOauth2
