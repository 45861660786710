import { interpolate } from 'd3-interpolate'

export function initProperty (element, propertyName, value) {
  propertyName = `data-interpolate-${propertyName}`
  element.setAttribute(propertyName, JSON.stringify(value))
  return value
}

export function interpolateProperty (element, propertyName, startValue, endValue, func) {
  const fullPropertyName = `data-interpolate-${propertyName}`

  startValue = JSON.parse(element.getAttribute(fullPropertyName)) || startValue

  const currentValue = interpolate(startValue, endValue)

  return t => {
    const value = currentValue(t)
    element.setAttribute(fullPropertyName, JSON.stringify(value))
    return func(value)
  }
}
