import Auth0Lock from 'auth0-lock'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import { signIn, RETURN_ROUTE_KEY } from 'state/session/actions'

import config from 'config'

import logo from './logo.png'

export class SignIn extends React.Component {
  constructor () {
    super()
    this.authenticated = false
  }

  componentWillMount () {
    this.auth0Lock = new Auth0Lock(config.authClientId, config.domain, {
      theme: {
        logo: logo
      },
      auth: {
        redirect: true,
        sso: true,
        params: {
          scope: 'openid app_metadata'
        },
        responseType: 'token id_token'
      },
      avatar: null,
      closable: false,
      hashCleanup: false,
      autoParseHash: false,
      _enableIdPInitiatedLogin: true
    })

    this.auth0Lock.resumeAuth(window.location.hash.split('?')[1], (error, authResult) => {
      if (error) {
        this._onError(error)
      }
    })

    this.auth0Lock.on('authenticated', (authResult) => this._onAuthenticated(authResult))
    this.auth0Lock.on('authorization_error', (error) => this._onError(error))

    if (this.props.returnRoute) {
      window.localStorage.setItem(RETURN_ROUTE_KEY, this.props.returnRoute)
    }
  }

  render () {
    return null
  }

  componentDidMount () {
    if (!this.authenticated) {
      this.auth0Lock.show()
    }
  }

  componentWillUnmount () {
    this.auth0Lock.hide()
  }

  _onAuthenticated (authResult) {
    this.authenticated = true
    this.auth0Lock.hide()

    this.auth0Lock.getUserInfo(authResult.accessToken, (error, profile) => {
      if (error) {
        this._onError(error)
        return
      }
      const returnRoute = window.localStorage.getItem(RETURN_ROUTE_KEY)
      window.localStorage.removeItem(RETURN_ROUTE_KEY)

      if (this.props.onSuccess) {
        this.props.onSuccess(authResult.idToken, profile, returnRoute)
      }
    })
  }

  _onError (error) {
    this.authenticated = false
    this.auth0Lock.show()

    if (this.props.onFailure) {
      this.props.onFailure(error)
    }
  }
}

SignIn.propTypes = {
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
  returnRoute: PropTypes.string
}

function mapDispatchToProps (dispatch) {
  return {
    onSuccess: (idToken, profile, returnRoute) => {
      dispatch(signIn(idToken, profile, returnRoute))
    }
  }
}

export default connect(null, mapDispatchToProps)(SignIn)
