import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'

import style from './Modal.styl'

export class Modal extends React.Component {
  static defaultProps = {
    backdrop: true
  }

  render () {
    const modalClasses = classNames(style.Modal, this.props.className, {
      [style.backdrop]: this.props.backdrop
    })

    return (
      <div
        className={modalClasses}
        ref={element => { this.modalElement = element }}
        onClick={({ target }) => {
          if (target === this.modalElement && this.props.onDismiss && !this.props.disableClickClose) {
            this.props.onDismiss()
          }
        }}>
        {this.props.children}
      </div>
    )
  }
}

Modal.propTypes = {
  disableClickClose: PropTypes.bool,
  backdrop: PropTypes.bool,
  onDismiss: PropTypes.func,
  className: PropTypes.string
}

export default Modal
