import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import style from './PageScrollPane.styl'

export function PageScrollPane ({ children, onScrollPositionChange, className }) {
  const onScroll = event => {
    if (onScrollPositionChange) {
      onScrollPositionChange(event.target.scrollTop)
    }
  }

  const ref = element => {
    if (element && onScrollPositionChange) {
      onScrollPositionChange(element.scrollTop)
    }
  }

  const pageScrollPaneClass = classNames(style.PageScrollPane, className)

  return (
    <div className={pageScrollPaneClass} onScroll={onScroll} ref={ref}>
      {children}
    </div>
  )
}

PageScrollPane.propTypes = {
  children: PropTypes.element.isRequired,
  className: PropTypes.string
}

export default PageScrollPane
