import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import {connect} from 'react-redux'

import { addAvailableGroup, updateLocation, saveCurrentQuery } from 'state/actions'

import style from './QuerySaver.styl'

export class QuerySaver extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      name: '',
      submitting: false
    }

    this._onNameChange = this._onNameChange.bind(this)
    this._onFormSubmit = this._onFormSubmit.bind(this)
  }

  _onNameChange (e) {
    this.setState({
      name: e.target.value
    })
  }

  _onFormSubmit (e) {
    e.preventDefault()

    if (this.state.submitting) {
      return
    }

    this.setState({
      submitting: true
    })

    this.props.onSave(this.state.name, this.props.currentQuery, this.props.activeResourceGroupId)
      .then(savedQuery => {
        this.setState({
          name: '',
          submitting: false
        })

        this.props.onSaveSuccess(savedQuery.body)
      }, () => {
        this.setState({
          submitting: false
        })
      })
  }

  render () {
    return (
      <div className={classNames(style.QuerySaver, this.props.className)}>
        <form onSubmit={this._onFormSubmit}>
          <input
            value={this.state.name}
            onChange={this._onNameChange}
            type='text'
            placeholder='Resource group name...'
            disabled={this.state.submitting} />
        </form>
      </div>
    )
  }
}

QuerySaver.propTypes = {
  className: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  onSaveSuccess: PropTypes.func.isRequired,
  activeResourceGroupId: PropTypes.string
}

function mapStateToProps (state) {
  return {
    activeResourceGroupId: state.activeResourceGroupId,
    currentQuery: state.currentQuery
  }
}

function mapDispatchToProps (dispatch, ownProps) {
  return {
    onSave: (queryName, currentQuery, activeResourceGroupId) => {
      return saveCurrentQuery(queryName, currentQuery, activeResourceGroupId, dispatch)
    },
    onSaveSuccess: (resourceGroup) => {
      dispatch(addAvailableGroup(resourceGroup))
      dispatch(updateLocation({query: {activeResourceGroupId: resourceGroup.id, query: undefined}}))

      if (ownProps.onSave) {
        ownProps.onSave()
      }
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuerySaver)
