import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import style from './Button.styl'

export const Button = ({
  children,
  className,
  type = Button.SECONDARY,
  flat = false,
  submit = false,
  disabled = false,
  onClick = () => {},
  onMouseDown = () => {}
}) => (
  <button
    className={classNames(className, style.Button, type, {
      [style.flat]: flat,
      [style.raised]: !flat
    })}
    type={submit ? 'submit' : 'button'}
    onClick={onClick}
    onMouseDown={onMouseDown}
    disabled={disabled}>
    {children}
  </button>
)

Button.PRIMARY = style.primary
Button.SECONDARY = style.secondary
Button.WARN = style.warn

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  type: PropTypes.string,
  flat: PropTypes.bool,
  submit: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  onMouseDown: PropTypes.func
}

export default Button
