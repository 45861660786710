import { OrderedMap, fromJS } from 'immutable'

export function indexByUniqueKey (collection, key) {
  return collection.reduce((acc, element) => {
    const elementKey = element[key]
    return {...acc, [elementKey]: element}
  }, {})
}

export function indexByNonUniqueKey (collection, key) {
  return collection.reduce((acc, element) => {
    const elementKey = element[key]
    const elementList = acc[elementKey] || []
    elementList.push(element)
    return { ...acc, [elementKey]: elementList }
  }, {})
}

export function indexByUniqueKeyOrderImmutable (collection, key) {
  return collection.reduce((acc, element) => {
    const elementKey = element[key]
    return acc.set(elementKey, fromJS(element))
  }, OrderedMap())
}
