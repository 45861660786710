import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'

import Search from 'components/Search'
import Card, { CardBody, CardHeader } from 'components/Card'
import ModalDialog from 'components/ModalDialog'
import NotificationsList from './components/NotificationsList'
import NotificationViewer from './components/NotificationViewer'
import Pagination from 'components/Pagination'

import {
  fetchNotifications,
  updateLocation,
  getNotification,
  toggleNotificationRead,
  notifyUser
} from 'state/actions'

import style from './Notifications.styl'

export class Notifications extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      pageSize: 200,
      currentPage: 1,
      showNotificationDetails: null,
      notificationQuery: ''
    }
  }

  componentWillMount () {
    const queryParam = this.props.location.query['notificationQuery']

    if (queryParam) {
      this.props.fetchNotifications(queryParam)

      const paramArray = Array.from(new window.URLSearchParams(decodeURIComponent(queryParam)))
      const queryString = paramArray.map(param => {
        if (param[0] === 'q') {
          return `${param[1]}`
        }
      }).filter(Boolean).join()
      this.setState({notificationQuery: queryString})
    } else {
      this.props.fetchNotifications({page_size: this.state.pageSize})
    }

    if (this.props.route.path === '/notifications/:id') {
      this.onShowNotificationDetails(this.props.params.id)
    }
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.route.path === '/notifications/:id') {
      if (this.props.route.path !== '/notifications/:id' || (nextProps.params.id !== this.props.params.id)) {
        this.onShowNotificationDetails(nextProps.params.id)
      }
    }

    if (this.props.notifications.size && this.state.showNotificationDetails) {
      if (!this.props.notifications.get(this.state.showNotificationDetails)) {
        this.props.getNotification(this.state.showNotificationDetails)
      }
    }
  }

  onShowNotificationDetails (notificationId) {
    if (this.props.notifications.size && !this.props.notifications.get(notificationId)) {
      this.props.getNotification(notificationId)
    }
    this.props.updateNotificationsPath('/' + notificationId)
    this.setState({ showNotificationDetails: notificationId })
  }

  onModalClose = () => {
    this.setState({showNotificationDetails: null})
    this.props.updateNotificationsPath('')
  }

  _paginate = page => {
    this.props.fetchNotifications({page_size: this.state.pageSize, page: page})
    this.setState({ currentPage: page })
  }

  _searchNotifications = searchString => {
    let params = { q: searchString }
    this.props.fetchNotifications(params, false)
  }

  render () {
    const notificationNumber = this.props.notifications
      ? ` (Unread: ${this.props.unreadNotificationCount} - Total: ${this.props.notificationCount} - Showing: ${this.props.notifications.size})`
      : ''

    const notificationModal = this.state.showNotificationDetails ? (
      <ModalDialog
        onDismiss={() => this.onModalClose()}
        title='Notification Overview'>
        <NotificationViewer
          toggleNotificationRead={this.props.toggleNotificationRead}
          notification={this.props.notifications.get(this.state.showNotificationDetails) || Map()} />
      </ModalDialog>
    ) : null

    const pageCount = Math.ceil(this.props.notificationCount / this.state.pageSize)

    return (
      <div className={style.content}>
        <Card>
          <CardHeader title={`Notifications${notificationNumber}`} />
          <Search
            searchText={this.state.notificationQuery}
            onSearch={this._searchNotifications} />
          <CardBody>
            <NotificationsList
              fetchNotifications={this.props.fetchNotifications}
              notifications={this.props.notifications}
              viewNotification={this.onShowNotificationDetails.bind(this)}
              toggleNotificationRead={this.props.toggleNotificationRead}
              notificationCount={this.props.notificationCount} />
            <Pagination pageCount={pageCount} currentPage={this.state.currentPage} handleChange={this._paginate} />
          </CardBody>
        </Card>
        {notificationModal}
      </div>
    )
  }
}

Notifications.propTypes = {
  notifications: ImmutablePropTypes.orderedMap.isRequired,
  notificationCount: PropTypes.number.isRequired,
  unreadNotificationCount: PropTypes.number.isRequired,
  fetchNotifications: PropTypes.func.isRequired,
  updateNotificationsPath: PropTypes.func.isRequired,
  getNotification: PropTypes.func.isRequired,
  toggleNotificationRead: PropTypes.func.isRequired
}

function mapStateToProps (state) {
  return {
    notifications: state.notifications,
    notificationCount: state.notificationCount,
    unreadNotificationCount: state.unreadNotificationCount
  }
}

function mapDispatchToProps (dispatch) {
  return {
    fetchNotifications: (params, updateCount) => dispatch(fetchNotifications(params, updateCount)),
    updateNotificationsPath: pathName => dispatch(updateLocation({ pathname: '/notifications' + pathName })),
    getNotification: (notificationId) => dispatch(getNotification(notificationId)),
    toggleNotificationRead: (notification, read) => dispatch(toggleNotificationRead(notification, read)),
    notifyUserError: (message) => dispatch(notifyUser(message, 'error'))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Notifications)
