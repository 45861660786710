const generateGroupTree = (policies, groups) => {
  const policiesByResourceGroupId = {}
  const rootNodes = []

  policies.forEach(policy => {
    const resourceGroupId = policy.resource_group_id
    const resourceGroup = groups[resourceGroupId]

    if (resourceGroup) {
      policy.resource_group = resourceGroup
      policiesByResourceGroupId[resourceGroupId] = policy
    }
  })

  policies.forEach(policy => {
    if (!policy.resource_group) {
      return
    }

    const parentPolicy = policiesByResourceGroupId[policy.resource_group.parent && policy.resource_group.parent.id]

    if (parentPolicy) {
      if (parentPolicy.children) {
        parentPolicy.children.push(policy)
      } else {
        parentPolicy.children = [policy]
      }
    } else {
      rootNodes.push(policy)
    }
  })

  return rootNodes
}

export {
  generateGroupTree
}
