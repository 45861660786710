import PropTypes from 'prop-types'
import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'

import style from './UriMetric.styl'

export const UriMetric = ({ value }) => (
  <a href={value.get('uri')} target='_blank' onClick={(e) => e.stopPropagation()} className={style.UriMetric}>
    {value.get('text')}
  </a>
)

UriMetric.propTypes = {
  value: ImmutablePropTypes.mapContains({
    text: PropTypes.string.isRequired,
    uri: PropTypes.string.isRequired
  }).isRequired
}

export default UriMetric
