import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import FontAwesome from 'react-fontawesome'
import { connect } from 'react-redux'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { formatPattern } from 'react-router'
import { List } from 'immutable'

import urljoin from 'url-join'

import config from 'config'

import Resources from 'scenes/Resources'

import Badge from 'components/Badge'
import Button from 'components/Button'
import Dropdown from 'components/Dropdown'
import FeatureTours from 'components/FeatureTours'
import tours from 'components/FeatureTours/services/tours'
import Notifications from 'components/Notifications'
import ProgressBar from 'components/ProgressBar'
import { Toolbar, ToolbarSection } from 'components/Toolbar'

import ConfirmationModal from './components/ConfirmationModal'
import HelpDropdown from './components/HelpDropdown'
import NotificationDropdown from './components/NotificationDropdown'
import OrganizationsList from './components/OrganizationsList'
import PageScrollPane from './components/PageScrollPane'
import Profile from './components/Profile'
import QueryBuilder from './components/QueryBuilder'
import SolutionContextsList from './components/SolutionContextsList'

import {
  SOLUTION_CONTEXT_SELECTOR,
  HELP_BUTTON,
  NOTIFICATION_BUTTON,
  MANAGE_BUTTON,
  ORGANIZATIONS_BUTTON
} from 'refs'

import {
  setOrganization,
  setQuery,
  setQueryDependencies,
  updateLocation,
  fetchCurrentUser,
  fetchAvailableSolutionContexts,
  fetchResourceGroups,
  setSolutionContext,
  updateSolutionContext,
  pollUnreadNotifications,
  setActiveResourceGroup,
  updateActiveResourceGroup,
  notifyUser,
  READONLY_POLICY
} from 'state/actions'
import { DEFAULT_SOLUTION_CONTEXT_ID } from 'state/constants'
import { signOut } from 'state/session/actions'
import { getRoleForCurrentOrganization } from 'state/selectors'

import logo from './logo.svg'
import style from './Root.styl'
import globalStyle from '../../style.styl'

const SOLUTION_CONTEXT_DROPDOWN = 'solutionContext'
const HELP_DROPDOWN = 'help'
const NOTIFICATIONS_DROPDOWN = 'notifications'
const PROFILE_DROPDOWN = 'profile'
const ORGANIZATIONS_DROPDOWN = 'organizations'

const TRANSITION_DURATION = 100
const TARNSITION_DELAY = 50

export class Root extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      solutionContextBuilderActive: false,
      activeDropdown: null,
      dropdownAnchor: null,
      contentLeaving: false,
      resourcesPathname: '/',
      upPathname: '/'
    }

    this.disabledDropdown = null
  }

  componentWillReceiveProps (nextProps) {
    const nextLocationQuery = nextProps.location.query
    const nextLocationOrganizationId = nextLocationQuery.organizationId
    const nextOrganizationId = nextProps.currentOrganization
    const currentOrganizationId = this.props.currentOrganization
    const organizationChanged = currentOrganizationId !== nextOrganizationId
    const organizationLocationChanged = nextLocationOrganizationId !== nextOrganizationId
    const nextLocationGroup = nextLocationQuery.activeResourceGroupId
    const nextGroup = nextProps.activeResourceGroupId
    this.updateQueryDependency(
      organizationLocationChanged,
      organizationChanged,
      nextLocationGroup,
      nextGroup,
      this.props.updateActiveResourceGroup
    )

    const nextLocationSolutionContextId = nextLocationQuery.solutionContextId
    const nextSolutionContextId = nextProps.currentSolutionContextId
    this.updateQueryDependency(
      organizationLocationChanged,
      organizationChanged,
      nextLocationSolutionContextId,
      nextSolutionContextId,
      this.props.updateSolutionContext
    )

    if (organizationLocationChanged) {
      // If navigating to a new org using query parameters, do not redirect to home page
      nextLocationOrganizationId
        ? this.props.setOrganization(nextLocationOrganizationId, { redirectToHome: false })
        : this.props.setOrganization(nextOrganizationId)
    }

    const nextLocationQueryQuery = nextLocationQuery.query
    const nextQuery = nextProps.query
    this.updateQueryDependency(
      organizationLocationChanged,
      organizationChanged,
      nextLocationQueryQuery,
      nextQuery,
      this.props.setQuery
    )

    const upPath = formatPattern(
      urljoin(
        ...nextProps.routes
          .map(route => route.path)
          .filter(route => route !== undefined)
          .slice(0, -1)
      ),
      nextProps.params
    )

    this.setState({ upPathname: upPath })

    if (this._isResourcesComponent(nextProps.children)) {
      this.setState({ resourcesPathname: nextProps.location.pathname })
    }

    const groups = nextProps.groups
    const currentGroup = this.props.activeResourceGroupId
    if (groups && groups.size > 0) {
      if (nextGroup && !groups.find(group => group.get('id') === nextGroup)) {
        const parentGroup = groups.find(group => group.parent === null)
        const parentGroupId = parentGroup ? parentGroup.id : null
        this.props.updateActiveResourceGroup(parentGroupId)
      } else if (nextLocationGroup !== currentGroup && groups.find(group => group.get('id') === nextLocationGroup)) {
        this.props.updateActiveResourceGroup(nextLocationGroup)
      }
    }

    const solutionContexts = nextProps.solutionContexts
    const currentSolutionContextId = this.props.currentSolutionContextId
    if (solutionContexts && solutionContexts.size > 2) {
      if (!nextSolutionContextId || !solutionContexts.get(nextSolutionContextId)) {
        this.props.updateSolutionContext(DEFAULT_SOLUTION_CONTEXT_ID)
      } else if (nextLocationSolutionContextId !== currentSolutionContextId && solutionContexts.get(nextLocationSolutionContextId)) {
        this.props.updateSolutionContext(nextLocationSolutionContextId)
      }
    }

    if (this.props.children.type.displayName !== nextProps.children.type.displayName) {
      this.setState({ contentLeaving: true })
      setTimeout(() => {
        this.setState({ contentLeaving: false })
      }, TRANSITION_DURATION + TARNSITION_DELAY)
    }
  }

  updateQueryDependency (organizationLocationChanged, organizationChanged, nextLocation, next, update) {
    if (!organizationLocationChanged && nextLocation && nextLocation !== next) {
      !organizationChanged && update(nextLocation)
    } else if (!organizationLocationChanged && !nextLocation && next) {
      update(next)
    }
  }

  _currentOrganization () {
    return this.props.organizations.find(org => org.get('id') === this.props.currentOrganization, undefined, List())
  }

  componentWillMount () {
    if (this.props.organizations.size === 0) {
      this.props.onUnauthorized()
      return null
    }

    if (!this._currentOrganization()) {
      this.props.onSelectOrganization()
      return null
    }

    if (this.props.onInitialize) {
      this.props.onInitialize()
    }
  }

  _isResourcesComponent (children) {
    return React.Children.toArray(children).some((child) => {
      return child.type === Resources
    })
  }

  _toggleSolutionContextBuilder () {
    this.setState({
      solutionContextBuilderActive: !this.state.solutionContextBuilderActive
    })
  }

  render () {
    const notifications = this.props.notifs && this.props.notifs.length > 0
      ? <Notifications />
      : null

    if (this.props.organizations.size === 0) {
      return null
    }

    const content = !this.state.contentLeaving ? (
      <div className={style.content} key={this.props.children.type.displayName}>
        {this.props.children}
      </div>
    ) : null


    const contentTransitionGroup = content ? (
      <TransitionGroup>
        <CSSTransition
          classNames={{
            enter: style.enter,
            enterActive: style.enterActive,
            exit: style.exit,
            exitActive: style.exitActive
          }}
          timeout={{ enter: TRANSITION_DURATION, exit: TRANSITION_DURATION }}>
          {content}
        </CSSTransition>
      </TransitionGroup>
    ) : null

    const contentScrollPane = contentTransitionGroup ? (
      <PageScrollPane className={style.pageScrollPane}>
        {contentTransitionGroup}
      </PageScrollPane>
    ) : null

    return (
      <div className={classNames('inverted', style.Root, {
        [globalStyle.disableTransitions]: !this.props.enableTransitions
      })}>
        {notifications}
        <header>
          <ProgressBar className={style.progress} />
          {this._renderHeader()}
        </header>
        <main>
          <ConfirmationModal />
          {contentScrollPane}
        </main>
        <FeatureTours
          tour={this.props.location.query.tour}
          onStepChange={(tour, step) => {
            if (this.props.onFeatureTourStepChange) {
              this.props.onFeatureTourStepChange(tour, step)
            }
          }}
          onEnd={() => {
            if (this.props.onFeatureTourEnd) {
              this.props.onFeatureTourEnd()
            }
          }}
          onError={() => {
            if (this.props.onFeatureTourError) {
              this.props.onFeatureTourError()
            }
          }} />
      </div>
    )
  }

  onQuery (query) {
    this.props.onQuery()
    this.props.setQuery(query)
  }

  _renderHeader () {
    const currentSolutionContext = this.props.solutionContexts && this.props.solutionContexts.get(this.props.currentSolutionContextId)

    const navigationButton = this._isResourcesComponent(this.props.children) ? (
      <a onClick={this.props.navigateToHome}>
        <img
          className={style.logo}
          src={logo} />
      </a>
    ) : (
      <Button
        onClick={() => {
          if (this.state.upPathname === '/') {
            this.props.navigateUp(this.state.resourcesPathname)
          } else {
            this.props.navigateUp(this.state.upPathname)
          }
        }}
        className={style.arrow}
        flat>
        ◀
      </Button>
    )

    const notificationCount = this.props.unreadNotificationCount
      ? this.props.unreadNotificationCount > 99
        ? '99+'
        : `${this.props.unreadNotificationCount}`
      : null

    const dropdown = this.state.activeDropdown ? (
      <Dropdown
        anchor={this.state.dropdownAnchor}
        onDismiss={() => { this._dismissDropdown() }}>
        {this._renderDropdownContent(this.state.activeDropdown)}
      </Dropdown>
    ) : null

    const currentOrganization = this._currentOrganization()

    const permissions = currentOrganization.get('role') === READONLY_POLICY ? (
      <div>{'(Read Only)'}</div>
    ) : null

    const organizationButton = (
      <div className={style.organizationButton}>
        {currentOrganization.get('name')}
        {permissions}
      </div>
    )

    return (
      <div>
        <Toolbar className={style.header}>
          <ToolbarSection className={style.navigation}>
            <div className={style.navigationContainer}>
              {navigationButton}
            </div>
            <div className={style.tooltipContainer}>
              <a
                data-ref={SOLUTION_CONTEXT_SELECTOR}
                className={classNames(style.button, style.solutionContext)}
                {...this._getDropdownButtonProps(SOLUTION_CONTEXT_DROPDOWN)}>
                <span className={style.label}>{currentSolutionContext ? currentSolutionContext.get('label') : ''}</span>
                <span className={style.icon}><FontAwesome name='chevron-down' /></span>
              </a>
              <div className={style.tooltip}>
                Solution Contexts
              </div>
            </div>
            <QueryBuilder
              className={style.queryBuilder}
              onQuery={this.onQuery.bind(this)}
              organizationRole={this.props.organizationRole} />
            <div data-ref={NOTIFICATION_BUTTON}>
              <Badge content={notificationCount}>
                <Button
                  className={style.notificationsButton}
                  {...this._getDropdownButtonProps(NOTIFICATIONS_DROPDOWN)}
                  flat>
                  <FontAwesome name='bell-o' />
                  <div className={style.tooltip}>
                    Notifications
                  </div>
                </Button>
              </Badge>
            </div>
            <div data-ref={MANAGE_BUTTON}>
              <Button
                className={style.manageButton}
                onClick={() => this.props.onManage()}
                flat>
                <FontAwesome name='cog' />
                <div className={style.tooltip}>
                  Manage
                </div>
              </Button>
            </div>
            <div data-ref={HELP_BUTTON}>
              <Button
                className={style.helpButton}
                {...this._getDropdownButtonProps(HELP_DROPDOWN)}
                flat>
                <FontAwesome name='question' />
                <div className={style.tooltip}>
                  Help
                </div>
              </Button>
            </div>
          </ToolbarSection>
          <ToolbarSection className={style.user}>
            <div data-ref={ORGANIZATIONS_BUTTON} className={style.tooltipContainer}
              onMouseEnter={() => this.props.refreshOrganizations()}>
              <Button
                className={classNames(style.button, style.organizations)}
                flat
                {...this._getDropdownButtonProps(ORGANIZATIONS_DROPDOWN)}>
                {organizationButton}
              </Button>
              <div className={style.tooltip}>
                Organizations
              </div>
            </div>
            <Button
              className={style.profileButton}
              {...this._getDropdownButtonProps(PROFILE_DROPDOWN)}>
              <img src={this.props.profilePhoto} />
            </Button>
          </ToolbarSection>
        </Toolbar>
        {dropdown}
      </div>
    )
  }

  _toggleDropdown (dropdown, buttonElement) {
    if (this.disabledDropdown !== dropdown) {
      const buttonDimensions = buttonElement.getBoundingClientRect()

      const dropdownPosition = {
        x: buttonDimensions.left + buttonDimensions.width / 2,
        y: buttonDimensions.bottom + 8
      }

      this.setState({
        activeDropdown: this.state.activeDropdown === dropdown ? null : dropdown,
        dropdownAnchor: dropdownPosition
      })
    }

    this.disabledDropdown = null
  }

  _renderDropdownContent (dropdown) {
    switch (dropdown) {
      case SOLUTION_CONTEXT_DROPDOWN:
        return (
          <SolutionContextsList
            className={style.dropdownContent}
            solutionContexts={this.props.solutionContexts}
            onSelectSolutionContext={solutionContextId => this._onSelectSolutionContext(solutionContextId)}
            currentSolutionContext={this.props.currentSolutionContextId} />
        )
      case HELP_DROPDOWN:
        return (
          <HelpDropdown
            className={style.dropdownContent}
            tours={Object.keys(tours)}
            onStartFeatureTour={tour => this._onStartFeatureTour(tour)}
            onAPIDocumentation={this.props.onAPIDocumentation}
            onOpenDocumentation={() => this._onOpenDocumentation()} />
        )
      case NOTIFICATIONS_DROPDOWN:
        return (
          <NotificationDropdown
            className={style.dropdownContent}
            notifications={this.props.unreadNotifications}
            onSelectNotifications={() => this._onSelectNotifications()}
            onSelectNotification={notificationId => this._onSelectNotification(notificationId)} />
        )
      case PROFILE_DROPDOWN:
        return (
          <Profile signOut={() => this._onSignOut()} />
        )
      case ORGANIZATIONS_DROPDOWN:
        return (
          <OrganizationsList
            className={style.dropdownContent}
            organizations={this.props.organizations}
            setOrganization={organizationId => this._onSetOrganization(organizationId)}
            currentOrganization={this.props.currentOrganization} />
        )
    }

    return null
  }

  _getDropdownButtonProps (dropdown) {
    return {
      onClick: event => { this._toggleDropdown(dropdown, event.currentTarget) },
      onMouseDown: () => {
        this.disabledDropdown = this.state.activeDropdown === dropdown ? dropdown : null
      }
    }
  }

  _dismissDropdown () {
    this.setState({ activeDropdown: null })
  }

  _onSelectSolutionContext (solutionContextId) {
    if (this.props.currentSolutionContextId !== solutionContextId) {
      this._dismissDropdown()
      this.props.setSolutionContext(solutionContextId)
    }
  }

  _onStartFeatureTour (tour) {
    this._dismissDropdown()
    this.props.onStartFeatureTour(tour)
  }

  _onOpenDocumentation () {
    this._dismissDropdown()
    this.props.onOpenDocumentation()
  }

  _onSelectNotifications () {
    this._dismissDropdown()
    this.props.onSelectNotifications()
  }

  _onSelectNotification (notificationId) {
    this._dismissDropdown()
    this.props.onSelectNotification(notificationId)
  }

  _onSetOrganization (organizationId) {
    if (organizationId !== this.props.currentOrganization) {
      this._dismissDropdown()
      this.props.updateSolutionContext(DEFAULT_SOLUTION_CONTEXT_ID)
      this.props.updateActiveResourceGroup(null)
      this.props.setOrganization(organizationId)
    }
  }

  _onSignOut () {
    this._dismissDropdown()
    this.props.signOut()
  }
}

Root.propTypes = {
  children: PropTypes.element.isRequired,
  unreadNotifications: ImmutablePropTypes.map,
  unreadNotificationCount: PropTypes.number,
  onSelectNotifications: PropTypes.func,
  onSelectNotification: PropTypes.func,
  profilePhoto: PropTypes.string,
  setQuery: PropTypes.func, // Set query action
  onQuery: PropTypes.func, // Update location with new query
  onInitialize: PropTypes.func,
  solutionContexts: ImmutablePropTypes.mapOf(
    ImmutablePropTypes.contains({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ),
  currentSolutionContextId: PropTypes.string,
  organizations: ImmutablePropTypes.list.isRequired,
  currentOrganization: PropTypes.string,
  signOut: PropTypes.func,
  onFeatureTourStepChange: PropTypes.func,
  onFeatureTourEnd: PropTypes.func,
  onFeatureTourError: PropTypes.func,
  onUnauthorized: PropTypes.func,
  onStartFeatureTour: PropTypes.func,
  onOpenDocumentation: PropTypes.func,
  onSelectOrganization: PropTypes.func,
  setActiveResourceGroup: PropTypes.func.isRequired,
  navigateToHome: PropTypes.func,
  navigateUp: PropTypes.func,
  organizationRole: PropTypes.number.isRequired
}

function mapStateToProps (state, ownProps) {
  return {
    activeResourceGroupId: state.activeResourceGroupId,
    notifs: state.notifs,
    children: ownProps.children,
    unreadNotifications: state.unreadNotifications,
    unreadNotificationCount: state.unreadNotificationCount,
    profilePhoto: state.auth && state.auth.profile.picture,
    solutionContexts: state.availableSolutionContexts,
    groups: state.availableGroups,
    currentSolutionContextId: state.currentSolutionContextId,
    organizations: state.availableOrganizations,
    currentOrganization: state.currentOrganization,
    enableTransitions: state.enableTransitions,
    organizationRole: getRoleForCurrentOrganization(state),
    query: state.currentQuery
  }
}

function mapDispatchToProps (dispatch, ownProps) {
  return {
    setQuery: (query) => {
      dispatch(setQuery(query))
    },
    signOut: () => {
      dispatch(signOut())
    },
    setSolutionContext: solutionContextId => dispatch(setSolutionContext(solutionContextId)),
    updateSolutionContext: solutionContextId => dispatch(updateSolutionContext(solutionContextId)),
    setOrganization: (...args) => dispatch(setOrganization(...args)),
    onInitialize: () => {
      dispatch(pollUnreadNotifications())
      dispatch(fetchAvailableSolutionContexts())
      dispatch(fetchResourceGroups())
      dispatch(setQueryDependencies(
        ownProps.location.query.activeResourceGroupId,
        ownProps.location.query.query,
        ownProps.location.query.solutionContextId,
        ownProps.location.query.organizationId
      ))
    },
    onStartFeatureTour: (tour) => {
      dispatch(updateLocation({ query: { tour: tour } }))
    },
    refreshOrganizations: () => {
      dispatch(fetchCurrentUser())
    },
    onOpenDocumentation: () => {
      window.open(config.docsUrl)
    },
    onSelectNotifications: () => {
      dispatch(updateLocation({ pathname: '/notifications' }))
    },
    onSelectNotification: (notificationId) => {
      dispatch(updateLocation({ pathname: `/notifications/${notificationId}` }))
    },
    onManage: () => {
      dispatch(updateLocation({ pathname: '/manage' }))
    },
    onAPIDocumentation: () => {
      dispatch(updateLocation({ pathname: '/api_docs' }))
    },
    onFeatureTourStepChange: (tour, step) => {
      if (step.route) {
        dispatch(updateLocation({ pathname: step.route, query: { tour } }))
      }
    },
    onFeatureTourEnd: () => {
      dispatch(updateLocation({ query: {
        ...ownProps.location.query,
        tour: undefined
      }}))
    },
    onFeatureTourError: () => {
      dispatch(notifyUser(`Error running feature tour`, 'error'))
      dispatch(updateLocation({ query: {
        ...ownProps.location.query,
        tour: undefined
      }}))
    },
    onUnauthorized: () => {
      dispatch(updateLocation({ pathname: '/unauthorized' }))
    },
    onSelectOrganization: () => {
      dispatch(updateLocation({ pathname: '/select_organization' }))
    },
    setActiveResourceGroup: resourceGroup => dispatch(setActiveResourceGroup(resourceGroup)),
    updateActiveResourceGroup: resourceGroup => dispatch(updateActiveResourceGroup(resourceGroup)),
    navigateToHome: () => {
      dispatch(updateLocation({ pathname: '/resource_groups' }))
    },
    onQuery: () => {
      dispatch(updateLocation({ pathname: '/resources' }))
    },
    navigateUp: (pathname) => {
      dispatch(updateLocation({ pathname: pathname }))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Root)
