import React from 'react'
import { connect } from 'react-redux'

import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import CopyToClipboard from 'react-copy-to-clipboard'
import FontAwesome from 'react-fontawesome'
import classNames from 'classnames'
import { Map } from 'immutable'

import Button from 'components/Button'
import Hint from 'components/Hint'

import style from './ExternalNotificationEndpointEditor.styl'
import { notifyUser } from 'state/actions'

import ConfigurationFormAwsSqs from '../ConfigurationForms/ConfigurationFormAwsSqs'
import ConfigurationFormAwsSns from '../ConfigurationForms/ConfigurationFormAwsSns'
import CredentialsFormAws from '../CredentialsForms/CredentialsFormAws'

export class ExternalNotificationEndpointEditor extends React.Component {
  constructor () {
    super()

    this._onSubmit = this._onSubmit.bind(this)
    this._setValue = this._setValue.bind(this)
    this._onConfigurationChange = this._onConfigurationChange.bind(this)
    this._onCredentialsChange = this._onCredentialsChange.bind(this)

    this.state = {
      type: '',
      name: '',
      editCredentials: false
    }

    this.configuration = {}
    this.credentials = {}
  }

  componentWillMount () {
    this.setState({
      type: this.props.endpoint.get('endpoint_type', ''),
      name: this.props.endpoint.get('name', '')
    })

    this.configuration = this.props.endpoint.get('configuration', Map()).toJS()
  }

  _onToggleEditCredentials () {
    this.setState({editCredentials: !this.state.editCredentials})
  }

  _setValue (key, value) {
    let state = {}
    state[key] = value.target.value
    this.setState(state)
  }

  _onConfigurationChange (configuration) {
    this.configuration = configuration
  }

  _onCredentialsChange (credentials) {
    this.credentials = credentials
  }

  _onSubmit (e) {
    e.preventDefault()

    const fields = {
      name: this.state.name,
      configuration: this.configuration
    }

    if (this.state.editCredentials) {
      fields['credentials'] = this.credentials
    }

    this.props.onUpdate(this.props.endpoint.get('id'), fields)
      .then(() => this.props.onClose())
  }

  getConfigurationComponent () {
    switch (this.state.type) {
      case 'aws-sqs':
        return (
          <ConfigurationFormAwsSqs
            onConfigurationChange={this._onConfigurationChange}
            initialConfiguration={this.configuration} />
        )
      case 'aws-sns':
        return (
          <ConfigurationFormAwsSns
            onConfigurationChange={this._onConfigurationChange}
            initialConfiguration={this.configuration} />
        )
    }
  }

  getCredentialsComponent () {
    let templateUrl = ''
    switch (this.state.type) {
      case 'aws-sqs':
        templateUrl = this.props.onReturnTemplateUrl('sqs')
        return (
          <div>
            <div className={style.description}>
              <p>
                Conductor uses IAM Roles for access to your AWS SQS queue.
                This requires that an IAM Role is deployed in the AWS account in which the queue exists.
                This can be deployed manually, or more easily via the following CloudFormation template.
                Instantiate a CloudFormation stack by copying the template URL - then copy the IAM Role ARN from the stack outputs into the form below.
              </p>
              <CopyToClipboard text={templateUrl}
                onCopy={() => {}}>
                <Button
                  className={style.copy} >
                  Copy CloudFormation Template URL
                </Button>
              </CopyToClipboard>
            </div>

            <CredentialsFormAws onCredentialsChange={this._onCredentialsChange} />
          </div>
        )
      case 'aws-sns':
        templateUrl = this.props.onReturnTemplateUrl('sns')
        return (
          <div>
            <div className={style.description}>
              <p>
                Conductor uses IAM Roles for access to your AWS SNS topic.
                This requires that an IAM Role is deployed in the AWS account in which the queue exists.
                This can be deployed manually, or more easily via the following CloudFormation template.
                Instantiate a CloudFormation stack by copying the template URL - then copy the IAM Role ARN from the stack outputs into the form below.
              </p>
              <CopyToClipboard text={templateUrl}
                onCopy={() => {}}>
                <Button
                  className={style.copy} >
                  Copy CloudFormation Template URL
                </Button>
              </CopyToClipboard>
            </div>

            <CredentialsFormAws onCredentialsChange={this._onCredentialsChange} />
          </div>
        )
    }
  }

  render () {
    return (
      <div className={style.contents}>
        <form onSubmit={this._onSubmit}>
          <div>
            <div className={style.formField}>
              <label>
                Endpoint Name
                <Hint className={style.hint}>
                  Enter a unique name for the external notification endpoint.
                </Hint>
              </label>
              <input
                required
                type='text'
                name='name'
                value={this.state.name}
                onChange={this._setValue.bind(this, 'name')} />
            </div>
            {this.getConfigurationComponent()}
            <div>
              <label className={style.checkboxContainer}>
                <div
                  className={classNames(style.checkbox, this.state.editCredentials ? style.active : style.inactive)}
                  onClick={() => this._onToggleEditCredentials()}>
                  <FontAwesome name={this.state.editCredentials ? 'check-square' : 'square'} />
                </div>
                Edit credentials
                <Hint className={style.hint}>
                  Choose whether you'd like to edit credentials.
                </Hint>
              </label>
            </div>
            {this.state.editCredentials && this.getCredentialsComponent()}
            <div className={style.controls}>
              <Button onClick={() => this.props.onClose()} flat>Cancel</Button>
              <Button type={Button.PRIMARY} submit>Save</Button>
            </div>
          </div>
        </form>
      </div>
    )
  }
}

ExternalNotificationEndpointEditor.propTypes = {
  onReturnTemplateUrl: PropTypes.func.isRequired,
  endpoint: ImmutablePropTypes.map,
  onUpdate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired
}

function mapDispatchToProps (dispatch) {
  return {
    onError: error => dispatch(notifyUser(error, 'error'))
  }
}

export default connect(
  null, mapDispatchToProps
)(ExternalNotificationEndpointEditor)
