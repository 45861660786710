import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'

import style from './NotificationDropdown.styl'

const MAX_NOTIFICATIONS = 5

export class NotificationDropdown extends React.Component {
  generateNotifications = notifications => (
    notifications.slice(0, MAX_NOTIFICATIONS).map((notification, index) => (
      <li key={index} onClick={() => this.viewNotification(notification.get('id'))}>
        {notification.get('subject')}
      </li>
    ))
  )

  viewNotification = notificationId => {
    this.props.onSelectNotification(notificationId)
  }

  viewNotifications = () => {
    this.props.onSelectNotifications()
  }

  render () {
    const notifications = this.props.notifications

    const notificationElements = notifications
      ? notifications.size
        ? this.generateNotifications(notifications.valueSeq().sortBy(_sortByLastSeen))
        : <li><i>There are no unread notifications.</i></li>
      : <li><i>Loading...</i></li>

    return (
      <div>
        <ul className={classNames(style.notifications, this.props.className)}>
          {notificationElements}
        </ul>
        <div className={style.divider} />
        <div
          className={style.allButton}
          onClick={this.viewNotifications}>
          View all notifications
        </div>
      </div>
    )
  }
}

function _sortByLastSeen (notification) {
  return -new Date(notification.get('updated_at')).getTime()
}

NotificationDropdown.propTypes = {
  className: PropTypes.string,
  onSelectNotifications: PropTypes.func,
  onSelectNotification: PropTypes.func,
  notifications: ImmutablePropTypes.map
}

export default NotificationDropdown
