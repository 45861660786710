import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'

import style from './JsonDisplay.styl'

export default function JsonDisplay ({ object, clickAction, className }) {
  if (!object) return null

  const displayableObject = JSON.stringify(object, null, 2)
  const displayStyles = classNames(style.JsonDisplay, { [style.clickable]: clickAction }, className)
  if (!clickAction) clickAction = () => false

  return (
    <pre className={displayStyles} onClick={clickAction}>
      {displayableObject}
    </pre>
  )
}

JsonDisplay.propTypes = {
  object: PropTypes.oneOfType([
    PropTypes.object.isRequired,
    PropTypes.string.isRequired
  ]),
  clickAction: PropTypes.func,
  className: PropTypes.string
}
