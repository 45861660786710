import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import FontAwesome from 'react-fontawesome'

import style from './Filter.styl'

export class Filter extends React.Component {
  constructor (props) {
    super()

    this.state = {
      filterText: ''
    }
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.close) {
      this.setState({ filterText: '' })
    }
  }

  _filter = () => {
    const filterText = this.state.filterText.trim()
    const filteredData = this.props.data.filter((value, key) =>
      key.toUpperCase().indexOf(filterText.toUpperCase()) !== -1
    )

    this.props.onFilter(filteredData)
  }

  _onFilterChange = filterText => {
    this.setState({ filterText }, () => {
      this._filter()
    })
  }

  _onFilterKeyDown (keyCode) {
    const escapeKeyCode = 27

    if (keyCode === escapeKeyCode) {
      this._onFilterChange('')
    }
  }

  render () {
    return (<div className={classNames(style.Filter, this.props.className)}>
      <FontAwesome name='filter' />
      <input
        onChange={event => { event.cancelable || this._onFilterChange(event.target.value) }}
        onKeyDown={event => {
          this._onFilterKeyDown(event.keyCode)

          if (this.state.filterText !== '') {
            event.stopPropagation()
          }
        }}
        value={this.state.filterText}
        placeholder='Filter...'
        ref={this.props.onRef}
      />
    </div>)
  }
}

Filter.propTypes = {
  className: PropTypes.string,
  data: ImmutablePropTypes.map.isRequired,
  onFilter: PropTypes.func.isRequired,
  onRef: PropTypes.func,
  close: PropTypes.bool
}

export default Filter
