import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { List } from 'immutable'

import { setExpandedCollectionLimit, toggleCollection } from 'state/resources/actions'

import Card from '../../../../components/Card'
import CollectionCard from './components/CollectionCard'
import style from './ResourceList.styl'

export class ResourceList extends React.Component {
  componentDidMount () {
    this.props.onInit()
  }

  _toggleExpanded (collectionId) {
    this.props.onCollectionToggle(collectionId)
  }

  shouldComponentUpdate (nextProps, nextState) {
    return nextProps.compact !== this.props.compact ||
      nextProps.filter !== this.props.filter ||
      nextProps.currentResourceId !== this.props.currentResourceId ||
      !nextProps.collectionSet.equals(this.props.collectionSet) ||
      !nextProps.attributeRankings.equals(this.props.attributeRankings)
  }

  render () {
    const className = classNames(style.collection, { [style.compact]: this.props.compact })
    const filter = this.props.filter !== ''
      ? new RegExp(`(${this.props.filter.replace(/([()])/g, '')})`, 'i')
      : null

    const collections = this.props.collectionSet.get('collections')
      .sort((a, b) => a.get('name').localeCompare(b.get('name')))
    const collectionComponents = collections.reduce((components, collection) => {
      collection = filterCollection(collection, filter)

      if (collection.get('size')) {
        const ranking = this.props.attributeRankings.get(collection.get('id'), List())

        components = components.push(<Card key={collection.get('id')} className={className}>
          <CollectionCard
            collection={collection}
            currentResourceId={this.props.currentResourceId}
            attributeRanking={ranking}
            onResourceSelect={this.props.onResourceSelect}
            onCollectionToggle={() => this._toggleExpanded(collection.get('id'))}
            compact={this.props.compact}
            open={collection.get('expanded')}
          />
        </Card>)
      }

      return components
    }, List())

    return collectionComponents.size > 0
      ? <section className={style.resourceList}>{collectionComponents}</section>
      : <div className={style.empty}>No resources</div>
  }
}

const filterCollection = (collection, filter) => {
  if (!filter) {
    return collection
  }

  const collectionNameMatch = !!collection.get('name').match(filter)

  const resources = collection
    .get('resources')
    .filter(resource => resource.get('name').match(filter))

  if (!collectionNameMatch) {
    collection = collection
      .set('resources', resources)
      .set('size', resources.size)
  }

  return collection
}

ResourceList.propTypes = {
  onInit: PropTypes.func.isRequired,
  currentResourceId: PropTypes.string,
  collectionSet: ImmutablePropTypes.map.isRequired,
  attributeRankings: ImmutablePropTypes.map.isRequired,
  onResourceSelect: PropTypes.func.isRequired,
  filter: PropTypes.string.isRequired,
  compact: PropTypes.bool
}

function mapStateToProps (state) {
  return {
    currentResourceId: state.currentResource.get('id'),
    attributeRankings: state.resourceTypes.map(t => t.get('attribute_ranking')),
    filter: state.resourcesFilter
  }
}

function mapDispatchToProps (dispatch) {
  return {
    onInit: () => dispatch(setExpandedCollectionLimit()),
    onCollectionToggle: (groupId) => dispatch(toggleCollection(groupId))
  }
}

export default connect(
  mapStateToProps, mapDispatchToProps
)(ResourceList)
