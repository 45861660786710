export function traverse (tree, f) {
  return find(tree, (...args) => {
    f(...args)
    return false
  })
}

export function find (tree, f) {
  const nodeQueue = [[null, tree, 0]]

  while (nodeQueue.length > 0) {
    const [ parent, node, index ] = nodeQueue.shift()
    if (f(node, parent, index)) {
      return node
    }

    if (node.children) {
      nodeQueue.splice(
        nodeQueue.length, 0,
        ...node.children.map((child, index) => [node, child, index]))
    }
  }
}
