import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'

import style from './ModalDialog.styl'

import Modal from 'components/Modal'
import { CardBody, CardHeader } from 'components/Card'

export class ModalDialog extends React.Component {
  render () {
    const dismissAction = {onClick: this.props.onDismiss, icon: 'times'}

    return (
      <div className={style.ModalDialog}>
        <Modal
          onDismiss={this.props.onDismiss}
          className={classNames(this.props.className, style.dialogContainer)}
          disableClickClose={this.props.disableClickClose}
        >
          <div className={style.dialog}>
            <CardHeader
              title={this.props.title}
              actions={this.props.actions.concat(dismissAction)} />
            <CardBody>
              {this.props.children}
            </CardBody>
          </div>
        </Modal>
      </div>
    )
  }
}

ModalDialog.defaultProps = {
  actions: []
}

ModalDialog.propTypes = {
  onDismiss: PropTypes.func.isRequired,
  disableClickClose: PropTypes.bool,
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  actions: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
  }))
}

export default ModalDialog
