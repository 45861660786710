import PropTypes from 'prop-types'
import React from 'react'
import FontAwesome from 'react-fontawesome'

import style from './PolicyValidationStatus.styl'

export const PolicyValidationStatus = ({ schemaValidation, propagatedResolution, propagatedValidation, hidden = false }) => {
  const schemaDiv = statusToDiv(
    schemaValidation,
    'Schema is valid',
    'Schema is invalid',
    `Schema is ${schemaValidation}`
  )
  const resolutionDiv = statusToDiv(
    propagatedResolution,
    'Propagated policy is resolvable',
    'Propagated policy is unresolvable',
    `Propagated policy resolution is ${propagatedResolution}`
  )
  const validationDiv = statusToDiv(
    propagatedValidation,
    'Propagated policy is valid',
    'Propagated policy is invalid',
    `Propagated policy validation is ${propagatedValidation}`
  )

  return (<div className={hidden ? style.policyValidationStatusHidden : style.policyValidationStatusVisible}>
    {schemaDiv}
    {schemaValidation === 'successful' && resolutionDiv}
    {schemaValidation === 'successful' && validationDiv}
  </div>)
}

PolicyValidationStatus.propTypes = {
  schemaValidation: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array
  ]),
  propagatedResolution: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array
  ]),
  propagatedValidation: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array
  ]),
  hidden: PropTypes.bool
}

function valid (text) {
  return <div className={style.validPolicy}>
    <FontAwesome name='check-square' /> {text}
  </div>
}

function invalid (text, error) {
  return <div className={style.invalidPolicy}>
    <FontAwesome name='exclamation-triangle' /> {text}
    <div className={style.invalidPolicyDetails}>
      <b>Details:</b>
      <pre>{JSON.stringify(error, null, 2)}</pre>
    </div>
  </div>
}

function pending (text) {
  return <div className={style.pendingPolicy}>
    <FontAwesome name='question-circle' /> {text}
  </div>
}

function statusToDiv (status, validText, invalidText, pendingText) {
  switch (status) {
    case 'successful':
      return valid(validText)
    case 'pending':
    case 'unknown':
      return pending(pendingText)
    default:
      return invalid(invalidText, status)
  }
}

export default PolicyValidationStatus
