import PropTypes from 'prop-types'
import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'

import LoadingIndicator from 'components/LoadingIndicator'

import style from './Relationships.styl'

const Arrow = ({ to = true }) => {
  const id = to ? 'fromArrow' : 'toArrow'
  const orient = to ? 'auto' : 'auto-start-reverse'
  const marker = {[to ? 'markerEnd' : 'markerStart']: `url(#${id})`}
  return (
    <svg width='50px' height='16px' xmlns='http://www.w3.org/2000/svg'>
      <defs>
        <marker id={id} markerWidth='100%' markerHeight='100%' refX='10' refY='4' orient={orient}>
          <polygon points='0,0 12,4 0,8' />
        </marker>
      </defs>
      <line x1='10%' y1='8' x2='90%' y2='8' stroke='#000' strokeWidth='1' {...marker} />
    </svg>
  )
}

function Relationships ({ relatedResources, showResourceDetails, resource }) {
  if (!relatedResources) {
    return <LoadingIndicator />
  }

  if (relatedResources.size === 0) {
    return <div className={style.noRelationships}>No Relationships</div>
  }

  const currentResource = (
    <td className={style.currentResource}>
      {resource.getIn(['blob', 'display_id'])}
      <div className={style.resourceType}>
        {resource.getIn(['blob', 'resource_type'])}
      </div>
    </td>
  )

  const createRelationshipRows = relatedResources => {
    const sortedRelationships = relatedResources.sortBy(
      relatedResource => relatedResource.get('direction')
    )

    return sortedRelationships.map((relationship, index) => {
      const relatedResource = (
        <td className={style.relatedResource} onClick={() => showResourceDetails(relationship.get('id'))}>
          {relationship.get('resource')}
          <div className={style.resourceType}>
            {relationship.get('resource_type')}
          </div>
        </td>
      )
      const fromResource = relationship.get('direction') !== 'from'
      return (
        <tr key={index}>
          {currentResource}
          <td className={style.arrow}><Arrow to={fromResource} /></td>
          {relatedResource}
        </tr>
      )
    })
  }

  return (
    <table className={style.Relationships}>
      <thead>
        <tr>
          <th className={style.currentResource}>This Resource</th>
          <th className={style.arrow} />
          <th className={style.relatedResourceHeader}>Related Resource</th>
        </tr>
      </thead>
      <tbody>
        {createRelationshipRows(relatedResources)}
      </tbody>
    </table>
  )
}

Relationships.propTypes = {
  resource: ImmutablePropTypes.map.isRequired,
  relatedResources: ImmutablePropTypes.list,
  showResourceDetails: PropTypes.func.isRequired
}

const mapStateToProps = (state, ownProps) => ({
  relatedResources: state.relatedResources.get(ownProps.resource.get('id'))
})

export default connect(
  mapStateToProps
)(Relationships)
