import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List } from 'immutable'

import RestrictedCredentialsList from './components/RestrictedCredentialsList'
import RestrictedCredentialsEditor from './components/RestrictedCredentialsEditor'

import { getPossibleRestrictedCredentials } from 'state/credentials/selectors'
import { fetchPolicyTypes } from 'state/actions'
import {
  fetchRestrictedCredentials,
  createOrUpdateRestrictedCredentials,
  deleteRestrictedCredentials
} from 'state/credentials/actions'

export class RestrictedCredentials extends React.Component {
  constructor () {
    super()

    this._onEditCredentials = this._onEditCredentials.bind(this)
    this._onDeleteCredentials = this._onDeleteCredentials.bind(this)
    this._onSaveCredentials = this._onSaveCredentials.bind(this)
    this._onCancel = this._onCancel.bind(this)

    this.state = {
      editing: null
    }
  }

  componentWillMount () {
    this.props.fetchPolicyTypes()
    this.props.fetchRestrictedCredentials()
  }

  _onEditCredentials (credentials) {
    this.setState({editing: credentials})
  }

  _onDeleteCredentials (credentials) {
    this.props.deleteRestrictedCredentials(credentials.get('id')).then(() => {
      this.props.fetchRestrictedCredentials()
    })
  }

  _onSaveCredentials (policyType, credentials) {
    this.props.createOrUpdateRestrictedCredentials(policyType.get('policy_type_id'), credentials).then(() => {
      this.props.fetchRestrictedCredentials()
      this.setState({editing: null})
    })
  }

  _onCancel () {
    this.setState({editing: null})
  }

  render () {
    return (
      <div>
        {
          this.state.editing ? (
            <RestrictedCredentialsEditor
              resourceProvider={this.props.provider}
              credentials={this.state.editing}
              onSave={this._onSaveCredentials.bind(null, this.state.editing)}
              onCancel={this._onCancel} />
          ) : (
            <RestrictedCredentialsList
              resourceProvider={this.props.provider}
              restrictedCredentials={this.props.restrictedCredentials}
              onEditCredentials={this._onEditCredentials}
              onDeleteCredentials={this._onDeleteCredentials}
              organizationRole={this.props.organizationRole} />
          )
        }
      </div>
    )
  }
}

RestrictedCredentials.defaultProps = {
  restrictedCredentials: List()
}

RestrictedCredentials.propTypes = {
  provider: ImmutablePropTypes.map.isRequired,
  restrictedCredentials: ImmutablePropTypes.list.isRequired,
  fetchPolicyTypes: PropTypes.func.isRequired,
  fetchRestrictedCredentials: PropTypes.func.isRequired,
  createOrUpdateRestrictedCredentials: PropTypes.func.isRequired,
  deleteRestrictedCredentials: PropTypes.func.isRequired,
  organizationRole: PropTypes.number.isRequired
}

function mapStateToProps (state, props) {
  return {
    restrictedCredentials: getPossibleRestrictedCredentials(state).get(props.provider.get('id', List()))
  }
}

function mapDispatchToProps (dispatch, props) {
  return {
    fetchPolicyTypes: () => dispatch(fetchPolicyTypes()),
    fetchRestrictedCredentials: () => dispatch(fetchRestrictedCredentials(props.provider.get('id'))),
    createOrUpdateRestrictedCredentials: (policyTypeId, credentials) => dispatch(createOrUpdateRestrictedCredentials(props.provider.get('id'), policyTypeId, credentials)),
    deleteRestrictedCredentials: (credentialsId) => dispatch(deleteRestrictedCredentials(props.provider.get('id'), credentialsId))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RestrictedCredentials)
