import { hierarchy } from 'd3-hierarchy'
import { List, Record } from 'immutable'

import applyHierarchyLayout from './layout'
import renderGroups from './renderGroups'
import renderEdges from './renderEdges'
import renderLabels from './renderLabels'

export const Group = Record({
  id: null,
  name: '',
  count: 1,
  mark: false,
  warn: false,
  secondary: false,
  expanded: false,
  expandedCount: 0, // Number of visible resources in an expanded collection, will stay 0 while api request is pending
  color: null,
  items: List()
})

export const Item = Record({
  id: null,
  name: '',
  mark: false,
  warn: false,
  secondary: false
})

export const Endpoint = Record({
  groupId: null,
  itemId: null
})

export const Edge = Record({
  from: new Endpoint(),
  to: new Endpoint(),
  count: 1
})

export const Model = Record({
  groups: List(),
  edges: List(),
  limit: 100
})

export default function render (model, enableTransitions, parentElements, callbacks, filter, activeItemId) {
  if (model === undefined) {
    model = new Model()
  }

  const { onSelectResource, onToggleGroupExpansion, onSelectGroup } = callbacks

  const rootNode = hierarchy(buildItemTree(model.groups))

  applyHierarchyLayout(rootNode, model.limit)

  if (parentElements.groups) {
    renderGroups(
      rootNode,
      parentElements.groups,
      onToggleGroupExpansion,
      enableTransitions,
      model.limit
    )
  }

  const labelNodes = getLabelNodes(rootNode)

  let currentEdges = null
  if (parentElements.edges) {
    const edgeNodes = buildEdgeNodes(model.edges, labelNodes)
    currentEdges = renderEdges(
      edgeNodes,
      rootNode,
      parentElements.edges,
      enableTransitions
    )
  }

  if (parentElements.labels) {
    renderLabels(
      rootNode,
      parentElements.labels,
      currentEdges,
      labelNode => labelNode.data.type === 'item'
        ? onSelectResource(labelNode.data.source)
        : onSelectGroup(labelNode.data.source),
      enableTransitions,
      filter,
      activeItemId
    )
  }
}

function buildItemTree (groups) {
  return {
    children: (groups || [])
      .map(group => {
        const children = group.expanded && group.items.size > 0
          ? group.items
              .map(item => ({ type: 'item', source: item }))
              .toArray()
          : [{ type: 'group', source: group }]

        return {
          group,
          children
        }
      })
      .toArray()
  }
}

function getLabelNodes (rootNode) {
  return (rootNode.children || []).reduce((labelNodes, groupNode) => {
    const groupId = groupNode.data.group.id

    labelNodes[groupId] = groupNode.children.reduce((labelNodes, labelNode) => {
      const labelId = labelNode.data.source.id
      const labelType = labelNode.data.type

      labelNodes[labelType === 'item' ? labelId : null] = labelNode

      return labelNodes
    }, {})

    return labelNodes
  }, {})
}

function buildEdgeNodes (edges, labelNodes) {
  const duplicates = {}

  return edges.reduce((edgeNodes, edge) => {
    const fromGroupNode = labelNodes[edge.from.groupId]
    const toGroupNode = labelNodes[edge.to.groupId]

    if (!fromGroupNode || !toGroupNode) {
      return edgeNodes
    }

    const sourceLabelNode = fromGroupNode[edge.from.itemId] || fromGroupNode[null]
    const targetLabelNode = toGroupNode[edge.to.itemId] || toGroupNode[null]

    if (!sourceLabelNode || !targetLabelNode) {
      return edgeNodes
    }

    const edgeNodeKey = `${edge.from.groupId}-${edge.from.itemId}-${edge.to.groupId}-${edge.to.itemId}`
    const reverseEdgeNodeKey = `${edge.to.groupId}-${edge.to.itemId}-${edge.from.groupId}-${edge.from.itemId}`

    if (duplicates[edgeNodeKey] || duplicates[reverseEdgeNodeKey]) {
      return edgeNodes
    }

    duplicates[edgeNodeKey] = true

    edgeNodes.push({
      sourceItemId: edge.from.itemId,
      sourceLabelNode,
      targetItemId: edge.to.itemId,
      targetLabelNode,
      count: edge.count
    })

    return edgeNodes
  }, [])
}
