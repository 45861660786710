import PropTypes from 'prop-types'
import React from 'react'
import Measure from 'react-measure'

import style from './Dropdown.styl'

const ARROW_WIDTH = 20
const ARROW_OFFSET = 10

class Dropdown extends React.Component {
  constructor () {
    super()

    this.dropdownClicked = false
  }

  render () {
    return (
      <Measure>
        {bounds => (
          <div className={style.Dropdown}>
            {this._renderDropdown(bounds)}
          </div>
        )}
      </Measure>
    )
  }

  componentDidMount () {
    this.dropdownElement.focus()
  }

  _renderDropdown (bounds) {
    const anchor = this.props.anchor

    return (
      <Measure>
        {dropdownDimensions => {
          const orientation = {
            horizontal: 1,
            vertical: 1
          }

          const anchorOffset = ARROW_OFFSET + ARROW_WIDTH / 2

          if (anchor.x + dropdownDimensions.width - anchorOffset > bounds.width) {
            orientation.horizontal = -1
          }

          if (anchor.y + dropdownDimensions.height > bounds.height) {
            orientation.vertical = -1
          }

          const origin = {
            x: anchor.x - orientation.horizontal * anchorOffset,
            y: anchor.y
          }

          const dropdownStyle = {}

          if (orientation.horizontal === 1) {
            dropdownStyle.left = `${origin.x}px`
          } else {
            dropdownStyle.right = `${bounds.width - origin.x}px`
          }

          if (orientation.vertical === 1) {
            dropdownStyle.top = `${origin.y}px`
          } else {
            dropdownStyle.bottom = `${bounds.height - origin.y}px`
          }

          const arrowLeft = orientation.horizontal === 1
            ? ARROW_OFFSET
            : dropdownDimensions.width - ARROW_WIDTH - ARROW_OFFSET

          const arrowStyle = {
            'left': `${arrowLeft}px`
          }

          return (
            <div
              ref={element => { this.dropdownElement = element }}
              className={style.dropdown}
              style={dropdownStyle}
              tabIndex={-1}
              onMouseDown={() => this._onMouseDown()}
              onBlur={(event) => this._onBlur(event)}
              onKeyDown={event => { this._onKeyDown(event.keyCode) }}>
              <div
                className={style.arrow}
                style={arrowStyle} />
              <div className={style.content}>
                {this.props.children}
              </div>
            </div>
          )
        }}
      </Measure>
    )
  }

  _onMouseDown () {
    this.dropdownClicked = true

    setTimeout(() => {
      this.dropdownClicked = false
    }, 0)
  }

  _onBlur (event) {
    if (!this.dropdownClicked && this.props.onDismiss) {
      this.props.onDismiss()
    }
  }

  _onKeyDown (keyCode) {
    const escapeKeyCode = 27

    if (keyCode === escapeKeyCode && this.props.onDismiss) {
      this.props.onDismiss()
    }
  }
}

Dropdown.propTypes = {
  anchor: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number
  }).isRequired,
  children: PropTypes.node,
  onDismiss: PropTypes.func
}

export default Dropdown
