import React, {Component} from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import SwaggerUi from 'swagger-ui'
import 'swagger-ui/dist/swagger-ui.css'

import { fetchApiDocs } from 'state/actions'

import style from './Docs.styl'
import config from 'config'

class Docs extends Component {
  componentWillMount () {
    this.props.fetchApiDocs()
  }

  docsLoaded = () => {
    if (Object.keys(this.props.docs).length !== 0 && this.props.docs.constructor === Object) {
      return true
    }
    return false
  }

  componentDidMount () {
    if (this.docsLoaded()) {
      SwaggerUi({
        dom_id: '#swaggerContainer',
        spec: this.props.docs,
        url: `${config.apiBaseUrl}docs.json`,
        presets: [SwaggerUI.presets.apis],
        authorizations: {},
        defaultModelRendering: 'model'
      })
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (this.docsLoaded()) {
      SwaggerUi({
        dom_id: '#swaggerContainer',
        spec: this.props.docs,
        url: `${config.apiBaseUrl}docs.json`,
        presets: [SwaggerUi.presets.apis]
      })
    }
  }

  render () {
    return (
      <div className={style.Docs}>
        <div id='swaggerContainer' />
        {!this.docsLoaded() ? <div className={style.loading}>Gathering information about API endpoints...</div> : null}
      </div>
    )
  }
}

Docs.propTypes = {
  fetchApiDocs: PropTypes.func.isRequired,
  docs: PropTypes.object.isRequired
}

function mapStateToProps (state, ownProps) {
  return {
    docs: state.docs
  }
}

function mapDispatchToProps (dispatch, ownProps) {
  return {
    fetchApiDocs: () => dispatch(fetchApiDocs())
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Docs)
