import moment from 'moment'
import apiRequest, { apiRequestPartial } from 'apiRequest'

import objectAssign from 'object-assign'
import { push } from 'react-router-redux'
import { Record } from 'immutable'

import { indexByUniqueKey, indexByNonUniqueKey, indexByUniqueKeyOrderImmutable } from 'services/indexing'
import { DEFAULT_SOLUTION_CONTEXT_ID, NO_SOLUTION_CONTEXT_ID } from 'state/constants'
import { getCurrentQuery, getResourcesVisualizationOptions, getMetricTypesForCurrentSolutionContext } from 'state/selectors'
import {
  resetExpandedCollections,
  fetchCollectionResources,
  ensureCollectionResources,
  selectCoveragePolicyType
} from 'state/resources/actions'

export const START_PROGRESS = 'START_PROGRESS'
export const COMPLETE_PROGRESS = 'COMPLETE_PROGRESS'
export const STORE_RESOURCES = 'STORE_RESOURCES'
export const STORE_METRICS = 'STORE_METRICS'
export const STORE_DATAPOINTS = 'STORE_DATAPOINTS'
export const STORE_RELATIONS = 'STORE_RELATIONS'
export const NOTIFY_ACTIVE_RESOURCE_GROUP_ID_CHANGED = 'NOTIFY_ACTIVE_RESOURCE_GROUP_ID_CHANGED'
export const NOTIFY_ENABLE_TRANSITIONS_CHANGED = 'NOTIFY_ENABLE_TRANSITIONS_CHANGED'
export const ADD_AVAILABLE_GROUP = 'ADD_AVAILABLE_GROUP'
export const UPDATE_AVAILABLE_GROUPS = 'UPDATE_AVAILABLE_GROUPS'
export const UPDATE_GROUPS_COMPLIANCE = 'UPDATE_GROUPS_COMPLIANCE'
export const NOTIFY_QUERY_CHANGED = 'NOTIFY_QUERY_CHANGED'
export const STORE_COLLECTION_SET = 'STORE_COLLECTION_SET'
export const STORE_COLLECTION_SOLUTION_CONTEXT_DATA = 'STORE_COLLECTION_SOLUTION_CONTEXT_DATA'
export const SET_QUERY_COLLECTION_SET = 'SET_QUERY_COLLECTION_SET'
export const UPDATE_CONTRIBUTED_POLICIES = 'UPDATE_CONTRIBUTED_POLICIES'
export const UPDATE_ANCESTRAL_POLICIES = 'UPDATE_ANCESTRAL_POLICIES'
export const UPDATE_NORMALIZED_RESOURCE_GROUPS = 'UPDATE_NORMALIZED_RESOURCE_GROUPS'
export const UPDATE_CONTAINING_RESOURCE_GROUPS = 'UPDATE_CONTAINING_RESOURCE_GROUPS'
export const UPDATE_AVAILABLE_ORGANIZATIONS = 'UPDATE_AVAILABLE_ORGANIZATIONS'
export const UPDATE_POLICY_TYPES = 'UPDATE_POLICY_TYPES'
export const NOTIFY_ORGANIZATION_CHANGED = 'NOTIFY_ORGANIZATION_CHANGED'
export const NOTIFY_SOLUTION_CONTEXT_CHANGED = 'NOTIFY_SOLUTION_CONTEXT_CHANGED'
export const UPDATE_AVAILABLE_SOLUTION_CONTEXTS = 'UPDATE_AVAILABLE_SOLUTION_CONTEXTS'
export const UPDATE_RESOURCES_VISUALIZATION_OPTIONS = 'UPDATE_RESOURCES_VISUALIZATION_OPTIONS'
export const UPDATE_RESOURCE_GROUPS_VISUALIZATION_OPTIONS = 'UPDATE_RESOURCE_GROUPS_VISUALIZATION_OPTIONS'
export const UPDATE_NOTIFICATIONS = 'UPDATE_NOTIFICATIONS'
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION'
export const UPDATE_UNREAD_NOTIFICATION_COUNT = 'UPDATE_UNREAD_NOTIFICATION_COUNT'
export const INCREMENT_UNREAD_NOTIFICATION_COUNT = 'INCREMENT_UNREAD_NOTIFICATION_COUNT'
export const DECREMENT_UNREAD_NOTIFICATION_COUNT = 'DECREMENT_UNREAD_NOTIFICATION_COUNT'
export const UPDATE_NOTIFICATION_COUNT = 'UPDATE_NOTIFICATION_COUNT'
export const UPDATE_UNREAD_NOTIFICATIONS = 'UPDATE_UNREAD_NOTIFICATIONS'
export const POLL = 'POLL'
export const SHOW_CONFIRMATION = 'SHOW_CONFIRMATION'
export const HIDE_CONFIRMATION = 'HIDE_CONFIRMATION'
export const SET_RESOURCES_FILTER = 'SET_RESOURCES_FILTER'
export const UPDATE_USERS = 'UPDATE_USERS'
export const UPDATE_API_DOCS = 'UPDATE_API_DOCS'
export const BEGIN_DATAPOINT_FETCHING = 'BEGIN_DATAPOINT_FETCHING'
export const BEGIN_COLLECTIONS_FETCHING = 'BEGIN_COLLECTIONS_FETCHING'
export const UPDATE_AVAILABLE_SOLUTIONS = 'UPDATE_AVAILABLE_SOLUTIONS'
export const NOTIF_SEND = 'NOTIF_SEND';
export const NOTIF_DISMISS = 'NOTIF_DISMISS';
export const NOTIF_CLEAR = 'NOTIF_CLEAR';

const NOTIFY_DISMISS_AFTER = moment.duration(5, 'seconds')
const POLL_NOTIFICATION_INTERVAL = moment.duration(1, 'minute')

const METRIC_PAGE_SIZE = 1000
const UUID_SIZE = 50
const API_URL_SIZE = 112
const MAX_URL_LENGTH = 4094

export const SOLUTIONINVOKE_POLICY = 2
export const ADMIN_POLICY = 1
export const READONLY_POLICY = 0

export function executeWithProgress (action) {
  return dispatch => {
    dispatch({ type: START_PROGRESS })

    return dispatch(action)
      .finally(() => {
        dispatch({ type: COMPLETE_PROGRESS })
      })
  }
}

export function notifyUser (message, kind = 'info') {
  return (dispatch) => {
    dispatch(notifSend({
      message: message,
      kind: kind,
      dismissAfter: NOTIFY_DISMISS_AFTER.asMilliseconds()
    }))
  }
}

export function storeRelations (relations) {
  return {
    type: STORE_RELATIONS,
    relations
  }
}

export function storeResources (resources) {
  return {
    type: STORE_RESOURCES,
    resources
  }
}

const applyParams = (validParams, params = {}) => {
  return validParams.reduce((ac, el) => {
    if (params[el]) {
      ac[el] = params[el]
    }

    return ac
  }, {})
}

export function updateLocation ({ query, pathname }) {
  return (dispatch, getState) => {
    const oldLocation = getState().routing.locationBeforeTransitions
    const validParams = ['query', 'activeResourceGroupId', 'solutionContextId', 'tour', 'notificationQuery', 'organizationId']

    const oldLocationParams = applyParams(validParams, oldLocation.query)
    const newLocationParams = applyParams(validParams, {...oldLocationParams, ...(query || {})})

    const newLocation = {
      pathname: pathname || oldLocation.pathname,
      query: newLocationParams
    }
    dispatch(push(newLocation))
  }
}

export function startFeatureTour (tour) {
  return dispatch => {
    if (tour) {
      dispatch(updateLocation({ query: { tour: tour } }))
    }
  }
}

export function storeDatapoints (datapointsByMetricAndResource) {
  return {
    type: STORE_DATAPOINTS,
    datapointsByMetricAndResource
  }
}

export function storeLatestDatapoints (datapoints) {
  return (dispatch, getState) => {
    const datapointsByMetric = indexByNonUniqueKey(datapoints, 'metric_type')

    const datapointsByMetricAndResource = Object.keys(datapointsByMetric).reduce(
      (acc, metric) => {
        return {...acc, [metric]: indexByUniqueKey(datapointsByMetric[metric], 'queryable')}
      },
      {})

    return dispatch(storeDatapoints(datapointsByMetricAndResource))
  }
}

export function fetchLatestDatapoints (resourceIds) {
  return executeWithProgress((dispatch, getState) => {
    const state = getState()
    const currentSolutionContextId = state.currentSolutionContextId

    if (currentSolutionContextId === NO_SOLUTION_CONTEXT_ID) {
      return Promise.resolve()
    }

    let metricIds = null
    let metricSize = 0

    if (currentSolutionContextId === DEFAULT_SOLUTION_CONTEXT_ID) {
      metricSize = state.normalizedMetrics.size
    } else {
      metricIds = getMetricTypesForCurrentSolutionContext(state).keySeq().toJS()
      metricSize = metricIds.length
    }

    if (metricSize < 1) {
      return Promise.resolve()
    }

    // We don't want more than a 1000 datum in the response, so we need to see how many queryables can we request data for
    const queryableBatchSizeBasedOnResponse = Math.floor(METRIC_PAGE_SIZE / metricSize)
    // URI's cant be longer than 4094 char length, so we need to calculate how many queryables we can request data for
    const queryableBatchSizeBasedOnURI = Math.floor(
      (MAX_URL_LENGTH - (API_URL_SIZE + (metricIds !== null ? metricSize * UUID_SIZE : 0))) / UUID_SIZE
    )

    // We need to take the smaller of the two above variables not to exceed either
    const queryableBatchSize = queryableBatchSizeBasedOnResponse < queryableBatchSizeBasedOnURI
      ? queryableBatchSizeBasedOnResponse
      : queryableBatchSizeBasedOnURI

    if (queryableBatchSize < 1) {
      dispatch(notifyUser('There are too many metric types in the solution. Unable to fetch metric data.', 'error'))
      return Promise.resolve()
    }

    const slicedResourceIds = []

    while (resourceIds.length > 0) {
      slicedResourceIds.push(resourceIds.splice(0, queryableBatchSize))
    }

    dispatch(beginDatapointFetching())

    return Promise.all(slicedResourceIds.map(resourceIds => {
      return apiRequest(dispatch, 'GET', `latest_metric_data/`)
        .query({
          ...(metricIds ? { metric_type: metricIds.join() } : {}),
          queryable: resourceIds.join()
        })
        .then(results => {
          return results.body.results
        })
    }))
      .then(results => {
        return dispatch(storeLatestDatapoints([].concat.apply([], results)))
      })
  })
}

export function beginDatapointFetching () {
  return {
    type: BEGIN_DATAPOINT_FETCHING
  }
}

export function updateActiveResourceGroup (resourceGroupId) {
  return (dispatch, getState) => {
    dispatch(notifyActiveResourceGroupIdChanged(resourceGroupId))
    return dispatch(updateLocation({query: {activeResourceGroupId: resourceGroupId}}))
  }
}

export function notifyActiveResourceGroupIdChanged (resourceGroupId) {
  return {
    type: NOTIFY_ACTIVE_RESOURCE_GROUP_ID_CHANGED,
    resourceGroupId
  }
}

export function setActiveResourceGroup (resourceGroupId = null) {
  return (dispatch, getState) => {
    dispatch(updateActiveResourceGroup(resourceGroupId))
    dispatch(fetchQueryCollections(resourceGroupId, getCurrentQuery(getState())))
    dispatch(resetExpandedCollections())
  }
}

export function addAvailableGroup (availableGroup) {
  return {
    type: ADD_AVAILABLE_GROUP,
    availableGroup
  }
}

export function updateAvailableGroups (availableGroups) {
  return {
    type: UPDATE_AVAILABLE_GROUPS,
    availableGroups
  }
}

export function fetchResourceGroups () {
  return executeWithProgress((dispatch, getState) => {
    dispatch(fetchResourceGroupsCompliance())
    return apiRequest(dispatch, 'GET', 'resource_groups/')
      .then((availableGroups) => {
        const groups = availableGroups.body.results

        if (getState && getState().activeResourceGroupId === null) {
          const rootResourceGroupId = groups.find(group => group.parent === null).id
          dispatch(updateActiveResourceGroup(rootResourceGroupId))
        }

        return dispatch(updateAvailableGroups(groups))
      })
  })
}

export function updateGroupsCompliance (groupsCompliance) {
  return {
    type: UPDATE_GROUPS_COMPLIANCE,
    groupsCompliance
  }
}

export function fetchResourceGroupsCompliance () {
  return executeWithProgress((dispatch, getState) => {
    return apiRequest(dispatch, 'GET', 'resource_groups/compliance/')
      .then((complianceInfo) => {
        return dispatch(updateGroupsCompliance(complianceInfo.body))
      })
  })
}

export function notifyQueryChanged (query) {
  return {
    type: NOTIFY_QUERY_CHANGED,
    query
  }
}

export function setQuery (query = null) {
  return (dispatch, getState) => {
    dispatch(updateLocation({ query: { query } }))
    dispatch(notifyQueryChanged(query))
    return dispatch(fetchQueryCollections(getState().activeResourceGroupId, query))
  }
}

export function setQueryDependencies (resourceGroupId = null, query = null, solutionContextId = DEFAULT_SOLUTION_CONTEXT_ID, organizationId = null) {
  return (dispatch, getState) => {
    const state = getState()
    dispatch(setActiveResourceGroup(resourceGroupId))
    dispatch(setQuery(query))

    if (solutionContextId === DEFAULT_SOLUTION_CONTEXT_ID && state.currentSolutionContextId !== DEFAULT_SOLUTION_CONTEXT_ID) {
      solutionContextId = state.currentSolutionContextId
    }
    dispatch(updateSolutionContext(solutionContextId))

    if (organizationId === null) {
      organizationId = state.currentOrganization
    }
    dispatch(setOrganization(organizationId, {redirectToHome: false}))
  }
}

export function beginCollectionsFetching () {
  return {
    type: BEGIN_COLLECTIONS_FETCHING
  }
}

export function fetchQueryCollections (resourceGroupId, query, solutionContextId) {
  return executeWithProgress((dispatch, getState) => {
    const state = getState()
    const viewOptions = getResourcesVisualizationOptions(state)

    const collectionSetId = `group: ${resourceGroupId}, query: ${query}, showRelated: ${viewOptions.showRelated}`

    const params = {
      q: query,
      depth: viewOptions.showRelated ? 1 : 0,
      resource_group: resourceGroupId
    }

    dispatch(setQueryCollectionSet(collectionSetId))
    dispatch(beginCollectionsFetching())

    return apiRequest(dispatch, 'GET', 'collections/')
      .query(params)
      .then(collectionResponse => {
        dispatch(storeCollectionSet(collectionSetId, collectionResponse.body))
        dispatch(ensureCollectionResources())
        return dispatch(fetchCollectionsSolutionContextData(resourceGroupId, query, solutionContextId, collectionSetId))
      })
  })
}

export function fetchCollectionsSolutionContextData (resourceGroupId, query, solutionContextId, collectionSetId = null) {
  return executeWithProgress((dispatch, getState) => {
    const state = getState()
    const viewOptions = getResourcesVisualizationOptions(state)

    if (!collectionSetId) {
      collectionSetId = state.queryCollectionSetID
    }

    if (!solutionContextId) {
      solutionContextId = state.currentSolutionContextId
    }

    if (solutionContextId === NO_SOLUTION_CONTEXT_ID) {
      return Promise.resolve()
    }

    const params = {
      q: query,
      depth: viewOptions.showRelated ? 1 : 0,
      resource_group: resourceGroupId
    }

    if (solutionContextId === DEFAULT_SOLUTION_CONTEXT_ID) {
      params.solution = 'all'
    } else if (solutionContextId !== NO_SOLUTION_CONTEXT_ID) {
      params.solution = solutionContextId
    }

    return apiRequest(dispatch, 'GET', 'collections/compliance/')
      .query(params)
      .then(complianceResponse => {
        return dispatch(storeCollectionSolutionContextData(collectionSetId, solutionContextId, indexByUniqueKey(complianceResponse.body, 'id')))
      })
  })
}

export function storeCollectionSet (collectionSetID, collectionSet = null) {
  return {
    type: STORE_COLLECTION_SET,
    collectionSetID,
    collectionSet
  }
}

export function storeCollectionSolutionContextData (collectionSetID, solutionContextId, collectionSolutionContextData = null) {
  return {
    type: STORE_COLLECTION_SOLUTION_CONTEXT_DATA,
    collectionSetID,
    solutionContextId,
    collectionSolutionContextData
  }
}

export function setQueryCollectionSet (collectionSetID = null) {
  return {
    type: SET_QUERY_COLLECTION_SET,
    collectionSetID
  }
}

export function updateContributedPolicies (policyId, contributedPolicies) {
  return {
    type: UPDATE_CONTRIBUTED_POLICIES,
    policyId,
    contributedPolicies
  }
}

export function updateAncestralPolicies (policyId, ancestralPolicies) {
  return {
    type: UPDATE_ANCESTRAL_POLICIES,
    policyId,
    ancestralPolicies
  }
}

export function updateNormalizedResourceGroups (resourceGroups) {
  return {
    type: UPDATE_NORMALIZED_RESOURCE_GROUPS,
    resourceGroups
  }
}

export function updateContainingResourceGroups (resourceId, resourceGroups) {
  return {
    type: UPDATE_CONTAINING_RESOURCE_GROUPS,
    resourceId,
    resourceGroups
  }
}

export function fetchAncestralPolicies (policyId) {
  return dispatch => {
    const params = {
      ancestors_of: policyId
    }

    return apiRequest(dispatch, 'GET', `propagated_policies/`)
      .query(params)
      .then(policiesResponse => {
        const resourceGroupIds = policiesResponse.body.results.map(policy => policy.resource_group_id)
        dispatch(updateAncestralPolicies(policyId, policiesResponse.body.results))
        dispatch(fetchResourceGroupsById(resourceGroupIds))
        return null
      })
  }
}

export function fetchContributedPolicies (policyId) {
  return dispatch => {
    const params = {
      contributes_to: policyId
    }

    return apiRequest(dispatch, 'GET', `propagated_policies/`)
      .query(params)
      .then(policiesResponse => {
        const resourceGroupIds = policiesResponse.body.results.map(policy => policy.resource_group_id)
        dispatch(updateContributedPolicies(policyId, policiesResponse.body.results))
        dispatch(fetchResourceGroupsById(resourceGroupIds))
        return null
      })
  }
}

export function fetchResourceGroupsById (resourceGroupIds) {
  return dispatch => {
    const params = {
      ids: resourceGroupIds.join(',')
    }

    return apiRequest(dispatch, 'GET', `resource_groups/`)
      .query(params)
      .then(resourceGroupResponse => {
        dispatch(updateNormalizedResourceGroups(resourceGroupResponse.body.results))
        return null
      })
  }
}

export function fetchContainingResourceGroups (resourceId) {
  return dispatch => {
    const params = {
      containing_queryable: resourceId
    }

    return apiRequest(dispatch, 'GET', `resource_groups/`)
      .query(params)
      .then(resourceGroupResponse => {
        dispatch(updateNormalizedResourceGroups(resourceGroupResponse.body.results))
        dispatch(updateContainingResourceGroups(resourceId, resourceGroupResponse.body.results))
        return null
      })
  }
}

export function fetchUsers () {
  return dispatch => {
    return apiRequest(dispatch, 'GET', 'users/')
      .then(response => {
        return dispatch(updateUsers(response.body.results))
      })
  }
}

export function updateUsers (users) {
  return {
    type: UPDATE_USERS,
    users
  }
}

export function fetchCurrentUser () {
  return dispatch => {
    return apiRequestPartial(dispatch, 'GET', 'users/current/')
      .then(response => {
        return dispatch(fetchAvailableOrganizations(response.body['permissions']))
      })
  }
}

export function fetchAvailableOrganizations (userPermissions) {
  return dispatch => {
    return apiRequestPartial(dispatch, 'GET', 'organizations/')
      .then(response => {
        const availableOrganizations = response.body.results.map(org => {
          org['role'] = userPermissions[org['id']] === undefined ? 0 : userPermissions[org['id']]
          return org
        })
        window.localStorage.setItem('organizations', JSON.stringify(availableOrganizations))

        const currentOrganization = window.localStorage.getItem('organizationId') || ''
        if (!availableOrganizations.some(org => org.id === currentOrganization)) {
          const org = availableOrganizations[0] || {id: ''}
          window.localStorage.setItem('organizationId', org.id)
          dispatch(setOrganization(org.id))
        }
        return dispatch(updateAvailableOrganizations(availableOrganizations))
      })
      .catch((err) => {
        if (!err.status || err.status >= 500) {
          dispatch(updateLocation({ pathname: '/server_error' }))
          throw err
        }
      })
  }
}

export function updateAvailableOrganizations (organizations) {
  return {
    type: UPDATE_AVAILABLE_ORGANIZATIONS,
    organizations
  }
}

export function setOrganization (organizationId, { redirectToHome = true } = {}) {
  return (dispatch, getState) => {
    const state = getState()
    const previousOrganization = state.currentOrganization
    window.localStorage.setItem('organizationId', organizationId)
    const query = { organizationId: organizationId }

    if (previousOrganization !== organizationId) {
      if (redirectToHome) {
        // do not call updateLocation as we want to clear old query parameters
        // other than the organizationId
        state.activeResourceGroupId = null
        dispatch(push({ pathname: '/', query }))
      } else {
        dispatch(updateLocation({
          pathname: state.routing.locationBeforeTransitions.pathname,
          query
        }))
      }
      dispatch(notifyOrganizationChanged(organizationId))
      window.location.reload()
    } else {
      dispatch(updateLocation({ query }))
    }
  }
}

export function notifyOrganizationChanged (organizationId) {
  return {
    type: NOTIFY_ORGANIZATION_CHANGED,
    organizationId
  }
}

export function updatePolicyTypes (policyTypes) {
  return {
    type: UPDATE_POLICY_TYPES,
    policyTypes
  }
}

export function fetchPolicyTypes () {
  return dispatch => {
    return apiRequest(dispatch, 'GET', `policy_types/`)
      .then(policyTypesResponse => {
        dispatch(updatePolicyTypes(policyTypesResponse.body.results))
        return null
      })
  }
}

export function updateSolutionContext (solutionContext) {
  return (dispatch, getState) => {
    window.localStorage.setItem('solutionContext', solutionContext)
    dispatch(notifySolutionContextChanged(solutionContext))
    return dispatch(updateLocation({ query: { solutionContextId: solutionContext } }))
  }
}

export function notifySolutionContextChanged (solutionContextId) {
  return {
    type: NOTIFY_SOLUTION_CONTEXT_CHANGED,
    solutionContextId
  }
}

export function setSolutionContext (solutionContext) {
  return (dispatch, getState) => {
    const state = getState()

    for (const collection of state.expandedCollections) {
      dispatch(executeWithProgress(fetchCollectionResources(collection)))
    }

    dispatch(updateSolutionContext(solutionContext))
    dispatch(selectCoveragePolicyType('NONE'))
    dispatch(fetchCollectionsSolutionContextData(state.activeResourceGroupId, state.currentQuery, solutionContext))
  }
}

export function updateAvailableSolutionContexts (availableSolutionContexts) {
  return {
    type: UPDATE_AVAILABLE_SOLUTION_CONTEXTS,
    availableSolutionContexts
  }
}

export function fetchAvailableSolutionContexts () {
  return dispatch => {
    dispatch(fetchAvailableSolutions())
    dispatch(fetchPolicyTypes())
    dispatch(fetchMetrics())
    return null
  }
}

export function updateAvailableSolutions (availableSolutions) {
  return {
    type: UPDATE_AVAILABLE_SOLUTIONS,
    availableSolutions
  }
}

export function fetchAvailableSolutions () {
  return dispatch => {
    return apiRequest(dispatch, 'GET', 'solutions/')
      .then(availableSolutions => {
        const solutions = availableSolutions.body.results
        const normalizedSolutionContexts = indexByUniqueKey(solutions, 'id')
        dispatch(updateAvailableSolutionContexts(normalizedSolutionContexts))
        dispatch(updateAvailableSolutions(solutions))
        return null
      })
  }
}

export function suspendPolicyType (policyTypeId, suspended, userName) {
  return (dispatch) => {
    const suspension = suspended
      ? {
        enabled: true,
        reason: `Policy type suspended manually by ${userName}.`
      }
      : {
        enabled: false
      }
    return apiRequest(dispatch, 'PATCH', `policy_types/${policyTypeId}/suspension/`)
      .type('application/json')
      .send(suspension)
      .then(response => dispatch(fetchAvailableSolutions()))
  }
}

export function storeMetrics (metrics) {
  return {
    type: STORE_METRICS,
    metrics
  }
}

export function fetchMetrics () {
  return dispatch => {
    return apiRequest(dispatch, 'GET', `metric_types/`)
      .then(metrics => {
        const normalizedMetrics = indexByUniqueKey(metrics.body.results, 'id')
        return dispatch(storeMetrics(normalizedMetrics))
      })
  }
}

export const ResourcesVisualizationOptions = Record(
  JSON.parse(window.localStorage.getItem('resourcesVisualizationOptions') || 'null') || {
    format: 'graph',
    showRelated: false,
    limit: 120
  }
)

export function updateResourcesVisualizationOptions (options) {
  return (dispatch, getState) => {
    const state = getState()
    const previousOptions = state.resourcesVisualizationOptions
    window.localStorage.setItem('resourcesVisualizationOptions', JSON.stringify(options.toJS()))
    dispatch({ type: UPDATE_RESOURCES_VISUALIZATION_OPTIONS, options })
    if (previousOptions.get('showRelated') !== options.get('showRelated')) {
      return dispatch(fetchQueryCollections(state.activeResourceGroupId, state.currentQuery))
    }
    return null
  }
}

export const ResourceGroupsVisualizationOptions = Record(
  JSON.parse(window.localStorage.getItem('resourceGroupsVisualizationOptions') || 'null') || {
    format: 'graph'
  }
)

export function updateResourceGroupsVisualizationOptions (options) {
  window.localStorage.setItem('resourceGroupsVisualizationOptions', JSON.stringify(options.toJS()))
  return (dispatch) => {
    dispatch({ type: UPDATE_RESOURCE_GROUPS_VISUALIZATION_OPTIONS, options })
  }
}

export function poll (id) {
  return {
    type: POLL,
    id
  }
}

export function updateNotifications (notifications) {
  return {
    type: UPDATE_NOTIFICATIONS,
    notifications
  }
}

export function updateNotification (notification) {
  return {
    type: UPDATE_NOTIFICATION,
    notification
  }
}

export function updateNotificationCount (notificationCount) {
  return {
    type: UPDATE_NOTIFICATION_COUNT,
    notificationCount
  }
}

export function updateUnreadNotifications (unreadNotifications) {
  return {
    type: UPDATE_UNREAD_NOTIFICATIONS,
    unreadNotifications
  }
}

export function updateUnreadNotificationCount (unreadNotificationCount) {
  return {
    type: UPDATE_UNREAD_NOTIFICATION_COUNT,
    unreadNotificationCount
  }
}

export function incrementNotificationUnreadCount () {
  return {
    type: INCREMENT_UNREAD_NOTIFICATION_COUNT
  }
}

export function decrementNotificationUnreadCount () {
  return {
    type: DECREMENT_UNREAD_NOTIFICATION_COUNT
  }
}

export function getNotification (notificationId) {
  return (dispatch, getState) => {
    return apiRequest(dispatch, 'GET', `notifications/${notificationId}/`)
      .type('application/json')
      .then(response => {
        dispatch(updateNotification(response.body))
      })
  }
}

export function pollUnreadNotifications () {
  return (dispatch, getState) => {
    const isPollingNotifications = getState().poll.get('notifications')

    if (isPollingNotifications) {
      return null
    }

    requestUnreadNotifications(dispatch, getState)

    dispatch(poll('notifications'))
    setInterval(() => {
      requestUnreadNotifications(dispatch, getState)
    }, POLL_NOTIFICATION_INTERVAL.asMilliseconds())
  }
}

export function fetchNotifications (params = {}, updateCount = true) {
  return (dispatch, getState) => {
    return apiRequest(dispatch, 'GET', 'notifications/?type=info&type=error')
      .query(params)
      .then((notifications) => {
        const normalizedNotifications = indexByUniqueKeyOrderImmutable(notifications.body.results, 'id')
        dispatch(updateNotifications(normalizedNotifications))
        if (updateCount) {
          const notificationCount = notifications.body.count
          dispatch(updateNotificationCount(notificationCount))
        }
        const queryParams = notifications.req.url.split(/\?(.+)/)[1].split('&').find(x => {
          return x.startsWith('q=')
        })
        dispatch(updateLocation({ query: { notificationQuery: queryParams } }))
      })
  }
}

export function viewNotification (notification) {
  return (dispatch, getState) => {
    dispatch(updateLocation({ pathname: `/notifications/${notification.get('id')}` }))
  }
}

const patchNotification = (dispatch, body, notificationId) => {
  return apiRequest(dispatch, 'PATCH', `notifications/${notificationId}/`)
    .type('application/json')
    .send(body)
    .then(response => {
      dispatch(updateNotification(response.body))
    })
}

export function toggleNotificationRead (notification, read = null) {
  return (dispatch, getState) => {
    if (read === null) {
      read = !notification.get('read')
    }

    read === true ? dispatch(decrementNotificationUnreadCount()) : dispatch(incrementNotificationUnreadCount())
    patchNotification(dispatch, { read }, notification.get('id'))
  }
}

const requestUnreadNotifications = (dispatch, getState) => {
  // don't poll if there is no user signed in
  const auth = getState().auth
  if (!auth) {
    return
  }

  // as we only display last 5 unread notifications there is no point for asking for more
  apiRequest(dispatch, 'GET', 'notifications/?type=info&type=error')
    .query({q: 'read:False', page_size: 5})
    .then((notifications) => {
      const unreadNotificationCount = notifications.body.count
      const normalizedUnreadNotifications = indexByUniqueKey(notifications.body.results, 'id')
      dispatch(updateUnreadNotifications(normalizedUnreadNotifications))
      dispatch(updateUnreadNotificationCount(unreadNotificationCount))
    })
}

export function showConfirmationModal (title, description, callback, disabled, enableInput = false) {
  return {
    type: SHOW_CONFIRMATION,
    title,
    description,
    callback,
    disabled,
    enableInput
  }
}

export function hideConfirmationModal () {
  return {
    type: HIDE_CONFIRMATION
  }
}

export function setResourcesFilter (resourcesFilter) {
  return {
    type: SET_RESOURCES_FILTER,
    resourcesFilter
  }
}

export function saveCurrentQuery (name, currentQuery, activeResourceGroupId, dispatch) {
  const body = {
    name: name,
    query: currentQuery,
    parent_id: activeResourceGroupId || null
  }

  return apiRequest(dispatch, 'POST', `resource_groups/`)
    .type('application/json')
    .send(body)
}

export function updateApiDocs (docs) {
  return {
    type: UPDATE_API_DOCS,
    docs
  }
}

export function fetchApiDocs () {
  return (dispatch, getState) => {
    return apiRequest(dispatch, 'GET', 'docs.json')
      .then((docs) => {
        return dispatch(updateApiDocs(JSON.parse(docs.text)))
      })
  }
}

/**
 * These three notif actions were taken directly from the abandoned repo
 * at https://github.com/cloudreach/re-notif/blob/master/src/actions.js
 *
 */
/**
 * Publish a notification,
 * - if `dismissAfter` was set, the notification will be auto dismissed after the given period.
 * - if id wasn't specified, a time based id will be generated.``
 */
export function notifSend (notif) {
  const payload = objectAssign({}, notif);
  if (!payload.id) {
    payload.id = new Date().getTime();
  }
  return dispatch => {
    dispatch({ type: NOTIF_SEND, payload });

    if (payload.dismissAfter) {
      setTimeout(() => {
        dispatch({
          type: NOTIF_DISMISS,
          payload: payload.id,
        });
      }, payload.dismissAfter);
    }
  };
}

/**
 * Dismiss a notification by the given id.
 */
export function notifDismiss (id) {
  return { type: NOTIF_DISMISS, payload: id };
}

/**
 * Clear all notifications
 */
export function notifClear () {
  return { type: NOTIF_CLEAR };
}
