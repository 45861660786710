import classNames from 'classnames'
import { easeCubic } from 'd3-ease'
import { select } from 'd3-selection'
import { curveBundle, radialLine } from 'd3-shape'

import { initProperty, interpolateProperty } from './interpolate'
import style from '../../ResourceGraph.styl'

const TRANSITION_DURATION = 700

export default function renderEdges (edgeNodes, rootNode, parentElement, enableTransitions) {
  const edgePathLineGenerator = (edgePath, xPositions) => {
    // The edgePath may be shorter in the case of relationships between
    // resources in a single collection
    if (edgePath.length === 3) {
      xPositions.splice(2, 2)
    } else if (edgePath.length === 1) {
      return ''
    }
    edgePath = edgePath.map((edgePathNode, index) => {
      return {
        x: xPositions[index],
        y: edgePathNode.y
      }
    })

    const edgePathLineGenerator = radialLine()
      .radius(pathNode => pathNode.y * 56)
      .angle(pathNode => pathNode.x)
      .curve(curveBundle.beta(0.85))

    return edgePathLineGenerator(edgePath)
  }

  const updatingEdges = select(parentElement)
    .selectAll(`path.${style.edge}`)
    .data(edgeNodes, edgeNode => getEdgeNodeId(edgeNode))

  const enteringEdges = updatingEdges.enter().append('path')
  const currentEdges = updatingEdges.merge(enteringEdges)
  const exitingEdges = updatingEdges.exit()

  enteringEdges
    .attr('d', (edgeNode, i, elements) => {
      const xPositions = initProperty(elements[i], 'xPositions', [
        edgeNode.sourceLabelNode.parent.x,
        edgeNode.sourceLabelNode.parent.x,
        0,
        edgeNode.targetLabelNode.parent.x,
        edgeNode.targetLabelNode.parent.x
      ])
      const edgePath = edgeNode.sourceLabelNode.path(edgeNode.targetLabelNode)
      return edgePathLineGenerator(edgePath, xPositions)
    })

  currentEdges.merge(exitingEdges)
    .sort(sortEdges)

  currentEdges
    .attr('class', edgeNode => classNames(style.edge, {
      [style.secondary]: isSecondary(edgeNode)
    }))
    .transition()
    .duration(enableTransitions ? TRANSITION_DURATION : 0)
    .ease(easeCubic)
    .attr('stroke-width', getStrokeWidth)
    .attr('data-source-type', edgeNode => getNodeType(edgeNode.sourceLabelNode))
    .attr('data-source-id', edgeNode => getNodeId(edgeNode.sourceLabelNode))
    .attr('data-target-type', edgeNode => getNodeType(edgeNode.targetLabelNode))
    .attr('data-target-id', edgeNode => getNodeId(edgeNode.targetLabelNode))
    .attrTween('d', (edgeNode, i, elements) => {
      return interpolateEdgePath(edgeNode, elements[i], edgePathLineGenerator, [
        edgeNode.sourceLabelNode.x,
        edgeNode.sourceLabelNode.parent.x,
        0,
        edgeNode.targetLabelNode.parent.x,
        edgeNode.targetLabelNode.x
      ])
    })

  exitingEdges
    .transition()
    .duration(enableTransitions ? TRANSITION_DURATION : 0)
    .ease(easeCubic)
    .attr('stroke-width', 0)
    .attrTween('d', (edgeNode, i, elements) => {
      const sourceGroupNode = edgeNode.sourceLabelNode.parent
      const targetGroupNode = edgeNode.targetLabelNode.parent
      return interpolateEdgePath(edgeNode, elements[i], edgePathLineGenerator, [
        sourceGroupNode[sourceGroupNode.clicked ? 'toggledX' : 'x'],
        sourceGroupNode[sourceGroupNode.clicked ? 'toggledX' : 'x'],
        0,
        targetGroupNode[targetGroupNode.clicked ? 'toggledX' : 'x'],
        targetGroupNode[targetGroupNode.clicked ? 'toggledX' : 'x']
      ])
    })
    .remove()

  return currentEdges
}

function getNodeId (labelNode) {
  return labelNode.data.source.id
}

function getNodeType (labelNode) {
  return labelNode.data.type
}

function getEdgeNodeId (edgeNode) {
  const { sourceLabelNode, targetLabelNode } = edgeNode

  const sourceGroupId = sourceLabelNode.parent.data.group.id
  const targetGroupId = targetLabelNode.parent.data.group.id

  const groupPairKey = [sourceGroupId, targetGroupId].sort().join(':')

  const groupPairEdge = sourceLabelNode.data.type === 'group' && targetLabelNode.data.type === 'group'

  if (groupPairEdge) {
    return groupPairKey
  }

  const { sourceItemId, targetItemId } = edgeNode
  const sourceId = `${sourceGroupId}${sourceItemId ? `-${sourceItemId}` : ''}`
  const targetId = `${targetGroupId}${targetItemId ? `-${targetItemId}` : ''}`

  return `${sourceId}:${targetId}`
}

function sortEdges (edgeNode1, edgeNode2) {
  const edgeNode1Periphery = isSecondary(edgeNode1)
  const edgeNode2Periphery = isSecondary(edgeNode2)

  if (edgeNode1Periphery === edgeNode2Periphery) {
    return 0
  }

  return edgeNode1Periphery ? -1 : 1
}

function isSecondary (edgeNode) {
  return edgeNode.sourceLabelNode.data.source.secondary || edgeNode.targetLabelNode.data.source.secondary
}

function interpolateEdgePath (edgeNode, element, edgePathLineGenerator, xPositions) {
  const edgePath = edgeNode.sourceLabelNode.path(edgeNode.targetLabelNode)

  return interpolateProperty(element, 'xPositions', null, xPositions, (xPositions) => {
    return edgePathLineGenerator(edgePath, xPositions)
  })
}

function getStrokeWidth (edgeNode) {
  return Math.max(0.2, (Math.log10(edgeNode.count - 1) * 0.7))
}
