import React from 'react'
import { Map, List } from 'immutable'

import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import FontAwesome from 'react-fontawesome'

import Button from 'components/Button'
import Hint from 'components/Hint'

import style from './ResourceProviderCreator.styl'
import httpStyle from '../CredentialsForms/CredentialsFormHttp/CredentialsFormHttp.styl'

export class ResourceProviderCreator extends React.Component {
  constructor () {
    super()

    this._onSubmit = this._onSubmit.bind(this)
    this._onTypeChange = this._onTypeChange.bind(this)
    this._onNameChange = this._onNameChange.bind(this)
    this._addDataItem = this._addDataItem.bind(this)
    this._removeDataItem = this._removeDataItem.bind(this)
    this._onDataKeyChange = this._onDataKeyChange.bind(this)
    this._onDataValueChange = this._onDataValueChange.bind(this)

    this.state = {
      type: '',
      name: '',
      data: [
        {key: '', value: '', required: false}
      ]
    }
  }

  componentWillMount () {
    if (this.props.onInitialize) {
      this.props.onInitialize()
    }
  }

  _onTypeChange ({ target }) {
    const schema = this.props.resourceProviderTypes.get(target.value).get('schema', Map())
    const data = schema.get('properties') ? schema.get('properties').reduce((acc, value, key) => {
      return acc.concat([{key, value: '', required: schema.get('required', List()).includes(key)}])
    }, []) : [{key: '', value: '', required: false}]
    this.setState({type: target.value, data})
  }

  _onNameChange ({ target }) {
    this.setState({name: target.value})
  }

  _addDataItem () {
    this.setState({
      data: this.state.data.concat([{key: '', value: '', required: false}])
    })
  }

  _removeDataItem (index) {
    this.state.data.splice(index, 1)
    this.setState({
      data: this.state.data
    })
  }

  _onDataKeyChange (index, { target }) {
    this.state.data[index].key = target.value
    this.setState({
      data: this.state.data
    })
  }

  _onDataValueChange (index, { target }) {
    this.state.data[index].value = target.value
    this.setState({
      data: this.state.data
    })
  }

  _onSubmit (e) {
    e.preventDefault()

    const data = this.state.data.reduce((acc, datum) => {
      return { ...acc, [datum.key]: datum.value }
    }, {})

    const fields = {
      type: this.state.type,
      name: this.state.name,
      data
    }

    this.props.onCreate(fields)
      .then(() => this.props.onClose())
  }

  renderResourceTypeSelect (resourceTypes) {
    if (this.state.type === '') {
      return (
        <select value={''} onChange={this._onTypeChange}>
          <option key={''} value={''}>-</option>
          {resourceTypes.valueSeq().map((type) => {
            return <option key={type.get('name')} value={type.get('name')}>{type.get('name')}</option>
          })}
        </select>
      )
    }

    return (
      <select value={this.state.type} onChange={this._onTypeChange}>
        {resourceTypes.valueSeq().map((type) => {
          return <option key={type.get('name')} value={type.get('name')}>{type.get('name')}</option>
        })}
      </select>
    )
  }

  renderProviderForm (resourceType) {
    if (resourceType == null) {
      return
    }

    return (
      <div>
        <div className={style.formField}>
          <label>
            Resource provider name
            <Hint className={style.hint}>
              Provide a unique name for the resource provider.
            </Hint>
          </label>
          <input
            required
            type='text'
            name='ResourceProviderName'
            value={this.state.name}
            onChange={this._onNameChange} />
        </div>
        <div className={httpStyle.splitField}>
          <label>
            Additional provider data
            <Hint className={style.hint}>
              Additional data fields
            </Hint>
          </label>
          {this.renderDataInputs()}
        </div>
      </div>
    )
  }

  renderDataInputs () {
    return this.state.data.map((data, index) => {
      return (<div key={index} className={httpStyle.inputContent}>
        <input
          name='data key' type='text'
          placeholder='key'
          pattern='^[a-zA-Z-]+'
          value={data.key || ''}
          readOnly={data.required}
          onChange={this._onDataKeyChange.bind(this, index)} />
        <input
          name='data value' type='text'
          placeholder='value'
          value={data.value || ''}
          required={data.required}
          onChange={this._onDataValueChange.bind(this, index)} />
        {
          !data.required &&
          <Button type={Button.WARN} onClick={this._removeDataItem.bind(this, index)} >
            <FontAwesome name='minus' />
          </Button>
        }
      </div>)
    }).concat([
      <Button key='add' onClick={this._addDataItem} >
        <FontAwesome name='plus' />
      </Button>
    ])
  }

  render () {
    return (
      <div className={style.contents}>
        <form onSubmit={this._onSubmit}>
          <div className={style.formField}>
            <label>
              Select the resource provider type
              <Hint className={style.hint}>
                Resource Providers are installed when solutions are installed.
                Installed resource providers are listed here.
                A resource provider will require different data, depending on the type of access it provides.
              </Hint>
            </label>
            {this.renderResourceTypeSelect(this.props.resourceProviderTypes)}
          </div>
          {this.renderProviderForm(this.props.resourceProviderTypes.get(this.state.type))}
          <div className={style.controls}>
            <Button onClick={() => this.props.onClose()} flat>Cancel</Button>
            {this.props.resourceProviderTypes.get(this.state.type) != null &&
              <Button type={Button.PRIMARY} submit>Save</Button>
            }
          </div>
        </form>
      </div>
    )
  }
}

ResourceProviderCreator.propTypes = {
  onInitialize: PropTypes.func.isRequired,
  resourceProviderTypes: ImmutablePropTypes.map.isRequired,
  onCreate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}

export default ResourceProviderCreator
