import PropTypes from 'prop-types'
import React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'

import { RadioButton, Item } from 'components/RadioButton'
import Button from 'components/Button'
import Hint from 'components/Hint'

import style from './SolutionRuntimeCreator.styl'
import awsLogo from 'scenes/Manage/awsLogo.svg'
import azureLogo from 'scenes/Manage/azureLogoGrey.svg'
import GCLogo from 'scenes/Manage/googleCloudLogoGrey.svg'

export class SolutionRuntimeCreator extends React.Component {
  constructor () {
    super()

    this.setValue = this.setValue.bind(this)
    this.onSubmit = this.onSubmit.bind(this)

    this.state = {
      type: 'awslambda',
      arn: '',
      name: ''
    }
  }

  setValue (key, value) {
    let state = {}
    state[key] = value.target.value
    this.setState(state)
  }

  onSubmit (e) {
    e.preventDefault()

    const fields = {
      type: this.state.type,
      credentials: {
        iam_role_arn: this.state.arn
      },
      name: this.state.name
    }

    this.props.onCreate(fields)
      .then(() => this.props.onClose())
  }

  render () {
    const logos =
      <RadioButton
        value={this.state.type}
        filled={false}
        onChange={type => { this.setState({type: type}) }} >
        <Item className={style.item} value='awslambda'><img src={awsLogo} className={style.logo} /></Item>
        <Item className={style.item} value='azure' disabled><img src={azureLogo} className={style.logo} /></Item>
        <Item className={style.item} value='GCP' disabled><img src={GCLogo} className={style.logo} /></Item>
      </RadioButton>

    return (
      <div>
        <form onSubmit={this.onSubmit}>
          <div className={style.formField}>
            <label>
              Select Provider
              <Hint className={style.hint}>
                Solutions require configuration in one or more of your public cloud providers.
                Please select a provider from the following supported.
                More information on what permissions are required, and how Conductor uses them is available at <a href='https://docs.conductor.cloudreach.com/' target='_blank'>docs.conductor.cloudreach.com</a>
              </Hint>
            </label>
            {logos}
          </div>
          <div className={style.description}>
            <p>
              Conductor uses IAM Roles for cross account access when configuring an AWS based provider.
              This requires that an IAM Role is deployed in each resource provider that is used as a Solution Runtime.
              This can be deployed manually, or more easily via the following CloudFormation template.
              Instantiate a CloudFormation stack by copying the template URL - then copy the IAM Role ARN from the stack outputs into the form below.
            </p>
            <CopyToClipboard text={this.props.templateUrl}
              onCopy={() => {}}>
              <Button
                className={style.copy} >
                Copy CloudFormation Template URL
              </Button>
            </CopyToClipboard>
          </div>
          <div className={style.formField}>
            <label>
              Enter Friendly Name
              <Hint className={style.hint}>
                Select a friendly name for the account.
                This field is optional.
                Will default to the AWS account ID from the ARN.
              </Hint>
            </label>
            <input
              type='text' name='accountName' value={this.state.name || ''}
              onChange={this.setValue.bind(this, 'name')} />
          </div>
          <div className={style.formField}>
            <label>
              Enter IAM role ARN
              <Hint className={style.hint}>
                Must be in the format: <div className={style.code}>arn:aws:iam::account-id:role/role-name</div>
              </Hint>
            </label>
            <input
              required name='arn' type='text'
              pattern='^arn:aws:iam::[0-9]{12}:role\/.*$'
              value={this.state.arn || ''}
              onChange={this.setValue.bind(this, 'arn')} />
          </div>
          <div className={style.controls}>
            <Button onClick={() => this.props.onClose()} flat>Cancel</Button>
            <Button type={Button.PRIMARY} submit>Save</Button>
          </div>
        </form>
      </div>
    )
  }
}

SolutionRuntimeCreator.propTypes = {
  templateUrl: PropTypes.string.isRequired,
  onCreate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}

export default SolutionRuntimeCreator
