import PropTypes from 'prop-types'
import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'

import Button from 'components/Button'
import Hint from 'components/Hint'

import style from './SolutionRuntimeEditor.styl'
import awsLogo from 'scenes/Manage/awsLogo.svg'

export class SolutionRuntimeEditor extends React.Component {
  constructor () {
    super()

    this.setValue = this.setValue.bind(this)
    this.onSubmit = this.onSubmit.bind(this)

    this.state = {
      arn: '',
      name: ''
    }
  }

  componentWillMount () {
    this.state = {
      arn: this.props.solutionRuntime.getIn(['credentials', 'arn'], ''),
      name: this.props.solutionRuntime.get('name', '')
    }
  }

  setValue (key, value) {
    let state = {}
    state[key] = value.target.value
    this.setState(state)
  }

  onSubmit (e) {
    e.preventDefault()

    const fields = {
      credentials: {
        iam_role_arn: this.state.arn
      },
      name: this.state.name
    }

    this.props.onUpdate(this.props.solutionRuntime.get('id'), fields)
      .then(() => this.props.onClose())
  }

  render () {
    return (
      <div>
        <form onSubmit={this.onSubmit}>
          <div className={style.title}>
            <div className={style.accountName}>
              <label>
                Account Name
              </label>
              <input
                required type='text' name='accountName' value={this.state.name || ''}
                onChange={this.setValue.bind(this, 'name')} />
            </div>
            <img src={awsLogo} className={style.logo} alt='awsLogo' />
          </div>
          <div className={style.formField}>
            <label>
              Account IAM role ARN
              <Hint className={style.hint}>
                Must be in the format: <div className={style.code}>arn:aws:iam::account-id:role/role-name</div>
              </Hint>
            </label>
            <input
              required name='arn' type='text'
              pattern='^arn:aws:iam::[0-9]{12}:role\/.*$'
              value={this.state.arn || ''}
              onChange={this.setValue.bind(this, 'arn')} />
          </div>
          <div className={style.controls}>
            <Button onClick={() => this.props.onClose()} flat>Cancel</Button>
            <Button type={Button.PRIMARY} submit>Save</Button>
          </div>
        </form>
      </div>
    )
  }
}

SolutionRuntimeEditor.propTypes = {
  solutionRuntime: ImmutablePropTypes.map,
  onUpdate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}

export default SolutionRuntimeEditor
