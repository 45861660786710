export default [
  '#cc434a',
  '#d5573b',
  '#ff8f00',
  '#ffae03',
  '#fabc3c',
  '#ffca3a',
  '#cbeae6',
  '#a9ddd6',
  '#86cfc6',
  '#64c2b6',
  '#42b5a6'
]
