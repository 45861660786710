import React from 'react'
import { Map } from 'immutable'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'

import { returnFiltered } from 'scenes/Manage'
import Filter from 'components/Filter'
import Card, { CardBody, CardHeader } from 'components/Card'
import ExternalNotificationEndpointsList from './components/ExternalNotificationEndpointsList'
import ExternalNotificationFiltersList from './components/ExternalNotificationFiltersList'
import {
  ADMIN_POLICY
} from 'state/actions'

import style from 'scenes/Manage/Manage.styl'

export class ExternalNotifications extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      filteredExternalNotificationEndpoints: this.props.externalNotificationEndpoints,
      filteredExternalNotificationFilters: this.props.externalNotificationFilters
    }
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.externalNotificationEndpoints !== this.props.externalNotificationEndpoints) {
      this.setState({
        filteredExternalNotificationEndpoints: nextProps.externalNotificationEndpoints
      })
    }
    if (nextProps.externalNotificationFilters !== this.props.externalNotificationFilters) {
      this.setState({
        filteredExternalNotificationFilters: nextProps.externalNotificationFilters
      })
    }
  }

  _onUpdateExternalNotificationEndpoints = filtered => {
    this.setState({filteredExternalNotificationEndpoints: returnFiltered(filtered)})
  }

  _onUpdateExternalNotificationFilters = filtered => {
    this.setState({filteredExternalNotificationFilters: returnFiltered(filtered)})
  }

  render () {
    const endpoints = this.props.externalNotificationEndpoints.reduce((endpointMap, endpoint) => {
      return endpointMap.set(`${endpoint.get('name')}`, endpoint)
    }, Map())

    const filters = this.props.externalNotificationFilters.reduce((filterMap, filter) => {
      return filterMap.set(`${filter.get('name')}`, filter)
    }, Map())

    return (
      <div>
        <Card>
          <CardHeader title='External Notification Endpoints'
            rightBarButtonItem={this.props.organizationRole === ADMIN_POLICY ? this.props.addExternalNotificationEndpoint : null} />
          <div className='normal'>
            <Filter
              className={style.filter}
              data={endpoints}
              onFilter={this._onUpdateExternalNotificationEndpoints} />
          </div>
          <CardBody>
            <ExternalNotificationEndpointsList
              endpoints={this.state.filteredExternalNotificationEndpoints}
              onDelete={this.props.onDeleteExternalNotificationEndpoint}
              onConfirmation={this.props.onShowConfirmationModal}
              onShowEditModal={this.props.onShowEditExternalNotificationEndpointModal}
              onShowConfiguration={this.props.onShowExternalNotificationEndpointConfiguration}
              organizationRole={this.props.organizationRole} />
          </CardBody>
        </Card>
        <Card>
          <CardHeader title='External Notification Filters'
            rightBarButtonItem={this.props.organizationRole === ADMIN_POLICY ? this.props.addExternalNotificationFilter : null} />
          <div className='normal'>
            <Filter
              className={style.filter}
              data={filters}
              onFilter={this._onUpdateExternalNotificationFilters} />
          </div>
          <CardBody>
            <ExternalNotificationFiltersList
              filters={this.state.filteredExternalNotificationFilters}
              onDelete={this.props.onDeleteExternalNotificationFilter}
              onShowEditModal={this.props.onShowEditExternalNotificationFilterModal}
              onConfirmation={this.props.onShowConfirmationModal}
              organizationRole={this.props.organizationRole} />
          </CardBody>
        </Card>
      </div>
    )
  }
}

ExternalNotifications.propTypes = {
  onShowConfirmationModal: PropTypes.func.isRequired,
  onShowEditExternalNotificationEndpointModal: PropTypes.func.isRequired,
  onShowExternalNotificationEndpointConfiguration: PropTypes.func.isRequired,
  onDeleteExternalNotificationFilter: PropTypes.func.isRequired,
  onDeleteExternalNotificationEndpoint: PropTypes.func.isRequired,
  onShowEditExternalNotificationFilterModal: PropTypes.func.isRequired,
  addExternalNotificationFilter: PropTypes.node.isRequired,
  addExternalNotificationEndpoint: PropTypes.node.isRequired,
  organizationRole: PropTypes.number.isRequired,
  externalNotificationEndpoints: ImmutablePropTypes.map,
  externalNotificationFilters: ImmutablePropTypes.map
}

export default ExternalNotifications
