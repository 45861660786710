import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import style from './ProgressBar.styl'

import { inProgress } from 'state/selectors'

export function ProgressBar ({ className, inProgress }) {
  const inProgressClass = inProgress ? style.inProgress : style.atRest

  return (<div className={classNames(style.ProgressBar, inProgressClass, className)} />)
}

ProgressBar.propTypes = {
  className: PropTypes.string,
  inProgress: PropTypes.bool
}

function mapStateToProps (state) {
  return {
    inProgress: inProgress(state)
  }
}

export default connect(mapStateToProps)(ProgressBar)
