import PropTypes from 'prop-types'
import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import classNames from 'classnames'
import { OrderedMap } from 'immutable'

import Filter from 'components/Filter'
import style from './OrganizationsList.styl'

import { READONLY_POLICY } from 'state/actions'

export class OrganizationsList extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      filteredOrganizations: props.organizations
    }
  }

  componentDidMount () {
    this.updateOrganizations(this.reduceOrganizations(this.props.organizations))
  }

  componentWillReceiveProps (nextProps) {
    if (this.props.organizations !== nextProps.organizations ||
        this.props.currentOrganization !== nextProps.currentOrganization) {
      this.updateOrganizations(this.reduceOrganizations(nextProps.organizations), nextProps.currentOrganization)
    }
  }

  _createOrganizationsList = (organizations, currentOrganization) => {
    return organizations.reduce((organizationElements, organization, organizationName) => {
      const currentOrganizationId = currentOrganization || this.props.currentOrganization
      const organizationId = organization['id']

      const activeOrganizationStyle = classNames(
        style.organizationButton,
        { [style.activeOrganization]: currentOrganizationId === organizationId }
      )

      const permissions = organization['role'] === READONLY_POLICY ? (
        <div>{'(Read Only)'}</div>
      ) : null

      organizationElements.push(
        <a className={activeOrganizationStyle} key={organizationId} onClick={() => this._setOrganization(organizationId)}>
          {organizationName}
          {permissions}
        </a>
      )

      return organizationElements
    }, [])
  }

  reduceOrganizations = (organizations) => {
    return organizations.reduce((orgs, org) => orgs.set(org.get('name'), {'id': org.get('id'), 'role': org.get('role')}), OrderedMap())
  }

  updateOrganizations = (organizations, currentOrganization) => {
    const filteredOrganizations = this._createOrganizationsList(organizations, currentOrganization)
    this.setState({ filteredOrganizations })
  }

  render () {
    const organizationList = this.state.filteredOrganizations

    return (
      <div className={classNames(style.organizations, this.props.className)}>
        <div className={style.profileHeader}>Organizations</div>
        <Filter
          className={style.filter}
          data={this.reduceOrganizations(this.props.organizations)}
          onFilter={this.updateOrganizations}
          onRef={ref => { this.filterRef = ref }}
          close={this.state.forceClose}
        />
        <div className={style.organizationList}>
          {organizationList.length > 0 ? organizationList : <i>No matching organizations</i>}
        </div>
      </div>
    )
  }

  _setOrganization (organizationId) {
    this.props.setOrganization(organizationId)
  }
}

OrganizationsList.propTypes = {
  className: PropTypes.string,
  organizations: ImmutablePropTypes.list,
  currentOrganization: PropTypes.string,
  setOrganization: PropTypes.func.isRequired
}

export default OrganizationsList
