import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List, Map } from 'immutable'

import RankedAttributeList from 'components/RankedAttributeList'

import style from './Overview.styl'

const attributeRoot = []

export default function Overview ({ resource }) {
  const summaryAttributeRanking = List([
    Map({ name: 'Resource', path: [ 'blob', 'display_id' ] }),
    Map({ name: 'Resource ID', path: [ 'id' ] }),
    Map({ name: 'Resource type', path: [ 'blob', 'resource_type' ] }),
    Map({ name: 'Last updated', path: [ 'blob', 'last_updated_at' ], format: Map({ type: 'relativeDateTime' }) })
  ])

  return (
    <div className={style.overview}>
      <div className={style.attributeList}>
        <RankedAttributeList
          attributeBase={attributeRoot}
          attributeRanking={summaryAttributeRanking}
          resource={resource} />
      </div>
    </div>
  )
}

Overview.propTypes = {
  resource: ImmutablePropTypes.map.isRequired
}
