import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import ImmutablePropTypes from 'react-immutable-proptypes'

import { List } from 'immutable'

import style from './ContainingResourceGroups.styl'

import ResourceGroupList from './components/ResourceGroupList'
import LoadingIndicator from 'components/LoadingIndicator'

import { fetchContainingResourceGroups } from 'state/actions'

export class ContainingResourceGroups extends React.Component {
  constructor (props) {
    super(props)
    this.state = this._emptyState()
    this.onLeafClick = group => {}
  }

  _emptyState () {
    return {
      leafGroups: null,
      focussedGroupId: null,
      ancestorGroups: List()
    }
  }

  _makeLeaves (groupIds, groups) {
    const parentIds = groupIds.map(id => groups.getIn([id, 'parent', 'id']), 'no parent').toSet()
    const leafIds = groupIds.filterNot(id => parentIds.has(id))
    return leafIds.map(id => groups.get(id))
  }

  _makeAncestors (groupId, groups) {
    const collectAncestorIds = id => {
      if (!id) {
        return List()
      }
      return collectAncestorIds(groups.getIn([id, 'parent', 'id'])).unshift(id)
    }

    return collectAncestorIds(groupId).map(id => groups.get(id))
  }

  componentDidMount () {
    this.props.fetchGroups(this.props.resource.get('id'))
  }

  componentWillReceiveProps (nextProps) {
    if (!this.props.resource.equals(nextProps.resource)) {
      this.props.fetchGroups(nextProps.resource.get('id'))
      this.setState(this._emptyState())
      return
    }

    if (!nextProps.containingGroupIds) {
      return
    }

    this.onLeafClick = groupId => {
      this.setState({focussedGroupId: groupId, ancestorGroups: this._makeAncestors(groupId, nextProps.resourceGroups)})
    }
    this.setState({leafGroups: this._makeLeaves(nextProps.containingGroupIds, nextProps.resourceGroups)})
  }

  _loading () {
    if (this.state.leafGroups) {
      return null
    }
    return <div className={style.loading}><LoadingIndicator /></div>
  }

  _leafList () {
    if (this.state.leafGroups) {
      return (
        <div className={style.leafGroups}>
          <ResourceGroupList resourceGroups={this.state.leafGroups} onClick={this.onLeafClick} highlightedResourceGroupId={this.state.focussedGroupId} title={'Resource groups that contain resource'} />
        </div>
      )
    }
    return null
  }

  _ancestorList () {
    if (this.state.leafGroups) {
      return (
        <div className={style.ancestorGroups}>
          <ResourceGroupList resourceGroups={this.state.ancestorGroups} highlightedResourceGroupId={this.state.focussedGroupId} title={'Ancestors of highlighted group'} />
        </div>
      )
    }
    return null
  }

  render () {
    return (
      <div className={style.containingResourceGroups}>
        {this._loading()}
        {this._leafList()}
        {this._ancestorList()}
      </div>
    )
  }
}

ContainingResourceGroups.propTypes = {
  resource: ImmutablePropTypes.map.isRequired,
  fetchGroups: PropTypes.func.isRequired,
  containingGroupIds: ImmutablePropTypes.list,
  resourceGroups: ImmutablePropTypes.map
}

function mapStateToProps (state, ownProps) {
  return {
    containingGroupIds: state.containingResourceGroups.get(ownProps.resource.get('id'), null),
    resourceGroups: state.normalizedResourceGroups
  }
}

function mapDispatchToProps (dispatch, ownProps) {
  return {
    fetchGroups: (resourceId) => {
      dispatch(fetchContainingResourceGroups(resourceId))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContainingResourceGroups)
