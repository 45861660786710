import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import FontAwesome from 'react-fontawesome'

import style from './Search.styl'

export class Search extends React.Component {
  constructor (props) {
    super()

    this.state = {
      searchText: '',
      formActive: false
    }
  }

  componentWillMount () {
    this.setState({searchText: this.props.searchText || ''})
  }

  _onSearchChange = searchText => {
    this.setState({
      formActive: true,
      searchText
    })
  }

  _onSearch = () => {
    this.setState({ formActive: false })
    this.props.onSearch(this.state.searchText)
  }

  render () {
    return (
      <form
        className={classNames(style.Search, this.props.className)}
        onSubmit={event => { event.preventDefault() }}>
        <input
          onChange={event => { event.cancelable || this._onSearchChange(event.target.value) }}
          value={this.state.searchText}
          placeholder='Search...'
        />
        <button
          className={classNames(style.searchButton, { [style.uncommittedSearch]: this.state.formActive })}
          onClick={this._onSearch}
          type='submit'>
          <FontAwesome name='search' />
        </button>
      </form>
    )
  }
}

Search.propTypes = {
  className: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
  searchText: PropTypes.string
}

export default Search
