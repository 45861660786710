import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'

import FontAwesome from 'react-fontawesome'

import { ADMIN_POLICY } from 'state/actions'
import Button from 'components/Button'

import style from './ExternalNotificationEndpointsList.styl'

export class ExternalNotificationEndpointsList extends React.Component {
  deleteEndpoint (endpoint) {
    const endpointName = endpoint.get('name')

    const confirmation = <span>
      <p>This will permanently delete <b>{endpointName}</b>.</p>
      <br />
      <p>Please confirm the name of the endpoint below to continue.</p>
    </span>

    this.props.onConfirmation(
      'Delete External Notification Endpoint',
      confirmation,
      () => this.props.onDelete(endpoint.get('id')),
      input => input !== endpointName
    )
  }

  listEndpoints (endpoints) {
    if (!endpoints || !endpoints.size) {
      return (<tr><td>No External Notification Endpoints</td></tr>)
    }

    return endpoints.map(endpoint => {
      return (
        <tr key={endpoint.get('id')}>
          <td>{endpoint.get('name')}</td>
          <td>{endpoint.get('endpoint_type')}</td>
          <td>
            <Button
              type={Button.PRIMARY}
              onClick={() => this.props.onShowConfiguration(endpoint)}
              flat>
              VIEW
            </Button>
          </td>
          {this.props.organizationRole === ADMIN_POLICY ? (
            <td>
              <Button
                onClick={() => this.props.onShowEditModal(endpoint)}
                className={style.edit}
                flat>
                <FontAwesome
                  name='pencil'
                  className='fa-align-right' />
              </Button>
            </td>
          ) : null}
          {this.props.organizationRole === ADMIN_POLICY ? (
            <td>
              <Button
                onClick={() => this.deleteEndpoint(endpoint)}
                type={Button.WARN}
                className={style.delete}
                flat>
                <FontAwesome name='trash' />
              </Button>
            </td>
          ) : null}
        </tr>
      )
    })
  }

  render () {
    return (
      <div>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Type</th>
              <th className={style.viewTitle}>Configuration</th>
              {this.props.organizationRole === ADMIN_POLICY ? <th /> : null}
              {this.props.organizationRole === ADMIN_POLICY ? <th /> : null}
            </tr>
          </thead>
          <tbody>
            {this.listEndpoints(this.props.endpoints.valueSeq())}
          </tbody>
        </table>
      </div>
    )
  }
}

ExternalNotificationEndpointsList.propTypes = {
  endpoints: ImmutablePropTypes.map.isRequired,
  onDelete: PropTypes.func.isRequired,
  onConfirmation: PropTypes.func.isRequired,
  onShowEditModal: PropTypes.func.isRequired,
  onShowConfiguration: PropTypes.func.isRequired,
  organizationRole: PropTypes.number.isRequired
}

export default ExternalNotificationEndpointsList
