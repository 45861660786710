import React from 'react'
import PropTypes from 'prop-types'
import FontAwesome from 'react-fontawesome'
import classNames from 'classnames'
import ImmutablePropTypes from 'react-immutable-proptypes'
import moment from 'moment'
import { List, Set } from 'immutable'
import { connect } from 'react-redux'
import { suspendPolicyType, ADMIN_POLICY } from 'state/actions'

import JsonDisplay from 'components/JsonDisplay'
import Markdown from 'components/Markdown'
import Time from 'components/Time'

import Accordion from 'components/Accordion'
import Button from 'components/Button'

import style from './SolutionDetails.styl'

const summaryStyle = {
  upperPanelCollapsed: classNames(style.summaryAccordion),
  upperPanelExpanded: classNames(style.summaryAccordion, style.active),
  lowerPanelCollapsed: classNames(style.summaryPanel),
  lowerPanelExpanded: classNames(style.summaryPanel, style.show)
}

function renderTags (tags) {
  return (tags || List()).map((tag, idx) => (
    <li key={idx}>{tag}</li>
  ))
}

function renderResourceType (resourceType) {
  return (
    <div>
      <li>
        <label>Name:</label>
        <span>{resourceType.get('name')}</span>
      </li>

      <li>
        <label>Description:</label>
        <span>{resourceType.get('description')}</span>
      </li>

      {resourceType.get('provider_access_type') ? (
        <li>
          <label>Provider Access Type:</label>
          <span>{resourceType.get('provider_access_type')}</span>
        </li>
      ) : null}
    </div>
  )
}

class Triggers extends React.Component {
  constructor (props) {
    super(props)
    this.state = { openTriggers: Set() }
  }

  render () {
    const onTriggerToggle = triggerId => {
      if (this.state.openTriggers.includes(triggerId)) {
        this.setState({ openTriggers: this.state.openTriggers.remove(triggerId) })
      } else {
        this.setState({ openTriggers: this.state.openTriggers.add(triggerId) })
      }
    }

    return (
      <div className={style.accordionList}>
        {this.props.triggers.map((trigger, idx) => {
          return (
            <div key={idx}>
              <Accordion
                content={
                  <div>
                    <label>Configuration:</label>
                    <li>
                      <JsonDisplay object={trigger.get('configuration')} className={style.jsonDisplay} />
                    </li>
                  </div>
                }
                title={trigger.get('type')}
                style={summaryStyle}
                color={classNames(style.accordionColor)}
                open={this.state.openTriggers.includes(trigger.get('id'))}
                onOpen={() => onTriggerToggle(trigger.get('id'))} />
            </div>
          )
        })
        }
      </div>
    )
  }
}

class ProviderAccess extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      openProviderAccess: Set()
    }
  }

  render () {
    const onProviderAccessToggle = accessType => {
      if (this.state.openProviderAccess.includes(accessType)) {
        this.setState({ openProviderAccess: this.state.openProviderAccess.remove(accessType) })
      } else {
        this.setState({ openProviderAccess: this.state.openProviderAccess.add(accessType) })
      }
    }

    return (
      <div className={style.accordionList}>
        {this.props.providerAccess.map((access, idx) => {
          return (
            <div key={idx}>
              <Accordion
                content={
                  <div>
                    <label>Permissions:</label>
                    <li>
                      <JsonDisplay object={access.get('permissions')} className={style.jsonDisplay} />
                    </li>
                  </div>
                }
                title={access.get('type')}
                style={summaryStyle}
                color={classNames(style.accordionColor)}
                open={this.state.openProviderAccess.includes(access.get('type'))}
                onOpen={() => onProviderAccessToggle(access.get('type'))} />
            </div>
          )
        })}
      </div>
    )
  }
}

function renderPolicyTypeItem (suspendPolicyType, policyType, currentUserName, organizationRole) {
  const suspension = policyType.get('suspension')
  const suspended = suspension.get('enabled')
  const suspensionElement =
    <div className={style.suspension}>
      {
        suspended
          ? (
            <p>
              Suspended since <Time value={moment.parseZone(suspension.get('since'))} />.
              Reason: {suspension.get('reason')}
            </p>
          )
          : <p> Not suspended </p>
      }
      {
        organizationRole === ADMIN_POLICY
          ? (
            <Button
              type={Button.PRIMARY}
              onClick={() => suspendPolicyType(policyType.get('id'), !suspended, currentUserName)} >
              {suspended ? 'Resume' : 'Suspend'}
            </Button>
          )
          : null
      }
    </div>

  return (
    <div>
      <li>
        <label>Name:</label>
        <span>{policyType.get('name')}</span>
      </li>

      <li>
        <label>Description:</label>
        <span>{policyType.get('description')}</span>
      </li>

      <li>
        <label>Suspension:</label>
        {suspensionElement}
      </li>

      <li>
        <label>Resource Types:</label>
        <ul>
          {policyType.get('resource_types').map((rt, idx) => {
            return (<li key={idx}>{rt}</li>)
          })}
        </ul>
      </li>

      <div className={style.nested}>
        <label>Triggers:</label>
        <Triggers triggers={policyType.get('triggers')} />
      </div>

      {policyType.get('provider_access') ? (
        <div className={style.nested}>
          <label>Provider Access:</label>
          <ProviderAccess providerAccess={policyType.get('provider_access')} />
        </div>
      ) : null}

      <label>Schema:</label>
      <li>
        <JsonDisplay object={policyType.get('schema')} className={style.jsonDisplay} />
      </li>
    </div>
  )
}

function renderMetricTypeItem (metricType) {
  return (
    <div>
      <li>
        <label>Name:</label>
        {metricType.get('name')}
      </li>

      <li>
        <label>Description:</label>
        {metricType.get('description')}
      </li>

      <li>
        <label>Type:</label>
        {metricType.get('type')}
      </li>

      {metricType.get('resource_types') && metricType.get('resource_types').size > 0 ? (
        <li>
          <label>Resource Types:</label>
          <ul>
            {metricType.get('resource_types').map(rt => {
              return <li key={rt}>{rt}</li>
            })}
          </ul>
        </li>
      ) : null}
    </div>
  )
}

export class SolutionDetails extends React.Component {
  render () {
    return (
      <div className={style.SolutionViewer}>
        <ul className={style.solutionsList}>
          <Name name={this.props.solution.get('name')} />
          <Version version={this.props.solution.get('version')} />
          <Maintainer maintainer={this.props.solution.get('maintained_by')} />
          {this.props.solution.get('tags') && this.props.solution.get('tags').size > 0
            ? <Tags tags={this.props.solution.get('tags')} />
            : null
          }
          <Description description={this.props.solution.get('description')} />
          <PolicyTypes
            policyTypes={this.props.solution.get('policy_types', List())}
            suspendPolicyType={this.props.suspendPolicyType}
            currentUserName={this.props.currentUserName}
            organizationRole={this.props.organizationRole} />
          {this.props.solution.get('metric_types') && this.props.solution.get('metric_types').size > 0
            ? <MetricTypes metricTypes={this.props.solution.get('metric_types')} />
            : null
          }
          {this.props.solution.get('resource_types') && this.props.solution.get('resource_types').size > 0
            ? <DiscoveredResourceTypes resourceTypes={this.props.solution.get('resource_types')} />
            : null
          }
        </ul>
      </div>
    )
  }
}

class Name extends React.Component {
  render () {
    const name = this.props.name
    return (<li>
      <label>Name:</label>
      <span>{name}</span>
    </li>)
  }
}

class Version extends React.Component {
  render () {
    const version = this.props.version
    return (<li>
      <label>Version:</label>
      <span>{version || 'Unknown'}</span>
    </li>)
  }
}

/**
 * Returns an appropriate Font Awesome key for given hyperlink. If none exists, returns the generic "link chain" key.
 * The returned value can be used to display an icon beside the hyperlink.
*/
function pickIconForLink (link) {
  // Matches
  // - https://slack.com/...
  // - https://slack.*.com/...
  // - https://*.slack.com/...
  const isSlack = link.match(/https:\/\/(.+\.)?slack(.*\.)com(\/)/g)
  if (isSlack) return 'slack'

  const isEmail = link.match(/^mailto:.*/g)
  if (isEmail) return 'envelope'

  return 'link'
}

function renderLinks (links) {
  const entries = []
  for (const [tag, link] of links) {
    entries.push(
      <li key={tag}>
        <a href={link}>
          <label><FontAwesome name={pickIconForLink(link)} />{tag}</label>
        </a>
      </li>
    )
  }

  return entries
}

class Maintainer extends React.Component {
  render () {
    let maintainer = this.props.maintainer
    if (!maintainer) return null

    const name = maintainer.get('name')
    const links = maintainer.get('links')
    if (!name || !links) return null

    return (
      <li>
        <label>Maintained by</label>
        <ul>
          <li><label>{name}</label></li>
          {renderLinks(links)}
        </ul>
      </li>
    )
  }
}

class Tags extends React.Component {
  render () {
    const tags = this.props.tags
    return (
      <li>
        <label>Tags:</label>
        <ul className={style.tags}>
          {renderTags(tags)}
        </ul>
      </li>)
  }
}

class Description extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      open: false
    }
  }

  render () {
    const description = this.props.description
    return (<li className={style.solutionDescription}>
      <label>Description:</label>
      <div className={style.accordionList}>
        <Accordion
          content={<Markdown source={description} />}
          title='Description'
          style={summaryStyle}
          color={classNames(style.accordionColor)}
          open={this.state.open}
          onOpen={() => this.setState({ open: !this.state.open })} />
      </div>
    </li>)
  }
}

export class PolicyTypes extends React.Component {
  constructor (props) {
    super(props)
    this.state = { openPolicyTypes: Set() }
  }

  render () {
    const onPolicyTypeToggle = policyTypeId => {
      if (this.state.openPolicyTypes.includes(policyTypeId)) {
        this.setState({ openPolicyTypes: this.state.openPolicyTypes.remove(policyTypeId) })
      } else {
        this.setState({ openPolicyTypes: this.state.openPolicyTypes.add(policyTypeId) })
      }
    }
    return (<li>
      <label>Policy Types:</label>
      <ul className={style.accordionList}>
        {this.props.policyTypes.map((policyType, idx) => {
          const info = policyType.getIn(['suspension', 'enabled'])
            ? <span
              className={style.policyDisplayTitleInfo}
              title={`Policy type suspended because: ${policyType.getIn(['suspension', 'reason'])}`}
            >
              <FontAwesome name='info-circle' className={style.info} />
            </span>
            : null
          const title = <span>{info} {policyType.get('label')}</span>
          return (<div key={idx}>
            <Accordion
              content={renderPolicyTypeItem(this.props.suspendPolicyType, policyType, this.props.currentUserName, this.props.organizationRole)}
              title={title}
              style={summaryStyle}
              color={classNames(style.accordionColor)}
              open={this.state.openPolicyTypes.includes(policyType.get('id'))}
              onOpen={() => onPolicyTypeToggle(policyType.get('id'))} />
          </div>)
        })}
      </ul>
    </li>)
  }
}

class DiscoveredResourceTypes extends React.Component {
  constructor (props) {
    super(props)
    this.state = { openResourceTypes: Set() }
  }

  render () {
    const onToggle = resourceTypeId => {
      if (this.state.openResourceTypes.includes(resourceTypeId)) {
        this.setState({ openResourceTypes: this.state.openResourceTypes.remove(resourceTypeId) })
      } else {
        this.setState({ openResourceTypes: this.state.openResourceTypes.add(resourceTypeId) })
      }
    }

    return (<li>
      <label>Discovered Resource Types:</label>
      <ul className={style.accordionList}>
        {this.props.resourceTypes.map((resourceType, idx) => {
          return (<div key={idx}>
            <Accordion
              content={renderResourceType(resourceType)}
              title={resourceType.get('label')}
              style={summaryStyle}
              color={classNames(style.accordionColor)}
              open={this.state.openResourceTypes.includes(resourceType.get('id'))}
              onOpen={() => onToggle(resourceType.get('id'))} />
          </div>)
        })}
      </ul>
    </li>)
  }
}

class MetricTypes extends React.Component {
  constructor (props) {
    super(props)
    this.state = { openMetricType: Set() }
  }

  render () {
    const onMetricTypeToggle = metricTypeId => {
      if (this.state.openMetricType.includes(metricTypeId)) {
        this.setState({ openMetricType: this.state.openMetricType.remove(metricTypeId) })
      } else {
        this.setState({ openMetricType: this.state.openMetricType.add(metricTypeId) })
      }
    }

    const metricTypes = this.props.metricTypes
    return (
      <li>
        <label>Metric Types:</label>
        <ul className={style.accordionList}>
          {metricTypes.map((metricType, idx) => {
            return (<div key={idx}>
              <Accordion
                content={renderMetricTypeItem(metricType)}
                title={metricType.get('label')}
                style={summaryStyle}
                color={classNames(style.accordionColor)}
                open={this.state.openMetricType.includes(metricType.get('id'))}
                onOpen={() => onMetricTypeToggle(metricType.get('id'))}
              />
            </div>)
          })}
        </ul>
      </li>
    )
  }
}

PolicyTypes.propTypes = {
  policyTypes: ImmutablePropTypes.list.isRequired,
  suspendPolicyType: PropTypes.func.isRequired,
  currentUserName: PropTypes.string.isRequired,
  organizationRole: PropTypes.number.isRequired
}

Name.propTypes = {
  name: PropTypes.string.isRequired
}

Version.propTypes = {
  version: PropTypes.string
}

Description.propTypes = {
  description: PropTypes.string.isRequired
}

Tags.propTypes = {
  tags: ImmutablePropTypes.list.isRequired
}

DiscoveredResourceTypes.propTypes = {
  resourceTypes: ImmutablePropTypes.list.isRequired
}

MetricTypes.propTypes = {
  metricTypes: ImmutablePropTypes.list.isRequired
}

SolutionDetails.propTypes = {
  solution: ImmutablePropTypes.map.isRequired,
  organizationRole: PropTypes.number.isRequired
}

function mapDispatchToProps (dispatch) {
  return {
    suspendPolicyType: (policyTypeId, suspended, userName) => dispatch(suspendPolicyType(policyTypeId, suspended, userName))
  }
}

function mapStateToProps (state) {
  return {
    currentUserName: state.auth.profile.name
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SolutionDetails)
