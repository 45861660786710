import PropTypes from 'prop-types'
import React from 'react'
import FontAwesome from 'react-fontawesome'

import Button from 'components/Button'

import style from './CardHeader.styl'

export class CardHeader extends React.Component {
  _createActionButtons = (actions) => {
    return actions.map((action, idx) => (
      <Button
        className={style.actionButton}
        onClick={action['onClick']}
        flat
        key={idx} >
        <FontAwesome name={action['icon']} />
      </Button>
    ))
  }

  _getActions = () => (
    this.props.actions ? (
      this._createActionButtons(this.props.actions)
    ) : null
  )

  render () {
    return (
      <div>
        <div className={style.CardHeader}>
          <div className={style.title}>{this.props.title}</div>
          {this.props.rightBarButtonItem}
          {this._getActions()}
        </div>
      </div>
    )
  }
}

CardHeader.propTypes = {
  title: PropTypes.node.isRequired,
  actions: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
  })),
  rightBarButtonItem: PropTypes.node
}

export default CardHeader
