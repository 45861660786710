import PropTypes from 'prop-types'
import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'

import LoadingIndicator from 'components/LoadingIndicator'
import { Attributes } from 'components/Attribute'

import style from './RankedAttributeList.styl'

export default function RankedAttributeList ({ attributeBase, attributeRanking, resource }) {
  if (!resource || !attributeRanking) {
    return <div className={style.loading}><LoadingIndicator /></div>
  } else if (attributeRanking.size === 0) {
    return <div>No Information</div>
  } else {
    return <Attributes attributes={attributeRanking} context={resource.getIn(attributeBase)} />
  }
}

RankedAttributeList.propTypes = {
  attributeBase: PropTypes.arrayOf(PropTypes.string),
  attributeRanking: ImmutablePropTypes.list,
  resource: ImmutablePropTypes.map
}

RankedAttributeList.defaultProps = {
  attributeBase: [ 'blob' ]
}
