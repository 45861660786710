import { Record } from 'immutable'

export const ConfirmationModalRecord = Record({
  title: '',
  description: '',
  callback: () => {},
  disabled: input => false,
  visible: false,
  enableInput: false
})
