import PropTypes from 'prop-types'
import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import FontAwesome from 'react-fontawesome'
import { List } from 'immutable'

import Button from 'components/Button'
import Hint from 'components/Hint'

import style from '../ResourceProviderCreator/ResourceProviderCreator.styl'
import httpStyle from '../CredentialsForms/CredentialsFormHttp/CredentialsFormHttp.styl'

export class ResourceProviderEditor extends React.Component {
  constructor () {
    super()

    this._onSubmit = this._onSubmit.bind(this)
    this._onNameChange = this._onNameChange.bind(this)
    this._addDataItem = this._addDataItem.bind(this)
    this._removeDataItem = this._removeDataItem.bind(this)
    this._onDataKeyChange = this._onDataKeyChange.bind(this)
    this._onDataValueChange = this._onDataValueChange.bind(this)

    this.state = {
      type: '',
      name: '',
      data: [
        {key: '', value: '', required: false}
      ]
    }
  }

  componentWillMount () {
    const required = this.props.resourceProviderType.getIn(['schema', 'required'], List())

    let keys = []

    let data = this.props.resourceProvider.get('data')
      ? this.props.resourceProvider.get('data').reduce((acc, value, key) => {
        keys.push(key)
        return acc.concat([{key, value, required: required.includes(key)}])
      }, []) : [{key: '', value: '', required: false}]

    required.forEach(requirement => {
      if (!keys.includes(requirement)) {
        data.push({key: requirement, value: '', required: true})
      }
    })

    this.state = {
      type: this.props.resourceProvider.get('type', ''),
      name: this.props.resourceProvider.get('name', ''),
      data
    }
  }

  _addDataItem () {
    this.setState({
      data: this.state.data.concat([{key: '', value: '', required: false}])
    })
  }

  _removeDataItem (index) {
    this.state.data.splice(index, 1)
    this.setState({
      data: this.state.data
    })
  }

  _onDataKeyChange (index, { target }) {
    this.state.data[index].key = target.value
    this.setState({
      data: this.state.data
    })
  }

  _onDataValueChange (index, { target }) {
    this.state.data[index].value = target.value
    this.setState({
      data: this.state.data
    })
  }

  _onNameChange ({ target }) {
    this.setState({name: target.value})
  }

  _onSubmit (e) {
    e.preventDefault()

    const data = this.state.data.reduce((acc, datum) => {
      return { ...acc, [datum.key]: datum.value }
    }, {})

    const fields = {
      name: this.state.name,
      type: this.state.type,
      data
    }

    this.props.onUpdate(this.props.resourceProvider.get('id'), fields)
      .then(() => this.props.onClose())
  }

  renderDataInputs () {
    return this.state.data.map((data, index) => {
      return (<div key={index} className={httpStyle.inputContent}>
        <input
          name='data key' type='text'
          placeholder='key'
          pattern='^[a-zA-Z-]+'
          value={data.key || ''}
          readOnly={data.required}
          onChange={this._onDataKeyChange.bind(this, index)} />
        <input
          name='data value' type='text'
          placeholder='value'
          value={data.value || ''}
          required={data.required}
          onChange={this._onDataValueChange.bind(this, index)} />
        {
          !data.required &&
          <Button type={Button.WARN} onClick={this._removeDataItem.bind(this, index)} >
            <FontAwesome name='minus' />
          </Button>
        }
      </div>)
    }).concat([
      <Button key='add' onClick={this._addDataItem} >
        <FontAwesome name='plus' />
      </Button>
    ])
  }

  render () {
    return (
      <div className={style.contents}>
        <form onSubmit={this._onSubmit}>
          <div>
            <div className={style.formField}>
              <label>
                Resource provider name
                <Hint className={style.hint}>
                  Provide a unique name for the resource provider.
                </Hint>
              </label>
              <input
                required
                type='text'
                name='ResourceProviderName'
                value={this.state.name}
                onChange={this._onNameChange} />
            </div>
            <div className={httpStyle.splitField}>
              <label>
                Additional provider data
                <Hint className={style.hint}>
                  Additional data fields
                </Hint>
              </label>
              {this.renderDataInputs()}
            </div>
            <div className={style.controls}>
              <Button onClick={() => this.props.onClose()} flat>Cancel</Button>
              <Button type={Button.PRIMARY} submit>Save</Button>
            </div>
          </div>
        </form>
      </div>
    )
  }
}

ResourceProviderEditor.propTypes = {
  resourceProvider: ImmutablePropTypes.map,
  resourceProviderType: ImmutablePropTypes.map,
  onUpdate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}

export default ResourceProviderEditor
