import React from 'react'
import { connect } from 'react-redux'

import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'

import Button from 'components/Button'

import style from './RestrictedCredentialsEditor.styl'
import { notifyUser } from 'state/actions'

import CredentialsFormAws from '../../../CredentialsForms/CredentialsFormAws'
import CredentialsFormAzure from '../../../CredentialsForms/CredentialsFormAzure'
import CredentialsFormHttp from '../../../CredentialsForms/CredentialsFormHttp'
import CredentialsFormOauth2 from '../../../CredentialsForms/CredentialsFormOauth2'

export class RestrictedCredentialsEditor extends React.Component {
  constructor () {
    super()

    this._onSubmit = this._onSubmit.bind(this)
    this._onCredentialsChange = this._onCredentialsChange.bind(this)

    this.credentials = {}
  }

  _onCredentialsChange (credentials) {
    this.credentials = credentials
  }

  _onSubmit (e) {
    e.preventDefault()

    this.props.onSave(this.credentials)
  }

  _displayCredentialsInformation (resourceProvider, credentials) {
    return `You are providing credentials for resource provider "${resourceProvider.get('name')}" to use policies of type "${credentials.get('policy_type_name')}"`
  }

  selectProviderAccessChildComponent (accessType) {
    switch (accessType) {
      case 'aws':
        return (
          <div>
            <div className={style.description}>
              <p>
              Provide a role ARN, whose permissions match those requested by the policy type
              </p>
            </div>

            <CredentialsFormAws onCredentialsChange={this._onCredentialsChange} />
          </div>
        )
      case 'azure':
        return (
          <div>
            <div className={style.description}>
              <p>
                This resource provider type uses a tenant ID, application ID and an application secret.
              </p>
            </div>

            <CredentialsFormAzure onCredentialsChange={this._onCredentialsChange} />
          </div>
        )
      case 'http':
        return <CredentialsFormHttp onCredentialsChange={this._onCredentialsChange} />
      case 'oauth2':
        return <CredentialsFormOauth2 onCredentialsChange={this._onCredentialsChange} />
      default:
        this.props.onError(accessType + ' is an unknown access type. It cannot be created at this time. Please use the "Provide feedback" button to report this problem.')
    }
  }

  render () {
    return (
      <div>
        <div className={style.description}>
          {this._displayCredentialsInformation(this.props.resourceProvider, this.props.credentials)}
        </div>
        <div className={style.contents}>
          <form onSubmit={this._onSubmit}>
            {this.selectProviderAccessChildComponent(this.props.credentials.get('access_type'))}
            <div className={style.controls}>
              <Button onClick={() => this.props.onCancel()} flat>Cancel</Button>
              <Button type={Button.PRIMARY} submit>Save</Button>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

RestrictedCredentialsEditor.propTypes = {
  resourceProvider: ImmutablePropTypes.map.isRequired,
  credentials: ImmutablePropTypes.map.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired
}

function mapDispatchToProps (dispatch) {
  return {
    onError: error => dispatch(notifyUser(error, 'error'))
  }
}

export default connect(
  null, mapDispatchToProps
)(RestrictedCredentialsEditor)
