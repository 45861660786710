import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import FontAwesome from 'react-fontawesome'

import { savePolicy, deletePolicy, validatePropagatedPolicy } from 'state/resources/actions'
import { startFeatureTour, ADMIN_POLICY } from 'state/actions'

import Button from 'components/Button'
import ClickableList from 'components/ClickableList'
import JsonDisplay from 'components/JsonDisplay'
import ModalDialog from 'components/ModalDialog'
import PolicyEditor from './components/PolicyEditor'
import { POLICY_TYPE_SELECTOR } from 'refs'

import style from './GroupPolicyDetails.styl'

export class GroupPolicyDetails extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      open: false,
      workingPolicy: this._getWorkingPolicy(props.currentPolicyTypeId, props.attachedPolicies)
    }
  }

  _getPolicyView = () => {
    let props = {
      policy: this.state.workingPolicy || {},
      policyType: this.props.policyTypes.find(policyType => policyType.get('id') === this.props.currentPolicyTypeId) || Map(),
      onUpdate: this.updatePolicy,
      onSave: this.onSave.bind(this),
      propagatedPolicyValidation: this.props.propagatedPolicyValidation,
      validatePropagatedPolicy: this.props.validatePropagatedPolicy
    }

    return (<PolicyEditor {...props} />)
  }

  _getWorkingPolicy (policyTypeId, attachedPolicies) {
    const workingPolicyMap = this._getPolicyOfType(attachedPolicies, policyTypeId)
    return workingPolicyMap ? workingPolicyMap.get('policy', Map()).toJS() : {}
  }

  _setWorkingPolicy (policyTypeId, attachedPolicies = this.props.attachedPolicies) {
    this.setState({ workingPolicy: this._getWorkingPolicy(policyTypeId, attachedPolicies) })
  }

  _getPolicyOfType (policies = this.props.attachedPolicies, policyTypeId = this.props.currentPolicyTypeId) {
    return policies.find(p => p.get('policy_type_id') === policyTypeId)
  }

  componentWillMount () {
    this.props.onPolicyTypeChanged(this.props.policyTypes, this.props.currentPolicyTypeId)
  }

  componentWillReceiveProps (nextProps) {
    const newResourceGroup = !nextProps.resourceGroup.equals(this.props.resourceGroup)
    const newPolicyType = nextProps.currentPolicyTypeId !== this.props.currentPolicyTypeId
    const newAttachedPolicies = !nextProps.attachedPolicies.equals(this.props.attachedPolicies)
    const newPolicyTypes = !nextProps.policyTypes.equals(this.props.policyTypes)
    if (newPolicyType || newAttachedPolicies) {
      this._setWorkingPolicy(nextProps.currentPolicyTypeId, nextProps.attachedPolicies)
    }
    if (newPolicyType || newPolicyTypes || newResourceGroup) {
      this.props.onPolicyTypeChanged(nextProps.policyTypes, nextProps.currentPolicyTypeId)
    }
  }

  toggleModal = () => {
    this.setState({
      open: !this.state.open
    })
  }

  onModalClose = () => {
    this.setState({
      open: false
    })
  }

  onSave = policy => {
    const activePolicy = this._getPolicyOfType()

    this.props.savePolicy(
      policy,
      this.props.currentPolicyTypeId,
      activePolicy ? activePolicy.get('id') : null
    )
      .then(() => this.onModalClose())
  }

  updatePolicy = (workingPolicy) => {
    this.setState({ workingPolicy })
  }

  render () {
    if (!this.props.policyTypes.size || this.props.resourceGroup.isEmpty()) {
      return <div>There are no policies in this context.</div>
    }

    const policy = this._getPolicyOfType()
    const policyType = this.props.policyTypes.find(policyType => policyType.get('id') === this.props.currentPolicyTypeId)
    const policyObject = policy ? policy.get('policy').toJS() : null
    const policyName = (this.props.policyTypes.find(policyType => policyType.get('id') === this.props.currentPolicyTypeId) || Map()).get('label')

    const policyEditorModal = this.state.open ? (
      this.props.organizationRole === ADMIN_POLICY ? (
        <ModalDialog
          onDismiss={this.onModalClose}
          title={policyName ? `Policy Editor (${policyName})` : 'Policy Editor'}
          actions={[{onClick: this.props.onStartFeatureTour, icon: 'question'}]}
          disableClickClose
        >
          {this._getPolicyView()}
        </ModalDialog>
      ) : (
        <ModalDialog
          onDismiss={this.onModalClose}
          title={policyName ? `Group Policy (${policyName})` : 'Group Policy'}
          disableClickClose
        >
          <JsonDisplay object={this.state.workingPolicy} className={style.jsonDisplay} />
        </ModalDialog>
      )
    ) : null

    const deleteButton = policy && this.props.organizationRole === ADMIN_POLICY ? (
      <Button
        type={Button.WARN}
        onClick={() => this.props.deletePolicy(policy.get('id'))}>
        Delete
      </Button>
    ) : null

    const policyDisplayInfo = policyType && policyType.getIn(['suspension', 'enabled'])
      ? <div className={style.policyDisplayInfo}>
        <FontAwesome name='info-circle' className={style.info} />
        Policy type suspended because: {policyType.getIn(['suspension', 'reason'])}
      </div>
      : null
    const policyDisplay = <div className={style.policyDisplay}>
      {policyDisplayInfo}
      {policy ? (
        <JsonDisplay object={policyObject} clickAction={this.toggleModal} className={style.jsonDisplay} />
      ) : (
        <JsonDisplay object={'No Policy'} className={style.jsonDisplay} />
      )}
    </div>

    const controls = this.props.organizationRole === ADMIN_POLICY ? (
      <div className={style.controls}>
        <div>
          <Button onClick={this.toggleModal} type={Button.PRIMARY}>
            {policy ? 'Edit' : 'Add'}
          </Button>
          {deleteButton}
        </div>
      </div>
    ) : null

    return (
      <div>
        <div className={style.groupPolicies} data-ref={POLICY_TYPE_SELECTOR}>
          <ClickableList
            items={this.props.policyTypes.sortBy(type => type.get('label'))}
            selected={this.props.currentPolicyTypeId}
            clickAction={this.props.onPolicyTypeChanged.bind(null, this.props.policyTypes)}
            displayAttribute='label'
            selectAttribute='id' />
          {policyDisplay}
          {controls}
        </div>
        <div className='inverted'>
          {policyEditorModal}
        </div>
      </div>
    )
  }
}

GroupPolicyDetails.propTypes = {
  policyTypes: ImmutablePropTypes.list.isRequired,
  validatePropagatedPolicy: PropTypes.func.isRequired,
  propagatedPolicyValidation: ImmutablePropTypes.map.isRequired,
  attachedPolicies: ImmutablePropTypes.list.isRequired,
  resourceGroup: ImmutablePropTypes.map.isRequired,
  onStartFeatureTour: PropTypes.func.isRequired,
  organizationRole: PropTypes.number.isRequired,
  onPolicyTypeChanged: PropTypes.func.isRequired,
  currentPolicyTypeId: PropTypes.string.isRequired
}

function mapStateToProps (state, ownProps) {
  return {
    propagatedPolicyValidation: state.propagatedPolicyValidation
  }
}

function mapDispatchToProps (dispatch, ownProps) {
  return {
    savePolicy: (policy, policyTypeId, policyId) => {
      return dispatch(savePolicy(
        ownProps.resourceGroup.get('id'),
        policy,
        policyTypeId,
        policyId
      ))
    },
    deletePolicy: attachedPolicyId => dispatch(deletePolicy(attachedPolicyId, ownProps.resourceGroup.get('id'))),
    validatePropagatedPolicy: (policy, policyTypeId) => {
      dispatch(validatePropagatedPolicy(ownProps.resourceGroup.get('id'), policy, policyTypeId))
    },
    onStartFeatureTour: () => dispatch(startFeatureTour('policies'))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupPolicyDetails)
