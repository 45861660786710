import PropTypes from 'prop-types'
import React from 'react'
import JSONTree from 'react-json-tree'

import { defaultTheme, compliantTheme, noncompliantTheme } from './theme'

function getTheme (compliant) {
  const theme = compliant === null ? defaultTheme : compliant ? compliantTheme : noncompliantTheme
  return {
    tree: { backgroundColor: 'none', margin: 0, fontWeight: 'bold' },
    nestedNode: { paddingLeft: 0, marginLeft: 0 },
    nestedNodeChildren: { paddingLeft: '0.875em', marginLeft: '0.875em' },
    arrowContainer: { paddingBottom: '0.375em' },
    value: { textIndent: 0, paddingLeft: 0, marginLeft: 0, paddintTop: 0, paddingBottom: '0.375em' },
    extend: theme
  }
}

export default function JsonTree ({ context, compliant = null }) {
  return <JSONTree data={context} theme={getTheme(compliant)} hideRoot invertTheme={false} />
}

JsonTree.propTypes = {
  context: PropTypes.object.isRequired,
  compliant: PropTypes.bool
}
