import React from 'react'
import FontAwesome from 'react-fontawesome'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import style from './Hint.styl'

export class Hint extends React.Component {
  render () {
    return (
      <div className={classNames(this.props.className, style.Hint)}>
        <FontAwesome name='info-circle' />
        <div className={style.text}>
          {this.props.children}
        </div>
      </div>
    )
  }
}

Hint.propTypes = {
  className: PropTypes.string
}

export default Hint
