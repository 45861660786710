export function splitArn (arn) {
  if (!arn) {
    return null
  }
  const splitedArn = arn.split(':')
  return {
    partition: splitedArn[1],
    service: splitedArn[2],
    region: splitedArn[3],
    account_id: splitedArn[4],
    path: splitedArn.slice(5).join(':')
  }
}
