import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'

import style from './Tabs.styl'

export class Tabs extends React.Component {
  renderNav = (children, selectedTabTitle) => {
    const navTabClass = title => (
      classNames(
        style.navigationTab,
        this.props.navTabClassName,
        { [style.active]: title === selectedTabTitle }
      )
    )

    return children.map((child, index) =>
      <li key={child.props.title} onClick={() => this.props.onTabChange(child.props.title)} className={navTabClass(child.props.title)}>
        {child.props.title}
      </li>)
  }

  render () {
    const children = React.Children.toArray(this.props.children)

    if (children.length === 0) {
      return null
    }

    const selectedTabTitle = this.props.selectedTabTitle ? this.props.selectedTabTitle : children[0].props.title

    const navigation = (<ul>{this.renderNav(children, selectedTabTitle)}</ul>)

    const content = children.find((child, index) =>
      child.props.title === selectedTabTitle
    )

    return (
      <div className={this.props.className}>
        <div>
          <div className={classNames(style.navigation, this.props.navClassName)}>
            {navigation}
            {content.props.tabBarExtra}
          </div>
        </div>
        <div className={this.props.contentClassName}>{content}</div>
      </div>
    )
  }
}

const titledType = PropTypes.shape({
  props: PropTypes.shape({
    title: PropTypes.string.isRequired
  })
})

Tabs.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(titledType),
    titledType
  ]),
  className: PropTypes.string,
  navClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  navTabClassName: PropTypes.string,
  activeNavTabClassName: PropTypes.string,
  disableSelectionReset: PropTypes.bool,
  onTabChange: PropTypes.func.isRequired,
  selectedTabTitle: PropTypes.string
}
