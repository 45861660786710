import classNames from 'classnames'
import { easeCubic } from 'd3-ease'
import { interpolate } from 'd3-interpolate'
import { scaleLinear, scaleOrdinal } from 'd3-scale'
import { select } from 'd3-selection'
import { arc } from 'd3-shape'

import colorScheme from './colorScheme'
import complianceColorScheme from 'scenes/Resources/complianceColors'
import style from '../ResourceGroupGraph.styl'

const TRANSITION_DURATION = 700
const HIGHLIGHT_TRANSITION_DURATION = 200
const MAX_DEPTH = 4

const xScale = scaleLinear().range([0, 2 * Math.PI])
const yScale = scaleLinear()

let currentDepth = 0

export function renderArcs (resourceGroupNodes, activeResourceGroupNode, parentElement, activeResourceGroupId, onSelect, renderText, enableTransitions, highlightedResourceGroup, highlightResourceGroup, resetResourceGroupHighlight) {
  const centerResourceGroupNode = activeResourceGroupNode.parent || activeResourceGroupNode

  if (!centerResourceGroupNode) {
    return
  }

  const color = scaleOrdinal(colorScheme)
  const updatingResourceGroups = select(parentElement).selectAll('g')
    .data(resourceGroupNodes, resourceGroupNode => resourceGroupNode.data.id)

  const enteringResourceGroups = updatingResourceGroups.enter().append('g')
  const currentResourceGroups = updatingResourceGroups.merge(enteringResourceGroups)
  const exitingResourceGroups = updatingResourceGroups.exit()

  const nodeVisibility = node => node.depth <= MAX_DEPTH + currentDepth ? 'visible' : 'hidden'

  enteringResourceGroups
    .append('path')
    .on('click', resourceGroupNode => onSelect(resourceGroupNode.data.id))
    .on('mouseover', resourceGroupNode => highlight(resourceGroupNode.data.id, currentResourceGroups, highlightResourceGroup))
    .on('mouseout', () => resetHighlight(currentResourceGroups, resetResourceGroupHighlight, highlightedResourceGroup))

  currentResourceGroups
    .select('path')
    .attr('class', resourceGroupNode => {
      return classNames(
        'resourceGroup',
        style.path,
        {
          [style.active]: resourceGroupNode.data.id === activeResourceGroupNode.data.id,
          [style.attachedPolicy]: hasAttachedPolicies(resourceGroupNode),
          [style.propagatedPolicy]: !hasAttachedPolicies(resourceGroupNode) && hasPropagatedPolicies(resourceGroupNode)
        }
      )
    })
    .style('fill', resourceGroupNode => {
      if (hasComplianceInfo(resourceGroupNode)) {
        return generateComplianceColor(resourceGroupNode)
      }
      if (resourceGroupNode.data.id === activeResourceGroupNode.data.id) {
        return null
      }
      return generateFillColor(resourceGroupNode, color)
    })
    .transition()
    .duration(enableTransitions ? TRANSITION_DURATION : 0)
    .ease(easeCubic)
    .attrTween('d', resourceGroupNode => {
      return interpolatePosition(activeResourceGroupNode, centerResourceGroupNode, resourceGroupNode)
    })
    .attrTween('visibility', resourceGroupNode => tween => {
      return tween < 1 || isVisible(centerResourceGroupNode, activeResourceGroupNode, resourceGroupNode) ? nodeVisibility(resourceGroupNode) : 'hidden'
    })
    .on('end', function (resourceGroupNode) {
      if (!isVisible(centerResourceGroupNode, activeResourceGroupNode, resourceGroupNode)) {
        return
      }

      if (centerResourceGroupNode === resourceGroupNode) {
        currentDepth = resourceGroupNode.depth
      }

      select(this.parentNode).select('path.resourceGroup')
        .attr('visibility', nodeVisibility)
    })

  currentResourceGroups
    .transition()
    .duration(enableTransitions ? 200 : 0)
    .style('opacity', resourceGroupNode => {
      if (!highlightedResourceGroup) {
        return 1
      }

      if (highlightedResourceGroup === resourceGroupNode.data.id) {
        return 1
      }

      return 0.3
    })

  exitingResourceGroups
    .transition()
    .duration(enableTransitions ? HIGHLIGHT_TRANSITION_DURATION : 0)
    .style('opacity', 0)
    .remove()
}

function isVisible (centerResourceGroupNode, activeResourceGroupNode, resourceGroupNode) {
  return isDescendant(activeResourceGroupNode, resourceGroupNode) ||
    activeResourceGroupNode.data.id === resourceGroupNode.data.id ||
    centerResourceGroupNode.data.id === resourceGroupNode.data.id
}

function isDescendant (parent, potentialChild) {
  if (!potentialChild.parent) {
    return false
  }

  if (parent === potentialChild.parent) {
    return true
  }

  return isDescendant(parent, potentialChild.parent)
}

function createArcGenerator () {
  return arc()
    .startAngle(resourceGroupNode => Math.max(0, Math.min(2 * Math.PI, xScale(resourceGroupNode.x0))))
    .endAngle(resourceGroupNode => Math.max(0, Math.min(2 * Math.PI, xScale(resourceGroupNode.x1))))
    .padAngle(1)
    .padRadius(1)
    .innerRadius(resourceGroupNode => Math.max(0, yScale(resourceGroupNode.y0)))
    .outerRadius(resourceGroupNode => Math.max(0, yScale(resourceGroupNode.y1)) - 1)
    .cornerRadius(1)
}

function interpolatePosition (activeResourceGroupNode, centerResourceGroupNode, resourceGroupNode) {
  const xd = interpolate(xScale.domain(), [activeResourceGroupNode.x0, activeResourceGroupNode.x1])
  const yd = interpolate(yScale.domain(), [centerResourceGroupNode.y0, centerResourceGroupNode.y1 + ((centerResourceGroupNode.y1 - centerResourceGroupNode.y0) * MAX_DEPTH)])
  const yr = interpolate(yScale.range(), [0, 100])

  const arcGenerator = createArcGenerator()

  return tween => {
    xScale.domain(xd(tween))
    yScale.domain(yd(tween)).range(yr(tween))
    return arcGenerator(resourceGroupNode)
  }
}

function highlight (resourceGroupId, resourceGroups, highlightResourceGroup) {
  resourceGroups
    .filter(resourceGroupNode => resourceGroupNode.data.id !== resourceGroupId)
    .transition()
    .style('opacity', 0.3)

  highlightResourceGroup(resourceGroupId)
}

function resetHighlight (resourceGroups, resetResourceGroupHighlight) {
  resourceGroups
    .transition()
    .style('opacity', 1)

  resetResourceGroupHighlight()
}

function hasAttachedPolicies (resourceGroupNode) {
  return resourceGroupNode.data.attached_policies && (Object.keys(resourceGroupNode.data.attached_policies).length !== 0)
}

function hasPropagatedPolicies (resourceGroupNode) {
  return resourceGroupNode.data.propagated_policies && (Object.keys(resourceGroupNode.data.propagated_policies).length !== 0)
}

function hasComplianceInfo (resourceGroupNode) {
  return typeof resourceGroupNode.data.complianceLevel !== 'undefined'
}

function generateComplianceColor (resourceGroupNode) {
  const complianceLevel = Math.floor(resourceGroupNode.data.complianceLevel * 10)
  return complianceColorScheme[complianceLevel]
}

function generateFillColor (resourceGroupNode, color) {
  return color(resourceGroupNode.depth)
}
