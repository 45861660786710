import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'

import { Entries } from 'components/Attribute'

import style from './Tags.styl'

export function Tags ({ tags }) {
  if (tags.size === 0) {
    return <div className={style.noTags}>No Tags</div>
  }

  return (
    <div>
      {Entries.fromMap(tags)}
    </div>
  )
}

Tags.propTypes = {
  tags: ImmutablePropTypes.map
}

export default Tags
