import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import Button from 'components/Button'
import ErrorPage from 'components/ErrorPage'
import { signOut } from 'state/session/actions'

export function ServerError ({ signOut }) {
  return (
    <ErrorPage>
      <p>A server error prevented the page from displaying correctly. Go back and try again or sign out.</p>
      <Button onClick={() => signOut()} type={Button.WARN}>Sign Out</Button>
    </ErrorPage>
  )
}

ServerError.propTypes = {
  signOut: PropTypes.func.isRequired
}

function mapDispatchToProps (dispatch, ownProps) {
  return {
    signOut: () => dispatch(signOut())
  }
}

export default connect(
  null,
  mapDispatchToProps
)(ServerError)
