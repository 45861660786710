import PropTypes from 'prop-types'
import React from 'react'
import ReactDOM from 'react-dom'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List, Map, OrderedMap } from 'immutable'
import { connect } from 'react-redux'
import FontAwesome from 'react-fontawesome'

import ModalDialog from 'components/ModalDialog'
import JsonDisplay from 'components/JsonDisplay'
import Button from 'components/Button'
import JsonTree from 'components/JsonTree'
import Card, { CardBody, CardHeader } from 'components/Card'
import LoadingIndicator from 'components/LoadingIndicator'
import Metrics from './components/Metrics'
import Overview from './components/Overview'
import ProviderDetails from './components/ProviderDetails'
import ContainingResourceGroups from './components/ContainingResourceGroups'
import ApplicablePolicyDetails from './components/ApplicablePolicyDetails'
import InvocationsList from './components/InvocationsList'
import Tags from './components/Tags'
import Relationships from './components/Relationships'
import PolicyHierarchy from '../PolicyHierarchy'
import { Tabs, Tab } from 'components/Tabs'
import { startFeatureTour, updateLocation, fetchLatestDatapoints } from 'state/actions'
import {
  invokeApplicablePolicy,
  fetchPolicyInvocations,
  updateApplicablePolicyDetailsForResource
} from 'state/resources/actions'
import {
  getCurrentSolutionContext,
  getAvailablePolicyTypesForResource,
  getTagsForCurrentResource
} from 'state/resources/selectors'
import { NO_SOLUTION_CONTEXT_ID } from 'state/constants'
import { RESOURCE_INFORMATION, RESOURCE_DETAILS } from 'refs'

import style from './ResourceDetails.styl'

export const ResourceDetailsVariants = {
  MINI: 'mini',
  NORMAL: 'normal'
}
const APPLICABLE_POLICY_MODAL = 'APPLICABLE_POLICY_MODAL'
const INVOCATIONS_LIST_MODAL = 'INVOCATIONS_LIST_MODAL'

export class ResourceDetails extends React.Component {
  static defaultProps = {
    applicablePolicies: List(),
    resourceProvider: Map()
  }

  constructor (props) {
    super(props)

    this.state = {
      showModal: null,
      modalInfo: null
    }
  }

  componentWillReceiveProps (nextProps, nextState) {
    if (!this.props.solutionContext.equals(nextProps.solutionContext)) {
      this.props.fetchLatestDatapoints(nextProps.resource.get('id'))
    }
  }

  onModalClose = () => {
    this.setState({showModal: null, modalInfo: null})
  }

  onShowInvocationsListModal = policyId => {
    this.setState({
      showModal: INVOCATIONS_LIST_MODAL,
      modalInfo: policyId
    })
  }

  onShowApplicablePolicyModal = policyDocument => {
    this.setState({
      showModal: APPLICABLE_POLICY_MODAL,
      modalInfo: policyDocument
    })
  }

  _renderModal (title, content, actions = []) {
    return (
      <ModalDialog
        onDismiss={this.onModalClose}
        title={title}
        actions={actions}>
        {content}
      </ModalDialog>
    )
  }

  _generateModal () {
    switch (this.state.showModal) {
      case APPLICABLE_POLICY_MODAL:
        return (
          this._renderModal(
            'Applicable Policy Document',
            <JsonDisplay object={this.state.modalInfo} className={style.jsonDisplay} />
          )
        )
      case INVOCATIONS_LIST_MODAL:
        const policyId = this.state.modalInfo
        const resourceId = this.props.resource.get('id')
        const invocations = this.props.invocationDetails.getIn([policyId, 'invocations'], List())
        const isFetching = this.props.invocationDetails.getIn([policyId, 'isFetching'], false)
        const cursor = this.props.invocationDetails.getIn([policyId, 'cursor'])

        const invocationsList = (
          <InvocationsList
            policyId={policyId}
            resourceId={resourceId}
            invocations={invocations}
            isFetching={isFetching}
            cursor={cursor}
            ref={'invocationsList'}
            fetchPolicyInvocations={() => this.props.fetchPolicyInvocations(resourceId, policyId, cursor)} />
        )

        return (
          this._renderModal(
            'Invocations',
            invocationsList,
            [{
              icon: 'refresh',
              onClick: () => {
                ReactDOM.findDOMNode(this.refs['invocationsList']).scrollTop = 0
                this.props.fetchPolicyInvocations(resourceId, policyId, null, true)
              }
            }]
          )
        )
    }

    return null
  }

  _getFeatureTourButton = () => (
    <Button
      className={style.featureTourButton}
      onClick={this.props.onStartFeatureTour}
      flat>
      <FontAwesome name='question' />
    </Button>
  )

  _getMoreTabsAvailable = () => (
    <div
      className={style.tabBarExtraEllipsis}>
      <FontAwesome name='ellipsis-h' />
    </div>
  )

  _getRefreshPolicyButton = () => (
    <Button
      className={style.featureTourButton}
      onClick={() => this.props.updateApplicablePolicyDetailsForResource(this.props.resource.get('id'))}
      flat>
      <FontAwesome name='refresh' />
    </Button>
  )

  buildResourceTabs () {
    const tabBarExtra = this.props.variant === ResourceDetailsVariants.NORMAL ? this._getFeatureTourButton() : this._getMoreTabsAvailable()

    return OrderedMap({
      'Overview': (
        <Tab title='Overview' key='Overview' tabBarExtra={tabBarExtra}>
          <CardBody>
            <Overview resource={this.props.resource} />
          </CardBody>
        </Tab>
      ),
      'Provider details': (
        <Tab title='Provider details' key='Provider details' tabBarExtra={tabBarExtra}>
          <CardBody>
            <ProviderDetails resource={this.props.resource} />
          </CardBody>
        </Tab>
      ),
      'Containing groups': (
        <Tab title='Containing groups' key='Containing groups' tabBarExtra={tabBarExtra}>
          <CardBody>
            <ContainingResourceGroups resource={this.props.resource} />
          </CardBody>
        </Tab>
      ),
      'Tags': (
        <Tab title='Tags' key='Tags' tabBarExtra={tabBarExtra}>
          <CardBody>
            <Tags tags={this.props.resourceTags} />
          </CardBody>
        </Tab>
      ),
      'Relationships': (
        <Tab title='Relationships' key='Relationships' tabBarExtra={tabBarExtra}>
          <CardBody>
            <Relationships resource={this.props.resource} showResourceDetails={this.props.showResourceDetails} />
          </CardBody>
        </Tab>
      ),
      'Raw data': (
        <Tab title='Raw data' key='Raw data' tabBarExtra={tabBarExtra}>
          <CardBody>
            <JsonTree context={this.props.resource.get('blob')} />
          </CardBody>
        </Tab>
      )
    })
  }

  buildPolicyTabs () {
    const multipleTabs = this.props.applicablePolicies.size

    const tabBarExtra = (
      this.props.variant === ResourceDetailsVariants.MINI && multipleTabs
      ? this._getMoreTabsAvailable()
      : null
    )

    const tabBarExtraPolicies = (
      this.props.variant === ResourceDetailsVariants.MINI && multipleTabs
      ? this._getMoreTabsAvailable()
      : this._getRefreshPolicyButton()
    )

    return OrderedMap({
      'Policies': (this.props.policyTypes.size
        ? (
          <Tab title='Policies' key='Policies' tabBarExtra={tabBarExtraPolicies}>
            <CardBody>
              <ApplicablePolicyDetails
                policyTypes={this.props.policyTypes}
                applicablePolicies={this.props.applicablePolicies}
                latestInvocations={this.props.latestInvocations}
                invokeApplicablePolicy={this.props.invokeApplicablePolicy}
                onShowApplicablePolicyModal={this.onShowApplicablePolicyModal}
                onShowInvocationsListModal={this.onShowInvocationsListModal}
                organizationRole={this.props.organizationRole}
                onPolicyTypeChanged={this.props.onSelectPolicyType}
                currentPolicyTypeId={this.props.currentPolicyTypeId}
                dataFetched={this.props.applicablePoliciesDetailsFetched}
                resource={this.props.resource} />
            </CardBody>
          </Tab>
        ) : <Tab title='Policies' key='Policies' tabBarExtra={tabBarExtra}><CardBody>No Applicable Policies</CardBody></Tab>
      ),
      ...(this.props.applicablePolicies.size
        ? {'Applicable policy hierarchy': (
          <Tab title='Applicable policy hierarchy' key='Applicable policy hierarchy' tabBarExtra={tabBarExtra}>
            <CardBody>
              <PolicyHierarchy policies={this.props.applicablePolicies} policyTypes={this.props.policyTypes} />
            </CardBody>
          </Tab>
        )} : {}
      )
    })
  }

  render () {
    if (!this.props.resource || this.props.resource.isEmpty()) {
      return <div className={style.loadingContainer}><LoadingIndicator /></div>
    }

    const inactiveResource = this.props.resourceProvider.get('active') === false ? (
      <span className={style.inactiveResource}>
        <FontAwesome name='warning' />
        This resource may be out of date because we are unable to connect to the {this.props.resourceProvider.get('display_id')} resource provider.
      </span>) : null

    const metricsCard = this.props.solutionContext && this.props.solutionContext.get('id') !== NO_SOLUTION_CONTEXT_ID ? (
      <Card>
        <CardHeader title='Metrics' />
        <CardBody>
          <Metrics />
        </CardBody>
      </Card>
    ) : null

    const builtResourceTabs = this.buildResourceTabs()
    const activeResourceTab = this.props.resourceDetailsActiveTab
    const selectedResourceTab = activeResourceTab === null || !builtResourceTabs.get(activeResourceTab)
      ? 'Overview'
      : activeResourceTab

    const resourceTabs = builtResourceTabs.reduce((tabs, tab, title) => {
      if (this.props.variant === ResourceDetailsVariants.MINI) {
        if (title === selectedResourceTab) {
          tabs.push(tab)
        }
      } else {
        tabs.push(tab)
      }
      return tabs
    }, [])

    const builtPolicyTabs = this.buildPolicyTabs()
    const activePolicyTab = this.props.policiesActiveTab
    const selectedPolicyTab = activePolicyTab === null || !builtPolicyTabs.get(activePolicyTab)
      ? 'Policies'
      : activePolicyTab

    const policyTabs = builtPolicyTabs.reduce((tabs, tab, title) => {
      if (this.props.variant === ResourceDetailsVariants.MINI) {
        if (title === selectedPolicyTab) {
          tabs.push(tab)
        }
      } else {
        tabs.push(tab)
      }
      return tabs
    }, [])

    return (
      <div data-ref={RESOURCE_INFORMATION}>
        {inactiveResource}
        <Card>
          <div data-ref={RESOURCE_DETAILS}>
            <Tabs
              onTabChange={this.props.onResourceDetailActiveTabChange}
              selectedTabTitle={selectedResourceTab}>
              {resourceTabs}
            </Tabs>
          </div>
        </Card>
        <Card>
          <Tabs onTabChange={this.props.onPoliciesActiveTabChange} selectedTabTitle={selectedPolicyTab}>
            {policyTabs}
          </Tabs>
        </Card>
        {metricsCard}
        {this._generateModal()}
      </div>
    )
  }
}

ResourceDetails.propTypes = {
  resource: ImmutablePropTypes.map,
  policyTypes: ImmutablePropTypes.list.isRequired,
  showResourceDetails: PropTypes.func.isRequired,
  invokeApplicablePolicy: PropTypes.func,
  applicablePolicies: ImmutablePropTypes.list,
  latestInvocations: ImmutablePropTypes.list,
  solutionContext: ImmutablePropTypes.map,
  resourceProvider: ImmutablePropTypes.map,
  onStartFeatureTour: PropTypes.func,
  organizationRole: PropTypes.number.isRequired,
  currentPolicyTypeId: PropTypes.string.isRequired,
  onResourceDetailActiveTabChange: PropTypes.func.isRequired,
  onPoliciesActiveTabChange: PropTypes.func.isRequired,
  resourceDetailsActiveTab: PropTypes.string,
  policiesActiveTab: PropTypes.string,
  applicablePoliciesDetailsFetched: PropTypes.bool.isRequired,
  onSelectPolicyType: PropTypes.func.isRequired,
  resourceTags: ImmutablePropTypes.map.isRequired
}

function mapDispatchToProps (dispatch) {
  return {
    showResourceDetails: resourceId => dispatch(updateLocation({pathname: `/resources/${resourceId}`})),
    onStartFeatureTour: () => dispatch(startFeatureTour('resource-view')),
    invokeApplicablePolicy: (applicablePolicy) => dispatch(invokeApplicablePolicy(applicablePolicy)),
    fetchLatestDatapoints: resourceId => dispatch(fetchLatestDatapoints([resourceId])),
    updateApplicablePolicyDetailsForResource: (resourceId) => dispatch(updateApplicablePolicyDetailsForResource(resourceId)),
    fetchPolicyInvocations: (resourceId, policyId, cursor, refetch = false) => {
      return dispatch(fetchPolicyInvocations(resourceId, policyId, cursor, refetch))
    }
  }
}

function mapStateToProps (state, ownProps) {
  const resource = state.currentResource
  const resourceProviderId = resource.getIn(['blob', 'AccountId'])
  const resourceProvider = state.normalizedResources.getIn([resourceProviderId, 'blob'])
  const resourceId = resource.get('id')

  return {
    resource: resource,
    applicablePolicies: state.applicablePolicies.get(resourceId),
    latestInvocations: state.latestInvocations.get(resourceId),
    solutionContext: getCurrentSolutionContext(state),
    policyTypes: getAvailablePolicyTypesForResource(state),
    resourceProvider: resourceProvider,
    currentPolicyTypeId: state.currentPolicyTypeId,
    invocationDetails: state.policyInvocations.get(resourceId, Map()),
    applicablePoliciesDetailsFetched: state.applicablePoliciesDetailsFetched,
    resourceTags: getTagsForCurrentResource(state)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResourceDetails)
