export default function applyHierarchyLayout (rootNode, limit) {
  rootNode.x = Math.PI
  rootNode.y = 0

  const groupNodes = rootNode.children || []

  const groupsInfo = getGroupsInfo(groupNodes)
  const totalCapacity = Math.min(limit, groupsInfo.itemCount)
  const minLabelSeparation = (2 * Math.PI) / totalCapacity

  const adjustedGroupsInfo = adjustGroupsInfo(groupNodes, groupsInfo, totalCapacity, minLabelSeparation)
  const adjustedTotalCapacity = Math.min(limit, adjustedGroupsInfo.itemCount)
  const adjustedMinLabelSeparation = (2 * Math.PI) / adjustedTotalCapacity

  applyGroupsLayout(
    groupNodes,
    adjustedGroupsInfo,
    adjustedTotalCapacity,
    adjustedMinLabelSeparation,
    (groupNode, x, y, width) => {
      groupNode.x = x
      groupNode.y = y
      groupNode.width = width
    },
    (labelNode, x, y) => {
      labelNode.x = x
      labelNode.y = y
    }
  )

  groupNodes.forEach(groupNode => {
    const { id, count, expandedCount } = groupNode.data.group

    const toggledGroupsInfo = {
      itemCount: groupsInfo.itemCount,
      expandedItemCount: groupsInfo.expandedItemCount + count * (expandedCount ? -1 : 1),
      expandedByGroupId: Object.assign({}, groupsInfo.expandedByGroupId, {
        [id]: !expandedCount
      })
    }

    const toggledAdjustedGroupsInfo = adjustGroupsInfo(groupNodes, toggledGroupsInfo, adjustedTotalCapacity, adjustedMinLabelSeparation)
    const toggledAdjustedTotalCapacity = Math.min(limit, toggledAdjustedGroupsInfo.itemCount)
    const toggledAdjustedMinLabelSeparation = (2 * Math.PI) / toggledAdjustedTotalCapacity

    const toggledXs = {}

    applyGroupsLayout(
      groupNodes,
      toggledAdjustedGroupsInfo,
      toggledAdjustedTotalCapacity,
      toggledAdjustedMinLabelSeparation,
      (groupNode, x) => {
        toggledXs[groupNode.data.group.id] = x
        if (groupNode.data.group.id === id) {
          groupNode.toggledX = x
          groupNode.toggledXs = toggledXs
        }
      },
      () => {}
    )
  })
}

function getGroupsInfo (groupNodes) {
  return groupNodes.reduce((groupsInfo, groupNode) => {
    const { id, count, expandedCount } = groupNode.data.group

    groupsInfo.itemCount += count
    groupsInfo.expandedItemCount += expandedCount ? count : 0
    groupsInfo.expandedByGroupId[id] = expandedCount

    return groupsInfo
  }, {
    itemCount: 0,
    expandedItemCount: 0,
    expandedByGroupId: {}
  })
}

function adjustGroupsInfo (groupNodes, groupsInfo, totalCapacity, minLabelSeparation) {
  return groupNodes.reduce((adjustedGroupsInfo, groupNode) => {
    const { id, count } = groupNode.data.group
    const expandedCount = groupsInfo.expandedByGroupId[id]

    const unexpandedCapacity = totalCapacity - adjustedGroupsInfo.expandedItemCount

    const unexpandedItemCount = adjustedGroupsInfo.itemCount - adjustedGroupsInfo.expandedItemCount

    const width = (count / unexpandedItemCount) *
      (unexpandedCapacity / totalCapacity) *
      (2 * Math.PI)

    adjustedGroupsInfo.forcedExpandedByGroupId[id] = !expandedCount && width < minLabelSeparation

    if (adjustedGroupsInfo.forcedExpandedByGroupId[id]) {
      adjustedGroupsInfo.expandedItemCount++
      adjustedGroupsInfo.itemCount -= count - 1
    }

    return adjustedGroupsInfo
  }, {
    itemCount: groupsInfo.itemCount,
    expandedItemCount: groupsInfo.expandedItemCount,
    expandedByGroupId: groupsInfo.expandedByGroupId,
    forcedExpandedByGroupId: {}
  })
}

function applyGroupsLayout (groupNodes, groupsInfo, totalCapacity, minLabelSeparation, applyGroup, applyLabel) {
  const {
    itemCount,
    expandedItemCount,
    expandedByGroupId,
    forcedExpandedByGroupId
  } = groupsInfo

  const unexpandedItemCount = itemCount - expandedItemCount
  const unexpandedCapacity = totalCapacity - expandedItemCount

  let xOffset = 0

  groupNodes = groupNodes || []

  groupNodes.forEach(groupNode => {
    const { id, count } = groupNode.data.group
    const expandedCount = expandedByGroupId[id]

    const width = expandedCount
      ? (count / totalCapacity) * (2 * Math.PI)
      : forcedExpandedByGroupId[id]
        ? (1 / totalCapacity) * (2 * Math.PI)
        : (count / unexpandedItemCount) * (unexpandedCapacity / totalCapacity) * (2 * Math.PI)

    const x = xOffset + (width / 2)
    applyGroup(groupNode, x, 0.5, width)

    xOffset += width

    if (expandedCount) {
      let nextLabelX = x - (minLabelSeparation * (count - 1) / 2)

      groupNode.children.forEach(labelNode => {
        applyLabel(labelNode, nextLabelX, 1)
        nextLabelX += minLabelSeparation
      })
    } else {
      const collapsedLabel = groupNode.children[0]
      applyLabel(collapsedLabel, x, 1)
    }
  })
}
