import React from 'react'
import PropTypes from 'prop-types'

import { convertUTCTimeToLocal } from 'services/time'

const Time = props => {
  const machineReadableDate = props.value.format()
  const humanReadableDate = convertUTCTimeToLocal(props.value)
  return <time dateTime={machineReadableDate}>{humanReadableDate}</time>
}

Time.propTypes = {
  value: PropTypes.object.isRequired
}

export default Time
