import { fromJS, Map, Set } from 'immutable'

import * as actions from './actions'

function pendingResourceTypes (state = null, action) {
  switch (action.type) {
    case actions.RESOURCE_TYPES_REQUEST:
      return action.pendingResourceTypes
    case actions.RESOURCE_TYPES_RESPONSE:
      return null
    default:
      return state
  }
}

function resourceTypes (state = Map(), action) {
  switch (action.type) {
    case actions.RESOURCE_TYPES_RESPONSE:
      return action.resourceTypes
    default:
      return state
  }
}

const expandedCollectionLimit = (state = null, action) => {
  switch (action.type) {
    case actions.SET_EXPANDED_COLLECTION_LIMIT:
      return action.limit
    default:
      return state
  }
}

const expandedCollections = (state = Set(), action) => {
  switch (action.type) {
    case actions.SET_EXPANDED_COLLECTION_NAMES:
      return action.expandedCollectionNames
    default:
      return state
  }
}

const collectionResources = (state = Map(), action) => {
  switch (action.type) {
    case actions.STORE_COLLECTION_RESOURCES:
      const oldstate = state.getIn([action.collectionSetId, action.collectionId], Set())
      const newstate = Set(action.resourceIds)
      if (oldstate.equals(newstate)) {
        return state
      }
      return state.setIn([action.collectionSetId, action.collectionId], newstate)
    default:
      return state
  }
}

const collectionRelations = (state = Map(), action) => {
  switch (action.type) {
    case actions.STORE_COLLECTION_RELATIONS:
      const oldstate = state.getIn([action.collectionSetId, action.collectionId], Set())
      const newstate = Set(action.relationIds)
      if (oldstate.equals(newstate)) {
        return state
      }
      return state.setIn([action.collectionSetId, action.collectionId], newstate)
    default:
      return state
  }
}

const currentResource = (state = Map(), action) => {
  switch (action.type) {
    case actions.UPDATE_CURRENT_RESOURCE:
      return fromJS(action.resource)
    case actions.BEGIN_RESOURCE_FETCHING:
      return Map()
    default:
      return state
  }
}

const currentResourceGroup = (state = Map(), action) => {
  switch (action.type) {
    case actions.UPDATE_CURRENT_RESOURCE_GROUP:
      return fromJS(action.resourceGroup)
    case actions.BEGIN_RESOURCE_GROUP_FETCHING:
      return Map()
    default:
      return state
  }
}

const propagatedPolicies = (state = Map(), action) => {
  switch (action.type) {
    case actions.UPDATE_PROPAGATED_POLICIES:
      return state.set(action.resourceGroupId, fromJS(action.policies))
    default:
      return state
  }
}

const attachedPolicies = (state = Map(), action) => {
  switch (action.type) {
    case actions.UPDATE_ATTACHED_POLICIES:
      return state.set(action.resourceGroupId, fromJS(action.policies))
    default:
      return state
  }
}

const latestInvocations = (state = Map(), action) => {
  switch (action.type) {
    case actions.UPDATE_INVOCATIONS:
      return state.set(action.resourceId, fromJS(action.invocations))
    default:
      return state
  }
}

const policyInvocations = (state = Map(), action) => {
  switch (action.type) {
    case actions.UPDATE_POLICY_INVOCATIONS:
      return state
        .setIn([action.resourceId, action.policyId, 'cursor'], action.cursor)
        .setIn([action.resourceId, action.policyId, 'invocations'], action.invocations)
        .setIn([action.resourceId, action.policyId, 'isFetching'], false)
    case actions.POLICY_INVOCATION_IS_FETCHING:
      return state.setIn([action.resourceId, action.policyId, 'isFetching'], true)
    default:
      return state
  }
}

const applicablePoliciesDetailsFetched = (state = false, action) => {
  switch (action.type) {
    case actions.APPLICABLE_POLICIES_DETAILS_FETCHED:
      return true
    case actions.BEGIN_APPLICABLE_POLICIES_DETAILS_FETCHING:
      return false
    default:
      return state
  }
}

const applicablePolicies = (state = Map(), action) => {
  switch (action.type) {
    case actions.UPDATE_APPLICABLE_POLICIES:
      return state.set(action.resourceId, fromJS(action.policies))
    default:
      return state
  }
}

const relatedResources = (state = Map(), action) => {
  switch (action.type) {
    case actions.UPDATE_RELATED_RESOURCES:
      return state.set(action.resourceId, fromJS(action.relatedResources))
    default:
      return state
  }
}

const propagatedPolicyValidation = (state = Map(), action) => {
  switch (action.type) {
    case actions.UPDATE_PROPAGATED_POLICY_VERIFICATION:
      return fromJS(action.propagatedPolicyVerification)
    default:
      return state
  }
}

const highlightedResourceGroup = (state = null, action) => {
  switch (action.type) {
    case actions.HIGHLIGHT_RESOURCE_GROUP:
      return fromJS({
        id: action.resourceGroupId,
        scroll: action.scroll
      })
    case actions.RESET_RESOURCE_GROUP_HIGHLIGHT:
      return null
    default:
      return state
  }
}

const currentCoveragePolicyType = (state = 'NONE', action) => {
  switch (action.type) {
    case actions.NOTIFY_COVERAGE_POLICY_TYPE_CHANGED:
      return action.policy
    default:
      return state
  }
}

const currentPolicyTypeId = (state = '', action) => {
  switch (action.type) {
    case actions.NOTIFY_POLICY_TYPE_CHANGED:
      return action.policyTypeId
    default:
      return state
  }
}

export {
  pendingResourceTypes,
  resourceTypes,
  expandedCollectionLimit,
  expandedCollections,
  collectionResources,
  collectionRelations,
  currentResource,
  currentResourceGroup,
  propagatedPolicies,
  attachedPolicies,
  latestInvocations,
  applicablePolicies,
  applicablePoliciesDetailsFetched,
  relatedResources,
  propagatedPolicyValidation,
  highlightedResourceGroup,
  policyInvocations,
  currentCoveragePolicyType,
  currentPolicyTypeId
}
