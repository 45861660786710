import PropTypes from 'prop-types'
import React from 'react'
import Joyride from 'react-joyride'
import moment from 'moment'

import tours from './services/tours'

require('react-joyride/lib/react-joyride-compiled.css')

const RUN_DELAY = moment.duration(2500, 'milliseconds')

export class FeatureTours extends React.Component {
  constructor (props) {
    super()

    this.state = {
      run: false
    }
  }

  render () {
    const tour = tours[this.props.tour]

    if (!tour) {
      return null
    }

    const steps = tour.steps.map((step) => {
      return {
        ...step,
        selector: (typeof step.selector === 'function' ? step.selector() : step.selector) || '//html'
      }
    })

    return this.state.run ? (
      <Joyride
        run
        autoStart
        showStepsProgress
        type='continuous'
        holePadding={0}
        showSkipButton
        locale={{
          next: 'Next',
          close: 'Close',
          last: 'Finish',
          skip: 'Skip'
        }}
        callback={event => {
          if (event.type === 'step:before' && this.props.onStepChange) {
            this.props.onStepChange(this.props.tour, event.step)
          } else if (event.type === 'finished' && this.props.onEnd) {
            this.props.onEnd()
          } else if (event.type === 'error:target_not_found') {
            this.props.onError()
            this.props.onEnd()
          }
        }}
        steps={steps} />
    ) : null
  }

  componentDidMount () {
    setTimeout(() => {
      this.setState({
        run: true
      })
    }, RUN_DELAY.asMilliseconds())
  }
}

FeatureTours.propTypes = {
  tour: PropTypes.string,
  onStepChange: PropTypes.func,
  onEnd: PropTypes.func,
  onError: PropTypes.func
}

export default FeatureTours
