import React from 'react'

import style from './ErrorPage.styl'

export default function ErrorPage ({ children }) {
  return (
    <div className={style.page}>
      <div className={style.error}>
        {children}
      </div>
    </div>
  )
}
