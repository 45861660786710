import PropTypes from 'prop-types'
import React from 'react'
import FontAwesome from 'react-fontawesome'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'

import SolutionGalleryItem from './components/SolutionGalleryItem'

import style from './SolutionGallery.styl'

export class SolutionGallery extends React.Component {
  _getWorkersForSolution (solutionId) {
    return this.props.managedWorkers.reduce((workers, worker) => {
      if (worker.get('solution') === solutionId) {
        return workers.set(worker.get('id'), worker)
      }
      return workers
    }, Map())
  }

  _generateSolutionItems () {
    if (!this.props.solutions.size) {
      return <span>No solutions available</span>
    }

    return this.props.solutions.valueSeq().map(solution => {
      let description
      description = (
        <div>
          {solution.get('policy_types').some(pt => pt.getIn(['suspension', 'enabled']))
            ? <div>
              <FontAwesome name='info-circle' className={style.info} />
              Solution has suspended policy types.
            </div>
            : null
          }
          {solution.get('description')}
        </div>
      )

      return <SolutionGalleryItem
        key={solution.get('id')}
        id={solution.get('id')}
        label={solution.get('label')}
        version={solution.get('version')}
        description={description}
        tags={solution.get('tags')}
        managedWorkers={this._getWorkersForSolution(solution.get('id'))}
        onShowWorkersModal={this.props.onShowWorkersModal}
        onShowSolutionDetails={this.props.onShowSolutionDetails} />
    })
  }

  render () {
    return (
      <div className={style.solutionGallery}>
        {this._generateSolutionItems()}
      </div>
    )
  }
}

SolutionGallery.propTypes = {
  solutions: ImmutablePropTypes.mapOf(
    ImmutablePropTypes.contains({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      version: PropTypes.string,
      description: PropTypes.string.isRequired,
      tags: ImmutablePropTypes.list.isRequired
    }),
    PropTypes.string
  ).isRequired,
  managedWorkers: ImmutablePropTypes.map,
  onShowWorkersModal: PropTypes.func.isRequired,
  onShowSolutionDetails: PropTypes.func.isRequired
}

export default SolutionGallery
