import PropTypes from 'prop-types'
import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import FontAwesome from 'react-fontawesome'

import { ADMIN_POLICY } from 'state/actions'
import Button from 'components/Button'

import style from './NonInteractiveClientsList.styl'

export class NonInteractiveClientsList extends React.Component {
  deleteClient (client) {
    const clientName = client.get('name')

    const confirmation = <span>
      <p>This will permanently delete <b>{clientName}</b>.</p>
      <br />
      <p>Please confirm the name of the non-interactive client below to continue.</p>
    </span>

    this.props.onConfirmation(
      'Delete Non-Interactive Client',
      confirmation,
      () => this.props.onDelete(client.get('id')),
      input => input !== clientName
    )
  }

  listClients (clients) {
    if (!clients || !clients.size) {
      return (<tr><td>No Non-Interactive Clients</td></tr>)
    }

    return clients.map(client => {
      return (
        <tr key={client.get('index')}>
          <td>{client.get('name')}</td>
          <td>{this.props.roleNamesById.get(client.get('role'))}</td>
          <td>{client.get('client_id')}</td>
          {this.props.organizationRole === ADMIN_POLICY ? (
            <td>
              <Button
                onClick={() => this.deleteClient(client)}
                type={Button.WARN}
                className={style.delete}
                flat>
                <FontAwesome name='trash' />
              </Button>
            </td>
          ) : null}
        </tr>
      )
    })
  }

  render () {
    return (
      <div>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Role</th>
              <th>Client ID</th>
              {this.props.organizationRole === ADMIN_POLICY ? <th /> : null}
            </tr>
          </thead>
          <tbody>
            {this.listClients(this.props.clients.valueSeq().sortBy(v => v.get('name')))}
          </tbody>
        </table>
      </div>
    )
  }
}

NonInteractiveClientsList.propTypes = {
  clients: ImmutablePropTypes.map.isRequired,
  roleNamesById: ImmutablePropTypes.map.isRequired,
  onDelete: PropTypes.func.isRequired,
  onConfirmation: PropTypes.func.isRequired,
  organizationRole: PropTypes.number.isRequired
}

export default NonInteractiveClientsList
