import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'
import ImmutablePropTypes from 'react-immutable-proptypes'
import MappleToolTip from 'reactjs-mappletooltip'

import style from './PolicyHierarchyTree.styl'

const TOOLTIP_BACKGROUND_COLOR = '#262d2e'

export function PolicyHierarchyTree ({ policies, className, policyClassName, resourceGroupClassName, policyTypes, onSelectResourceGroup }) {
  const generateBranches = policy => {
    const policyType = getPolicyType(policyTypes, policy.policy_type)

    if (!policyType) {
      return null
    }

    const generateBranch = branch => branch.map(policy => generateBranches(policy))
    const generateLeaf = (policy, policyType) => {
      const policyElements = policy.resolved_policy || {}

      return (<li className={classNames(style.policy, policyClassName)}>
        <label>{policyType.get('label')}</label>
        <div className={style.tooltip}>
          <MappleToolTip direction='right' backgroundColor={TOOLTIP_BACKGROUND_COLOR} float>
            <label>{Object.keys(policyElements).length} element(s)</label>
            <pre>{JSON.stringify(policyElements, null, 2)}</pre>
          </MappleToolTip>
        </div>
      </li>)
    }

    return (<li className={classNames(style.resourceGroup, resourceGroupClassName)} key={policy.resource_group.id}>
      <label onClick={() => onSelectResourceGroup(policy.resource_group_id)}>{policy.resource_group.name}</label>
      <label>({getPolicyCount(policy)}) ↓</label>
      <ul>
        {generateLeaf(policy, policyType)}
        {policy.children && generateBranch(policy.children)}
      </ul>
    </li>)
  }

  let hierarchy = policies.map((policy, index) =>
    <ul key={index}>{generateBranches(policy)}</ul>
  )

  if (hierarchy.length === 0) {
    hierarchy = <p>No policy hiearchy tree available</p>
  }

  return <div className={classNames(style.tree, className)}>
    {hierarchy}
  </div>
}

const getPolicyCount = obj => {
  let children = 1

  if (obj.children) {
    children = Object.keys(obj.children).length

    obj.children.forEach(child => {
      children += getPolicyCount(child)
    })
  }

  return children
}

const getPolicyType = (policyTypes, policyTypeId) => policyTypes.find(
  policy => policy.get('id') === policyTypeId
)

PolicyHierarchyTree.propTypes = {
  className: PropTypes.string,
  policyClassName: PropTypes.string,
  resourceGroupClassName: PropTypes.string,
  policies: PropTypes.array.isRequired,
  policyTypes: ImmutablePropTypes.list.isRequired,
  onSelectResourceGroup: PropTypes.func.isRequired
}

export default PolicyHierarchyTree
