import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import FontAwesome from 'react-fontawesome'
import CopyToClipboard from 'react-copy-to-clipboard'

import PropTypes from 'prop-types'

import Button from 'components/Button'

import style from './CreatedNonInteractiveClient.styl'

export class CreatedNonInteractiveClient extends React.Component {
  render () {
    const clientId = this.props.createdClient && this.props.createdClient.get('client_id')
    const clientSecret = this.props.createdClient && this.props.createdClient.get('client_secret')

    return (
      <div>
        <div className={style.help}>
          Please record the below information securely, you will not be able to retrieve the secret at a later date.
        </div>
        <table>
          <thead>
            <tr>
              <th>
                Client ID
                <CopyToClipboard text={clientId} onCopy={() => {}}>
                  <Button
                    className={style.copy}
                    flat>
                    <FontAwesome name='copy' />
                  </Button>
                </CopyToClipboard>
              </th>
              <th>
                Client Secret
                <CopyToClipboard text={clientSecret} onCopy={() => {}}>
                  <Button
                    className={style.copy}
                    flat>
                    <FontAwesome name='copy' />
                  </Button>
                </CopyToClipboard>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{clientId}</td>
              <td>{clientSecret}</td>
            </tr>
          </tbody>
        </table>
        <div className={style.controls}>
          <Button onClick={() => this.props.onClose()} flat>Close</Button>
        </div>
      </div>
    )
  }
}

CreatedNonInteractiveClient.propTypes = {
  onClose: PropTypes.func.isRequired,
  createdClient: ImmutablePropTypes.map.isRequired
}

export default CreatedNonInteractiveClient
