import React from 'react'
import PropTypes from 'prop-types'

import Hint from 'components/Hint'

import style from './ConfigurationFormAwsSqs.styl'

export class ConfigurationFormAwsSqs extends React.Component {
  constructor () {
    super()

    this._onQueueArnChange = this._onQueueArnChange.bind(this)

    this.state = {
      queue_arn: ''
    }
  }

  componentWillMount () {
    this.setState({queue_arn: this.props.initialConfiguration.queue_arn})
  }

  _onQueueArnChange ({ target }) {
    this.setState((prevState, props) => {
      return { queue_arn: target.value }
    }, () => {
      this.props.onConfigurationChange(this.state)
    })
  }

  render () {
    return (
      <div>
        <div className={style.formField}>
          <label>
            Queue ARN
            <Hint className={style.hint}>
              Enter the ARN for the SQS to publish to. Must be in the format: <div className={style.code}>arn:aws:sqs:region:account-id:queue-name</div>
            </Hint>
          </label>
          <input
            required
            type='text'
            name='queue'
            value={this.state.queue_arn || ''}
            onChange={this._onQueueArnChange} />
        </div>
      </div>
    )
  }
}

ConfigurationFormAwsSqs.propTypes = {
  onConfigurationChange: PropTypes.func.isRequired,
  initialConfiguration: PropTypes.object
}

export default ConfigurationFormAwsSqs
