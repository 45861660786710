import React from 'react'
import { connect } from 'react-redux'
import { Map, fromJS } from 'immutable'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'

import ExternalNotifications from './components/ExternalNotifications'
import AccessManagement from './components/AccessManagement'
import Providers from './components/Providers'
import Solutions from './components/Solutions'
import ModalDialog from 'components/ModalDialog'
import ClickableList from 'components/ClickableList'
import RestrictedCredentials from './components/RestrictedCredentials'
import SolutionDetails from './components/SolutionDetails'
import ResourceProviderCreator from './components/ResourceProviderCreator'
import ResourceProviderEditor from 'scenes/Manage/components/ResourceProviderEditor'
import SolutionRuntimeEditor from 'scenes/Manage/components/SolutionRuntimeEditor'
import SolutionRuntimeCreator from 'scenes/Manage/components/SolutionRuntimeCreator'
import RoleEditor from 'scenes/Manage/components/RoleEditor'
import RoleCreator from 'scenes/Manage/components/RoleCreator'
import WorkersList from 'scenes/Manage/components/WorkersList'
import NonInteractiveClientCreator from 'scenes/Manage/components/NonInteractiveClientCreator'
import CreatedNonInteractiveClient from 'scenes/Manage/components/CreatedNonInteractiveClient'
import ExternalNotificationEndpointCreator from './components/ExternalNotificationEndpointCreator'
import ExternalNotificationEndpointEditor from './components/ExternalNotificationEndpointEditor'
import ExternalNotificationFilterCreator from './components/ExternalNotificationFilterCreator'
import ExternalNotificationFilterEditor from './components/ExternalNotificationFilterEditor'
import ConfigurationViewer from './components/ConfigurationViewer'
import { getRoleForCurrentOrganization } from 'state/selectors'
import { getResourceProviderTypes } from 'state/resources/selectors'
import {
  showConfirmationModal,
  updateLocation,
  fetchUsers,
  READONLY_POLICY,
  ADMIN_POLICY,
  SOLUTIONINVOKE_POLICY
} from 'state/actions'
import {
  fetchResourceProviders,
  fetchSolutionRuntimes,
  fetchManagedWorkers,
  deleteSolutionRuntime,
  updateSolutionRuntime,
  createSolutionRuntime,
  deleteResourceProvider,
  updateResourceProvider,
  createResourceProvider,
  fetchRoles,
  deleteRole,
  createRole,
  updateRole,
  fetchClients,
  deleteClient,
  createClient,
  fetchExternalNotificationEndpoints,
  createExternalNotificationEndpoint,
  updateExternalNotificationEndpoint,
  deleteExternalNotificationEndpoint,
  fetchExternalNotificationFilters,
  createExternalNotificationFilter,
  updateExternalNotificationFilter,
  deleteExternalNotificationFilter,
  validateExternalNotificationFilterConfiguration
} from 'state/organization/actions'
import { ensureResourceTypes } from 'state/resources/actions'

import style from './Manage.styl'

export const POLICIES = {
  [READONLY_POLICY]: 'Read Only',
  [ADMIN_POLICY]: 'Admin',
  [SOLUTIONINVOKE_POLICY]: 'Solution Invoke'
}

const ADD_RESOURCE_PROVIDER_MODAL = 'ADD_RESOURCE_PROVIDER_MODAL'
const ADD_SOLUTION_RUNTIME_MODAL = 'ADD_SOLUTION_RUNTIME_MODAL'
const ADD_ROLE_MODAL = 'ADD_ROLE_MODAL'
const ADD_EXTERNAL_NOTIFICATION_ENDPOINT = 'ADD_EXTERNAL_NOTIFICATION_ENDPOINT'
const ADD_EXTERNAL_NOTIFICATION_FILTER = 'ADD_EXTERNAL_NOTIFICATION_FILTER'
const ADD_CLIENT_MODAL = 'ADD_CLIENT_MODAL'
const CREATED_CLIENT_MODAL = 'CREATED_CLIENT_MODAL'
const EDIT_SOLUTION_RUNTIME_MODAL = 'EDIT_SOLUTION_RUNTIME_MODAL'
const EDIT_RESOURCE_PROVIDER_MODAL = 'EDIT_RESOURCE_PROVIDER_MODAL'
const EDIT_ROLE_MODAL = 'EDIT_ROLE_MODAL'
const EDIT_EXTERNAL_NOTIFICATION_ENDPOINT = 'EDIT_EXTERNAL_NOTIFICATION_ENDPOINT'
const EDIT_EXTERNAL_NOTIFICATION_FILTER = 'EDIT_EXTERNAL_NOTIFICATION_FILTER'
const SHOW_WORKERS_MODAL = 'SHOW_WORKERS_MODAL'
const SHOW_SOLUTION_DETAILS = 'SHOW_SOLUTION_DETAILS'
const SHOW_PROVIDER_CREDENTIALS_MODAL = 'SHOW_PROVIDER_CREDENTIALS_MODAL'
const SHOW_CONFIGURATION = 'SHOW_CONFIGURATION'

const TEMPLATE_PREFIX = `https://s3-eu-west-1.amazonaws.com/cr-conductor-cfn-templates/`
const STACK_PREFIX = 'cloudreach-conductor-'
const TEMPLATE_SUFFIX = process.env.ENVIRONMENT
const STACK_SUFFIX = `-${TEMPLATE_SUFFIX === 'prod' ? '' : TEMPLATE_SUFFIX}`

const SOLUTIONS_PAGE = 'solutions'
const PROVIDERS_PAGE = 'providers'
const ACCESS_MANAGEMENT_PAGE = 'access_management'
const EXTERNAL_NOTIFICATIONS_PAGE = 'external_notifications'

const SOLUTIONS_LABEL = 'Solutions'
const PROVIDERS_LABEL = 'Providers'
const ACCESS_MANAGEMENT_LABEL = 'Access Management'
const EXTERNAL_NOTIFICATIONS_LABEL = 'External Notifications'

const OPTIONS = {
  [SOLUTIONS_PAGE]: {name: SOLUTIONS_LABEL, page: SOLUTIONS_PAGE},
  [PROVIDERS_PAGE]: {name: PROVIDERS_LABEL, page: PROVIDERS_PAGE},
  [ACCESS_MANAGEMENT_PAGE]: {name: ACCESS_MANAGEMENT_LABEL, page: ACCESS_MANAGEMENT_PAGE},
  [EXTERNAL_NOTIFICATIONS_PAGE]: {name: EXTERNAL_NOTIFICATIONS_LABEL, page: EXTERNAL_NOTIFICATIONS_PAGE},
}

export const returnFiltered = filtered => {
  return filtered.reduce((items, item) => {
    return items.set(item.get('id'), item)
  }, Map())
}

export class Manage extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      filteredSolutions: this.props.solutions,
      filteredResourceProviders: this.props.resourceProviders,
      filteredSolutionRuntimes: this.props.solutionRuntimes,
      filteredUsers: this.props.users,
      filteredRoles: this.props.roles,
      filteredExternalNotificationEndpoints: this.props.externalNotificationEndpoints,
      filteredExternalNotificationFilters: this.props.externalNotificationFilters,
      showModal: null,
      modalInfo: null,
      selectedOptions: SOLUTIONS_PAGE
    }
  }

  componentWillMount () {
    this.props.fetchResourceProviders()
    this.props.fetchSolutionRuntimes()
    this.props.fetchManagedWorkers()
    this.props.ensureResourceTypes()
    if (this.props.organizationRole === ADMIN_POLICY) {
      this.props.fetchRoles()
      this.props.fetchClients()
      this.props.fetchUsers()
      this.props.fetchExternalNotificationEndpoints()
      this.props.fetchExternalNotificationFilters()
    }

    if (this.props.route.path === '/manage/:option') {
      if (this.props.params.option in OPTIONS) {
        this.setState({selectedOptions: this.props.params.option})
      } else {
        this.setState({selectedOptions: SOLUTIONS_PAGE})
      }
    }

    if (this.props.route.path === '/manage/:option/:id' && this.props.params.option === SOLUTIONS_PAGE) {
      this.onShowSolutionDetails(this.props.params.id)
    }
  }

  componentWillReceiveProps (nextProps, nextState) {
    if (nextProps.route.path === '/manage/:option/:id' &&
        nextProps.params.option === SOLUTIONS_PAGE &&
        this.props.params.id !== nextProps.params.id) {
      this.onShowSolutionDetails(nextProps.params.id)
    }

    if (this.props.params.option !== nextProps.params.option) {
      if (nextProps.params.option in OPTIONS) {
        this.setState({selectedOptions: nextProps.params.option})
      } else {
        this.setState({selectedOptions: SOLUTIONS_PAGE})
      }
    }

    if (nextProps.createdClient && nextProps.createdClient !== this.props.createdClient) {
      this.setState({showModal: CREATED_CLIENT_MODAL})
    }
  }

  onSelectOptions = option => {
    this.props.updateManagePath('/' + option)
    this.setState({selectedOptions: option})
  }

  onModalClose = () => {
    this.setState({showModal: null, modalInfo: null})
    this.props.updateManagePath('/' + this.props.params.option)
  }

  onShowEditSolutionRuntimeModal = environment => {
    this.setState({
      showModal: EDIT_SOLUTION_RUNTIME_MODAL,
      modalInfo: environment
    })
  }

  onShowEditRoleModal = role => {
    this.setState({
      showModal: EDIT_ROLE_MODAL,
      modalInfo: role
    })
  }

  onShowEditExternalNotificationEndpointModal = endpoint => {
    this.setState({
      showModal: EDIT_EXTERNAL_NOTIFICATION_ENDPOINT,
      modalInfo: endpoint
    })
  }

  onShowEditExternalNotificationFilterModal = filter => {
    this.setState({
      showModal: EDIT_EXTERNAL_NOTIFICATION_FILTER,
      modalInfo: filter
    })
  }

  onShowEditResourceProviderModal = account => {
    const providerAccessType = this.props.resourceProviderTypes.getIn([account.get('type'), 'provider_access_type'])
    account = account.set('provider_access_type', providerAccessType)
    this.setState({
      showModal: EDIT_RESOURCE_PROVIDER_MODAL,
      modalInfo: account
    })
  }

  onShowResourceProviderCredentialsModal = provider => {
    this.setState({
      showModal: SHOW_PROVIDER_CREDENTIALS_MODAL,
      modalInfo: provider
    })
  }

  onShowShowWorkersModal = workers => {
    this.setState({
      showModal: SHOW_WORKERS_MODAL,
      modalInfo: workers
    })
  }

  onShowSolutionDetails = solutionId => {
    this.props.updateManagePath('/' + SOLUTIONS_PAGE + '/' + solutionId)
    this.setState({
      showModal: SHOW_SOLUTION_DETAILS,
      modalInfo: solutionId
    })
  }

  onShowConfiguration = configuration => {
    this.setState({
      showModal: SHOW_CONFIGURATION,
      modalInfo: configuration
    })
  }

  _rightBarButtonItem = (onClick, text) => {
    return (
      <button type='button' className={style.navBarExtra} onClick={onClick}>
        {text}
      </button>
    )
  }

  addResourceProvider = this._rightBarButtonItem(
    () => this.setState({showModal: ADD_RESOURCE_PROVIDER_MODAL}),
    'Add Resource Provider'
  )

  addSolutionRuntime = this._rightBarButtonItem(
    () => this.setState({showModal: ADD_SOLUTION_RUNTIME_MODAL}),
    'Add Solution Runtime'
  )

  addRole = this._rightBarButtonItem(
    () => this.setState({showModal: ADD_ROLE_MODAL}),
    'Add Role'
  )

  addClient = this._rightBarButtonItem(
    () => this.setState({showModal: ADD_CLIENT_MODAL}),
    'Add Non-Interactive Client'
  )

  addExternalNotificationEndpoint = this._rightBarButtonItem(
    () => this.setState({showModal: ADD_EXTERNAL_NOTIFICATION_ENDPOINT}),
    'Add External Notification Endpoint'
  )

  addExternalNotificationFilter = this._rightBarButtonItem(
    () => this.setState({showModal: ADD_EXTERNAL_NOTIFICATION_FILTER}),
    'Add External Notification Filter'
  )

  solutionRuntimeTemplateUrl = () => {
    return this._templateUrl('solution_runtime')
  }

  externalNotificationTemplateUrl = (type) => {
    return this._templateUrl(`external_notification_endpoint_${type}`)
  }

  _templateUrl (templateName) {
    const templateUrl = `${TEMPLATE_PREFIX}${templateName}_${TEMPLATE_SUFFIX}.yaml`
    const organizationRef = this.props.organizationId.substr(-17, 4)
    const stackName = `${STACK_PREFIX}${templateName.replace(/_/g, '-')}-${organizationRef}${STACK_SUFFIX}`

    return `https://eu-west-1.console.aws.amazon.com/cloudformation/home?region=eu-west-1#/stacks/create/review?` +
      `templateURL=${templateUrl}&stackName=${stackName}&param_ExternalId=${this.props.organizationId}`
  }

  _renderModal (title, content) {
    return (
      <ModalDialog
        onDismiss={this.onModalClose}
        title={title}>
        {content}
      </ModalDialog>
    )
  }

  _displayOptions () {
    switch (this.state.selectedOptions) {
      case PROVIDERS_PAGE:
        return (
          <Providers
            resourceProviders={this.props.resourceProviders}
            solutionRuntimes={this.props.solutionRuntimes}
            managedWorkers={this.props.managedWorkers}
            addSolutionRuntime={this.addSolutionRuntime}
            addResourceProvider={this.addResourceProvider}
            onShowConfirmationModal={this.props.showConfirmationModal}
            onDeleteSolutionRuntime={this.props.deleteSolutionRuntime}
            onShowEditSolutionRuntimeModal={this.onShowEditSolutionRuntimeModal.bind(this)}
            onDeleteResourceProvider={this.props.deleteResourceProvider}
            onShowEditResourceProviderModal={this.onShowEditResourceProviderModal.bind(this)}
            onShowResourceProviderCredentialsModal={this.onShowResourceProviderCredentialsModal.bind(this)}
            organizationRole={this.props.organizationRole} />
        )
      case ACCESS_MANAGEMENT_PAGE:
        return (
          <AccessManagement
            roles={this.props.roles}
            clients={this.props.clients}
            onShowConfirmationModal={this.props.showConfirmationModal}
            onDeleteRole={this.props.deleteRole}
            onDeleteClient={this.props.deleteClient}
            onShowEditRoleModal={this.onShowEditRoleModal.bind(this)}
            organizationRole={this.props.organizationRole}
            addRole={this.addRole}
            addClient={this.addClient}
            users={this.props.users} />
        )
      case EXTERNAL_NOTIFICATIONS_PAGE:
        return (
          <ExternalNotifications
            externalNotificationEndpoints={this.props.externalNotificationEndpoints}
            externalNotificationFilters={this.props.externalNotificationFilters}
            onDelete={this.props.deleteExternalNotificationEndpoint}
            onShowConfirmationModal={this.props.showConfirmationModal}
            onShowEditExternalNotificationEndpointModal={this.onShowEditExternalNotificationEndpointModal.bind(this)}
            onShowExternalNotificationEndpointConfiguration={this.onShowConfiguration.bind(this)}
            onDeleteExternalNotificationFilter={this.props.deleteExternalNotificationFilter}
            onDeleteExternalNotificationEndpoint={this.props.deleteExternalNotificationEndpoint}
            onShowEditExternalNotificationFilterModal={this.onShowEditExternalNotificationFilterModal.bind(this)}
            addExternalNotificationFilter={this.addExternalNotificationFilter}
            addExternalNotificationEndpoint={this.addExternalNotificationEndpoint}
            organizationRole={this.props.organizationRole} />
        )
      default:
        return (
          <Solutions
            solutions={this.props.solutions}
            managedWorkers={this.props.managedWorkers}
            solutionRuntimes={this.props.solutionRuntimes}
            onShowWorkersModal={this.onShowShowWorkersModal.bind(this)}
            onShowSolutionDetails={this.onShowSolutionDetails.bind(this)} />
        )
    }
  }

  _generateModal () {
    switch (this.state.showModal) {
      case ADD_SOLUTION_RUNTIME_MODAL:
        return (
          this._renderModal(
            'Add Solution Runtime',
            <SolutionRuntimeCreator
              onClose={this.onModalClose.bind(this)}
              onCreate={this.props.createSolutionRuntime}
              templateUrl={this.solutionRuntimeTemplateUrl()} />
          )
        )
      case ADD_RESOURCE_PROVIDER_MODAL:
        return (
          this._renderModal(
            'Add Resource Provider',
            <ResourceProviderCreator
              onClose={this.onModalClose.bind(this)}
              onCreate={this.props.createResourceProvider}
              onInitialize={this.props.ensureResourceTypes}
              resourceProviderTypes={this.props.resourceProviderTypes} />
          )
        )
      case EDIT_SOLUTION_RUNTIME_MODAL:
        return (
          this._renderModal(
            `Edit Solution Runtime (${this.state.modalInfo.get('name')})`,
            <SolutionRuntimeEditor
              solutionRuntime={this.state.modalInfo}
              onClose={this.onModalClose.bind(this)}
              onUpdate={this.props.updateSolutionRuntime}
              formId='modalForm' />
          )
        )
      case EDIT_RESOURCE_PROVIDER_MODAL:
        const resourceProvider = this.state.modalInfo
        return (
          this._renderModal(
            `Edit Resource Provider (${this.state.modalInfo.get('name')})`,
            <ResourceProviderEditor
              resourceProvider={resourceProvider}
              resourceProviderType={this.props.resourceProviderTypes.get(resourceProvider.get('type'))}
              onClose={this.onModalClose.bind(this)}
              onUpdate={this.props.updateResourceProvider} />
          )
        )
      case SHOW_WORKERS_MODAL:
        return (
          this._renderModal(
            `View Workers (${this.state.modalInfo['name']})`,
            <WorkersList
              managedWorkers={this.state.modalInfo['workers']}
              onClose={this.onModalClose.bind(this)} />
          )
        )
      case SHOW_SOLUTION_DETAILS:
        const solutionId = this.state.modalInfo
        const solution = this.props.solutions.get(solutionId)
        if (!solution) {
          return
        }
        return (
          this._renderModal(
            `Solution Details (${solution.get('label')})`,
            <SolutionDetails solution={solution} organizationRole={this.props.organizationRole} />
          )
        )
      case SHOW_PROVIDER_CREDENTIALS_MODAL:
        return (
          this._renderModal(
            `Resource Provider Credentials`,
            <RestrictedCredentials
              provider={this.state.modalInfo}
              organizationRole={this.props.organizationRole} />
          )
        )
      case ADD_ROLE_MODAL:
        return (
          this._renderModal(
            'Add Role',
            <RoleCreator
              onClose={this.onModalClose.bind(this)}
              onCreate={this.props.createRole} />
          )
        )
      case ADD_CLIENT_MODAL:
        return (
          this._renderModal(
            'Add Non-Interactive Client',
            <NonInteractiveClientCreator
              roles={this.props.roles}
              onClose={this.onModalClose.bind(this)}
              onCreate={this.props.createClient} />
          )
        )
      case CREATED_CLIENT_MODAL:
        return (
          this._renderModal(
            'Add Non-Interactive Client',
            <CreatedNonInteractiveClient
              createdClient={this.props.createdClient}
              onClose={this.onModalClose.bind(this)} />
          )
        )
      case EDIT_ROLE_MODAL:
        return (
          this._renderModal(
            `Edit Role (${this.state.modalInfo.get('name')})`,
            <RoleEditor
              role={this.state.modalInfo}
              onClose={this.onModalClose.bind(this)}
              onUpdate={this.props.updateRole} />
          )
        )
      case ADD_EXTERNAL_NOTIFICATION_ENDPOINT:
        return (
          this._renderModal(
            'Add External Notification Endpoint',
            <ExternalNotificationEndpointCreator
              onClose={this.onModalClose.bind(this)}
              onCreate={this.props.createExternalNotificationEndpoint}
              onReturnTemplateUrl={this.externalNotificationTemplateUrl.bind(this)} />
          )
        )
      case EDIT_EXTERNAL_NOTIFICATION_ENDPOINT:
        return (
          this._renderModal(
            `Edit External Notification Endpoint (${this.state.modalInfo.get('name')})`,
            <ExternalNotificationEndpointEditor
              endpoint={this.state.modalInfo}
              onUpdate={this.props.updateExternalNotificationEndpoint}
              onClose={this.onModalClose.bind(this)}
              onReturnTemplateUrl={this.externalNotificationTemplateUrl.bind(this)} />
          )
        )
      case SHOW_CONFIGURATION:
        return (
          this._renderModal(
            `Configuration (${this.state.modalInfo.get('name')})`,
            <ConfigurationViewer
              configuration={this.state.modalInfo.get('configuration')} />
          )
        )
      case ADD_EXTERNAL_NOTIFICATION_FILTER:
        return (
          this._renderModal(
            'Add External Notification Filter',
            <ExternalNotificationFilterCreator
              filter={this.state.modalInfo}
              endpoints={this.props.externalNotificationEndpoints}
              onClose={this.onModalClose.bind(this)}
              onCreate={this.props.createExternalNotificationFilter}
              validateConfiguration={this.props.validateExternalNotificationFilterConfiguration}
              configurationValidation={this.props.externalNotificationFilterConfigurationValidation} />
          )
        )
      case EDIT_EXTERNAL_NOTIFICATION_FILTER:
        return (
          this._renderModal(
            `Edit External Notification Filter (${this.state.modalInfo.get('name')})`,
            <ExternalNotificationFilterEditor
              filter={this.state.modalInfo}
              onUpdate={this.props.updateExternalNotificationFilter}
              onClose={this.onModalClose.bind(this)}
              endpoints={this.props.externalNotificationEndpoints}
              validateConfiguration={this.props.validateExternalNotificationFilterConfiguration}
              configurationValidation={this.props.externalNotificationFilterConfigurationValidation} />
          )
        )
    }

    return null
  }

  render () {
    const options = this.props.organizationRole === ADMIN_POLICY ? (
      fromJS([
        OPTIONS[SOLUTIONS_PAGE],
        OPTIONS[PROVIDERS_PAGE],
        OPTIONS[ACCESS_MANAGEMENT_PAGE],
        OPTIONS[EXTERNAL_NOTIFICATIONS_PAGE],
      ])
    ) : (
      fromJS([
        OPTIONS[SOLUTIONS_PAGE],
        OPTIONS[PROVIDERS_PAGE]
      ])
    )

    return (
      <div className={style.Manage}>
        <div className={style.optionPanel}>
          <ClickableList
            items={options}
            selected={this.state.selectedOptions}
            clickAction={this.onSelectOptions.bind(this)}
            className={style.optionList}
            selectedClassName={style.selectedOption}
            selectAttribute='page'
            displayAttribute='name' />
        </div>
        <div className={style.separator} />
        <div>
          <div className={style.optionDisplay}>
            {this._displayOptions()}
          </div>
        </div>
        {this._generateModal()}
      </div>
    )
  }
}

Manage.propTypes = {
  solutions: ImmutablePropTypes.map.isRequired,
  resourceProviders: ImmutablePropTypes.map.isRequired,
  solutionRuntimes: ImmutablePropTypes.map.isRequired,
  managedWorkers: ImmutablePropTypes.map.isRequired,
  organizationId: PropTypes.string.isRequired,
  fetchResourceProviders: PropTypes.func.isRequired,
  fetchSolutionRuntimes: PropTypes.func.isRequired,
  fetchManagedWorkers: PropTypes.func.isRequired,
  ensureResourceTypes: PropTypes.func.isRequired,
  showConfirmationModal: PropTypes.func.isRequired,
  deleteSolutionRuntime: PropTypes.func.isRequired,
  updateSolutionRuntime: PropTypes.func.isRequired,
  deleteResourceProvider: PropTypes.func.isRequired,
  updateResourceProvider: PropTypes.func.isRequired,
  createResourceProvider: PropTypes.func.isRequired,
  resourceProviderTypes: ImmutablePropTypes.map.isRequired,
  organizationRole: PropTypes.number.isRequired,
  users: ImmutablePropTypes.map,
  roles: ImmutablePropTypes.map,
  clients: ImmutablePropTypes.map,
  createdClient: ImmutablePropTypes.map,
  externalNotificationEndpoints: ImmutablePropTypes.map,
  externalNotificationFilters: ImmutablePropTypes.map,
  fetchRoles: PropTypes.func.isRequired,
  deleteRole: PropTypes.func.isRequired,
  updateRole: PropTypes.func.isRequired,
  createRole: PropTypes.func.isRequired,
  fetchExternalNotificationEndpoints: PropTypes.func.isRequired,
  fetchExternalNotificationFilters: PropTypes.func.isRequired,
  validateExternalNotificationFilterConfiguration: PropTypes.func.isRequired,
  externalNotificationFilterConfigurationValidation: ImmutablePropTypes.map.isRequired
}

function mapStateToProps (state) {
  return {
    solutions: state.availableSolutions,
    resourceProviders: state.resourceProviders,
    solutionRuntimes: state.solutionRuntimes,
    managedWorkers: state.managedWorkers,
    organizationId: state.currentOrganization,
    resourceProviderTypes: getResourceProviderTypes(state),
    organizationRole: getRoleForCurrentOrganization(state),
    users: state.users,
    roles: state.roles,
    clients: state.clients,
    createdClient: state.createdClient,
    externalNotificationEndpoints: state.externalNotificationEndpoints,
    externalNotificationFilters: state.externalNotificationFilters,
    externalNotificationFilterConfigurationValidation: state.externalNotificationFilterConfigurationValidation
  }
}

function mapDispatchToProps (dispatch) {
  return {
    fetchResourceProviders: () => dispatch(fetchResourceProviders()),
    fetchSolutionRuntimes: () => dispatch(fetchSolutionRuntimes()),
    fetchManagedWorkers: () => dispatch(fetchManagedWorkers()),
    fetchUsers: () => dispatch(fetchUsers()),
    showConfirmationModal: (title, description, callback, disabled) => dispatch(showConfirmationModal(title, description, callback, disabled, true)),
    deleteSolutionRuntime: (id) => dispatch(deleteSolutionRuntime(id)),
    updateSolutionRuntime: (id, fields) => dispatch(updateSolutionRuntime(id, fields)),
    createSolutionRuntime: (fields) => dispatch(createSolutionRuntime(fields)),
    deleteResourceProvider: (id) => dispatch(deleteResourceProvider(id)),
    updateResourceProvider: (id, fields) => dispatch(updateResourceProvider(id, fields)),
    createResourceProvider: (fields) => dispatch(createResourceProvider(fields)),
    ensureResourceTypes: () => dispatch(ensureResourceTypes()),
    updateManagePath: pathName => dispatch(updateLocation({ pathname: '/manage' + pathName })),
    fetchRoles: () => dispatch(fetchRoles()),
    deleteRole: (id) => dispatch(deleteRole(id)),
    updateRole: (id, fields) => dispatch(updateRole(id, fields)),
    createRole: (fields) => dispatch(createRole(fields)),
    fetchClients: () => dispatch(fetchClients()),
    deleteClient: (id) => dispatch(deleteClient(id)),
    createClient: (fields) => dispatch(createClient(fields)),
    fetchExternalNotificationEndpoints: () => dispatch(fetchExternalNotificationEndpoints()),
    createExternalNotificationEndpoint: (fields) => dispatch(createExternalNotificationEndpoint(fields)),
    updateExternalNotificationEndpoint: (id, fields) => dispatch(updateExternalNotificationEndpoint(id, fields)),
    deleteExternalNotificationEndpoint: (id) => dispatch(deleteExternalNotificationEndpoint(id)),
    fetchExternalNotificationFilters: () => dispatch(fetchExternalNotificationFilters()),
    createExternalNotificationFilter: (fields) => dispatch(createExternalNotificationFilter(fields)),
    updateExternalNotificationFilter: (id, fields) => dispatch(updateExternalNotificationFilter(id, fields)),
    deleteExternalNotificationFilter: (id) => dispatch(deleteExternalNotificationFilter(id)),
    validateExternalNotificationFilterConfiguration: (config) => dispatch(validateExternalNotificationFilterConfiguration(config))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Manage)
