import React from 'react'
import ReactDOM from 'react-dom'
import Thunk from 'redux-thunk'
import { Provider } from 'react-redux'
import { Router, Route, IndexRedirect, hashHistory } from 'react-router'
import { createStore, combineReducers, applyMiddleware } from 'redux'
import { composeWithDevToolsDevelopmentOnly } from '@redux-devtools/extension'
import { syncHistoryWithStore, routerMiddleware, routerReducer } from 'react-router-redux'
import 'd3-transition'

import AuthCallback from 'components/AuthCallback'
import Authentication from './components/Authentication'
import Notifications from './scenes/Notifications'
import Root from './scenes/Root'
import Manage from './scenes/Manage'
import Resources from './scenes/Resources'
import SignIn from './scenes/SignIn'
import SelectOrganization from './scenes/SelectOrganization'
import Unauthorized from './scenes/Unauthorized'
import ServerError from './scenes/ServerError'
import Docs from './scenes/Docs'
import reducers from 'state/reducers'

import './style'

const middleware = routerMiddleware(hashHistory)
const store = createStore(
  combineReducers({
    ...reducers,
    routing: routerReducer
  }),
  composeWithDevToolsDevelopmentOnly(
    applyMiddleware(Thunk, middleware)
))

const history = syncHistoryWithStore(hashHistory, store)

export function routes (store) {
  return (
    <Router history={history}>
      <Route path='/' component={Authentication}>
        <IndexRedirect to='/resource_groups' />
        <Route path='/' component={Root}>
          <Route path='/resource_groups' component={Resources} />
          <Route path='/resource_groups/:id' component={Resources} />
          <Route path='/resources' component={Resources} />
          <Route path='/resources/:id' component={Resources} />
          <Route path='/manage' component={Manage} />
          <Route path='/manage/:option' component={Manage} />
          <Route path='/manage/:option/:id' component={Manage} />
          <Route path='/notifications' component={Notifications} />
          <Route path='/notifications/:id' component={Notifications} />
          <Route path='/api_docs' component={Docs} />
        </Route>
      </Route>
      <Route path='/signin(?:callbackHash)' component={({ location }) => {
        return (
          <SignIn
            returnRoute={location.query.returnRoute}
            message={location.query.message} />
        )
      }} />
      <Route path='/select_organization' component={SelectOrganization} />
      <Route path='/unauthorized' component={Unauthorized} />
      <Route path='/server_error' component={ServerError} />
      <Route path='/(:pre)access_token:post' component={AuthCallback} />
    </Router>
  )
}

ReactDOM.render(
  <Provider store={store}>
    {routes(store)}
  </Provider>,
  document.getElementById('app')
)
