import React from 'react'
import PropTypes from 'prop-types'

import JsonEditor from 'components/JsonEditor'

import style from './ConfigurationViewer.styl'

export class ConfigurationViewer extends React.Component {
  render () {
    return (
      <div className={style.configurationView}>
        <div className={style.main}>
          <div className={style.textBoxes}>
            <div className={style.editor}>
              <label>CONFIGURATION</label>
              <JsonEditor
                className={style.jsonEditor}
                jsonObject={this.props.configuration}
                showLineNumbering
                readOnly />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

ConfigurationViewer.propTypes = {
  configuration: PropTypes.object.isRequired
}

export default ConfigurationViewer
