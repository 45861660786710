import React from 'react'
import PropTypes from 'prop-types'

import Hint from 'components/Hint'

import style from './CredentialsFormAws.styl'

export class CredentialsFormAws extends React.Component {
  constructor () {
    super()

    this._onArnChange = this._onArnChange.bind(this)

    this.state = {
      iam_role_arn: null
    }
  }

  componentWillMount () {
    this.props.onCredentialsChange(this.state)
  }

  _onArnChange ({ target }) {
    this.setState((prevState, props) => {
      return { iam_role_arn: target.value }
    }, () => {
      this.props.onCredentialsChange(this.state)
    })
  }

  render () {
    return (
      <div>
        <div className={style.formField}>
          <label>
            Enter IAM role ARN
            <Hint className={style.hint}>
              Must be in the format: <div className={style.code}>arn:aws:iam::account-id:role/role-name</div>
            </Hint>
          </label>
          <input
            required name='arn' type='text'
            disabled={!this.props.editable}
            pattern='^arn:aws:iam::[0-9]{12}:role\/.*$'
            value={this.state.iam_role_arn || ''}
            onChange={this._onArnChange} />
        </div>
      </div>
    )
  }
}

CredentialsFormAws.defaultProps = {
  editable: true
}

CredentialsFormAws.propTypes = {
  onCredentialsChange: PropTypes.func.isRequired,
  editable: PropTypes.bool.isRequired
}

export default CredentialsFormAws
