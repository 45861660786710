import React from 'react'

const NavigationElement = {
  UP_PREVIOUS: 'upPrevious',
  UP: 'up',
  ACTIVE: 'active',
  DOWN: 'down',
  DOWN_EXIT: 'downExit',
  DOWN_NEXT: 'downNext'
}

const NodeStatus = {
  UP: 'up',
  ACTIVE: 'active',
  DOWN: 'down'
}

const Transition = {
  UP: 'up',
  DOWN: 'down'
}

export { NodeStatus, Transition }

export default function getNavigationItems (onUp, onDown, onTransitionEnd) {
  return {
    [NavigationElement.UP_PREVIOUS]: {
      index: ({ activeNode }) => activeNode.parent && activeNode.parent.index,
      transitionStatus: ({ transition }) => {
        if (transition.type === Transition.UP && transition.to.parent) {
          return NavigationElement.UP
        }
      },
      content: ({ transition, style }) => {
        return transition.type === Transition.UP && transition.to.parent
          ? renderNodeContent(transition.to.parent, NodeStatus.UP, null, style)
          : null
      },
      element: ({ classNames, content, style }) => {
        return (
          <div className={classNames}>
            <div className={style.elementContent}>{content}</div>
          </div>
        )
      }
    },

    [NavigationElement.UP]: {
      index: ({ activeNode }) => activeNode.parent && activeNode.parent.index,
      transitionStatus: ({ transition }) => {
        switch (transition.type) {
          case Transition.DOWN: return NavigationElement.UP_PREVIOUS
          case Transition.UP: return NavigationElement.ACTIVE
        }
      },
      content: ({ activeNode, style, transition }) => {
        const transitionStatus = transition.type === Transition.UP ? NodeStatus.ACTIVE : null

        return renderNodeContent(activeNode.parent, NodeStatus.UP, transitionStatus, style)
      },
      element: ({ activeNode, classNames, content, style }) => {
        return (
          <div className={classNames}
            onClick={content && onUp}>
            <div className={style.arrow}>◀</div>
            <div className={style.elementContent}>
              {content}
            </div>
            <div className={style.label}>{activeNode.parent.title}</div>
          </div>
        )
      },
      condition: ({ activeNode }) => activeNode.parent
    },

    [NavigationElement.ACTIVE]: {
      index: ({ activeNode }) => activeNode.index,
      transitionStatus: ({ transition }) => {
        switch (transition.type) {
          case Transition.DOWN: return NavigationElement.UP
          case Transition.UP: return NavigationElement.DOWN
        }
      },
      content: ({ activeNode, style, transition }) => {
        let transitionStatus = null
        switch (transition.type) {
          case Transition.DOWN: transitionStatus = NodeStatus.UP; break
          case Transition.UP: transitionStatus = NodeStatus.DOWN; break
        }

        return renderNodeContent(activeNode, NodeStatus.ACTIVE, transitionStatus, style)
      },
      element: ({ activeNode, classNames, content, index, ref, style, transition }) => {
        const downStyle = transition.type === Transition.UP
          ? {
            height: `${activeNode.weight || 100}%`,
            top: `${activeNode.offset || 0}%`
          }
          : null

        return (
          <div className={classNames} style={downStyle}
            ref={ref}
            data-index={index}
            onTransitionEnd={onTransitionEnd}>
            <div className={style.elementContent}>{content}</div>
          </div>
        )
      }
    },

    [NavigationElement.DOWN]: {
      index: ({ activeNode, elementIndex }) => {
        return activeNode.children[elementIndex] && activeNode.children[elementIndex].index
      },
      transitionStatus: ({ transition, index }) => {
        switch (transition.type) {
          case Transition.DOWN:
            return transition.child === index ? NavigationElement.ACTIVE : NavigationElement.DOWN_EXIT
          case Transition.UP:
            return NavigationElement.DOWN_NEXT
        }
      },
      content: ({ activeNode, index, style, transition }) => {
        const transitionStatus = transition.type === Transition.DOWN ? NodeStatus.ACTIVE : null

        return renderNodeContent(activeNode.children[index], NodeStatus.DOWN, transitionStatus, style)
      },
      element: ({ activeNode, classNames, content, elementIndex, index, style, transitionStatus }) => {
        const childNode = activeNode.children[index]

        const downStyle = transitionStatus === NavigationElement.ACTIVE
          ? null
          : {
            height: `${childNode.weight || 100}%`,
            top: `${childNode.offset || 0}%`
          }

        return (
          <div className={classNames} style={downStyle}
            key={elementIndex} data-index={index}
            onClick={content && (() => onDown(index))}>
            {content && <div className={style.label}>{childNode.title} <span className={style.arrow}>▶</span></div>}
            <div className={style.elementContent}>{content}</div>
          </div>
        )
      },
      count: ({ activeNode }) => activeNode.children.length
    },

    [NavigationElement.DOWN_EXIT]: {
      index: ({ activeNode, elementIndex }) => {
        return activeNode.children[elementIndex] && activeNode.children[elementIndex].index
      },
      transitionStatus: ({ transition, index }) => {
        if (transition.type === Transition.UP &&
            transition.to.children[index] &&
            transition.child !== index) {
          return NavigationElement.DOWN
        }
      },
      content: ({ index, style, transition }) => {
        return transition.type === Transition.UP && transition.to.children[index]
          ? renderNodeContent(transition.to.children[index], NodeStatus.DOWN, null, style)
          : null
      },
      element: ({ classNames, content, elementIndex, index, style, transition }) => {
        const downStyle = transition.type === Transition.UP && transition.to.children[index]
          ? {
            height: `${transition.to.children[index].weight || 100}%`,
            top: `${transition.to.children[index].offset || 0}%`
          }
          : null

        return (
          <div className={classNames} style={downStyle}
            key={elementIndex} data-index={index}>
            <div className={style.elementContent}>{content}</div>
          </div>
        )
      },
      count: 2
    },

    [NavigationElement.DOWN_NEXT]: {
      index: ({ activeNode, elementIndex }) => {
        return activeNode.children[elementIndex] && activeNode.children[elementIndex].index
      },
      transitionStatus: ({ transition, index }) => {
        if (transition.type === Transition.DOWN &&
            transition.from.children[transition.child].children[index]) {
          return NavigationElement.DOWN
        }
      },
      content: ({ index, style, transition }) => {
        return transition.type === Transition.DOWN && transition.to.children[index]
          ? renderNodeContent(transition.to.children[index], NodeStatus.DOWN, null, style)
          : null
      },
      element: ({ classNames, content, elementIndex, index, style, transition }) => {
        const downStyle = transition.type === Transition.DOWN && transition.to.children[index]
          ? {
            height: `${transition.to.children[index].weight || 100}%`,
            top: `${transition.to.children[index].offset || 0}%`
          }
          : null

        return (
          <div className={classNames} style={downStyle}
            key={elementIndex} data-index={index}>
            <div className={style.elementContent}>{content}</div>
          </div>
        )
      },
      count: 2
    }
  }
}

function renderNodeContent (node, form, transitioning, style) {
  if (typeof node.content !== 'function') {
    return node.content
  }

  return node.content(form, transitioning)
}
