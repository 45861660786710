import React from 'react'
import { Map } from 'immutable'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'

import { POLICIES, returnFiltered } from 'scenes/Manage'
import Filter from 'components/Filter'
import Card, { CardBody, CardHeader } from 'components/Card'
import RolesList from './components/RolesList'
import NonInteractiveClientsList from './components/NonInteractiveClientsList'
import UsersList from './components/UsersList'
import {
  ADMIN_POLICY
} from 'state/actions'

import style from 'scenes/Manage/Manage.styl'

export class AccessManagement extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      filteredUsers: this.props.users,
      filteredRoles: this.props.roles,
      filteredClients: this.props.clients
    }
  }

  componentWillReceiveProps (nextProps) {
    [
      ['users', 'filteredUsers'],
      ['roles', 'filteredRoles'],
      ['clients', 'filteredClients']
    ].map(([propName, stateName]) => {
      if (nextProps[propName] !== this.props[propName]) {
        this.setState({
          [stateName]: nextProps[propName]
        })
      }
    })
  }

  _onUpdateUsers = filtered => {
    this.setState({filteredUsers: returnFiltered(filtered)})
  }

  _onUpdateRoles = filtered => {
    this.setState({filteredRoles: returnFiltered(filtered)})
  }

  _onUpdateClients = filtered => {
    this.setState({filteredClients: returnFiltered(filtered)})
  }

  render () {
    const users = this.props.users.reduce((userMap, user) => {
      return userMap.set(`${user.get('name')} ${POLICIES[user.get('policy')]}`, user)
    }, Map())

    const roles = this.props.roles.reduce((groups, role) => {
      return groups.set(
        `${role.get('name')} ${POLICIES[role.get('policy')]} ${role.get('auth0_connection')} ${role.get('idp_groups')}`,
        role
      )
    }, Map())

    const roleNamesById = this.props.roles.reduce(
      (names, role) => names.set(role.get('id'), role.get('name')),
      Map()
    )

    const clients = this.props.clients.reduce((clientMap, client) => {
      return clientMap.set(
        `${client.get('name')} ${roleNamesById.get(client.get('role'))} ${client.get('client_id')}`,
        client
      )
    }, Map())

    return (
      <div>
        <Card>
          <CardHeader title='Roles'
            rightBarButtonItem={this.props.organizationRole === ADMIN_POLICY ? this.props.addRole : null} />
          <div className='normal'>
            <Filter
              className={style.filter}
              data={roles}
              onFilter={this._onUpdateRoles} />
          </div>
          <CardBody>
            <RolesList
              roles={this.state.filteredRoles}
              onConfirmation={this.props.onShowConfirmationModal}
              onDelete={this.props.onDeleteRole}
              onShowEditModal={this.props.onShowEditRoleModal}
              organizationRole={this.props.organizationRole} />
          </CardBody>
        </Card>
        <Card>
          <CardHeader title='Non-Interactive Clients'
            rightBarButtonItem={this.props.organizationRole === ADMIN_POLICY ? this.props.addClient : null} />
          <div className='normal'>
            <Filter
              className={style.filter}
              data={clients}
              onFilter={this._onUpdateClients} />
          </div>
          <CardBody>
            <NonInteractiveClientsList
              clients={this.state.filteredClients}
              roleNamesById={roleNamesById}
              onConfirmation={this.props.onShowConfirmationModal}
              onDelete={this.props.onDeleteClient}
              organizationRole={this.props.organizationRole} />
          </CardBody>
        </Card>
        <Card>
          <CardHeader title='Users' />
          <div className='normal'>
            <Filter
              className={style.filter}
              data={users}
              onFilter={this._onUpdateUsers} />
          </div>
          <CardBody>
            <UsersList
              users={this.state.filteredUsers} />
          </CardBody>
        </Card>
      </div>
    )
  }
}

AccessManagement.propTypes = {
  onShowConfirmationModal: PropTypes.func.isRequired,
  organizationRole: PropTypes.number.isRequired,
  onDeleteRole: PropTypes.func.isRequired,
  onDeleteClient: PropTypes.func.isRequired,
  onShowEditRoleModal: PropTypes.func.isRequired,
  addRole: PropTypes.node.isRequired,
  addClient: PropTypes.node.isRequired,
  users: ImmutablePropTypes.map,
  roles: ImmutablePropTypes.map,
  clients: ImmutablePropTypes.map
}

export default AccessManagement
