export const defaultTheme = {
  base00: '#1d1f21',
  base01: '#282a2e',
  base02: '#373b41',
  base03: '#969896',
  base04: '#b4b7b4',
  base05: '#c5c8c6',
  base06: '#e0e0e0',
  base07: '#000000',
  base08: '#808182',
  base09: '#efa30b',
  base0A: '#FBA922',
  base0B: '#27afc5',
  base0C: '#3971ED',
  base0D: '#000000',
  base0E: '#A36AC7',
  base0F: '#3971ED'
}

export const compliantTheme = {
  ...defaultTheme,
  base09: '#267126'
}

export const noncompliantTheme = {
  ...defaultTheme,
  base09: '#d6332c'
}
