import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import style from './Toolbar.styl'

export function Toolbar ({ children, className }) {
  return (
    <div className={classNames(style.Toolbar, className)}>
      {children}
    </div>
  )
}

Toolbar.propTypes = {
  className: PropTypes.string
}

export function ToolbarSection ({ children, className }) {
  return (
    <div className={classNames(style.ToolbarSection, className)}>
      {children}
    </div>
  )
}

ToolbarSection.propTypes = {
  className: PropTypes.string
}
