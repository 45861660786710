import * as actions from './actions'

const initialAuthState = JSON.parse(window.localStorage.getItem('auth'))

const auth = (state = initialAuthState, action) => {
  switch (action.type) {
    case actions.SIGN_IN:
      return {
        idToken: action.idToken,
        profile: action.profile
      }
    case actions.SIGN_OUT:
      return null
    default:
      return state
  }
}

export { auth }
