import PropTypes from 'prop-types'
import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'

import Card, { CardHeader, CardBody } from 'components/Card'

import style from './SolutionGalleryItem.styl'

function genTags (tags) {
  return tags.map((tag, idx) => (
    <li key={idx}>{tag}</li>
  ))
}

export class SolutionGalleryItem extends React.Component {
  render () {
    const workerText = this.props.managedWorkers.size === 1 ? 'WORKER' : 'WORKERS'

    return (
      <Card className={style.galleryItem}>
        <div className='inverted'>
          <CardHeader title={
            <span className={style.cardHeader}>
              <span>{this.props.label}</span>
              <span className={style.version}>{this.props.version}</span>
            </span>} />
        </div>
        <CardBody>
          <div className={style.description}>{this.props.description}</div>
          <ul className={style.tags}>{genTags(this.props.tags)}</ul>
          <div className={style.actions}>
            <a
              className={style.action}
              onClick={() => this.props.onShowSolutionDetails(this.props.id)}
              id='details' >
              DETAILS
            </a>
            <a
              className={style.action}
              onClick={() => this.props.onShowWorkersModal({workers: this.props.managedWorkers, name: this.props.label})}
              id='workers' >
              {this.props.managedWorkers.size} {workerText}
            </a>
          </div>
        </CardBody>
      </Card>
    )
  }
}

SolutionGalleryItem.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  version: PropTypes.string,
  description: PropTypes.object.isRequired,
  tags: ImmutablePropTypes.list.isRequired,
  managedWorkers: ImmutablePropTypes.map,
  onShowWorkersModal: PropTypes.func.isRequired,
  onShowSolutionDetails: PropTypes.func.isRequired
}

export default SolutionGalleryItem
