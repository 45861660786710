import React from 'react'
import { Map } from 'immutable'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'

import Filter from 'components/Filter'
import Card, { CardBody, CardHeader } from 'components/Card'
import ResourceProvidersList from './components/ResourceProvidersList'
import { returnFiltered } from 'scenes/Manage'
import SolutionRuntimesList from './components/SolutionRuntimesList'
import {
  ADMIN_POLICY
} from 'state/actions'

import style from 'scenes/Manage/Manage.styl'

export class Providers extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      filteredResourceProviders: this.props.resourceProviders,
      filteredSolutionRuntimes: this.props.solutionRuntimes
    }
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.resourceProviders !== this.props.resourceProviders) {
      this.setState({
        filteredResourceProviders: nextProps.resourceProviders
      })
    }
    if (nextProps.solutionRuntimes !== this.props.solutionRuntimes) {
      this.setState({
        filteredSolutionRuntimes: nextProps.solutionRuntimes
      })
    }
  }

  _onUpdateResourceProviders = filtered => {
    this.setState({filteredResourceProviders: returnFiltered(filtered)})
  }

  _onUpdateSolutionRuntimes = filtered => {
    this.setState({filteredSolutionRuntimes: returnFiltered(filtered)})
  }

  _getNumberOfWorkersInEnvironment (environmentId) {
    return this.props.managedWorkers.reduce((total, worker) => {
      if (worker.get('solution_runtime') === environmentId) {
        return total + 1
      }
    }, 0)
  }

  _processSolutionRuntimes (envs) {
    return envs.reduce((environments, environment) => {
      const env = environment.set('workers', this._getNumberOfWorkersInEnvironment(environment.get('id')))
      return environments.set(environment.get('id'), env)
    }, Map())
  }

  _processResourceProviders (providers) {
    return providers.reduce((providers, provider) => {
      return providers.set(provider.get('id'), provider)
    }, Map())
  }

  render () {
    const resourceProviders = this.props.resourceProviders.reduce((accounts, account) => {
      return accounts.set(account.get('name'), account)
    }, Map())

    const solutionRuntimes = this.props.solutionRuntimes.reduce((environments, environment) => {
      return environments.set(environment.get('name'), environment)
    }, Map())

    return (
      <div>
        <Card>
          <CardHeader
            title='Solution Runtimes'
            rightBarButtonItem={this.props.organizationRole === ADMIN_POLICY ? this.props.addSolutionRuntime : null} />
          <div className='normal'>
            <Filter
              className={style.filter}
              data={solutionRuntimes}
              onFilter={this._onUpdateSolutionRuntimes} />
          </div>
          <CardBody>
            <SolutionRuntimesList
              solutionRuntimes={this._processSolutionRuntimes(this.state.filteredSolutionRuntimes)}
              onConfirmation={this.props.onShowConfirmationModal}
              onDelete={this.props.onDeleteSolutionRuntime}
              onShowEditModal={this.props.onShowEditSolutionRuntimeModal}
              organizationRole={this.props.organizationRole} />
          </CardBody>
        </Card>
        <Card>
          <CardHeader
            title='Resource Providers'
            rightBarButtonItem={this.props.organizationRole === ADMIN_POLICY ? this.props.addResourceProvider : null} />
          <div className='normal'>
            <Filter
              className={style.filter}
              data={resourceProviders}
              onFilter={this._onUpdateResourceProviders} />
          </div>
          <CardBody>
            <ResourceProvidersList
              resourceProviders={this._processResourceProviders(this.state.filteredResourceProviders)}
              onConfirmation={this.props.onShowConfirmationModal}
              onDelete={this.props.onDeleteResourceProvider}
              onShowEditModal={this.props.onShowEditResourceProviderModal}
              onShowCredentialsModal={this.props.onShowResourceProviderCredentialsModal}
              organizationRole={this.props.organizationRole} />
          </CardBody>
        </Card>
      </div>
    )
  }
}

Providers.propTypes = {
  resourceProviders: ImmutablePropTypes.map.isRequired,
  solutionRuntimes: ImmutablePropTypes.map.isRequired,
  managedWorkers: ImmutablePropTypes.map.isRequired,
  addResourceProvider: PropTypes.node.isRequired,
  addSolutionRuntime: PropTypes.node.isRequired,
  onShowConfirmationModal: PropTypes.func.isRequired,
  onDeleteSolutionRuntime: PropTypes.func.isRequired,
  onShowEditResourceProviderModal: PropTypes.func.isRequired,
  onShowResourceProviderCredentialsModal: PropTypes.func.isRequired,
  onDeleteResourceProvider: PropTypes.func.isRequired,
  onShowEditSolutionRuntimeModal: PropTypes.func.isRequired,
  organizationRole: PropTypes.number.isRequired
}

export default Providers
