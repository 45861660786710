import PropTypes from 'prop-types'
import React from 'react'

export default class AuthCallback extends React.Component {
  static contextTypes = {
    router: PropTypes.shape({
      replace: PropTypes.func.isRequired
    }).isRequired
  }

  render () {
    return null
  }

  componentDidMount () {
    const query = this.props.location.pathname.substring(1)
    this.context.router.replace(`/signin?${query}`)
  }
}
