import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import FontAwesome from 'react-fontawesome'

import { splitArn } from 'services/splitter'

import style from './WorkersList.styl'

const WORKER_DOCUMENTATION_URL = 'https://docs.conductor.cloudreach.com/components/solutions/introduction.html'

export class WorkersList extends React.Component {
  _listWorkers (workers) {
    if (!workers || !workers.size) {
      return (<tr><td colSpan='4'>No Workers</td></tr>)
    }

    return workers.map(worker => {
      const splittedArn = splitArn(worker.getIn(['data', 'arn']))
      const reference = splittedArn
        ? `${splittedArn['partition']}:${splittedArn['service']}:${splittedArn['account_id']}`
        : null
      const active = true

      const solutionRuntime = worker.get('solution_runtime')
      const environmentArn = splitArn(solutionRuntime.getIn(['credentials', 'arn']))['account_id']
      const environment = solutionRuntime
        ? `${solutionRuntime.get('name')} (${solutionRuntime.get('provider_id')}: ${environmentArn})`
        : null

      return (
        <tr key={worker.get('id')}>
          <td>{worker.get('id')}</td>
          <td>{environment}</td>
          <td>{reference}</td>
          <td className={active ? style.active : style.inactive}>
            <FontAwesome name={active ? 'check-square' : 'square'} />
          </td>
        </tr>
      )
    })
  }

  render () {
    return (
      <div>
        <div className={style.help}>
          Find out how to <a href={WORKER_DOCUMENTATION_URL} target='_blank'>add a worker</a>
        </div>
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Solution Runtime</th>
              <th>Reference</th>
              <th>Status</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {this._listWorkers(this.props.managedWorkers.valueSeq().sortBy(v => v.index))}
          </tbody>
        </table>
      </div>
    )
  }
}

WorkersList.propTypes = {
  managedWorkers: ImmutablePropTypes.map.isRequired
}

export default WorkersList
