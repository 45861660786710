import classNames from 'classnames'
import { easeCubic } from 'd3-ease'
import { select } from 'd3-selection'
import { arc } from 'd3-shape'

import { initProperty, interpolateProperty } from './interpolate'
import style from '../../ResourceGraph.styl'

const GROUP_ARC_START_RADIUS = 60
const GROUP_ARC_WIDTH = 5
const COMPLIANCE_ARC_RADIUS_OFFSET = -3
const COMPLIANCE_ARC_WIDTH = 2

const TRANSITION_DURATION = 700
const FLASH_DURATION = 150
const FLASH_DELAY = 50

export default function renderGroups (rootNode, parentElement, onSelectGroup, enableTransitions, limit) {
  const updatingGroups = select(parentElement)
    .selectAll('g.group')
    .data(rootNode.children || [], groupNode => groupNode.data.group.id)

  const enteringGroups = updatingGroups.enter().append('g')
  const currentGroups = updatingGroups.merge(enteringGroups)
  const exitingGroups = updatingGroups.exit()

  renderEmpty(parentElement, currentGroups.empty())

  const groupArcGenerator = createArcGenerator(GROUP_ARC_START_RADIUS, GROUP_ARC_WIDTH)
  const complianceArcStartRadius = GROUP_ARC_START_RADIUS + COMPLIANCE_ARC_RADIUS_OFFSET
  const complianceArcGenerator = createArcGenerator(complianceArcStartRadius, COMPLIANCE_ARC_WIDTH)

  enteringGroups
    .attr('class', classNames('group', style.group))
    .style('opacity', 0)
    .style('fill', groupNode => {
      return groupNode.data.group.get('color')
    })

  enteringGroups
    .append('path')
    .attr('class', 'main')
    .attr('id', groupNode => `arc-${groupNode.data.group.id}`)
    .attr('d', (groupNode, i, elements) => {
      const position = initProperty(elements[i], 'position', { x: groupNode.x, width: groupNode.width })
      return groupArcGenerator(position)
    })

  updatingGroups
    .select('path.main')
    .transition()
    .duration(enableTransitions ? TRANSITION_DURATION : 0)
    .ease(easeCubic)
    .attrTween('d', (groupNode, i, elements) => {
      return interpolatePosition(groupNode, elements[i], groupArcGenerator)
    })

  currentGroups
    .transition()
    .duration(enableTransitions ? TRANSITION_DURATION : 0)
    .style('opacity', 1)
    .attr('data-group-id', getGroupNodeId)

  currentGroups
    .select('path.main')
    .on('mouseover', groupNode => highlightGroup(currentGroups, groupNode.data.group.id))
    .on('mouseout', groupNode => resetHighlight(currentGroups))
    .on('click', groupNode => {
      groupNode.clicked = true
      flashGroup(groupNode.data.group.id, currentGroups)
      onSelectGroup(groupNode.data.group)
    })

  exitingGroups
    .transition()
    .duration(enableTransitions ? TRANSITION_DURATION : 0)
    .style('opacity', 0)
    .remove()

  const updatingCompliance = currentGroups
    .selectAll('path.compliance')
    .data(groupNode => groupNode.data.group.mark ? [groupNode] : [])

  const enteringCompliance = updatingCompliance.enter().append('path')
  const exitingCompliance = updatingCompliance.exit()

  enteringCompliance
    .attr('class', classNames('compliance', style.nonCompliant))
    .attr('id', groupNode => groupNode.data.group.id)
    .attr('d', (groupNode, i, elements) => {
      const position = initProperty(elements[i], 'position', { x: groupNode.x, width: groupNode.width })
      return complianceArcGenerator(position)
    })

  updatingCompliance
    .transition()
    .duration(enableTransitions ? TRANSITION_DURATION : 0)
    .ease(easeCubic)
    .attrTween('d', (groupNode, i, elements) => {
      return interpolatePosition(groupNode, elements[i], complianceArcGenerator)
    })

  exitingCompliance.remove()

  const updatingWarning = currentGroups
    .selectAll('path.warning')
    .data(groupNode => groupNode.data.group.warn ? [groupNode] : [])

  const enteringWarning = updatingWarning.enter().append('path')
  const exitingWarning = updatingWarning.exit()

  enteringWarning
    .attr('class', classNames('warning', style.warning))
    .attr('id', groupNode => groupNode.data.group.id)
    .attr('title', 'Some policies have yet to be invoked')
    .attr('d', (groupNode, i, elements) => {
      const position = initProperty(elements[i], 'position', { x: groupNode.x, width: groupNode.width })
      return complianceArcGenerator(position)
    })

  updatingWarning
    .transition()
    .duration(enableTransitions ? TRANSITION_DURATION : 0)
    .ease(easeCubic)
    .attrTween('d', (groupNode, i, elements) => {
      return interpolatePosition(groupNode, elements[i], complianceArcGenerator)
    })

  exitingWarning.remove()
}

function getGroupNodeId (groupNode) {
  return groupNode.data.group.id
}

function createArcGenerator (startRadius, width) {
  return arc()
    .innerRadius(startRadius)
    .outerRadius(startRadius + width)
    .cornerRadius(1)
    .padAngle(0.003 * Math.PI)
    .startAngle(groupNode => groupNode.x - groupNode.width / 2)
    .endAngle(groupNode => groupNode.x + groupNode.width / 2)
}

function interpolatePosition (groupNode, element, arcGenerator) {
  const endPosition = {
    x: groupNode.x,
    width: groupNode.width
  }

  return interpolateProperty(element, 'position', null, endPosition, (position) => {
    const interpolatedArcGenerator = arcGenerator
      .startAngle(_ => position.x - position.width / 2)
      .endAngle(_ => position.x + position.width / 2)

    return interpolatedArcGenerator(groupNode)
  })
}

function flashGroup (groupName, groups) {
  const group = groups
    .filter(groupNode => groupNode.data.group.id === groupName)

  group
    .transition()
    .duration(FLASH_DELAY)
    .transition()
    .duration(FLASH_DURATION)
    .style('opacity', 0)
    .transition()
    .duration(FLASH_DURATION)
    .style('opacity', 1)
}

function highlightGroup (groups, groupName) {
  groups
    .filter(groupNode => groupNode.data.group.id !== groupName)
    .transition()
    .style('opacity', 0.3)
}

function resetHighlight (groups) {
  groups
    .transition()
    .style('opacity', 1)
}

function renderEmpty (parentElement, empty) {
  let emptyContainerElement = select(parentElement)
    .selectAll('g.empty')

  if (!empty) {
    emptyContainerElement.remove()
    return
  }

  emptyContainerElement = select(parentElement)
    .append('g')
    .attr('class', classNames('empty', style.empty))

  const emptyArc = arc()
    .innerRadius(GROUP_ARC_START_RADIUS)
    .outerRadius(GROUP_ARC_START_RADIUS + GROUP_ARC_WIDTH)
    .startAngle(node => 0)
    .endAngle(node => 2 * Math.PI)

  emptyContainerElement
    .append('path')
    .attr('d', emptyArc)

  emptyContainerElement
    .append('text')
    .attr('text-anchor', 'middle')
    .attr('dominant-baseline', 'middle')
    .text('No resources')
}
