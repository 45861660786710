import PropTypes from 'prop-types'
import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import classNames from 'classnames'

import { RESOURCE_GRAPH } from 'refs'
import { hierarchy, partition } from 'd3-hierarchy'

import {
  setActiveResourceGroup
} from 'state/actions'

import {
  highlightResourceGroup,
  resetResourceGroupHighlight
} from 'state/resources/actions'

import {
  getResourceGroupTree
} from 'state/selectors'

import ResourceGroupTable from './components/ResourceGroupTable'

import * as renderer from './services'
import style from './ResourceGroupGraph.styl'

const TRANSITION_DURATION = 1000

export const ResourceGroupGraphVariants = {
  MICRO: 'micro',
  MINI: 'mini',
  NORMAL: 'normal'
}

const GraphLegend = () => {
  return (
    <ul className={style.graphLegend}>
      <li className={style.legendAttached}>Policy attached</li>
      <li className={style.legendInherited}>Policy inherited</li>
    </ul>
  )
}

export class ResourceGroupGraph extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      resourceGroupGraphTransitioning: false,
      resourceGroupNodes: [],
      activeResourceGroupNode: null
    }
  }

  componentWillMount () {
    this._updateNodes()
  }

  componentDidMount () {
    this._draw(false)
  }

  componentWillReceiveProps (nextProps, nextState) {
    if ((nextProps.activeResourceGroupId && this.props.activeResourceGroupId !== nextProps.activeResourceGroupId) ||
        !this.props.resourceGroupTree.equals(nextProps.resourceGroupTree)) {
      this._updateNodes(nextProps.activeResourceGroupId, nextProps.resourceGroupTree)
    }
  }

  componentDidUpdate (prevProps) {
    this._draw(true)
  }

  _highlightedResourceGroupId = () => {
    return (this.props.highlightedResourceGroup && this.props.highlightedResourceGroup.get('id', null)) || null
  }

  _onSelectResourceGroup (resourceGroupId) {
    if (resourceGroupId === this.props.activeResourceGroupId) {
      return
    }

    this.setState({ resourceGroupGraphTransitioning: true }, () => {
      setTimeout(() => {
        this.setState({ resourceGroupGraphTransitioning: false })
      }, TRANSITION_DURATION)
    })

    this.props.onSelectResourceGroup(resourceGroupId)
  }

  _updateNodes (activeResourceGroupId = this.props.activeResourceGroupId, resourceGroupTree = this.props.resourceGroupTree) {
    const nodesHierarchy = hierarchy(resourceGroupTree.toJS())
    nodesHierarchy.sum(resourceGroupNode => resourceGroupNode.children ? 0 : 1)
    partition()(nodesHierarchy)

    const resourceGroupNodes = nodesHierarchy.descendants()
    const activeResourceGroupNode = resourceGroupNodes.find(resourceGroupNode => {
      return resourceGroupNode.data.id === activeResourceGroupId
    })
    this.setState({ resourceGroupNodes, activeResourceGroupNode })
  }

  _draw (enableTransitions) {
    if (!this.state.activeResourceGroupNode) {
      return null
    }

    const renderText = this.props.variant === 'normal'

    renderer.renderArcs(
      this.state.resourceGroupNodes,
      this.state.activeResourceGroupNode,
      this.refs.graph,
      this.props.activeResourceGroupId,
      resourceGroupId => this._onSelectResourceGroup(resourceGroupId),
      renderText,
      enableTransitions && this.props.enableTransitions,
      this._highlightedResourceGroupId(),
      this.props.highlightResourceGroup,
      this.props.resetResourceGroupHighlight
    )
  }

  render () {
    const viewBoxSize = 250
    const viewBoxOffset = -viewBoxSize / 2

    const viewBox = `${viewBoxOffset} ${viewBoxOffset} ${viewBoxSize} ${viewBoxSize}`
    const svgClassName = classNames({ [style.disabled]: this.state.resourceGroupGraphTransitioning })

    const graphLegend = this.props.showLegend ? <GraphLegend /> : null

    return (
      <div className={style.ResourceGroupGraph}>
        <div data-ref={RESOURCE_GRAPH} className={style.layout}>
          <svg className={svgClassName} ref='graph' viewBox={viewBox} />
        </div>
        <ResourceGroupTable
          variant={this.props.variant}
          activeResourceGroupId={this.props.activeResourceGroupId}
          onSelect={this._onSelectResourceGroup.bind(this)}
          highlightedResourceGroup={this.props.highlightedResourceGroup}
          highlightResourceGroup={this.props.highlightResourceGroup}
          resetResourceGroupHighlight={this.props.resetResourceGroupHighlight}
          isTransitioning={this.state.resourceGroupGraphTransitioning}
          resourceGroupNodes={this.state.resourceGroupNodes}
          activeResourceGroupNode={this.state.activeResourceGroupNode} />
        {graphLegend}
      </div>
    )
  }
}

ResourceGroupGraph.propTypes = {
  activeResourceGroupId: PropTypes.string,
  onSelectResourceGroup: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(
    Object.keys(ResourceGroupGraphVariants).map(key => ResourceGroupGraphVariants[key])
  ),
  highlightedResourceGroup: ImmutablePropTypes.contains({
    id: PropTypes.string,
    scroll: PropTypes.bool
  }),
  highlightResourceGroup: PropTypes.func.isRequired,
  resetResourceGroupHighlight: PropTypes.func.isRequired,
  resourceGroupTree: ImmutablePropTypes.contains({
    id: PropTypes.string,
    name: PropTypes.string,
    children: ImmutablePropTypes.list
  }),
  enableTransitions: PropTypes.bool.isRequired,
  showLegend: PropTypes.bool.isRequired
}

function mapStateToProps (state) {
  return {
    resourceGroupTree: getResourceGroupTree(state),
    enableTransitions: state.enableTransitions,
    highlightedResourceGroup: state.highlightedResourceGroup
  }
}

function mapDispatchToProps (dispatch) {
  return {
    highlightResourceGroup: (resourceGroupId, scroll) => dispatch(highlightResourceGroup(resourceGroupId, scroll)),
    resetResourceGroupHighlight: () => dispatch(resetResourceGroupHighlight()),
    onSelectResourceGroup: (resourceGroup) => dispatch(setActiveResourceGroup(resourceGroup))
  }
}

export default connect(
  mapStateToProps, mapDispatchToProps
)(ResourceGroupGraph)
