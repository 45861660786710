import decodeJwt from 'jwt-decode'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'

import TimeSync from 'components/TimeSync'

import style from './Authentication.styl'
import { signOut } from 'state/session/actions'

const EXPIRY_PREEMPTION_DURATION = moment.duration(1, 'minutes')
const WARNING_DURATION = moment.duration(15, 'minutes')

export class Authentication extends React.Component {
  constructor () {
    super()

    this.expiryTimeout = null
    this.warningTimeout = null

    this.state = {
      showWarning: false
    }
  }

  render () {
    if (!this.props.idToken || isIdTokenExpired(this.props.idToken)) {
      return null
    }

    this.identity = decodeJwt(this.props.idToken)
    this.expiryMoment = moment.unix(this.identity.exp).subtract(EXPIRY_PREEMPTION_DURATION)

    const warningElement = this.state.showWarning ? (
      <TimeSync render={(now) => {
        const returnRoute = this.props.location ? this.props.location.pathname : '/'

        return (
          <div className={style.warning}>
            {getExpiryWarningText(this.expiryMoment, returnRoute)}
          </div>
        )
      }} />
    ) : null

    return (
      <div>
        {this.props.children}
        {warningElement}
      </div>
    )
  }

  componentDidMount () {
    this._checkAuthentication()
  }

  componentDidUpdate () {
    this._checkAuthentication()
  }

  componentWillUnmount () {
    window.clearTimeout(this.warningTimeout)
    window.clearTimeout(this.expiryTimeout)
  }

  _checkAuthentication () {
    if (this.identity) {
      this._autoExpire()
    } else if (this.props.onExpire) {
      this.props.onExpire()
    }
  }

  _autoExpire () {
    if (this.expiryTimeout !== null) {
      return
    }

    const expiryDuration = moment.duration(this.expiryMoment.diff(moment()))

    const expiryDurationMilliseconds = expiryDuration.asMilliseconds()

    this.expiryTimeout = window.setTimeout(
      this.props.onExpire,
      Math.max(expiryDurationMilliseconds, 0)
    )

    if (expiryDurationMilliseconds <= 0) {
      return
    }

    const warningDuration = moment.duration(expiryDurationMilliseconds)
    warningDuration.subtract(WARNING_DURATION)

    this.warningTimeout = window.setTimeout(
      () => this.setState({ showWarning: true }),
      Math.max(warningDuration.asMilliseconds(), 0)
    )
  }
}

function isIdTokenExpired (idToken) {
  const identity = decodeJwt(idToken)
  const expiryMoment = moment.unix(identity.exp).subtract(EXPIRY_PREEMPTION_DURATION)
  return expiryMoment.isBefore(moment())
}

function getExpiryWarningText (expiryMoment, returnRoute) {
  const expiryDuration = moment.duration(expiryMoment.diff(moment()))

  const minutes = Math.floor(expiryDuration.asMinutes())
  let expiryDurationText = minutes >= 1
    ? `${minutes} minute${minutes !== 1 ? 's' : ''}`
    : `${Math.floor(expiryDuration.asSeconds())} seconds`

  const signInRoute = {
    pathname: '/signin',
    query: { returnRoute }
  }
  const signInLink = <Link to={signInRoute}>sign in</Link>

  return (
    <span>
      You will be automatically signed out in {expiryDurationText}.
      To continue using Cloudreach Conductor, please {signInLink} again.
    </span>
  )
}

Authentication.propTypes = {
  idToken: PropTypes.string,
  location: PropTypes.object,
  onExpire: PropTypes.func
}

function mapStateToProps (state) {
  return {
    idToken: state.auth && state.auth.idToken
  }
}

function mapDispatchToProps (dispatch) {
  return {
    onExpire: () => {
      dispatch(signOut())
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Authentication)
