import { Map, List } from 'immutable'
import { createSelector } from 'reselect'

const getPossibleRestrictedCredentials = createSelector(
  state => state.policyTypes,
  state => state.restrictedCredentials,
  state => state.resourceTypes,
  state => state.resourceProviders,
  (policyTypes, credentials, resourceTypes, resourceProviders) => {
    return credentials.map((creds, providerId) => {
      const resourceProvider = resourceProviders.get(providerId, Map())

      const providerTypeName = resourceProvider.get('type')
      if (!providerTypeName) {
        return List()
      }

      const accessType = resourceTypes.get(providerTypeName, Map()).get('provider_access_type')
      if (!accessType) {
        return List()
      }

      // Gather the full set of credentials we will want
      const keyedCredentials = policyTypes.reduce((acc, policyType) => {
        if (!policyType.get('provider_access').find((access) => access.get('type') === accessType)) {
          return acc
        }

        return acc.set(policyType.get('id'), Map({
          policy_type_name: policyType.get('name'),
          policy_type_label: policyType.get('label'),
          policy_type_id: policyType.get('id'),
          access_type: accessType,
          is_managed: resourceProvider.get('has_management_credentials'),
          present: false
        }))
      }, Map())

      // Add info for the credentials we have been supplied
      const allCredentials = creds.reduce((acc, cred) => {
        return acc.update(cred.get('requester_id'), null, kc => {
          if (!kc) {
            return kc
          }
          return kc.set('id', cred.get('id')).set('present', true).set('is_managed', cred.get('is_managed'))
        })
      }, keyedCredentials)

      return allCredentials.toList()
    })
  }
)

export {
  getPossibleRestrictedCredentials
}
