import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'

import PropTypes from 'prop-types'

import Button from 'components/Button'
import Hint from 'components/Hint'

import style from './NonInteractiveClientCreator.styl'

export class NonInteractiveClientCreator extends React.Component {
  constructor () {
    super()

    this._onSubmit = this._onSubmit.bind(this)
    this._onChange = this._onChange.bind(this)

    this.state = {
      name: '',
      role: '',
      submitted: false
    }
  }

  _onChange ({ target }) {
    this.setState({[target.name]: target.value})
    this.setState({submitted: false})
  }

  _onSubmit (e) {
    e.preventDefault()

    const fields = {
      name: this.state.name,
      role: this.state.role
    }

    this.setState({submitted: true})
    this.props.onCreate(fields)
  }

  render () {
    return (
      <div className={style.contents}>
        <form onSubmit={this._onSubmit}>
          <div className={style.formField}>
            <label>
              Non-interactive client name
              <Hint className={style.hint}>
                Provide a unique name for the client.
              </Hint>
            </label>
            <input
              required
              type='text'
              name='name'
              value={this.state.name}
              onChange={this._onChange} />
          </div>
          <label>
            Role
            <Hint className={style.hint}>
              The role which the client will use to access Conductor.
            </Hint>
          </label>
          <select
            className={style.formField}
            onChange={this._onChange}
            name='role'>
            <option key='' value=''>-</option>
            {this.props.roles.valueSeq().map((role) => {
              return <option key={role.get('id')} value={role.get('id')}>{role.get('name')}</option>
            })}
          </select>
          <div className={style.controls}>
            <Button onClick={() => this.props.onClose()} flat>Cancel</Button>
            <Button type={Button.PRIMARY} submit disabled={this.state.submitted}>Save</Button>
          </div>
        </form>
      </div>
    )
  }
}

NonInteractiveClientCreator.propTypes = {
  onCreate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  roles: ImmutablePropTypes.map.isRequired
}

export default NonInteractiveClientCreator
