export default [
  '#3d4242',
  '#494f50',
  '#53595a',
  '#62696a',
  '#6e7677',
  '#7a8385',
  '#888f91',
  '#959c9d',
  '#a2a8a9',
  '#afb5b6'
]
