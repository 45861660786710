import PropTypes from 'prop-types'
import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'

import Button from 'components/Button'
import ErrorPage from 'components/ErrorPage'
import { updateLocation } from 'state/actions'
import { signOut } from 'state/session/actions'

export function Unauthorized ({ organizations, signOut, onAuthorized }) {
  if (organizations.size !== 0) {
    onAuthorized()
    return null
  }

  return (
    <ErrorPage>
      <p>You aren't authorised to view any organizations</p>
      <Button onClick={() => signOut()} type={Button.WARN}>Sign Out</Button>
    </ErrorPage>
  )
}

Unauthorized.propTypes = {
  organizations: ImmutablePropTypes.list.isRequired,
  signOut: PropTypes.func.isRequired
}

function mapStateToProps (state) {
  return {
    organizations: state.availableOrganizations
  }
}

function mapDispatchToProps (dispatch, ownProps) {
  return {
    signOut: () => { dispatch(signOut()) },
    onAuthorized: () => { dispatch(updateLocation({ pathname: '/resource_groups' })) }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Unauthorized)
