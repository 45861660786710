import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import style from './Card.styl'

export const Card = ({ children, className }) => (
  <div className={classNames(style.Card, className)}>
    <div className={style.card}>
      {children}
    </div>
  </div>
)

Card.propTypes = {
  className: PropTypes.string
}

export default Card
