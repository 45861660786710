import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import FontAwesome from 'react-fontawesome'
import moment from 'moment'
import { connect } from 'react-redux'

import { ADMIN_POLICY, SOLUTIONINVOKE_POLICY, notifyUser } from 'state/actions'
import Button from 'components/Button'
import ClickableList from 'components/ClickableList'
import JsonDisplay from 'components/JsonDisplay'
import LoadingIndicator from 'components/LoadingIndicator'

import style from './ApplicablePolicyDetails.styl'

export class ApplicablePolicyDetails extends React.Component {
  componentWillMount () {
    this.props.onPolicyTypeChanged(this.props.policyTypes, this.props.currentPolicyTypeId)
  }

  componentWillReceiveProps (nextProps) {
    const newPolicyType = nextProps.currentPolicyTypeId !== this.props.currentPolicyTypeId
    const newPolicyTypes = !nextProps.policyTypes.equals(this.props.policyTypes)
    if (newPolicyType || newPolicyTypes) {
      this.props.onPolicyTypeChanged(nextProps.policyTypes, nextProps.currentPolicyTypeId)
    }
  }

  componentDidMount () {
    this._checkLatestInvocationState()
  }

  componentDidUpdate (prevProps, prevState) {
    this._checkLatestInvocationState()
  }

  _checkLatestInvocationState () {
    const policy = this._getActivePolicy()
    const policyId = policy.get('id')

    const latestInvocation = policyId && this.props.latestInvocations ? (
      this.props.latestInvocations.find((invocation) => invocation.get('applicable_policy') === policyId)
    ) : null

    if (latestInvocation && latestInvocation.get('state') === 'DISCARDED') {
      this.props.onError('Something went wrong', 'error')
    }
  }

  _getActivePolicy () {
    return this.props.applicablePolicies.find(p => p.get('policy_type') === this.props.currentPolicyTypeId, undefined, Map())
  }

  _getPolicyType (applicablePolicy) {
    return this.props.policyTypes.find(pt => pt.get('id') === applicablePolicy.get('policy_type'))
  }

  _generateWarning (text, level = 'error') {
    const className = level === 'error' ? style.error : style.warning
    return (
      <p className={className}>
        <FontAwesome name='warning' />
        {' ' + text}
      </p>
    )
  }

  _latestInvocationSummary (latestInvocation) {
    if (!latestInvocation) {
      return (
        <div className={style.invocationStatus}>
          <div className={style.invocationLogo}>
            <FontAwesome name='check' className={style.success} />
          </div>
          <div className={style.invocationTime}>
            No Invocations
          </div>
        </div>
      )
    }

    let iconName = 'check'
    let iconClass = style.success
    let message = ''

    switch (latestInvocation.get('state')) {
      case 'FAILED':
        iconName = 'warning'
        iconClass = style.error
        message = latestInvocation.get('error')
        break

      case 'SENT':
        iconName = 'spinner'
        iconClass = style.spinner
        message = 'Invocation is sent to solution'
        break

      case 'PENDING':
        iconName = 'spinner'
        iconClass = style.spinner
        message = 'Invocation is waiting to be sent to solution'
        break

      case 'RECEIVED':
        iconName = 'spinner'
        iconClass = style.spinner
        message = 'Invocation response has been received'
        break

      case 'PROCESSING':
        iconName = 'spinner'
        iconClass = style.spinner
        message = 'Invocation response is being processed'
        break

      case 'DISCARDED':
        console.error('Latest invocation must not be in DISCARDED state.')
        return
    }

    return (
      <div className={style.invocationStatus}>
        <div className={style.invocationLogo}>
          <FontAwesome name={iconName} className={iconClass} />
        </div>
        <div className={style.invocationTime}>
          {moment.utc(latestInvocation.get('created_at')).fromNow()}
        </div>
        <div className={style.invocationError}>
          {message}
        </div>
      </div>
    )
  }

  _getPolicyDisplay (policyState, policyObject, policyType) {
    // Case: An applicable policy hasn't been created yet
    if (!policyObject && !policyState) {
      return 'No Applicable Policy'
    }

    switch (policyState) {
      case 'RESOLVED':
        const policyDisplayInfo = policyType && policyType.getIn(['suspension', 'enabled'])
          ? <div className={style.policyDisplayInfo}>
            <FontAwesome name='info-circle' className={style.info} />
            Policy type suspended because: {policyType.getIn(['suspension', 'reason'])}
          </div>
          : null

        return policyObject ? (
          <div>
            {policyDisplayInfo}
            <JsonDisplay
              className={style.jsonDisplay}
              object={policyObject}
              clickAction={() => this.props.onShowApplicablePolicyModal(policyObject)} />
          </div>
        ) : 'No Applicable Policy'
      case 'UNRESOLVED':
        return (
          <div>
            <FontAwesome name='spinner' className={style.spinner} />
            <span className={style.applicablePolicyStatus}>Resolving Applicable Policy...</span>
          </div>
        )
      case 'UNRESOLVABLE':
        return 'Applicable Policy cannot be resolved'
      default:
        return `Applicable Policy is ${policyState}`
    }
  }

  render () {
    if (!this.props.resource.size) return null
    if (!this.props.dataFetched) {
      return <LoadingIndicator />
    }

    const policy = this._getActivePolicy()
    const policyType = this._getPolicyType(policy)
    const resolvedPolicy = policy.get('resolved_policy')
    const policyState = policy.get('state')
    const policyObject = resolvedPolicy ? resolvedPolicy.toJS() : null
    const policyId = policy.get('id')

    const latestInvocation = policy && this.props.latestInvocations ? (
      this.props.latestInvocations.find((invocation) => invocation.get('applicable_policy') === policyId)
    ) : null

    const policyDisplay = this._getPolicyDisplay(policyState, policyObject, policyType)

    let invokeAction

    if (policyObject && [SOLUTIONINVOKE_POLICY, ADMIN_POLICY].includes(this.props.organizationRole)) {
      const suspended = policyType.getIn(['suspension', 'enabled'])
      invokeAction = <Button
        disabled={suspended}
        className={style.invokeButton}
        type={Button.PRIMARY}
        onClick={() => this.props.invokeApplicablePolicy(policy)} >
        Invoke
      </Button>
      if (suspended) {
        invokeAction = <div title='Policy suspended'>
          {invokeAction}
        </div>
      }
    }

    const invocationList = policy ? (
      <Button
        className={style.invokeButton}
        type={Button.PRIMARY}
        flat
        onClick={() => this.props.onShowInvocationsListModal(policyId)} >
        Show Invocations
      </Button>
    ) : null

    return (
      <div className={style.applicablePolicies}>
        <div className={style.policyList}>
          <ClickableList
            items={this.props.policyTypes.sortBy(type => type.get('label'))}
            selected={this.props.currentPolicyTypeId}
            clickAction={this.props.onPolicyTypeChanged.bind(null, this.props.policyTypes)}
            displayAttribute='label'
            selectAttribute='id' />
        </div>
        <div className={style.policyTitle}>Applicable Policy</div>
        <div className={style.policyDisplay}>
          {policyDisplay}
        </div>
        <div className={style.invocationTitle}>Last Invocation</div>
        {this._latestInvocationSummary(latestInvocation)}
        <div className={style.invoke}>
          {invokeAction}
          {invocationList}
        </div>
      </div>
    )
  }
}

ApplicablePolicyDetails.propTypes = {
  policyTypes: ImmutablePropTypes.list.isRequired,
  resource: ImmutablePropTypes.map.isRequired,
  latestInvocations: ImmutablePropTypes.list,
  applicablePolicies: ImmutablePropTypes.list.isRequired,
  invokeApplicablePolicy: PropTypes.func.isRequired,
  onShowApplicablePolicyModal: PropTypes.func.isRequired,
  onShowInvocationsListModal: PropTypes.func.isRequired,
  organizationRole: PropTypes.number.isRequired,
  onPolicyTypeChanged: PropTypes.func.isRequired,
  currentPolicyTypeId: PropTypes.string.isRequired,
  dataFetched: PropTypes.bool.isRequired
}

function mapDispatchToProps (dispatch) {
  return {
    onError: (error) => dispatch(notifyUser(error, 'error'))
  }
}

export default connect(null, mapDispatchToProps)(ApplicablePolicyDetails)
