import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'
import ImmutablePropTypes from 'react-immutable-proptypes'

import style from './ResourceGroupList.styl'

export function ResourceGroupList ({ resourceGroups, className, highlightedResourceGroupId = null, onClick = null, title = '' }) {
  const itemStyle = group => {
    return (group.get('id') === highlightedResourceGroupId) ? style.highlightedItem : style.item
  }

  const generateItem = group => {
    return group.get('name')
  }

  let clickableStyle = null
  let onClickMaker = (group) => () => {}

  if (onClick) {
    clickableStyle = style.clickable
    onClickMaker = (group) => () => { onClick(group.get('id')) }
  }

  const generateList = groups => groups.map((group, index) =>
    <li className={classNames(itemStyle(group), clickableStyle, className)} key={index} onClick={onClickMaker(group)}>
      {generateItem(group)}
    </li>
  )

  return (
    <div className={classNames(style.list, className)}>
      <ul>
        <li className={classNames(style.title, className)} key={title}>{title}</li>
        {generateList(resourceGroups)}
      </ul>
    </div>
  )
}

ResourceGroupList.propTypes = {
  className: PropTypes.string,
  resourceGroups: ImmutablePropTypes.list.isRequired,
  highlightedResourceGroupId: PropTypes.string,
  onClick: PropTypes.func,
  title: PropTypes.string
}

export default ResourceGroupList
