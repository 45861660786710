import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import style from './LoadingIndicator.styl'

export default function LoadingIndicator ({ className }) {
  return <span className={classNames(style.LoadingIndicator, className)}>&nbsp;</span>
}

LoadingIndicator.propTypes = {
  className: PropTypes.string
}
