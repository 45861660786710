import PropTypes from 'prop-types'
import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import classNames from 'classnames'

import Filter from 'components/Filter'
import style from './SolutionContextsList.styl'
import { DEFAULT_SOLUTION_CONTEXT_ID, NO_SOLUTION_CONTEXT_ID } from 'state/constants'

export class SolutionContextsList extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      filteredSolutionContexts: props.solutionContexts.delete(DEFAULT_SOLUTION_CONTEXT_ID).delete(NO_SOLUTION_CONTEXT_ID)
    }
  }

  componentDidMount () {
    this.updateSolutionContexts(this.props.solutionContexts.delete(DEFAULT_SOLUTION_CONTEXT_ID).delete(NO_SOLUTION_CONTEXT_ID))
  }

  componentWillReceiveProps (nextProps) {
    if (this.props.solutionContexts !== nextProps.solutionContexts ||
        this.props.currentSolutionContext !== nextProps.nextProps) {
      this.updateSolutionContexts(nextProps.solutionContexts.delete(DEFAULT_SOLUTION_CONTEXT_ID).delete(NO_SOLUTION_CONTEXT_ID), nextProps.currentSolutionContext)
    }
  }

  _createSolutionContextLink = (solutionContext, currentSolutionContext) => {
    const currentSolutionContextId = currentSolutionContext || this.props.currentSolutionContext

    const activeSolutionContextStyle = classNames(
      { [style.activeSolutionContext]: currentSolutionContextId === solutionContext.get('id') }
    )

    return (
      <a className={activeSolutionContextStyle} key={solutionContext.get('id')} onClick={() => this._setSolutionContext(solutionContext.get('id'))}>
        {solutionContext.get('label')}
      </a>
    )
  }

  _sortSolutionContexts = (solutionContexts) => {
    return solutionContexts.valueSeq().sortBy(solutionContext => solutionContext.get('label').toUpperCase())
  }

  _createSolutionContextsList = (solutionContexts, currentSolutionContext) => {
    return this._sortSolutionContexts(solutionContexts).reduce((solutionContextElements, solutionContext, solutionContextId) => {
      solutionContextElements.push(this._createSolutionContextLink(solutionContext, currentSolutionContext))

      return solutionContextElements
    }, [])
  }

  updateSolutionContexts = (solutionContexts, currentSolutionContext = this.props.currentSolutionContext) => {
    const filteredSolutionContexts = this._createSolutionContextsList(solutionContexts, currentSolutionContext)
    this.setState({ filteredSolutionContexts })
  }

  handleFilterFocus = () => {
    if (this.filterRef) {
      this.filterRef.focus()
    }
  }

  render () {
    const allSolutionContext = this.props.solutionContexts.get(DEFAULT_SOLUTION_CONTEXT_ID)
    const noneSolutionContext = this.props.solutionContexts.get(NO_SOLUTION_CONTEXT_ID)

    const solutionContexts = this.props.solutionContexts
      .delete(DEFAULT_SOLUTION_CONTEXT_ID)
      .delete(NO_SOLUTION_CONTEXT_ID)
      .reduce((solutionContextElements, solutionContext, solutionContextId) => {
        return solutionContextElements.set(solutionContext.get('label'), solutionContext)
      }, Map())

    const solutionContextsList = this.state.filteredSolutionContexts

    return (
      <div className={classNames(style.SolutionContextsList, this.props.className)}>
        <div className={style.solutionContextHeader}>Solution Contexts</div>
        <Filter
          className={style.filter}
          data={solutionContexts}
          onFilter={this.updateSolutionContexts}
          onRef={ref => { this.filterRef = ref }}
          close={this.state.forceClose}
        />
        <div className={style.solutionContextsList}>
          {this._createSolutionContextLink(allSolutionContext, this.props.currentSolutionContext)}
          {this._createSolutionContextLink(noneSolutionContext, this.props.currentSolutionContext)}
          <div className={style.divider} />
          {solutionContextsList.length > 0 ? solutionContextsList : <i>No matching solutionContexts</i>}
        </div>
      </div>
    )
  }

  _setSolutionContext (solutionContextId) {
    this.props.onSelectSolutionContext(solutionContextId)
  }
}

SolutionContextsList.propTypes = {
  className: PropTypes.string,
  solutionContextButton: PropTypes.element,
  solutionContexts: ImmutablePropTypes.map,
  currentSolutionContext: PropTypes.string,
  onSelectSolutionContext: PropTypes.func
}

export default SolutionContextsList
