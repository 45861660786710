import PropTypes from 'prop-types'
import React from 'react'

export const Tab = ({ children, className }) => (
  <div className={className}>
    {children}
  </div>
)

Tab.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  tabBarExtra: PropTypes.node
}
