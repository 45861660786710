import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import FontAwesome from 'react-fontawesome'

import { ADMIN_POLICY } from 'state/actions'
import Button from 'components/Button'

import style from './SolutionRuntimesList.styl'

export class SolutionRuntimesList extends React.Component {
  deleteEnvironment (environment) {
    const environmentName = environment.get('name')

    const confirmation = <span>
      <p>This will permanently delete <b>{environmentName}</b>.</p>
      <br />
      <p>Please confirm the name of the environment below to continue.</p>
    </span>

    this.props.onConfirmation(
      'Delete Solution Runtime',
      confirmation,
      () => this.props.onDelete(environment.get('id')),
      input => input !== environmentName
    )
  }

  listSolutionRuntimes (environments) {
    if (!environments || !environments.size) {
      return (<tr><td>No Solution Runtimes</td></tr>)
    }

    return environments.map(environment => {
      return (
        <tr key={environment.get('index')}>
          <td>{environment.get('name')}</td>
          <td>{environment.get('type')}</td>
          <td>{environment.get('workers')}</td>
          {this.props.organizationRole === ADMIN_POLICY ? (
            <td>
              <Button
                onClick={() => this.props.onShowEditModal(environment)}
                className={style.edit}
                flat>
                <FontAwesome
                  name='pencil'
                  className='fa-align-right' />
              </Button>
            </td>
          ) : null}
          {this.props.organizationRole === ADMIN_POLICY ? (
            <td>
              <Button
                onClick={() => this.deleteEnvironment(environment)}
                type={Button.WARN}
                className={style.delete}
                flat>
                <FontAwesome
                  name='trash'
                  className='fa-align-right' />
              </Button>
            </td>
          ) : null}
        </tr>
      )
    })
  }

  render () {
    return (
      <div>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Type</th>
              <th>Active Solutions</th>
              {this.props.organizationRole === ADMIN_POLICY ? <th /> : null}
              {this.props.organizationRole === ADMIN_POLICY ? <th /> : null}
            </tr>
          </thead>
          <tbody>
            {this.listSolutionRuntimes(this.props.solutionRuntimes.valueSeq().sortBy(v => v.index))}
          </tbody>
        </table>
      </div>
    )
  }
}

SolutionRuntimesList.propTypes = {
  solutionRuntimes: ImmutablePropTypes.map.isRequired,
  onDelete: PropTypes.func.isRequired,
  onConfirmation: PropTypes.func.isRequired,
  onShowEditModal: PropTypes.func.isRequired,
  organizationRole: PropTypes.number.isRequired
}

export default SolutionRuntimesList
