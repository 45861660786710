import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Table } from 'components/Attribute'
import { Map } from 'immutable'

export const TableMetric = ({ value }) => {
  const columns = value.get('columns').map(column => {
    return Map.isMap(column) ? column.set('path', [column.get('name')]) : column
  })
  const context = value.get('rows').map(row => {
    return row.reduce((entry, item, index) => {
      const column = value.get('columns').get(index)
      const name = Map.isMap(column) ? column.get('name') : column
      return entry.set(name, item)
    }, Map())
  })
  const format = Map({ type: 'table', columns })
  return <Table context={context} format={format} />
}

TableMetric.propTypes = {
  value: ImmutablePropTypes.mapContains({
    columns: ImmutablePropTypes.list.isRequired,
    rows: ImmutablePropTypes.list.isRequired
  }).isRequired
}

export default TableMetric
