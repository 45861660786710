import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { Children } from 'react'

import style from './RadioButton.styl'

export function RadioButton ({ children, className, value, onChange, filled = true }) {
  const transformedChildren = Children.map(children, child => {
    const transformedProps = {}

    if (onChange) {
      transformedProps.onSelect = onChange
    }

    if (child.props.value === value) {
      transformedProps.className = classNames(child.props.className, style.active,
                                              filled ? style.filled : style.empty)
    }

    transformedProps.filled = filled

    return React.cloneElement(child, transformedProps)
  })

  return (
    <div className={classNames(style.RadioButton, className)}>
      {transformedChildren}
    </div>
  )
}

RadioButton.propTypes = {
  children: PropTypes.arrayOf(component(Item)),
  className: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  filled: PropTypes.bool
}

export function Item ({ children, className, value, onSelect, disabled = false, filled }) {
  return (
    <div
      className={classNames(style.Item, className, {[style.disabled]: disabled}, filled ? style.filled : style.empty)}
      onClick={disabled ? () => {} : () => onSelect(value)}>
      {children}
    </div>
  )
}

Item.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  value: PropTypes.any,
  onSelect: PropTypes.func,
  disabled: PropTypes.bool
}

function component (type) {
  return (props, propName, descriptiveName, locationName) => {
    if (props[propName].type && props[propName].type === type) {
      return null
    }

    return new Error(
      `Invalid ${locationName} \`${propName}\` of type \`${typeof props[propName]}\` ` +
      `supplied to \`${descriptiveName}\`, expected \`${type.name}\`.`
    )
  }
}
