import PropTypes from 'prop-types'
import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'

import Button from 'components/Button'
import { convertUTCTimeToLocal } from 'services/time'

import style from './NotificationsList.styl'

export class NotificationsList extends React.Component {
  listNotifications (notifications) {
    if (!notifications.size) {
      return (<tr><td>No Notifications</td></tr>)
    }

    return notifications.map(notification => {
      const rowStyle = notification.get('read') ? style.read : style.unread
      return (
        <tr className={rowStyle} key={notification.get('id')}>
          <td className={rowStyle} onClick={() => this.props.viewNotification(notification.get('id'))}>{notification.get('notification_type')}</td>
          <td className={rowStyle} onClick={() => this.props.viewNotification(notification.get('id'))}>{notification.get('subject')}</td>
          <td className={rowStyle} onClick={() => this.props.viewNotification(notification.get('id'))}>{notification.get('count')}</td>
          <td className={rowStyle} onClick={() => this.props.viewNotification(notification.get('id'))}>{convertUTCTimeToLocal(notification.get('updated_at'))}</td>
          <td>
            <Button onClick={() => this.props.toggleNotificationRead(notification)} type={Button.PRIMARY} flat>
              {notification.get('read') ? 'MARK AS UNREAD' : 'MARK AS READ'}
            </Button>
          </td>
        </tr>
      )
    })
  }

  render () {
    const notificationElements = this.props.notifications
      ? this.listNotifications(this.props.notifications.valueSeq())
      : <tr><td>Loading...</td></tr>

    return (
      <div className={style.table}>
        <table>
          <thead>
            <tr>
              <th>Type</th>
              <th>Subject</th>
              <th>Count</th>
              <th>Last seen</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {notificationElements}
          </tbody>
        </table>
      </div>
    )
  }
}

NotificationsList.propTypes = {
  fetchNotifications: PropTypes.func.isRequired,
  notifications: ImmutablePropTypes.orderedMap.isRequired,
  notificationCount: PropTypes.number.isRequired,
  viewNotification: PropTypes.func.isRequired,
  toggleNotificationRead: PropTypes.func.isRequired
}

export default NotificationsList
