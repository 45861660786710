import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import style from './HelpDropdown.styl'

export class HelpDropdown extends React.Component {
  _startFeatureTour = name => {
    this.props.onStartFeatureTour(name)
  }

  _openDocumentation = () => {
    this.props.onOpenDocumentation()
  }

  _openAPIDocumentation = () => {
    this.props.onAPIDocumentation()
  }

  _generateTours = tours => (
    tours.map((name, index) =>
      <li
        key={index}
        onClick={() => this._startFeatureTour(name)}>
        {name}
      </li>
    )
  )

  render () {
    return (
      <div>
        <div
          className={style.documentation}
          onClick={this._openDocumentation}>
          Documentation
        </div>
        <div
          className={style.documentation}
          onClick={this._openAPIDocumentation}>
          API Documentation
        </div>
        <div className={style.divider} />
        <div className={style.featureTours}>
          Feature Tours
        </div>
        <ul className={classNames(style.tours, this.props.className)}>
          <li onClick={() => this._startFeatureTour('overview')}>Overview</li>
          <li onClick={() => this._startFeatureTour('resource-groups')}>Resource Groups</li>
          <li onClick={() => this._startFeatureTour('querying')}>Querying</li>
          <li onClick={() => this._startFeatureTour('solution-contexts')}>Solution Contexts</li>
        </ul>
      </div>
    )
  }
}

HelpDropdown.propTypes = {
  className: PropTypes.string,
  onOpenDocumentation: PropTypes.func.isRequired,
  onStartFeatureTour: PropTypes.func.isRequired,
  tours: PropTypes.array.isRequired,
  onAPIDocumentation: PropTypes.func.isRequired
}

export default HelpDropdown
