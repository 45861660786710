import React from 'react'
import { Map } from 'immutable'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'

import { returnFiltered } from 'scenes/Manage'
import Filter from 'components/Filter'
import Card, { CardBody, CardHeader } from 'components/Card'
import SolutionGallery from './components/SolutionGallery'

import style from 'scenes/Manage/Manage.styl'

export class Solutions extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      filteredSolutions: this.props.solutions
    }
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.solutions !== this.props.solutions) {
      this.setState({
        filteredSolutions: nextProps.solutions
      })
    }
  }

  _onUpdateSolutionGallery = filtered => {
    this.setState({filteredSolutions: returnFiltered(filtered)})
  }

  _processManagedWorkers (managedWorkers) {
    return managedWorkers.reduce((workers, worker) => {
      const work = worker.set('solution_runtime', this.props.solutionRuntimes.get((worker.get('solution_runtime'))))
      return workers.set(worker.get('id'), work)
    }, Map())
  }

  render () {
    const solutions = this.props.solutions.reduce((solutionItems, solution) => {
      return solutionItems.set(solution.get('label'), solution)
    }, Map())

    return (
      <Card>
        <CardHeader title='Solutions' />
        <div className='normal'>
          <Filter
            className={style.filter}
            data={solutions}
            onFilter={this._onUpdateSolutionGallery} />
        </div>
        <CardBody>
          <SolutionGallery
            solutions={this.state.filteredSolutions}
            managedWorkers={this._processManagedWorkers(this.props.managedWorkers)}
            onShowWorkersModal={this.props.onShowWorkersModal}
            onShowSolutionDetails={this.props.onShowSolutionDetails} />
        </CardBody>
      </Card>
    )
  }
}

Solutions.propTypes = {
  solutions: ImmutablePropTypes.map.isRequired,
  managedWorkers: ImmutablePropTypes.map.isRequired,
  solutionRuntimes: ImmutablePropTypes.map.isRequired,
  onShowWorkersModal: PropTypes.func.isRequired,
  onShowSolutionDetails: PropTypes.func.isRequired
}

export default Solutions
