export const AVAILABLE_RESOURCES = 'available-resources'
export const EDIT_POLICY_SCHEMA = 'edit-policy-schema'
export const HELP_BUTTON = 'help-button'
export const MANAGE_BUTTON = 'manage-button'
export const NOTIFICATION_BUTTON = 'notification-button'
export const ORGANIZATIONS_BUTTON = 'organizations-button'
export const POLICY_TYPE_SELECTOR = 'policy-type-selector'
export const QUERY_BUILDER = 'query-builder'
export const QUERY_BUILDER_RESOURCE_GROUP = 'query-builder-resource-group'
export const RESOURCE_GRAPH = 'resource-graph'
export const RESOURCE_GROUP = 'resource-group'
export const RESOURCE_INFORMATION = 'resource-information'
export const RESOURCE_DETAILS = 'resource-details'
export const SAVE_QUERY_BUTTON = 'save-query-button'
export const SHOW_SCHEMA_BUTTON = 'show-schema-button'
export const TOGGLE_RELATED_BUTTON = 'toggle-related-button'
export const TOGGLE_RESOURCE_SOLUTION_CONTEXT_BUTTON = 'toggle-resource-view-button'
export const VALIDATE_SAVE_POLICY_BUTTON = 'view-policy-schema'
export const SOLUTION_CONTEXT_SELECTOR = 'view-selector'
