import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import getNavigationItems from './services/navigationItems'
import { Toolbar } from 'components/Toolbar'
import { find, traverse } from './services/tree'

import style from './ResourcesLayout.styl'

export default class ResourcesLayout extends React.Component {
  constructor (props) {
    super(props)

    this.itemElements = {}

    this.state = this._initializeState(this.props, false)
  }

  componentWillReceiveProps (nextProps) {
    // When the user triggers an up or down navigation and the onNavigate callback is fired,
    // the user of this component is expected to provide a new initialNode, determining the
    // new, transitioned state of the layout.
    if (this.props.initialNode !== nextProps.initialNode) {
      this.setState(this._initializeState(nextProps, true))
    } else if (this.props.navigation !== nextProps.initialNode) {
      this._augmentNavigationTree(nextProps.navigation)
      const nextActiveNode = find(nextProps.navigation, (node) => node.key === nextProps.initialNode)
      const currentActiveNode = find(this.props.navigation, (node) => node.key === nextProps.initialNode)
      if (nextActiveNode !== currentActiveNode) {
        this.setState({ activeNode: nextActiveNode })
      }
    }
  }

  _initializeState (props, disableTransitions) {
    this._augmentNavigationTree(props.navigation)

    const activeNode = find(props.navigation, (node) => node.key === props.initialNode)

    const state = {
      activeNode,
      disableTransitions,
      transition: null,
      transitioningChild: 0,
      initializing: true
    }

    setTimeout(() => {
      this.setState({
        initializing: false,
        disableTransitions: false
      })
    })

    return state
  }

  render () {
    const resourcesLayoutClasses = classNames(
      style.ResourcesLayout,
      {
        [style.transitioning]: !!this.state.transition,
        [style.initializing]: this.state.initializing
      }
    )

    const navigationItems = getNavigationItems(
      () => { this._onUp() },
      (index) => { this._onDown(index) },
      (event) => { this._onTransitionEnd(event) }
    )

    const elements = Object.keys(navigationItems).reduce((elements, name) => {
      elements[name] = this._generateElement(name, navigationItems[name])
      return elements
    }, {})

    return (
      <div className={resourcesLayoutClasses}>
        <div className={style.main}>
          <div className={style.navigationContainer}>
            <div className={style.navigation}>
              {elements.upPrevious}
              {elements.up}
              {elements.active}
              {elements.downExit}
              {elements.down}
              {elements.downNext}
              <Toolbar className={style.toolbar}>
                {this._renderToolbarContent()}
              </Toolbar>
              <div className={style.separator} />
            </div>
          </div>
        </div>
      </div>
    )
  }

  _augmentNavigationTree (navigation) {
    traverse(navigation, (node, parent, index) => {
      node.key = this.props.nodeKey(node)
      node.parent = parent
      node.index = index
      if (!node.children) {
        node.children = []
      }
    })
  }

  _onUp () {
    if (this.state.transition) {
      return
    }

    this.setState({
      transition: 'up',
      transitioningChild: this.state.activeNode.index
    })
  }

  _onDown (index) {
    if (this.state.transition) {
      return
    }

    this.itemElements.active.dataset.index = index
    this.setState({
      transition: 'down',
      transitioningChild: index
    })
  }

  _onTransitionEnd (event) {
    const transition = this._getTransitionInfo()
    if (!transition.type) {
      return
    }

    if (event.propertyName === 'background-color') {
      return
    }

    this.props.onNavigate(transition.to)
  }

  _getTransitionInfo () {
    return {
      type: this.state.transition,
      from: this.state.activeNode,
      to: this._getNextActiveNode(),
      child: this.state.transitioningChild
    }
  }

  _getNextActiveNode () {
    switch (this.state.transition) {
      case 'up':
        return this.state.activeNode.parent
      case 'down':
        const children = this.state.activeNode.children
        return children[this.state.transitioningChild]
    }
  }

  _generateElement (name, navigationItem) {
    const options = {
      activeNode: this.state.activeNode,
      transition: this._getTransitionInfo(),
      style
    }

    if (typeof navigationItem.condition !== 'undefined' &&
      !navigationItem.condition(options)) {
      return
    }

    const count = typeof navigationItem.count === 'function'
      ? navigationItem.count(options)
      : navigationItem.count

    const numElements = typeof count === 'undefined' ? 1 : count

    const items = range(numElements).map((elementIndex) => {
      Object.assign(options, { elementIndex })
      const index = navigationItem.index(options)

      const ref = (element) => {
        if (count) {
          this.itemElements[name][index] = element
        } else {
          this.itemElements[name] = element
        }
      }

      Object.assign(options, { index, ref })

      options.transitionStatus = navigationItem.transitionStatus(options)

      const content = navigationItem.content(options)

      const classNames = this._generateClassNames(name, content, options)

      Object.assign(options, { classNames, content })

      return navigationItem.element(options)
    })

    if (!count) {
      return items[0]
    }

    return items
  }

  _generateClassNames (name, content, state) {
    const className = state.transitionStatus || name
    const originalClassName = `${name}Original`

    return classNames(
      style.navigationItem,
      style[originalClassName],
      style[className],
      {
        [style.reset]: this.state.disableTransitions,
        [style.hasContent]: !!content
      }
    )
  }

  _renderToolbarContent () {
    return this.state.activeNode.toolbar
      ? this.state.activeNode.toolbar()
      : null
  }
}

function range (n) {
  return [...Array(n).keys()]
}

const contentPropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.func
])

const navigationShape = {
  content: contentPropType
}

navigationShape.children = PropTypes.arrayOf(PropTypes.shape(navigationShape))

ResourcesLayout.propTypes = {
  navigation: PropTypes.shape(navigationShape).isRequired,
  nodeKey: PropTypes.func.isRequired,
  initialNode: PropTypes.any.isRequired,
  onNavigate: PropTypes.func.isRequired
}
