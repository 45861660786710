import PropTypes from 'prop-types'
import React from 'react'

import style from './Profile.styl'

export class Profile extends React.Component {
  render () {
    return (
      <div className={style.profile}>
        <a onClick={() => this.props.signOut()}>Sign Out</a>
      </div>
    )
  }

  _signOut () {
    this.props.signOut()
  }
}

Profile.propTypes = {
  signOut: PropTypes.func.isRequired
}

export default Profile
