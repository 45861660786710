import * as refs from 'refs'

export default {
  'overview': {
    steps: [
      {
        title: 'Solution Context Selector',
        text: 'Select a Solution Context to view the output from that solution on a given Resource.',
        selector: `[data-ref="${refs.SOLUTION_CONTEXT_SELECTOR}"]`,
        position: 'bottom-left',
        route: '/resource_groups'
      },
      {
        title: 'Query Builder',
        text: 'The query builder is where you can search for Resources. It has a powerful underlying query engine, giving you flexibility when you search.',
        selector: `[data-ref="${refs.QUERY_BUILDER}"]`,
        position: 'bottom'
      },
      {
        title: 'Create Resource Groups',
        text: 'With the <i class="fa fa-plus-circle"></i> icon you can easily save a query, which will appear as a Resource Group in the Resource Group Graph below.<br><br> To learn more about the search syntax <a href=https://docs.conductor.cloudreach.com/components/query-engine/introduction.html target="_blank">read our docs</a>.',
        selector: `[data-ref="${refs.SAVE_QUERY_BUTTON}"]`,
        position: 'bottom'
      },
      {
        title: 'Notifications',
        text: 'View notifications such as Solution errors and Resource Discovery information, generated by Conductor.',
        selector: `[data-ref="${refs.NOTIFICATION_BUTTON}"]`,
        position: 'bottom'
      },
      {
        title: 'Manage Area',
        text: 'The Manage Area enables you to view your Solution Gallery and modify your Solution Runtimes, Resource Providers and Managed Solutions.',
        selector: `[data-ref="${refs.MANAGE_BUTTON}"]`,
        position: 'bottom'
      },
      {
        title: 'Organization Selection',
        text: 'Quickly change the customer organization you are working in by selecting an organization from the drop-down. You can also filter organizations, if you are struggling to find the one you want. ',
        selector: `[data-ref="${refs.ORGANIZATIONS_BUTTON}"]`,
        position: 'bottom'
      },
      {
        title: 'Resource Graph',
        text: 'This is the main pane in Conductor. It displays the Resource Groups and the Resources that have been queried.',
        selector: `[data-ref="${refs.RESOURCE_GRAPH}"]`,
        position: 'right'
      },
      {
        title: 'Resource View Toggle',
        text: 'You can switch between a list view and the graph view by clicking this toggle button.',
        selector: `[data-ref="${refs.TOGGLE_RESOURCE_SOLUTION_CONTEXT_BUTTON}"]`,
        position: 'left'
      },
      {
        title: 'Resource Information',
        text: 'This section contains more information on the Resource or Resource Group that you have selected.',
        selector: `[data-ref="${refs.RESOURCE_INFORMATION}"]`,
        position: 'left'
      },
      {
        title: 'Available Resources',
        text: 'This section shows the Resources available based on the Resource Group selected.',
        selector: `[data-ref="${refs.AVAILABLE_RESOURCES}"]`,
        position: 'left'
      }
    ]
  },
  'policies': {
    steps: [
      {
        title: 'View Schema',
        text: 'View the Schema that you need to follow.',
        selector: `[data-ref="${refs.SHOW_SCHEMA_BUTTON}"]`,
        position: 'bottom'
      },
      {
        title: 'Edit Schema',
        text: 'Edit the Policy Document you want to add to the Resource Group.',
        selector: `[data-ref="${refs.EDIT_POLICY_SCHEMA}"]`,
        position: 'top'
      },
      {
        title: 'Validate and Save',
        text: 'Enter a Policy and validate by clicking “Validate”. Once validated click “Save” to apply the Policy.',
        selector: `[data-ref="${refs.VALIDATE_SAVE_POLICY_BUTTON}"]`,
        position: 'bottom'
      }
    ]
  },
  'querying': {
    steps: [
      {
        title: 'Enter a Query',
        text: 'Type a query, using correct query syntax to filter Resources. Let’s start with querying for: (resource_type: aws.ec2.instance) AND (Region: eu-west-2). For more information on how to write queries see our documentation',
        selector: `[data-ref="${refs.QUERY_BUILDER}"]`,
        position: 'bottom',
        route: '/resources'
      },
      {
        title: 'View Results',
        text: 'The Resources matching your query will appear as results on HEB graph and List view.',
        selector: `[data-ref="${refs.AVAILABLE_RESOURCES}"]`,
        position: 'right'
      }
    ]
  },
  'resource-groups': {
    steps: [
      {
        title: 'Query for Resource',
        text: 'First, let\'s get a set of Resources that we want to save as a Resource Group. We need to write a query that will return the Resources we want. Enter into the query bar: resource_type: aws.ec2.instance.',
        selector: `[data-ref="${refs.QUERY_BUILDER}"]`,
        position: 'bottom',
        route: '/resource_groups'
      },
      {
        title: 'Save the Query',
        text: 'Click the ' + ' button to save this query as a Resource Group. Enter a meaningful name for your Resource Group',
        selector: `[data-ref="${refs.SAVE_QUERY_BUTTON}"]`,
        position: 'bottom'
      },
      {
        title: 'Select Your Resource Group',
        text: 'Your Resource Group is now saved and you can select it to filter to Resources in this group.',
        selector: `[data-ref="${refs.RESOURCE_GRAPH}"]`,
        position: 'top'
      },
      {
        title: 'Continue to Query',
        text: 'With your Resource Group selected you can further query using the query bar as normal.',
        selector: `[data-ref="${refs.QUERY_BUILDER_RESOURCE_GROUP}"]`,
        position: 'bottom'
      },
      {
        title: 'Policy Type',
        text: 'Select the Policy type of the solution that you wish to add or modify.',
        selector: `[data-ref="${refs.POLICY_TYPE_SELECTOR}"]`,
        position: 'bottom'
      }
    ]
  },
  'resource-view': {
    steps: [
      {
        title: 'Overview',
        text: 'The Overview tab displays the Resource Name, Resource ID and the Type of Resource so that you can quickly identify the Resource you are working with.',
        selector: () => getSelector(getElementWithContent('li', 'Overview')),
        position: 'bottom'
      },
      {
        title: 'Provider Details',
        text: 'The Provider Details tab displays the information about the Resource that the Cloud Provider can return via it’s API. For example, an AWS EC2 instance will display information about its IP, Subnet and Virtualization Type.',
        selector: () => getSelector(getElementWithContent('li', 'Provider details')),
        position: 'bottom'
      },
      {
        title: 'Containing Groups',
        text: 'Containing Group displays the Resource Groups that the Resource is part of. Resources can be in more than one Resource Group, and all Resources are part of at least one Resource Group (the All group).',
        selector: () => getSelector(getElementWithContent('li', 'Containing Groups')),
        position: 'bottom'
      },
      {
        title: 'Tags',
        text: 'The Tags tab displays any Tags that are associated with the Resource. Such as the tags that are automatically created if using a CloudFormation template. If the Resource does not contain any Tags then the tab will not be displayed.',
        selector: () => getSelector(getElementWithContent('li', 'Tags')) || `[data-ref="${refs.RESOURCE_DETAILS}"]`,
        position: 'bottom'
      },
      {
        title: 'Relationships',
        text: 'Resources can have directional relationships with other Resources. For example, an AWS EC2 instance can be related to and EC2 Subnet. All Resources have at least one relationship to a Cloud Resource Provider. On this page, you can see the relationship the direction of the relationship and the depth or distance between the Resources and the Resource that is related. You can click on a related Resource to view it.',
        selector: () => getSelector(getElementWithContent('li', 'Relationships')),
        position: 'bottom'
      },
      {
        title: 'Raw Data',
        text: 'Raw Data displays the JSON structured response from the Conductor API for a given Resource. It can help you identify the properties you can use as part of your queries. Learn to use these properties as part of a Conductor Query here.',
        selector: () => getSelector(getElementWithContent('li', 'Raw data')),
        position: 'bottom'
      },
      {
        title: 'Policies Panel',
        text: 'In this section you can see the Policies that are applied to a Resource. If there are multiple Policies then you can select which one you want to view by using the drop-down box.',
        selector: () => getSelector(getElementWithContent('li', 'Policies')),
        position: 'top'
      },
      {
        title: 'Metrics Panel',
        text: 'The Metrics panel shows the output from a Solution. Step 1: Select a Resource that has a Policy applied. Step 2: Select the Metric View you want to see in the Metric Panel (top menu item). Step 3: View the Metric Output in the Metric Panel in the Resource View.',
        selector: () => getSelector(getElementWithContent('li', 'Metrics')),
        position: 'top'
      }
    ]
  },
  'solution-contexts': {
    steps: [
      {
        title: 'Solution Contexts',
        text: 'Solution Contexts are useful to separate the output of different solutions from eachother. To see metrics, compliance and policy information only from a specific solution, change the Solution Context and this will modify what is displayed in the UI accordingly. The output is determined by the Solution Author when designing their Solution. It is also possible to select the `All Solutions` and `No Solutions` contexts to see data from all solutions and no solutions accordingly.',
        selector: `[data-ref="${refs.SOLUTION_CONTEXT_SELECTOR}"]`,
        position: 'bottom',
        route: '/resource_groups'
      }
    ]
  }
}

function getElementWithContent (tagName, content) {
  for (const element of document.getElementsByTagName(tagName)) {
    if (element.textContent === content) {
      return element
    }
  }

  return null
}

function getSelector (element) {
  if (!element) {
    return
  }

  const names = []
  while (element.parentNode) {
    if (element.id) {
      names.unshift(`#${element.id}`)
      break
    }

    if (element === element.ownerDocument.documentElement) {
      names.unshift(element.tagName)
    } else {
      for (var c = 1, e = element; e.previousElementSibling; e = e.previousElementSibling, c++) {}
      names.unshift(`${element.tagName}:nth-child(${c})`)
    }

    element = element.parentNode
  }

  return names.join(' > ')
}
