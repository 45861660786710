import React from 'react'
import FontAwesome from 'react-fontawesome'

import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'

import { List } from 'immutable'

import Button from 'components/Button'
import Hint from 'components/Hint'
import ConfigurationValidationStatus from '../ConfigurationValidationStatus'

import style from './ExternalNotificationFilterEditor.styl'

export class ExternalNotificationFilterEditor extends React.Component {
  constructor () {
    super()

    this._onSubmit = this._onSubmit.bind(this)
    this._onChangeConfiguration = this._onChangeConfiguration.bind(this)
    this._setValue = this._setValue.bind(this)
    this._addEndpoint = this._addEndpoint.bind(this)
    this._removeEndpoint = this._removeEndpoint.bind(this)
    this._validateConfiguration = this._validateConfiguration.bind(this)

    this.state = {
      name: '',
      configuration: '',
      endpoints: {0: ''},
      num_endpoints: 0,
      configurationValidated: false,
      showValidation: false,
      schemaValidation: 'pending'
    }
  }

  componentWillMount () {
    this.setState({
      name: this.props.filter.get('name', ''),
      configuration: this.props.filter.get('filter_query', '')
    })

    this.props.filter.get('endpoints', List()).forEach((endpoint, index) => {
      const target = {name: index, value: endpoint}
      this._onChangeEndpoint({target})
      this.setState({num_endpoints: index + 1})
    })
  }

  componentWillReceiveProps (nextProps) {
    const validationErrors = nextProps.configurationValidation.get('errors')
    this.setState({
      configurationValidated: !validationErrors,
      schemaValidation: validationErrors || 'successful'
    })
  }

  _validateConfiguration () {
    this.props.validateConfiguration(this.state.configuration)
    this.setState({showValidation: true})
  }

  _setValue (key, value) {
    let state = {}
    state[key] = value.target.value
    this.setState(state)
  }

  _addEndpoint () {
    const key = this.state.num_endpoints + 1
    this.setState({num_endpoints: key})

    const endpoints = this.state.endpoints
    endpoints[key] = ''
    this.setState({endpoints: endpoints})
  }

  _removeEndpoint (key) {
    let endpoints = this.state.endpoints
    delete endpoints[key]
    this.setState({endpoints: endpoints})
  }

  _onChangeEndpoint ({ target }) {
    const endpoints = this.state.endpoints
    endpoints[target.name] = target.value
    this.setState({endpoints: endpoints})
  }

  _onChangeConfiguration ({ target }) {
    const configuration = target.value
    this.setState({
      configuration: configuration,
      showValidation: false,
      configurationValidated: false
    })
  }

  _onSubmit (e) {
    e.preventDefault()

    const fields = {
      name: this.state.name,
      filter_query: this.state.configuration || '',
      endpoints: Object.values(this.state.endpoints)
    }

    this.props.onUpdate(this.props.filter.get('id'), fields)
      .then(() => this.props.onClose())
  }

  _renderEndpoints () {
    return Object.keys(this.state.endpoints).map((key) => {
      return (
        <div key={key} className={style.endpointField}>
          <select
            className={style.endpointSelect}
            value={this.state.endpoints[key]}
            onChange={this._onChangeEndpoint.bind(this)}
            name={key}>
            <option key='' value=''>-</option>
            {this.props.endpoints.valueSeq().filter((endpoint) => {
              return true
            }).map((endpoint) => {
              return <option key={endpoint.get('id')} value={endpoint.get('name')}>{endpoint.get('name')}</option>
            })}
          </select>
          {
            Object.keys(this.state.endpoints).length > 1 &&
            <Button type={Button.WARN} onClick={() => this._removeEndpoint(key)} >
              <FontAwesome name='minus' />
            </Button>
          }
          <Button key='add' onClick={this._addEndpoint} >
            <FontAwesome name='plus' />
          </Button>
        </div>
      )
    })
  }

  render () {
    return (
      <div className={style.contents}>
        <form onSubmit={this._onSubmit}>
          <div>
            <div className={style.formField}>
              <label>
                Filter Name
                <Hint className={style.hint}>
                  Enter a unique name for the external notification filter.
                </Hint>
              </label>
              <input
                required
                type='text'
                name='name'
                value={this.state.name}
                onChange={this._setValue.bind(this, 'name')} />
            </div>
            <div>
              <label>
                Endpoints
                <Hint className={style.hint}>
                  Select the external notification endpoints to send notifications to.
                </Hint>
              </label>
              {this._renderEndpoints()}
            </div>
            <div className={style.formField}>
              <label>
                Filter Query
                <Hint className={style.hint}>
                  The filter query that determines which notifications should be accepted by the filter.
                </Hint>
              </label>
              <input
                type='text' name='configuration' value={this.state.configuration || ''}
                onChange={this._onChangeConfiguration.bind(this)} />
            </div>
            <div className={style.validationStatus}>
              <ConfigurationValidationStatus
                hidden={!this.state.showValidation}
                configurationValidation={this.state.schemaValidation} />
            </div>
            <div className={style.controls}>
              <Button onClick={() => this.props.onClose()} flat>Cancel</Button>
              <Button
                onClick={this._validateConfiguration.bind(this)}
                disabled={this.state.showValidation}
                flat>
                Validate
              </Button>
              <Button
                type={Button.PRIMARY}
                submit
                disabled={!this.state.configurationValidated}>
                Save
              </Button>
            </div>
          </div>
        </form>
      </div>
    )
  }
}

ExternalNotificationFilterEditor.propTypes = {
  filter: ImmutablePropTypes.map,
  onUpdate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  endpoints: ImmutablePropTypes.map.isRequired,
  validateConfiguration: PropTypes.func.isRequired,
  configurationValidation: ImmutablePropTypes.map.isRequired
}

export default ExternalNotificationFilterEditor
