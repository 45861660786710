import PropTypes from 'prop-types'
import React from 'react'

import style from './Pagination.styl'

export default function Pagination ({ pageCount, currentPage, handleChange }) {
  const MAXDISPLAYEDPAGES = 7

  let pages = []
  let displayedPages = []
  if (pageCount > 1) {
    for (let i = 1; i <= pageCount; i++) {
      if (currentPage === i) {
        pages.push(<li key={i} className={style.active}>{i}</li>)
      } else {
        pages.push(<li key={i} onClick={() => handleChange(i)}>{i}</li>)
      }
    }

    if (pages.length <= MAXDISPLAYEDPAGES) {
      displayedPages.push.apply(displayedPages, pages)
    } else {
      if (currentPage !== 1) {
        displayedPages.push(<li key={'prev'} onClick={() => handleChange(currentPage - 1)}>{'<'}</li>)
      }

      displayedPages.push(pages[currentPage - 2])
      displayedPages.push(pages[currentPage - 1])
      displayedPages.push(pages[currentPage])

      if (currentPage === 1) {
        displayedPages.push(pages[currentPage + 1])
      }
      if (currentPage < pages.length - 2) {
        displayedPages.push(<li key={'more'} onClick={() => handleChange(currentPage + 3)}>{'..'}</li>)
      }
      if (currentPage < pages.length - 1) {
        displayedPages.push(pages[pages.length - 1])
      }
      if (currentPage !== pageCount) {
        displayedPages.push(<li key={'next'} onClick={() => handleChange(currentPage + 1)}>{'>'}</li>)
      }
    }
  }

  return (
    <ul className={style.pagination}>
      {displayedPages}
    </ul>
  )
}

Pagination.propTypes = {
  pageCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired
}
