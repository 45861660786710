import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import moment from 'moment'
import { fromJS, List } from 'immutable'

import { POLICIES } from 'scenes/Manage'

export class UsersList extends React.Component {
  listUsers (users) {
    if (!users || !users.size) {
      return (<tr><td>No Users</td></tr>)
    }

    return users.map(user => {
      const roles = fromJS(user.get('roles', List())).join(', ')
      return (
        <tr key={user.get('id')}>
          <td>{user.get('name')}</td>
          <td>{roles}</td>
          <td>{POLICIES[user.get('policy')] || 'Invalid'}</td>
          <td>{moment.utc(user.get('last_sync_time')).fromNow()}</td>
        </tr>
      )
    })
  }

  render () {
    return (
      <div>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Roles</th>
              <th>Policy</th>
              <th>Last Synced</th>
            </tr>
          </thead>
          <tbody>
            {this.listUsers(this.props.users.valueSeq().sortBy(v => v.get('name')))}
          </tbody>
        </table>
      </div>
    )
  }
}

UsersList.propTypes = {
  users: ImmutablePropTypes.map.isRequired
}

export default UsersList
