import { createSelector } from 'reselect'
import { List, Map, Set, OrderedMap } from 'immutable'

import { Endpoint, Group, Item, Model, Edge } from 'scenes/Resources/components/ResourceGraph/services/renderer'
import { formatMetricDatum } from 'scenes/Resources/components/ResourceDetails/components/Metrics'
import {
  getComplianceMetricTypesForCurrentSolutionContext,
  getMetricTypesForCurrentSolutionContext,
  getAvailablePolicyTypes
} from 'state/selectors'

const emptyCollectionSet = Map({
  total_size: 0,
  collections: List(),
  relations: List()
})

function complianceForResourceAndMetric (resource, metricId, datapoints) {
  return datapoints.getIn([metricId, resource.get('id'), 'value', 'compliant'])
}

function complianceForResourceAndSolutionContext (resource, solutionContext, metricTypes, datapoints) {
  const filteredMetricTypeList = metricTypes.filter(metricType => {
    const metricTypeResourceTypes = metricType.get('resource_types', List())
    return metricTypeResourceTypes.includes(resource.getIn(['blob', 'resource_type']))
  })

  const compliance = filteredMetricTypeList.map(metric => complianceForResourceAndMetric(resource, metric.get('id'), datapoints))

  if (compliance.includes(false)) {
    return false
  }
  return compliance.includes(true) || undefined
}

const addEdgeToMap = (endpoints, edgeMap, count) => {
  count = count || 1
  endpoints.sort((a, b) => {
    if (a.groupId < b.groupId) return -1
    if (a.groupId > b.groupId) return 1
    if (a.itemId < b.itemId) return -1
    if (a.itemId > b.itemId) return 1
    return 0
  })
  endpoints = JSON.stringify(endpoints)
  if (endpoints in edgeMap) {
    edgeMap[endpoints] += count
    return
  }
  edgeMap[endpoints] = count
}

const getPendingResourceTypes = state => state.pendingResourceTypes

const getCurrentDatapoints = createSelector(
  state => state.datapoints.get('datapoints'),
  datapoints => datapoints || Map()
)

const getCurrentSolutionContext = createSelector(
  state => state.currentSolutionContextId,
  state => state.availableSolutionContexts,
  (solutionContextId, availableSolutionContexts) => availableSolutionContexts.get(solutionContextId, Map())
)

const getResourceProviderTypes = createSelector(
  state => state.resourceTypes,
  (resourceTypes) => {
    const types = resourceTypes.filter((resourceType) => {
      return resourceType.get('provider_access_type', null)
    })

    return types
  }
)

const makeGetAttributeRanking = resourceType => {
  return createSelector(
    state => state.resourceTypes,
    resourceTypes => {
      return resourceTypes.getIn([ resourceType, 'attribute_ranking' ], null)
    }
  )
}

const getQueryCollectionSet = createSelector(
  state => state.queryCollectionSetID,
  state => state.normalizedCollectionSets,
  (id, normalizedCollectionSets) => normalizedCollectionSets.getIn(['collectionSets', id], emptyCollectionSet)
)

const getQueryCollectionFetched = createSelector(
  state => state.normalizedCollectionSets,
  normalizedCollectionSets => normalizedCollectionSets.get('isFetched')
)

const getQueryCollectionSolutionContextData = createSelector(
  state => state.queryCollectionSetID,
  state => state.currentSolutionContextId,
  state => state.collectionSolutionContextData,
  (id, solutionContextId, collectionSolutionContextData) => collectionSolutionContextData.getIn([id, solutionContextId], Map())
)

const getQueryCollectionResources = createSelector(
  state => state.queryCollectionSetID,
  state => state.collectionResources,
  (id, collectionResources) => collectionResources.get(id, Map())
)

const getQueryCollectionRelations = createSelector(
  state => state.queryCollectionSetID,
  state => state.collectionRelations,
  (id, collectionRelations) => collectionRelations.get(id, Map())
)

const getAnnotatedCollectionSet = createSelector(
  state => state.normalizedResources,
  state => state.normalizedRelations,
  state => state.expandedCollections,
  getQueryCollectionSet,
  getQueryCollectionSolutionContextData,
  getQueryCollectionResources,
  getQueryCollectionRelations,
  getCurrentSolutionContext,
  getCurrentDatapoints,
  getComplianceMetricTypesForCurrentSolutionContext,
  (
    normalizedResources,
    normalizedRelations,
    expandedCollections,
    collectionSet,
    collectionSolutionContextData,
    collectionResources,
    collectionRelations,
    solutionContext,
    datapoints,
    complianceMetricTypes
  ) => {
    // Orders and annotates collections within the collectionSet
    const ranking = solutionContext.getIn(['collections', 'ranking'], List())

    const collections = collectionSet.get('collections').map(collection => {
      const collectionId = collection.get('id')
      let compliant = true
      const metrics = collectionSolutionContextData.getIn([collectionId, 'metrics'], Map())
      if (!complianceMetricTypes.isEmpty()) {
        complianceMetricTypes.map((metricType, metricTypeId) => {
          if (metrics.hasIn([metricTypeId, 'false'])) {
            compliant = false
          }
          return false
        })
      }

      const warn = false
      const warnedResources = Set()

      const resources = collectionResources.get(collectionId, List()).map(id => {
        const resource = normalizedResources.get(id)
        const compliant = complianceForResourceAndSolutionContext(resource, solutionContext, complianceMetricTypes, datapoints)

        return resource.withMutations(r => {
          r
            .set('name', resource.getIn(['blob', 'display_id']))
            .set('compliant', compliant)
            .set('warn', compliant && warn ? warnedResources.includes(id) : false)
        })
      })

      const relations = collectionRelations.get(collectionId, List())
          .map(id => normalizedRelations.get(id))

      const expanded = expandedCollections.has(collectionId)
      const expandedCount = expanded ? resources.size : 0
      const distance = collection.get('direct') ? 0 : 1

      return collection.withMutations(c => {
        c
          .set('distance', distance)
          .set('compliant', compliant)
          .set('warn', warn)
          .set('resources', resources.sortBy(resource => `${resource.get('name')}${resource.get('id')}`))
          .set('relations', relations)
          .set('expanded', expanded)
          .set('expandedCount', expandedCount)
      })
    })

    return collectionSet.set('collections', collections.sortBy(collection => {
      const rank = ranking.indexOf(collection.get('id'))
      return rank === -1 ? ranking.size : rank
    }))
  }
)

const getAvailablePolicyTypesForResource = createSelector(
  state => state.currentResource,
  getAvailablePolicyTypes,
  (currentResource, policyTypes) => {
    return policyTypes.filter(policyType => {
      return policyType.get('resource_types').includes(currentResource.getIn(['blob', 'resource_type']))
    })
  }
)

const getMetricDataForCurrentResourceAndSolutionContext = createSelector(
  state => state.currentResource.get('id'),
  getMetricTypesForCurrentSolutionContext,
  state => state.datapoints,
  (currentResourceId, metricTypes, datapoints) => {
    const metricData = datapoints.get('datapoints').reduce((acc, metricData, metricType) => {
      const metricDatum = metricData.get(currentResourceId)
      const metricTypeId = metricTypes.getIn([metricType, 'id'])

      if (metricTypes.has(metricTypeId) && metricDatum) {
        const type = metricTypes.getIn([metricType, 'type'])
        const label = metricTypes.getIn([metricType, 'label'])

        acc = acc.push(OrderedMap({ [label]: formatMetricDatum(type, metricDatum) }))
      }

      return acc
    }, List()).sortBy(item => item.keySeq().first())

    if (metricData.size === 0 && !datapoints.get('isFetched')) {
      return null
    }

    return metricData
  }
)

const getGraphDataFromAnnotatedCollectionSet = createSelector(
  state => state.expandedCollections,
  getAnnotatedCollectionSet,
  (expandedCollections, annotatedCollectionSet) => {
    const edgeMap = {}
    for (const relation of annotatedCollectionSet.get('relations')) {
      addEdgeToMap([
        { groupId: relation.get('from_collection') },
        { groupId: relation.get('to_collection') }
      ], edgeMap, relation.get('size'))
    }

    const groups = annotatedCollectionSet.get('collections').map(collection => {
      const items = collection.get('resources').map(resource => new Item({
        id: resource.get('id'),
        name: resource.get('name'),
        mark: resource.get('compliant') === false,
        warn: resource.get('warn'),
        secondary: resource.get('depth') > 0
      }))

      if (collection.get('expanded')) {
        for (const relation of collection.get('relations')) {
          const fromRT = relation.get('from_resource_type')
          const toRT = relation.get('to_resource_type')
          const fromEndpoint = { groupId: fromRT }
          const toEndpoint = { groupId: toRT }
          if (expandedCollections.has(fromRT)) {
            fromEndpoint.itemId = relation.get('from_queryable')
          }
          if (expandedCollections.has(toRT)) {
            toEndpoint.itemId = relation.get('to_queryable')
          }

          addEdgeToMap([fromEndpoint, toEndpoint], edgeMap)
        }
      }

      return new Group({
        id: collection.get('id'),
        name: collection.get('name'),
        count: collection.get('size'),
        mark: !collection.get('compliant'),
        warn: collection.get('warn'),
        secondary: collection.get('distance') > 0,
        expanded: collection.get('expanded'),
        expandedCount: collection.get('expandedCount'),
        color: collection.get('color'),
        items
      })
    })

    const edges = List().withMutations(e => {
      for (const [endpointData, count] of Object.entries(edgeMap)) {
        const endpoints = JSON.parse(endpointData)
        e.push(new Edge({
          from: Endpoint(endpoints[0]),
          to: Endpoint(endpoints[1]),
          count
        }))
      }
    })

    return new Model({
      groups,
      edges
    })
  }
)

const getTagsForCurrentResource = createSelector(
  state => state.currentResource,
  (resource) => {
    if (!resource) {
      return OrderedMap()
    }

    const tags = resource.getIn([ 'blob', '_tags' ])
    if (tags === undefined) {
      console.warn('legacy tags format suggests an outdated discovery solution')
      if (resource.getIn([ 'blob', 'resource_type' ], 'aws').startsWith('aws')) {
        return resource.getIn([ 'blob', 'Tags' ], OrderedMap())
          .reduce((tags, tag) => tags.set(tag.get('Key'), tag.get('Value')), OrderedMap())
      }
      return resource.getIn([ 'blob', 'tags' ], OrderedMap())
    }
    return tags
  }
)

export {
  getCurrentSolutionContext,
  getPendingResourceTypes,
  getResourceProviderTypes,
  makeGetAttributeRanking,
  getQueryCollectionResources,
  getQueryCollectionRelations,
  getQueryCollectionFetched,
  getAnnotatedCollectionSet,
  getCurrentDatapoints,
  getGraphDataFromAnnotatedCollectionSet,
  getAvailablePolicyTypesForResource,
  getMetricDataForCurrentResourceAndSolutionContext,
  getTagsForCurrentResource
}
