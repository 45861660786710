import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import AceEditor from 'react-ace'

import 'brace/mode/json'
import 'brace/theme/tomorrow_night'

const INDENT_SIZE = 2

export function JsonEditor ({ className, jsonObject, showLineNumbering = false, readOnly = false, onUpdate = () => null }) {
  const onChange = document => {
    if (!onUpdate || !document) {
      return
    }

    onUpdate(document)
  }

  const aceOptions = {
    showInvisibles: !readOnly, // only show invisibles in edit mode,
    showGutter: showLineNumbering,
    showPrintMargin: false,
    useWorker: false
  }

  return (
    <div className={classNames(className)}>
      <AceEditor
        height='100%'
        width='100%'
        editorProps={{$blockScrolling: Infinity}}
        tabSize={INDENT_SIZE}
        mode='json'
        theme={'tomorrow_night'}
        readOnly={readOnly}
        onChange={onChange}
        value={typeof jsonObject === 'object' ? JSON.stringify(jsonObject, null, INDENT_SIZE) : jsonObject}
        setOptions={aceOptions}
      />
    </div>
  )
}

JsonEditor.propTypes = {
  className: PropTypes.string,
  jsonObject: PropTypes.any,
  readOnly: PropTypes.bool,
  onUpdate: PropTypes.func
}

export default JsonEditor
