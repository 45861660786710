import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'

export default class TimeSync extends React.Component {
  constructor () {
    super()

    this.state = {
      date: new Date()
    }
  }

  render () {
    return this.props.render(this.state.date)
  }

  componentDidMount () {
    this.interval = window.setInterval(() => {
      this.setState({
        date: new Date()
      })
    }, moment.duration(1, 'seconds').asMilliseconds())
  }

  componentWillUnmount () {
    window.clearInterval(this.interval)
  }
}

TimeSync.propTypes = {
  render: PropTypes.func.isRequired
}
