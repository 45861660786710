import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import { getGraphDataFromAnnotatedCollectionSet } from 'state/resources/selectors'
import { setExpandedCollectionLimit, toggleCollection } from 'state/resources/actions'
import render from './services/renderer'
import { AVAILABLE_RESOURCES } from 'refs'

import style from './ResourceGraph.styl'

export const ResourceGraphVariants = {
  MICRO: 'micro',
  MINI: 'mini',
  NORMAL: 'normal'
}

export class ResourceGraph extends React.Component {
  componentDidMount () {
    this._draw(false)
    this.props.onInit()
  }

  render () {
    const viewBoxSize = 200
    const viewBoxOffset = -viewBoxSize / 2

    const viewBox = `${viewBoxOffset} ${viewBoxOffset} ${viewBoxSize} ${viewBoxSize}`

    return (
      <div data-ref={AVAILABLE_RESOURCES} className={style.graphContainer}>
        <svg viewBox={viewBox}>
          <g ref='groups' data-type='groups' />
          <g ref='labels' data-type='labels' />
          <g ref='edges' data-type='edges' />
        </svg>
      </div>
    )
  }

  shouldComponentUpdate (nextProps, nextState) {
    return !(nextProps.expandedCollections === this.props.expandedCollections &&
      nextProps.filter === this.props.filter &&
      nextProps.currentResourceId === this.props.currentResourceId &&
      nextProps.model.equals(this.props.model))
  }

  componentDidUpdate (prevProps, prevState) {
    this._draw(true)
  }

  _draw (enableTransitions) {
    const parentElements = {
      groups: this.refs.groups,
      edges: this.refs.edges,
      labels: this.props.variant === ResourceGraphVariants.NORMAL ? this.refs.labels : null
    }

    render(
      this.props.model,
      enableTransitions && this.props.enableTransitions,
      parentElements,
      {
        onSelectResource: resource => this.props.onSelectNode(resource.id),
        onToggleGroupExpansion: group => this.props.onToggleCollectionExpanded(group.id),
        onSelectGroup: group => this.props.onToggleCollectionExpanded(group.id)
      },
      this.props.filter, this.props.currentResourceId)
  }
}

ResourceGraph.propTypes = {
  onToggleCollectionExpanded: PropTypes.func.isRequired,
  onSelectNode: PropTypes.func.isRequired,
  onInit: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(
    Object.keys(ResourceGraphVariants).map(key => ResourceGraphVariants[key])
  ),
  currentResourceId: PropTypes.string,
  filter: PropTypes.string
}

function mapStateToProps (state) {
  return {
    expandedCollections: state.expandedCollections,
    currentResourceId: state.currentResource.get('id'),
    filter: state.resourcesFilter,
    model: getGraphDataFromAnnotatedCollectionSet(state),
    enableTransitions: state.enableTransitions
  }
}

function mapDispatchToProps (dispatch, ownProps) {
  return {
    onToggleCollectionExpanded: collectionName => dispatch(toggleCollection(collectionName)),
    onInit: () => dispatch(setExpandedCollectionLimit(100))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResourceGraph)
