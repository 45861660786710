import { push } from 'react-router-redux'

import {
  fetchCurrentUser,
  updateLocation
} from 'state/actions'

export const SIGN_IN = 'SIGN_IN'
export const SIGN_OUT = 'SIGN_OUT'
export const RETURN_ROUTE_KEY = 'signInReturnRoute'

export function signIn (idToken, profile, returnRoute) {
  if (!returnRoute) {
    returnRoute = '/'
  }

  return (dispatch, getState) => {
    window.localStorage.setItem('auth', JSON.stringify({ idToken, profile }))

    dispatch(fetchCurrentUser()).then(response => {
      dispatch(updateLocation({ pathname: returnRoute }))
    })

    dispatch({
      type: SIGN_IN,
      idToken,
      profile
    })
  }
}

export function signOut () {
  return (dispatch, getState) => {
    window.localStorage.removeItem('auth')
    window.localStorage.removeItem('organizations')
    window.localStorage.removeItem('solutionContext')
    const returnRoute = getState().routing.locationBeforeTransitions.pathname
    if (returnRoute !== '/unauthorized') {
      window.localStorage.setItem(RETURN_ROUTE_KEY, returnRoute)
    }

    dispatch({
      type: SIGN_OUT
    })

    dispatch(push('/signin'))
  }
}
