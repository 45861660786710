import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import ImmutablePropTypes from 'react-immutable-proptypes'

import { generateGroupTree } from './services'
import { fetchAncestralPolicies, updateLocation } from 'state/actions'

import PolicyHierarchyTree from './PolicyHierarchyTree'

export class PolicyHierarchyList extends React.Component {
  constructor (props) {
    super(props)
    props.fetchAncestralPolicies(props.policyId)
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.policy.get('id') !== this.props.policy.get('id') ||
        nextProps.isResourceGroup !== this.props.isResourceGroup ||
        nextProps.policyTypes !== this.props.policyTypes) {
      this.props.fetchAncestralPolicies(nextProps.policyId)
    }
  }

  render () {
    return <PolicyHierarchyTree
      className={this.props.className}
      policyClassName={this.props.policyClassName}
      resourceGroupClassName={this.props.resourceGroupClassName}
      policies={this.props.policies}
      policyTypes={this.props.policyTypes}
      onSelectResourceGroup={this.props.onSelectResourceGroup}
    />
  }
}

PolicyHierarchyList.propTypes = {
  className: PropTypes.string,
  policyClassName: PropTypes.string,
  resourceGroupClassName: PropTypes.string,
  policy: PropTypes.object.isRequired,
  policyId: PropTypes.string.isRequired,
  policies: PropTypes.array.isRequired,
  policyTypes: ImmutablePropTypes.list.isRequired,
  isResourceGroup: PropTypes.bool,
  fetchAncestralPolicies: PropTypes.func.isRequired,
  onSelectResourceGroup: PropTypes.func.isRequired
}

function mapStateToProps (state, ownProps) {
  const propagatedPolicies = state.propagatedPolicies.get(state.activeResourceGroupId)

  const policyId = ownProps.isResourceGroup
    ? ownProps.policy.get('id')
    : propagatedPolicies.find(policy => policy.get('policy_type') === ownProps.policy.get('policy_type')).get('id')

  const statePolicies = state.ancestralPolicies.get(policyId)

  return {
    policies: generateGroupTree(
      statePolicies && statePolicies.toJS() || [],
      state.normalizedResourceGroups.toJS()
    ),
    policyId
  }
}

function mapDispatchToProps (dispatch) {
  return {
    fetchAncestralPolicies: (policyId) => {
      dispatch(fetchAncestralPolicies(policyId))
    },
    onSelectResourceGroup: (resourceGroupId) => {
      dispatch(updateLocation({ pathname: `/resources/${resourceGroupId}` }))
    }
  }
}

export default connect(
  mapStateToProps, mapDispatchToProps
)(PolicyHierarchyList)
