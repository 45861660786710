import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import ImmutablePropTypes from 'react-immutable-proptypes'

import OrganizationsList from '../Root/components/OrganizationsList'

import Button from 'components/Button'
import { signOut } from 'state/session/actions'
import { setOrganization } from 'state/actions'

import style from './SelectOrganization.styl'

export function SelectOrganization ({ signOut, setOrganization, organizations }) {
  return (
    <div className={style.page}>
      <div className={style.selectOrganization}>
        <OrganizationsList
          organizations={organizations}
          setOrganization={organizationId => setOrganization(organizationId)} />
        <div className={style.controls}>
          <Button
            onClick={() => signOut()}
            type={Button.WARN}>
              Sign Out
          </Button>
        </div>
      </div>
    </div>
  )
}

SelectOrganization.propTypes = {
  signOut: PropTypes.func.isRequired,
  organizations: ImmutablePropTypes.list.isRequired
}

function mapDispatchToProps (dispatch, ownProps) {
  return {
    signOut: () => dispatch(signOut()),
    setOrganization: organizationId => dispatch(setOrganization(organizationId))
  }
}

function mapStateToProps (state, ownProps) {
  return {
    organizations: state.availableOrganizations
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectOrganization)
