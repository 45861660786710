import { fromJS, List, Map } from 'immutable'
import { createSelector } from 'reselect'

import { DEFAULT_SOLUTION_CONTEXT_ID, NO_SOLUTION_CONTEXT_ID } from 'state/constants'

const getRoleForCurrentOrganization = createSelector(
  state => state.availableOrganizations,
  state => state.currentOrganization,
  (availableOrganizations, currentOrganization) => {
    return availableOrganizations.find(org => org.get('id') === currentOrganization, undefined, List()).get('role')
  }
)

const inProgress = createSelector(
  state => state.inProgressCount,
  count => count > 0
)

const getActiveResourceGroup = createSelector(
  state => state.availableGroups,
  state => state.activeResourceGroupId,
  (groups, activeGroupId) => {
    const group = (groups || List()).find(group => {
      return group.get('id') === activeGroupId
    }, null, Map())
    return group
  }
)

const getMetricTypesForCurrentSolutionContext = createSelector(
  state => state.normalizedMetrics,
  state => state.currentSolutionContextId,
  (currentMetricTypes, currentSolutionContextId) => {
    if (currentSolutionContextId === NO_SOLUTION_CONTEXT_ID) {
      return Map()
    }

    if (currentSolutionContextId === DEFAULT_SOLUTION_CONTEXT_ID) {
      return currentMetricTypes
    }

    return currentMetricTypes.filter((metricType, id) => {
      return metricType.get('solution') === currentSolutionContextId
    })
  }
)

const getComplianceMetricTypesForCurrentSolutionContext = createSelector(
  getMetricTypesForCurrentSolutionContext,
  (metricTypes) => {
    return metricTypes.filter(metricType => metricType.get('type') === 'compliance')
  }
)

const getCurrentQuery = createSelector(
  state => state.currentQuery,
  query => query
)

const getComplianceInfo = (group, metricTypes) => {
  if (!group) {
    return
  }

  let compliant = 0
  let nonCompliant = 0
  for (const [metricId] of group) {
    if (metricTypes.has(metricId)) {
      const metricInfo = group.get(metricId)
      compliant += metricInfo.get('true', 0)
      nonCompliant += metricInfo.get('false', 0)
    }
  }
  if (compliant || nonCompliant) {
    return compliant / (compliant + nonCompliant)
  }
}

const filterPolicyInfo = (group, policyTypes) => {
  const policyTypeIds = policyTypes.map(policyType => policyType.get('id'))
  group.attached_policies = (group.attached_policies || []).filter(
    attachedPolicy => policyTypeIds.includes(attachedPolicy.policy_type_id)
  )
  group.propagated_policies = (group.propagated_policies || []).filter(propagatedPolicy => {
    if (!propagatedPolicy.resolved_policy) {
      return false
    }
    return policyTypeIds.includes(propagatedPolicy.policy_type)
  })
}

const getAvailablePolicyTypes = createSelector(
  state => state.currentSolutionContextId,
  state => state.policyTypes,
  (currentSolutionContextId, policyTypes) => {
    if (currentSolutionContextId === DEFAULT_SOLUTION_CONTEXT_ID) {
      return policyTypes
    }

    if (currentSolutionContextId === NO_SOLUTION_CONTEXT_ID) {
      return List()
    }

    return policyTypes.filter(policyType => policyType.get('solution') === currentSolutionContextId)
  }
)

const getSelectedPolicyTypes = createSelector(
  state => state.currentCoveragePolicyType,
  getAvailablePolicyTypes,
  (currentCoveragePolicyId, policyTypes) => {
    if (currentCoveragePolicyId === 'ALL') {
      return policyTypes
    }

    if (currentCoveragePolicyId === 'NONE') {
      return List()
    }

    return policyTypes.filter(policyType => policyType.get('id') === currentCoveragePolicyId)
  }
)

const getResourceGroupTree = createSelector(
  state => state.availableGroups,
  getComplianceMetricTypesForCurrentSolutionContext,
  getSelectedPolicyTypes,
  state => state.groupsCompliance,
  (resourceGroupsImmmutable, currentMetricTypes, selectedPolicyTypes, groupsCompliance) => {
    const resourceGroups = resourceGroupsImmmutable.toJS()
    const resourceGroupById = resourceGroups.reduce((groups, group) => {
      const complianceLevel = getComplianceInfo(groupsCompliance.get(group.id), currentMetricTypes)
      if (complianceLevel !== undefined) {
        group.complianceLevel = complianceLevel
      }
      filterPolicyInfo(group, selectedPolicyTypes)
      groups[group.id] = group
      delete groups[group.id].children
      return groups
    }, {})
    const childNodes = []

    resourceGroups.forEach(resourceGroup => {
      if (resourceGroup.parent && resourceGroup.parent.id) {
        const parent = resourceGroupById[resourceGroup.parent.id]
        parent.children = parent.children || []
        parent.children.push(resourceGroupById[resourceGroup.id])
      } else {
        childNodes.push(resourceGroupById[resourceGroup.id])
      }
    })

    return fromJS(childNodes[0] || Map())
  }
)

const getResourcesVisualizationOptions = createSelector(
  state => state.resourcesVisualizationOptions,
  options => options
)

const getResourceGroupsVisualizationOptions = createSelector(
  state => state.resourceGroupsVisualizationOptions,
  options => options
)

export {
  getRoleForCurrentOrganization,
  inProgress,
  getActiveResourceGroup,
  getCurrentQuery,
  getResourceGroupTree,
  getResourcesVisualizationOptions,
  getResourceGroupsVisualizationOptions,
  getAvailablePolicyTypes,
  getMetricTypesForCurrentSolutionContext,
  getComplianceMetricTypesForCurrentSolutionContext
}
