import React from 'react'
import FontAwesome from 'react-fontawesome'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List } from 'immutable'

import {
  READONLY_POLICY,
  ADMIN_POLICY
} from 'state/actions'
import { POLICIES } from '../../Manage'
import Button from 'components/Button'
import Hint from 'components/Hint'

import style from '../RoleCreator/RoleCreator.styl'

export class RoleEditor extends React.Component {
  constructor () {
    super()

    this._onSubmit = this._onSubmit.bind(this)
    this._onChange = this._onChange.bind(this)
    this._addGroup = this._addGroup.bind(this)
    this._removeGroup = this._removeGroup.bind(this)

    this.state = {
      name: '',
      auth0_connection: '',
      policy: READONLY_POLICY,
      idp_groups: {},
      groups: 0
    }
  }

  componentWillMount () {
    let numGroups = 0
    const idpGroups = this.props.role.get('idp_groups', List()).reduce((groups, group) => {
      groups[numGroups] = group
      numGroups += 1
      return groups
    }, {})
    this.state = {
      name: this.props.role.get('name', ''),
      auth0_connection: this.props.role.get('auth0_connection', ''),
      policy: this.props.role.get('policy', READONLY_POLICY),
      idp_groups: idpGroups,
      groups: numGroups
    }
  }

  _onChange ({ target }) {
    this.setState({[target.name]: target.value})
  }

  _onChangeGroup ({ target }) {
    const groups = this.state.idp_groups
    groups[target.name] = target.value
    this.setState({idp_groups: groups})
  }

  _onSubmit (e) {
    e.preventDefault()

    const idpGroups = Object.keys(this.state.idp_groups).map(key => {
      return this.state.idp_groups[key]
    })

    const fields = {
      name: this.state.name,
      auth0_connection: this.state.auth0_connection,
      policy: this.state.policy,
      idp_groups: idpGroups
    }

    this.props.onUpdate(this.props.role.get('id'), fields)
      .then(() => this.props.onClose())
  }

  _addGroup () {
    const key = this.state.groups + 1
    this.setState({groups: key})

    const groups = this.state.idp_groups
    groups[key] = ''
    this.setState({idp_groups: groups})
  }

  _removeGroup (key) {
    var groups = this.state.idp_groups
    delete groups[key]
    this.setState({idp_groups: groups})
  }

  _renderGroups () {
    return Object.keys(this.state.idp_groups).map((key) => {
      return (
        <div key={key} className={style.groupField}>
          <input
            name={key}
            type='text'
            value={this.state.idp_groups[key]}
            onChange={this._onChangeGroup.bind(this)} />
          {
            Object.keys(this.state.idp_groups).length > 1 &&
            <Button type={Button.WARN} onClick={() => this._removeGroup(key)} >
              <FontAwesome name='minus' />
            </Button>
          }
          <Button key='add' onClick={this._addGroup} >
            <FontAwesome name='plus' />
          </Button>
        </div>
      )
    })
  }

  render () {
    return (
      <div className={style.contents}>
        <form onSubmit={this._onSubmit}>
          <div className={style.formField}>
            <label>
              Role name
              <Hint className={style.hint}>
                Provide a unique name for the role.
              </Hint>
            </label>
            <input
              required
              type='text'
              name='name'
              value={this.state.name}
              onChange={this._onChange} />
          </div>
          <div className={style.formField}>
            <label>
              Policy
              <Hint className={style.hint}>
                Select the permissions for the users who have this role.
              </Hint>
            </label>
            <select value={this.state.policy} onChange={this._onChange} name='policy'>
              <option value={READONLY_POLICY}>{POLICIES[READONLY_POLICY]}</option>
              <option value={ADMIN_POLICY}>{POLICIES[ADMIN_POLICY]}</option>
            </select>
          </div>
          <div className={style.formField}>
            <label>
              Auth0 Connection
              <Hint className={style.hint}>
                Specify the Auth0 Connection name.
              </Hint>
            </label>
            <input
              type='text'
              name='auth0_connection'
              value={this.state.auth0_connection}
              onChange={this._onChange} />
          </div>
          <div>
            <label>
              IDP Groups
              <Hint className={style.hint}>
                Specify the IDP Group names.
              </Hint>
            </label>
            {this._renderGroups()}
          </div>
          <div className={style.controls}>
            <Button onClick={() => this.props.onClose()} flat>Cancel</Button>
            <Button type={Button.PRIMARY} submit>Save</Button>
          </div>
        </form>
      </div>
    )
  }
}

RoleEditor.propTypes = {
  role: ImmutablePropTypes.map,
  onUpdate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}

export default RoleEditor
