import { executeWithProgress } from 'state/actions'
import apiRequest from 'apiRequest'

export const STORE_RESTRICTED_CREDENTIALS = 'STORE_RESTRICTED_CREDENTIALS'

export function fetchRestrictedCredentials (resourceProviderId) {
  return executeWithProgress(dispatch => {
    return apiRequest(dispatch, 'GET', `resource_providers/${resourceProviderId}/restricted_credentials/`)
      .then(response => {
        return dispatch(storeRestrictedCredentials(resourceProviderId, response.body))
      })
  })
}

export function storeRestrictedCredentials (resourceProviderId, restrictedCredentials) {
  return {
    type: STORE_RESTRICTED_CREDENTIALS,
    resourceProviderId,
    restrictedCredentials
  }
}

export function createOrUpdateRestrictedCredentials (resourceProviderId, policyTypeId, credentials) {
  return executeWithProgress(dispatch => {
    const payload = {
      requester_id: policyTypeId,
      credentials: credentials
    }

    return apiRequest(dispatch, 'POST', `resource_providers/${resourceProviderId}/restricted_credentials/`)
      .send(payload)
  })
}

export function deleteRestrictedCredentials (resourceProviderId, restrictedCredentialsId) {
  return executeWithProgress(dispatch => {
    return apiRequest(dispatch, 'DELETE', `resource_providers/${resourceProviderId}/restricted_credentials/${restrictedCredentialsId}/`)
  })
}
