import superagent from 'superagent'
import config from 'config'
import { notifyUser } from 'state/actions'

export default function apiRequest (dispatch, method, path, ...args) {
  const url = `${config.apiBaseUrl}${path}`

  const idToken = JSON.parse(window.localStorage.getItem('auth')).idToken
  const organizationHeader = window.localStorage.getItem('organizationId')
  const authHeader = `Bearer ${idToken}`

  return superagent(method, url, ...args)
    .set('Accept', 'application/json')
    .set('Authorization', authHeader)
    .set('Cloudreach-Organization-Id', organizationHeader)
    .use(apiInterceptor(dispatch))
}

export function apiRequestPartial (dispatch, method, path, ...args) {
  const url = `${config.apiBaseUrl}${path}`

  const idToken = JSON.parse(window.localStorage.getItem('auth')).idToken
  const authHeader = `Bearer ${idToken}`

  return superagent(method, url, ...args)
    .set('Accept', 'application/json')
    .set('Authorization', authHeader)
    .use(apiInterceptor(dispatch))
}

export const errorFormatter = error => {
  try {
    JSON.parse(error.response.text)
    return error.response.text
  } catch (e) {
    return error.message
  }
}

const apiInterceptor = dispatch => _apiInterceptor((err, res) => {
  if (err) {
    dispatch(notifyUser(errorFormatter(err), 'error'))
  }
})

function _apiInterceptor () {
  const callbacks = Array.prototype.slice.call(arguments)
  return req => {
    const callback = req.callback
    req.callback = (err, res) => {
      callbacks.forEach(e => e.call(req, err, res))
      callback.call(req, err, res)
    }
  }
}
