import PropTypes from 'prop-types'
import React from 'react'

import Button from 'components/Button'

import style from './ResourceGroupEditor.styl'

export class ResourceGroupEditor extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      groupId: null,
      groupName: '',
      groupQuery: ''
    }
  }

  _handleInputChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  _handleSubmit = e => {
    e.preventDefault()

    this.props.onSave(this.props.resourceGroup.id, {
      name: this.state.groupName,
      query: this.state.groupQuery
    })
      .then(() => this.props.onClose())
  }

  componentWillMount () {
    this.setState({
      groupId: this.props.resourceGroup.id,
      groupName: this.props.resourceGroup.name,
      groupQuery: this.props.resourceGroup.query
    })
  }

  componentWillReceiveProps (newProps) {
    if (newProps.resourceGroup !== this.props.resourceGroup) {
      this.setState({
        groupId: newProps.resourceGroup.id,
        groupName: newProps.resourceGroup.name,
        groupQuery: newProps.resourceGroup.query
      })
    }
  }

  render () {
    return (
      <div>
        <div className={style.query}>
          <strong>Existing resolved query</strong>
          <p>{this.props.resourceGroup.resolved_query}</p>
        </div>
        <form onSubmit={this._handleSubmit}>
          <div className={style.formField}>
            <label>Name</label>
            <input type='text' name='groupName' value={this.state.groupName} onChange={this._handleInputChange} />
          </div>
          <div className={style.formField}>
            <label>Query</label>
            <input type='text' name='groupQuery' value={this.state.groupQuery} onChange={this._handleInputChange} />
          </div>
          <div className={style.controls}>
            <Button
              className={style.cancelButton}
              onClick={() => this.props.onClose()}
              flat>
              Cancel
            </Button>
            <Button
              className={style.saveButton}
              type={Button.PRIMARY}
              submit>
              Save
            </Button>
          </div>
        </form>
      </div>
    )
  }
}

ResourceGroupEditor.propTypes = {
  resourceGroup: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}

export default ResourceGroupEditor
