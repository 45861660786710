import { fromJS, List } from 'immutable'
import * as actions from './actions'

const restrictedCredentials = (state = List(), action) => {
  switch (action.type) {
    case actions.STORE_RESTRICTED_CREDENTIALS:
      return fromJS({[action.resourceProviderId]: action.restrictedCredentials})
    default:
      return state
  }
}

export { restrictedCredentials }
