import PropTypes from 'prop-types'
import React from 'react'
import Notifs from 'components/Notifs'
import FontAwesome from 'react-fontawesome'
import classNames from 'classnames'

import style from './Notifications.styl'

const fontAwesomeMapping = {
  info: {
    iconName: 'info',
    iconBgColor: '#41a8b9',
    messageBgColor: '#7cc1ff'
  },
  success: {
    iconName: 'check',
    iconBgColor: '#267126',
    messageBgColor: '#b5f7b5'
  },
  error: {
    iconName: 'times-circle',
    iconBgColor: '#bd3731',
    messageBgColor: '#ed9191'
  }
}

function CustomNotification ({ message, kind }) {
  return (<div className={style.popup}>
    <div className={style.icon} style={{ backgroundColor: fontAwesomeMapping[kind].iconBgColor }}>
      <FontAwesome
        className={classNames(style.fontIcon, style[kind])}
        name={fontAwesomeMapping[kind].iconName}
        style={{ color: 'white' }}
      />
    </div>
    <div className={style.message} style={{ backgroundColor: fontAwesomeMapping[kind].messageBgColor }}>
      {parse(message)}
    </div>
  </div>)
}

const parse = message => {
  try {
    const parsedMessage = JSON.parse(message)
    return Object.keys(parsedMessage).map((key, index) => {
      if (index === 0) {
        return `${key}: ${parsedMessage[key]}`
      } else {
        return <div key={index}>{key}: {parsedMessage[key]}</div>
      }
    })
  } catch (e) {
    return message
  }
}

CustomNotification.propTypes = {
  message: PropTypes.string.isRequired,
  kind: PropTypes.oneOf([
    'success',
    'info',
    'error'
  ]).isRequired
}

export function Notifications () {
  return (
    <div className={style.Notifications}>
      <Notifs CustomComponent={CustomNotification} className={style.container} />
    </div>
  )
}

export default Notifications
