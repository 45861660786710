import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import FontAwesome from 'react-fontawesome'
import moment from 'moment'

import Waypoint from 'react-waypoint'
import ReactPlaceholder from 'react-placeholder'

import 'react-placeholder/lib/reactPlaceholder.css'
import style from './InvocationsList.styl'

export class InvocationsList extends React.Component {
  _listInvocations (invocations, isFetching) {
    if (!isFetching && (!invocations || !invocations.size)) {
      return (<tr><td colSpan='4'>No Invocations</td></tr>)
    }

    return invocations.map(invocation => {
      let iconName = 'check'
      let iconClass = style.success
      let message = ''
      switch (invocation.get('state')) {
        case 'FAILED':
          iconName = 'warning'
          iconClass = style.error
          message = invocation.get('error')
          break

        case 'DISCARDED':
          iconName = 'info-circle'
          iconClass = style.info
          message = 'Invocation has been discarded'
          break

        case 'SENT':
          iconName = 'spinner'
          iconClass = style.spinner
          message = 'Invocation is sent to solution'
          break

        case 'PENDING':
          iconName = 'spinner'
          iconClass = style.spinner
          message = 'Invocation is waiting to be sent to solution'
          break

        case 'RECEIVED':
          iconName = 'spinner'
          iconClass = style.spinner
          message = 'Invocation response has been received'
          break

        case 'PROCESSING':
          iconName = 'spinner'
          iconClass = style.spinner
          message = 'Invocation response is being processed'
          break
      }

      return (
        <tr key={invocation.get('id')}>
          <td className={style.status}>
            <FontAwesome name={iconName} className={iconClass} />
          </td>
          <td className={style.time}>{moment.utc(invocation.get('created_at')).fromNow()}</td>
          <td>{invocation.get('invocation_details') ? JSON.stringify(invocation.get('invocation_details').toJS()) : null}</td>
          <td className={style.message}>{message}</td>
        </tr>
      )
    })
  }

  render () {
    return (
      <div className={style.InvocationsList}>
        <table>
          <thead>
            <tr>
              <th />
              <th>Time</th>
              <th>Details</th>
              <th>Message</th>
            </tr>
          </thead>
          <tbody>
            {this._listInvocations(this.props.invocations, this.props.isFetching)}
          </tbody>
        </table>
        <ReactPlaceholder showLoadingAnimation type='text' ready={!this.props.isFetching} rows={7} color='#E0E0E0'>
          {this.props.cursor !== null && <Waypoint onEnter={this.props.fetchPolicyInvocations} />}
        </ReactPlaceholder>
      </div>
    )
  }
}

InvocationsList.propTypes = {
  invocations: ImmutablePropTypes.list.isRequired,
  cursor: PropTypes.string,
  isFetching: PropTypes.bool,
  policyId: PropTypes.string,
  resourceId: PropTypes.string,
  fetchPolicyInvocations: PropTypes.func.isRequired
}

export default InvocationsList
