import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import {Light as SyntaxHighlighter} from 'react-syntax-highlighter'
import bash from 'react-syntax-highlighter/dist/cjs/languages/hljs/bash'
import defaultStyle from 'react-syntax-highlighter/dist/cjs/styles/hljs/default-style'

import { getMetricDataForCurrentResourceAndSolutionContext } from 'state/resources/selectors'
import { Default, Entries } from 'components/Attribute'
import TableMetric from './components/TableMetric'
import UriMetric from './components/UriMetric'
import JsonTree from 'components/JsonTree'
import LoadingIndicator from 'components/LoadingIndicator'

import style from './Metrics.styl'

class Metrics extends React.Component {
  render () {
    const { metricData } = this.props
    if (!metricData) {
      return <LoadingIndicator />
    }
    return <div>
      {metricData.isEmpty() ? 'No Metrics' : <dl>{metricData.map((data, i) =>
        <div className={style.metricEntry} key={i}>{Entries.fromMap(data)}</div>
      )}</dl>}
    </div>
  }
}

Metrics.propTypes = {
  metricData: ImmutablePropTypes.list
}

function mapStateToProps (state, ownProps) {
  return {
    metricData: getMetricDataForCurrentResourceAndSolutionContext(state)
  }
}

export default connect(mapStateToProps, null)(Metrics)

export function formatMetricDatum (metricDatumType, metricDatum) {
  const value = metricDatum.get('value')

  switch (metricDatumType) {
    case 'string':
      return <Default context={value || ''} />
    case 'boolean':
      return <Default context={value ? 'Yes' : 'No'} />
    case 'json':
    case 'compliance':
      return <JsonTree context={value} compliant={value.get('compliant')} />
    case 'table':
      return <TableMetric value={value} />
    case 'uri':
      return <UriMetric value={value} />
    case 'command':
      SyntaxHighlighter.registerLanguage('bash', bash)
      return <SyntaxHighlighter language='bash' style={defaultStyle}>{value}</SyntaxHighlighter>
    default:
      return <Default context={value || ''} />
  }
}
