import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import ImmutablePropTypes from 'react-immutable-proptypes'

import { makeGetAttributeRanking } from 'state/resources/selectors'
import RankedAttributeList from 'components/RankedAttributeList'

export function ProviderDetails ({ attributeRanking, resource }) {
  return (
    <div>
      <RankedAttributeList
        attributeRanking={attributeRanking}
        resource={resource} />
    </div>
  )
}

ProviderDetails.propTypes = {
  attributeRanking: ImmutablePropTypes.list,
  resource: ImmutablePropTypes.mapContains({
    blob: ImmutablePropTypes.mapContains({
      resource_type: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
}

function mapStateToProps (state, ownProps) {
  const resourceType = getResourceType(ownProps.resource)
  const attributeRanking = resourceType && makeGetAttributeRanking(resourceType)(state)

  return { attributeRanking }
}

export default connect(mapStateToProps)(ProviderDetails)

function getResourceType (resource) {
  return resource.getIn([ 'blob', 'resource_type' ])
}
