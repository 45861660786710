import * as actions from './actions'
import { Map, fromJS } from 'immutable'

const managedWorkers = (state = Map(), action) => {
  switch (action.type) {
    case actions.UPDATE_MANAGED_WORKERS:
      return fromJS(action.managedWorkers)
        .reduce((acc, el) => acc.set(el.get('id'), el), Map().asMutable()).asImmutable()
    default:
      return state
  }
}

const validation = (state = Map().set('key', ''), action) => {
  switch (action.type) {
    case actions.UPDATE_VALIDATION:
      return fromJS(action.validation)
    default:
      return state
  }
}

const resourceProviders = (state = Map(), action) => {
  switch (action.type) {
    case actions.UPDATE_RESOURCE_PROVIDERS:
      return fromJS(action.accounts)
    default:
      return state
  }
}

const solutionRuntimes = (state = Map(), action) => {
  switch (action.type) {
    case actions.UPDATE_SOLUTION_RUNTIMES:
      return fromJS(action.environments)
    default:
      return state
  }
}

const roles = (state = Map(), action) => {
  switch (action.type) {
    case actions.UPDATE_ROLES:
      return fromJS(action.roles)
    default:
      return state
  }
}

const clients = (state = Map(), action) => {
  switch (action.type) {
    case actions.UPDATE_CLIENTS:
      return fromJS(action.clients)
    default:
      return state
  }
}

const createdClient = (state = Map(), action) => {
  switch (action.type) {
    case actions.UPDATE_CREATED_CLIENT:
      return fromJS(action.client)
    default:
      return state
  }
}

const externalNotificationEndpoints = (state = Map(), action) => {
  switch (action.type) {
    case actions.UPDATE_EXTERNAL_NOTIFICATION_ENDPOINTS:
      return fromJS(action.endpoints)
    default:
      return state
  }
}

const externalNotificationFilters = (state = Map(), action) => {
  switch (action.type) {
    case actions.UPDATE_EXTERNAL_NOTIFICATION_FILTERS:
      return fromJS(action.filters)
    default:
      return state
  }
}

const externalNotificationFilterConfigurationValidation = (state = Map(), action) => {
  switch (action.type) {
    case actions.UPDATE_EXTERNAL_NOTIFICATION_FILTER_CONFIGURATION_VALIDATION:
      return fromJS(action.configurationValidation)
    default:
      return state
  }
}

export {
  managedWorkers,
  validation,
  resourceProviders,
  solutionRuntimes,
  roles,
  clients,
  createdClient,
  externalNotificationEndpoints,
  externalNotificationFilters,
  externalNotificationFilterConfigurationValidation
}
