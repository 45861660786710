import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'

import Button from 'components/Button'
import { convertUTCTimeToLocal } from 'services/time'

import style from './NotificationViewer.styl'

export class NotificationViewer extends React.Component {
  render () {
    const { notification } = this.props

    let rawMessage

    try {
      rawMessage = JSON.stringify(notification.get('raw'), null, 2)
    } catch (e) {
      rawMessage = notification.get('raw')
    }

    const notificationBody = notification && notification.size ? (
      <ul className={style.notificationsList}>
        <li >
          <label>Notification ID:</label>
          <span>{notification.get('id')}</span>
        </li>
        <li>
          <label>Subject:</label>
          <span>{notification.get('subject')}</span>
        </li>
        <li>
          <label>Body:</label>
          <span>{notification.get('body')}</span>
        </li>
        <li>
          <label>Count:</label>
          <span>{notification.get('count')}</span>
        </li>
        <li>
          <label>First seen:</label>
          <span>{convertUTCTimeToLocal(notification.get('created_at'))}</span>
        </li>
        <li>
          <label>Last seen:</label>
          <span>{convertUTCTimeToLocal(notification.get('updated_at'))}</span>
        </li>
        <li>
          <label>Raw message:</label>
          <pre>{rawMessage}</pre>
        </li>
      </ul>
    ) : <p>Loading...</p>

    const readButton = notification && notification.size ? (
      <Button onClick={() => this.props.toggleNotificationRead(this.props.notification)} type={Button.PRIMARY} flat>
        {this.props.notification.get('read') ? 'Mark as Unread' : 'Mark as Read'}
      </Button>
    ) : null

    return (
      <div>
        {notificationBody}
        <div className={style.controls}>
          {readButton}
        </div>
      </div>
    )
  }
}

NotificationViewer.propTypes = {
  notification: ImmutablePropTypes.map.isRequired,
  toggleNotificationRead: PropTypes.func.isRequired
}

export default NotificationViewer
