import * as actions from 'state/actions'
import { List, Map, fromJS, OrderedMap } from 'immutable'

import { DEFAULT_SOLUTION_CONTEXT_ID, NO_SOLUTION_CONTEXT_ID } from 'state/constants'
import { auth } from 'state/session/reducers'
import { restrictedCredentials } from 'state/credentials/reducers'
import { ConfirmationModalRecord } from 'records'

import {
  pendingResourceTypes,
  resourceTypes,
  collectionResources,
  collectionRelations,
  expandedCollectionLimit,
  expandedCollections,
  currentResource,
  currentResourceGroup,
  propagatedPolicies,
  attachedPolicies,
  latestInvocations,
  applicablePolicies,
  applicablePoliciesDetailsFetched,
  relatedResources,
  propagatedPolicyValidation,
  highlightedResourceGroup,
  policyInvocations,
  currentCoveragePolicyType,
  currentPolicyTypeId
} from 'state/resources/reducers'
import {
  managedWorkers,
  validation,
  resourceProviders,
  solutionRuntimes,
  roles,
  clients,
  createdClient,
  externalNotificationEndpoints,
  externalNotificationFilters,
  externalNotificationFilterConfigurationValidation
} from 'state/organization/reducers'

const defaultSolutionContext = {
  [DEFAULT_SOLUTION_CONTEXT_ID]: {
    id: DEFAULT_SOLUTION_CONTEXT_ID,
    name: 'all_solutions',
    label: 'All Solutions',
    description: 'The default context that displays all metrics and policies for all solutions.',
    resource_types: [],
    metric_types: []
  },
  [NO_SOLUTION_CONTEXT_ID]: {
    id: NO_SOLUTION_CONTEXT_ID,
    name: 'no_solutions',
    label: 'No Solutions',
    description: 'Displays no metrics and no policy types for any solution.',
    resource_types: [],
    metric_types: []
  }
}

const inProgressCount = (state = 0, action) => {
  switch (action.type) {
    case actions.START_PROGRESS:
      return state + 1
    case actions.COMPLETE_PROGRESS:
      return Math.max(state - 1, 0)
    default:
      return state
  }
}

const normalizedResources = (state = Map(), action) => {
  switch (action.type) {
    case actions.STORE_RESOURCES:
      return fromJS(action.resources).reduce((state, resource) => {
        return state.set(resource.get('id'), resource)
      }, state)
    default:
      return state
  }
}

const initialDatapoints = Map({
  isFetched: false,
  datapoints: Map()
})

const datapoints = (state = initialDatapoints, action) => {
  switch (action.type) {
    case actions.STORE_DATAPOINTS:
      return state
        .set('datapoints', state.get('datapoints').mergeDeep(action.datapointsByMetricAndResource))
        .set('isFetched', true)
    case actions.BEGIN_DATAPOINT_FETCHING:
      return state.set('isFetched', false)
    default:
      return state
  }
}

const normalizedRelations = (state = Map(), action) => {
  switch (action.type) {
    case actions.STORE_RELATIONS:
      return fromJS(action.relations).reduce((state, edge) => {
        return state.set(edge.get('id'), edge)
      }, state)
    default:
      return state
  }
}

const initialCollectionSets = Map({
  isFetched: false,
  collectionSets: Map()
})

const normalizedCollectionSets = (state = initialCollectionSets, action) => {
  switch (action.type) {
    case actions.STORE_COLLECTION_SET:
      const oldstate = state.getIn(['collectionSets', action.collectionSetID], Map())
      const newstate = oldstate.merge(action.collectionSet)
      if (oldstate.equals(newstate)) {
        return state
          .set('isFetched', true)
      }
      return state
        .setIn(['collectionSets', action.collectionSetID], newstate)
        .set('isFetched', true)
    case actions.BEGIN_COLLECTIONS_FETCHING:
      return state.set('isFetched', false)
    default:
      return state
  }
}

const collectionSolutionContextData = (state = Map(), action) => {
  switch (action.type) {
    case actions.STORE_COLLECTION_SOLUTION_CONTEXT_DATA:
      const oldstate = state.getIn([action.collectionSetID, action.solutionContextId], Map())
      const newstate = oldstate.merge(action.collectionSolutionContextData)
      if (oldstate.equals(newstate)) {
        return state
      }
      return state.setIn([action.collectionSetID, action.solutionContextId], newstate)
    default:
      return state
  }
}

const queryCollectionSetID = (state = null, action) => {
  switch (action.type) {
    case actions.SET_QUERY_COLLECTION_SET:
      return action.collectionSetID
    default:
      return state
  }
}

const currentQuery = (state = null, action) => {
  switch (action.type) {
    case actions.NOTIFY_QUERY_CHANGED:
      return action.query
    default:
      return state
  }
}

const activeResourceGroupId = (state = null, action) => {
  switch (action.type) {
    case actions.NOTIFY_ACTIVE_RESOURCE_GROUP_ID_CHANGED:
      return action.resourceGroupId
    default:
      return state
  }
}

const enableTransitions = (state = process.env.NODE_ENV !== 'test', action) => {
  switch (action.type) {
    case actions.NOTIFY_ENABLE_TRANSITIONS_CHANGED:
      return action.enableTransitions
    default:
      return state
  }
}

const availableGroups = (state = List(), action) => {
  switch (action.type) {
    case actions.UPDATE_AVAILABLE_GROUPS:
      return fromJS(action.availableGroups)
    case actions.ADD_AVAILABLE_GROUP:
      return state.push(fromJS(action.availableGroup))
    default:
      return state
  }
}

const groupsCompliance = (state = Map(), action) => {
  switch (action.type) {
    case actions.UPDATE_GROUPS_COMPLIANCE:
      return fromJS(action.groupsCompliance)
    default:
      return state
  }
}

const contributedPolicies = (state = Map(), action) => {
  switch (action.type) {
    case actions.UPDATE_CONTRIBUTED_POLICIES:
      return state.set(action.policyId, fromJS(action.contributedPolicies))
    default:
      return state
  }
}

const ancestralPolicies = (state = Map(), action) => {
  switch (action.type) {
    case actions.UPDATE_ANCESTRAL_POLICIES:
      return state.set(action.policyId, fromJS(action.ancestralPolicies))
    default:
      return state
  }
}

const normalizedResourceGroups = (state = Map(), action) => {
  switch (action.type) {
    case actions.UPDATE_NORMALIZED_RESOURCE_GROUPS:
      return state.merge(fromJS(action.resourceGroups)
        .map(group => [group.get('id'), group]))
    default:
      return state
  }
}

const containingResourceGroups = (state = Map(), action) => {
  switch (action.type) {
    case actions.UPDATE_CONTAINING_RESOURCE_GROUPS:
      return state.set(
        action.resourceId,
        fromJS(action.resourceGroups).map(group => group.get('id'))
      )
    default:
      return state
  }
}

const users = (state = Map(), action) => {
  switch (action.type) {
    case actions.UPDATE_USERS:
      return fromJS(action.users)
        .reduce((acc, el) => acc.set(el.get('id'), el), Map().asMutable()).asImmutable()
    default:
      return state
  }
}

const organizationsState = JSON.parse(window.localStorage.getItem('organizations')) || []

const availableOrganizations = (state = fromJS(organizationsState), action) => {
  switch (action.type) {
    case actions.UPDATE_AVAILABLE_ORGANIZATIONS:
      return fromJS(action.organizations)
    default:
      return state
  }
}

const currentOrganizationState = window.localStorage.getItem('organizationId') || ''

const currentOrganization = (state = currentOrganizationState, action) => {
  switch (action.type) {
    case actions.NOTIFY_ORGANIZATION_CHANGED:
      return action.organizationId
    default:
      return state
  }
}

const policyTypes = (state = List(), action) => {
  switch (action.type) {
    case actions.UPDATE_POLICY_TYPES:
      return fromJS(action.policyTypes).sortBy(policyType => policyType.get('label'))
    default:
      return state
  }
}

const availableSolutionContexts = (state = fromJS(defaultSolutionContext), action) => {
  switch (action.type) {
    case actions.UPDATE_AVAILABLE_SOLUTION_CONTEXTS:
      return fromJS(defaultSolutionContext).merge(action.availableSolutionContexts)
    default:
      return state
  }
}

const currentSolutionContextState = window.localStorage.getItem('solutionContext') || DEFAULT_SOLUTION_CONTEXT_ID

const currentSolutionContextId = (state = currentSolutionContextState, action) => {
  switch (action.type) {
    case actions.NOTIFY_SOLUTION_CONTEXT_CHANGED:
      return action.solutionContextId
    default:
      return state
  }
}

const normalizedMetrics = (state = Map(), action) => {
  switch (action.type) {
    case actions.STORE_METRICS:
      return fromJS(action.metrics)
    default:
      return state
  }
}

const resourcesVisualizationOptions = (state = new actions.ResourcesVisualizationOptions(), action) => {
  switch (action.type) {
    case actions.UPDATE_RESOURCES_VISUALIZATION_OPTIONS:
      return action.options
    default:
      return state
  }
}

const resourceGroupsVisualizationOptions = (state = new actions.ResourceGroupsVisualizationOptions(), action) => {
  switch (action.type) {
    case actions.UPDATE_RESOURCE_GROUPS_VISUALIZATION_OPTIONS:
      return action.options
    default:
      return state
  }
}

const notifications = (state = OrderedMap(), action) => {
  switch (action.type) {
    case actions.UPDATE_NOTIFICATIONS:
      return action.notifications
    case actions.UPDATE_NOTIFICATION:
      return state.set(action.notification['id'], fromJS(action.notification))
    default:
      return state
  }
}

const notificationCount = (state = 0, action) => {
  switch (action.type) {
    case actions.UPDATE_NOTIFICATION_COUNT:
      return action.notificationCount
    default:
      return state
  }
}

const unreadNotifications = (state = Map(), action) => {
  switch (action.type) {
    case actions.UPDATE_UNREAD_NOTIFICATIONS:
      return state.mergeDeep(action.unreadNotifications)
    case actions.UPDATE_NOTIFICATION:
      if (action.notification['read']) {
        return state.delete(action.notification['id'])
      }
      return state.set(action.notification['id'], fromJS(action.notification))
    default:
      return state
  }
}

const unreadNotificationCount = (state = 0, action) => {
  switch (action.type) {
    case actions.UPDATE_UNREAD_NOTIFICATION_COUNT:
      return action.unreadNotificationCount
    case actions.INCREMENT_UNREAD_NOTIFICATION_COUNT:
      const incrementState = state += 1
      return incrementState
    case actions.DECREMENT_UNREAD_NOTIFICATION_COUNT:
      const decrementState = state -= 1
      return decrementState
    default:
      return state
  }
}

const poll = (state = Map(), action) => {
  switch (action.type) {
    case actions.POLL:
      return state.merge({[action.id]: true})
    default:
      return state
  }
}

const resourcesFilter = (state = '', action) => {
  switch (action.type) {
    case actions.SET_RESOURCES_FILTER:
      return action.resourcesFilter
    default:
      return state
  }
}

const confirmationModal = (state = new ConfirmationModalRecord(), action) => {
  switch (action.type) {
    case actions.SHOW_CONFIRMATION:
      return new ConfirmationModalRecord({
        title: action.title,
        description: action.description,
        callback: action.callback,
        disabled: action.disabled,
        visible: true,
        enableInput: action.enableInput
      })
    case actions.HIDE_CONFIRMATION:
      return new ConfirmationModalRecord()
    default:
      return state
  }
}

const docs = (state = {}, action) => {
  switch (action.type) {
    case actions.UPDATE_API_DOCS:
      return action.docs
    default:
      return state
  }
}

const availableSolutions = (state = Map(), action) => {
  switch (action.type) {
    case actions.UPDATE_AVAILABLE_SOLUTIONS:
      return fromJS(action.availableSolutions)
        .reduce((acc, el) => acc.set(el.get('id'), el), Map().asMutable()).asImmutable()
    default:
      return state
  }
}

/**
 * This reducer was taken directly from the abandoned repo
 * at https://github.com/cloudreach/re-notif/blob/master/src/reducer.js
 *
 */
const notifs = (domain = [], action) => {
  if (!action || !action.type) return domain;

  switch (action.type) {
    case actions.NOTIF_SEND:
      return [action.payload, ...domain.filter(({ id }) => id !== action.payload.id)];
    case actions.NOTIF_DISMISS:
      return domain.filter(notif =>
          notif.id !== action.payload
      );
    case actions.NOTIF_CLEAR:
      return [];
    default:
      return domain;
  }
}

export default {
  auth,
  restrictedCredentials,
  confirmationModal,
  inProgressCount,
  normalizedResources,
  datapoints,
  normalizedRelations,
  normalizedCollectionSets,
  collectionSolutionContextData,
  queryCollectionSetID,
  availableSolutionContexts,
  availableSolutions,
  availableGroups,
  groupsCompliance,
  currentSolutionContextId,
  currentQuery,
  collectionResources,
  collectionRelations,
  expandedCollectionLimit,
  expandedCollections,
  currentResource,
  currentResourceGroup,
  activeResourceGroupId,
  enableTransitions,
  notificationCount,
  unreadNotifications,
  unreadNotificationCount,
  pendingResourceTypes,
  resourceTypes,
  resourceProviders,
  solutionRuntimes,
  roles,
  clients,
  createdClient,
  currentPolicyTypeId,
  policyTypes,
  propagatedPolicies,
  attachedPolicies,
  latestInvocations,
  policyInvocations,
  applicablePolicies,
  applicablePoliciesDetailsFetched,
  propagatedPolicyValidation,
  contributedPolicies,
  ancestralPolicies,
  normalizedResourceGroups,
  containingResourceGroups,
  relatedResources,
  users,
  availableOrganizations,
  currentOrganization,
  validation,
  normalizedMetrics,
  resourcesVisualizationOptions,
  resourceGroupsVisualizationOptions,
  notifications,
  poll,
  resourcesFilter,
  managedWorkers,
  highlightedResourceGroup,
  externalNotificationEndpoints,
  externalNotificationFilters,
  externalNotificationFilterConfigurationValidation,
  currentCoveragePolicyType,
  docs,
  notifs,
}
