import apiRequest from 'apiRequest'
import {
  executeWithProgress,
  fetchResourceGroups,
  setActiveResourceGroup,
  notifyUser
} from 'state/actions'
import { indexByUniqueKey, indexByUniqueKeyOrderImmutable } from 'services/indexing'

export const UPDATE_MANAGED_WORKERS = 'UPDATE_MANAGED_WORKERS'
export const UPDATE_VALIDATION = 'UPDATE_VALIDATION'
export const UPDATE_RESOURCE_PROVIDERS = 'UPDATE_RESOURCE_PROVIDERS'
export const UPDATE_SOLUTION_RUNTIMES = 'UPDATE_SOLUTION_RUNTIMES'
export const UPDATE_ROLES = 'UPDATE_ROLES'
export const UPDATE_CLIENTS = 'UPDATE_CLIENTS'
export const UPDATE_CREATED_CLIENT = 'UPDATE_CREATED_CLIENT'
export const UPDATE_EXTERNAL_NOTIFICATION_ENDPOINTS = 'UPDATE_EXTERNAL_NOTIFICATION_ENDPOINTS'
export const UPDATE_EXTERNAL_NOTIFICATION_FILTERS = 'UPDATE_EXTERNAL_NOTIFICATION_FILTERS'
export const UPDATE_EXTERNAL_NOTIFICATION_FILTER_CONFIGURATION_VALIDATION = 'UPDATE_EXTERNAL_NOTIFICATION_FILTER_CONFIGURATION_VALIDATION'

export function updateManagedWorkers (managedWorkers) {
  return {
    type: UPDATE_MANAGED_WORKERS,
    managedWorkers
  }
}

export function fetchManagedWorkers () {
  return dispatch => {
    return apiRequest(dispatch, 'GET', 'managed_workers/')
      .then(managedWorkers => {
        dispatch(updateManagedWorkers(managedWorkers.body.results))
        return null
      })
  }
}

export function createResourceProvider (fields) {
  return (dispatch, getState) => {
    return apiRequest(dispatch, 'POST', `resource_providers/`)
      .send(fields)
      .then((response) => {
        dispatch(notifyUser(`Created Resource Provider`, 'success'))
        return dispatch(fetchResourceProviders())
      })
      .catch(error => {
        dispatch(notifyUser(`Error creating Resource Provider`, 'error'))
        throw error
      })
  }
}

export function updateResourceProvider (id, fields) {
  return (dispatch, getState) => {
    return apiRequest(dispatch, 'PATCH', `resource_providers/${id}/`)
      .send(fields)
      .then((response) => {
        dispatch(notifyUser(`Updated Resource Provider`, 'success'))
        return dispatch(fetchResourceProviders())
      })
      .catch(error => {
        dispatch(notifyUser(`Error updating Resource Provider`, 'error'))
        throw error
      })
  }
}

export function updateResourceProviders (accounts) {
  return {
    type: UPDATE_RESOURCE_PROVIDERS,
    accounts
  }
}

export function deleteResourceProvider (id) {
  return executeWithProgress(dispatch => {
    return apiRequest(dispatch, 'DELETE', `resource_providers/${id}/`)
      .then((accounts) => {
        dispatch(notifyUser(`Deleted resource provider`, 'success'))
        dispatch(fetchResourceProviders())
        return null
      })
  })
}

export function fetchResourceProviders () {
  return (dispatch, getState) => {
    return apiRequest(dispatch, 'GET', 'resource_providers/')
      .then((accounts) => {
        const indexedAccounts = accounts.body.results.map((account, i) => ({ ...account, index: i }))
        const normalizedAccounts = indexByUniqueKey(indexedAccounts, 'id')
        dispatch(updateResourceProviders(normalizedAccounts))
        return null
      })
  }
}

export function updateSolutionRuntimes (environments) {
  return {
    type: UPDATE_SOLUTION_RUNTIMES,
    environments
  }
}

export function fetchSolutionRuntimes () {
  return (dispatch, getState) => {
    return apiRequest(dispatch, 'GET', 'solution_runtimes/')
      .then((environments) => {
        const indexedEnvironments = environments.body.results.map((environment, i) => ({ ...environment, index: i }))
        const normalizedEnvironments = indexByUniqueKey(indexedEnvironments, 'id')
        dispatch(updateSolutionRuntimes(normalizedEnvironments))
        return null
      })
  }
}

export function deleteSolutionRuntime (id) {
  return (dispatch, getState) => {
    return apiRequest(dispatch, 'DELETE', `solution_runtimes/${id}/`)
      .then((environments) => {
        dispatch(notifyUser(`Deleted solution runtime`, 'success'))
        dispatch(fetchSolutionRuntimes())
        return null
      })
  }
}

export function updateSolutionRuntime (id, fields) {
  return (dispatch, getState) => {
    return apiRequest(dispatch, 'PATCH', `solution_runtimes/${id}/`)
      .send(fields)
      .then((response) => {
        dispatch(notifyUser(`Updated solution runtime`, 'success'))
        return dispatch(fetchSolutionRuntimes())
      })
      .catch(error => {
        dispatch(notifyUser(`Error updating solution runtime`, 'error'))
        throw error
      })
  }
}

export function createSolutionRuntime (fields) {
  return (dispatch, getState) => {
    return apiRequest(dispatch, 'POST', `solution_runtimes/`)
      .send(fields)
      .then((response) => {
        dispatch(notifyUser(`Created Solution Runtime`, 'success'))
        return dispatch(fetchSolutionRuntimes())
      })
      .catch(error => {
        dispatch(notifyUser(`Error creating Solution Runtime`, 'error'))
        throw error
      })
  }
}

export function updateRoles (roles) {
  return {
    type: UPDATE_ROLES,
    roles
  }
}

export function fetchRoles () {
  return (dispatch, getState) => {
    return apiRequest(dispatch, 'GET', 'roles/')
      .then(response => {
        const indexedRoles = response.body.results.map((role, i) => ({ ...role, index: i }))
        const normalizedRoles = indexByUniqueKey(indexedRoles, 'id')
        return dispatch(updateRoles(normalizedRoles))
      })
  }
}

export function deleteRole (id) {
  return (dispatch, getState) => {
    return apiRequest(dispatch, 'DELETE', `roles/${id}/`)
      .then((response) => {
        dispatch(notifyUser(`Deleted role`, 'success'))
        return dispatch(fetchRoles())
      })
  }
}

export function updateRole (id, fields) {
  return (dispatch, getState) => {
    return apiRequest(dispatch, 'PATCH', `roles/${id}/`)
      .send(fields)
      .then((response) => {
        dispatch(notifyUser(`Updated role`, 'success'))
        return dispatch(fetchRoles())
      })
      .catch(error => {
        dispatch(notifyUser(`Error updating role`, 'error'))
        throw error
      })
  }
}

export function createRole (fields) {
  return (dispatch, getState) => {
    return apiRequest(dispatch, 'POST', `roles/`)
      .send(fields)
      .then((response) => {
        dispatch(notifyUser(`Created role`, 'success'))
        return dispatch(fetchRoles())
      })
      .catch(error => {
        dispatch(notifyUser(`Error creating role`, 'error'))
        throw error
      })
  }
}

export function updateClients (clients) {
  return {
    type: UPDATE_CLIENTS,
    clients
  }
}

export function fetchClients () {
  return (dispatch, getState) => {
    return apiRequest(dispatch, 'GET', 'non_interactive_clients/')
      .then(response => {
        const indexedClients = response.body.results.map((client, i) => ({ ...client, index: i }))
        const normalizedClients = indexByUniqueKey(indexedClients, 'id')
        return dispatch(updateClients(normalizedClients))
      })
  }
}

export function deleteClient (id) {
  return (dispatch, getState) => {
    return apiRequest(dispatch, 'DELETE', `non_interactive_clients/${id}/`)
      .then((response) => {
        dispatch(notifyUser(`Deleted non-interactive client`, 'success'))
        return dispatch(fetchClients())
      })
  }
}

export function createClient (fields) {
  return (dispatch, getState) => {
    return apiRequest(dispatch, 'POST', `non_interactive_clients/`)
      .send(fields)
      .then((response) => {
        dispatch(notifyUser(`Created non-interactive client`, 'success'))
        dispatch(updateCreatedClient(response.body))
        return dispatch(fetchClients())
      })
      .catch(error => {
        dispatch(notifyUser(`Error creating non-interactive client`, 'error'))
        throw error
      })
  }
}

export function updateCreatedClient (client) {
  return {
    type: UPDATE_CREATED_CLIENT,
    client
  }
}

export function createExternalNotificationEndpoint (fields) {
  return (dispatch, getState) => {
    return apiRequest(dispatch, 'POST', `external_notification_endpoints/`)
      .send(fields)
      .then((response) => {
        dispatch(notifyUser(`Created external notification endpoint`, 'success'))
        return dispatch(fetchExternalNotificationEndpoints())
      })
      .catch(error => {
        dispatch(notifyUser(`Error creating external notification endpoint`, 'error'))
        throw error
      })
  }
}

export function updateExternalNotificationEndpoint (id, fields) {
  return (dispatch, getState) => {
    return apiRequest(dispatch, 'PATCH', `external_notification_endpoints/${id}/`)
      .send(fields)
      .then((response) => {
        dispatch(notifyUser(`Updated external notification endpoint`, 'success'))
        return dispatch(fetchExternalNotificationEndpoints())
      })
      .catch(error => {
        dispatch(notifyUser(`Error updating external notification endpoint`, 'error'))
        throw error
      })
  }
}

export function deleteExternalNotificationEndpoint (id) {
  return (dispatch, getState) => {
    return apiRequest(dispatch, 'DELETE', `external_notification_endpoints/${id}/`)
      .then((response) => {
        dispatch(notifyUser(`Deleted external notification endpoint`, 'success'))
        return dispatch(fetchExternalNotificationEndpoints())
      })
      .then((response) => {
        return dispatch(fetchExternalNotificationFilters())
      })
  }
}

export function updateExternalNotificationEndpoints (endpoints) {
  return {
    type: UPDATE_EXTERNAL_NOTIFICATION_ENDPOINTS,
    endpoints
  }
}

export function fetchExternalNotificationEndpoints () {
  return (dispatch, getState) => {
    return apiRequest(dispatch, 'GET', 'external_notification_endpoints/')
      .then((endpoints) => {
        const normalizedEndpoints = indexByUniqueKeyOrderImmutable(endpoints.body.results, 'id')
        dispatch(updateExternalNotificationEndpoints(normalizedEndpoints))
      })
  }
}

export function updateExternalNotificationFilters (filters) {
  return {
    type: UPDATE_EXTERNAL_NOTIFICATION_FILTERS,
    filters
  }
}

export function fetchExternalNotificationFilters () {
  return (dispatch, getState) => {
    return apiRequest(dispatch, 'GET', 'external_notification_filters/')
      .then((filters) => {
        const normalizedFilters = indexByUniqueKeyOrderImmutable(filters.body.results, 'id')
        dispatch(updateExternalNotificationFilters(normalizedFilters))
      })
  }
}

export function createExternalNotificationFilter (fields) {
  return (dispatch, getState) => {
    return apiRequest(dispatch, 'POST', `external_notification_filters/`)
      .send(fields)
      .then((response) => {
        dispatch(notifyUser(`Created external notification filter`, 'success'))
        return dispatch(fetchExternalNotificationFilters())
      })
      .catch(error => {
        dispatch(notifyUser(`Error creating external notification filter`, 'error'))
        throw error
      })
  }
}

export function updateExternalNotificationFilter (id, fields) {
  return (dispatch, getState) => {
    return apiRequest(dispatch, 'PATCH', `external_notification_filters/${id}/`)
      .send(fields)
      .then((response) => {
        dispatch(notifyUser(`Updated external notification filter`, 'success'))
        return dispatch(fetchExternalNotificationFilters())
      })
      .catch(error => {
        dispatch(notifyUser(`Error updating external notification filter`, 'error'))
        throw error
      })
  }
}

export function deleteExternalNotificationFilter (id) {
  return (dispatch, getState) => {
    return apiRequest(dispatch, 'DELETE', `external_notification_filters/${id}/`)
      .then((response) => {
        dispatch(notifyUser(`Deleted external notification filter`, 'success'))
        return dispatch(fetchExternalNotificationFilters())
      })
  }
}

export function validateExternalNotificationFilterConfiguration (configuration) {
  return (dispatch, getState) => {
    dispatch(updateExternalNotificationFilterConfigurationValidation({
      'errors': ''
    }))

    return apiRequest(dispatch, 'POST', `external_notification_filters/validate_filter_query/`)
      .type('application/json')
      .send({'configuration': configuration})
      .then((response) => dispatch(updateExternalNotificationFilterConfigurationValidation(response.body)))
      .catch((error) => dispatch(updateExternalNotificationFilterConfigurationValidation({'errors': String(error.message)})))
  }
}

function updateExternalNotificationFilterConfigurationValidation (configurationValidation) {
  return {
    type: UPDATE_EXTERNAL_NOTIFICATION_FILTER_CONFIGURATION_VALIDATION,
    configurationValidation
  }
}

export function updateResourceGroup (id, fields) {
  return executeWithProgress(dispatch => {
    return apiRequest(dispatch, 'PATCH', `resource_groups/${id}/`)
      .send(fields)
      .then(() => {
        dispatch(notifyUser(`Updated resource group`, 'success'))
        return dispatch(fetchResourceGroups())
      })
      .catch(error => {
        dispatch(notifyUser(`Error updating resource group`, 'error'))
        throw error
      })
  })
}

export function deleteResourceGroup (id) {
  return (dispatch, getState) => {
    return apiRequest(dispatch, 'DELETE', `resource_groups/${id}/`)
      .then(response => {
        if (id === getState().activeResourceGroupId) {
          dispatch(setActiveResourceGroup())
        }
        dispatch(executeWithProgress(fetchResourceGroups()))
        return null
      })
  }
}
