import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import ImmutablePropTypes from 'react-immutable-proptypes'
import classNames from 'classnames'
import { Set } from 'immutable'
import FontAwesome from 'react-fontawesome'

import complianceColorScheme from 'scenes/Resources/complianceColors'
import style from './ResourceGroupTree.styl'

import {
  showConfirmationModal,
  setActiveResourceGroup,
  READONLY_POLICY
} from 'state/actions'
import { getResourceGroupTree } from 'state/selectors'
import { deleteResourceGroup } from 'state/organization/actions'
import Button from 'components/Button'

export class ResourceGroupTree extends React.Component {
  componentWillMount () {
    if (this.props.onInitialize) {
      this.props.onInitialize()
    }

    this.state = {
      hiddenNodes: Set()
    }
  }

  deleteGroup (group) {
    const groupName = group.name

    const confirmation = <span>
      <p>This will permanently delete <b>{groupName}</b>.</p>
      <br />
      <p>Please confirm the name of the group below to continue.</p>
    </span>

    this.props.showConfirmationModal(
      'Delete Resource Group',
      confirmation,
      () => this.props.onGroupDelete(group.id),
      input => input !== groupName
    )
  }

  _toggle (nodeId) {
    if (this.state.hiddenNodes.has(nodeId)) {
      this.setState({
        hiddenNodes: this.state.hiddenNodes.delete(nodeId)
      })
    } else {
      this.setState({
        hiddenNodes: this.state.hiddenNodes.add(nodeId)
      })
    }
  }

  _nodeToTable (node, depth) {
    let childNodes = []
    let toggle = null

    const isRoot = node.ancestors && node.ancestors.length === 0

    if (node.children && node.children.length > 0) {
      const nodeHidden = this.state.hiddenNodes.has(node.id)
      if (!nodeHidden) {
        childNodes = node.children.map(child => this._nodeToTable(child, depth + 1))
      }
      toggle = (
        <span
          className={style.toggle}
          onClick={event => {
            this._toggle(node.id)
            event.stopPropagation()
          }}>
          {nodeHidden ? '▶' : '▼'}
        </span>
      )
    }

    const nodeQuery = node.query || ''
    const nodeStyle = { 'paddingLeft': `${depth + 1}rem` }
    const editButton = isRoot || this.props.organizationRole === READONLY_POLICY ? null : (
      <Button
        onClick={event => {
          if (this.props.onGroupEdit) {
            this.props.onGroupEdit(node)
          }
          event.stopPropagation()
        }}
        className={style.edit}
        flat>
        <FontAwesome name='pencil' />
      </Button>
    )
    const deleteButton = (node.children && node.children.length > 0) || isRoot || this.props.organizationRole === READONLY_POLICY ? null : (
      <Button
        onClick={() => this.deleteGroup(node)}
        className={style.delete}
        flat>
        <FontAwesome name='trash' />
      </Button>
    )

    const className = classNames({
      [style.active]: this.props.activeResourceGroupId === node.id
    })

    const complianceClassName = classNames(style.compliance, {
      [style.deEmphasized]: !hasComplianceInfo(node)
    })
    let complianceSummary, complianceStyle
    if (typeof node.complianceLevel !== 'undefined') {
      complianceSummary = `${Math.floor(node.complianceLevel * 100)}%`
      const complianceLevel = Math.floor(node.complianceLevel * 10)
      complianceStyle = { backgroundColor: complianceColorScheme[complianceLevel] }
    } else {
      complianceSummary = 'n/a'
      complianceStyle = {}
    }

    childNodes.unshift(
      <tr
        key={node.id}
        className={className}
        onClick={() => this.props.onGroupSelect && this.props.onGroupSelect(node.id)}>
        <td style={nodeStyle}>
          {toggle}
          {node.name}
        </td>
        <td className={style.queryCell}>{nodeQuery}</td>
        <td className={complianceClassName}><div style={complianceStyle} />{complianceSummary}</td>
        <td>
          {editButton}
        </td>
        <td>
          {deleteButton}
        </td>
      </tr>
    )
    return childNodes
  }

  render () {
    if (!this.props.resourceGroupTree.size) {
      return null
    }

    const groupTableContents = this._nodeToTable(this.props.resourceGroupTree.toJS(), 0)
    return (
      <div className={style.resourceGroups}>
        <table>
          <thead>
            <tr>
              <th className={style.names}>Name</th>
              <th className={style.queries}>Query</th>
              <th>Compliance</th>
              <th />
              <th />
            </tr>
          </thead>
          <tbody>{groupTableContents}</tbody>
        </table>
      </div>
    )
  }
}

ResourceGroupTree.defaultProps = {
  organizationRole: 0
}

ResourceGroupTree.propTypes = {
  activeResourceGroupId: PropTypes.string,
  resourceGroupTree: ImmutablePropTypes.map,
  onInitialize: PropTypes.func,
  onGroupEdit: PropTypes.func,
  onGroupDelete: PropTypes.func,
  onGroupSelect: PropTypes.func,
  showConfirmationModal: PropTypes.func.isRequired,
  organizationRole: PropTypes.number.isRequired
}

function mapStateToProps (state) {
  return {
    activeResourceGroupId: state.activeResourceGroupId,
    resourceGroupTree: getResourceGroupTree(state)
  }
}

function hasComplianceInfo (node) {
  return typeof node.complianceLevel !== 'undefined'
}

function mapDispatchToProps (dispatch) {
  return {
    onGroupDelete: id => dispatch(deleteResourceGroup(id)),
    onGroupSelect: id => dispatch(setActiveResourceGroup(id)),
    showConfirmationModal: (title, description, callback, disabled) => dispatch(showConfirmationModal(title, description, callback, disabled, true))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResourceGroupTree)
