import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { fromJS, List } from 'immutable'

import FontAwesome from 'react-fontawesome'

import { ADMIN_POLICY } from 'state/actions'
import Button from 'components/Button'

import style from './ExternalNotificationFiltersList.styl'

export class ExternalNotificationFiltersList extends React.Component {
  deleteFilter (filter) {
    const filterName = filter.get('name')

    const confirmation = <span>
      <p>This will permanently delete <b>{filterName}</b>.</p>
      <br />
      <p>Please confirm the name of the filter below to continue.</p>
    </span>

    this.props.onConfirmation(
      'Delete External Notification Filter',
      confirmation,
      () => this.props.onDelete(filter.get('id')),
      input => input !== filterName
    )
  }

  listFilters (filters) {
    if (!filters || !filters.size) {
      return (<tr><td>No External Notification Filters</td></tr>)
    }

    return filters.map(filter => {
      const endpoints = fromJS(filter.get('endpoints', List())).join(', ')
      return (
        <tr key={filter.get('id')}>
          <td>{filter.get('name')}</td>
          <td>{endpoints || this._notAvailable()}</td>
          <td>{filter.get('filter_query')}</td>
          {this.props.organizationRole === ADMIN_POLICY ? (
            <td>
              <Button
                onClick={() => this.props.onShowEditModal(filter)}
                className={style.edit}
                flat>
                <FontAwesome
                  name='pencil'
                  className='fa-align-right' />
              </Button>
            </td>
          ) : null}
          {this.props.organizationRole === ADMIN_POLICY ? (
            <td>
              <Button
                onClick={() => this.deleteFilter(filter)}
                type={Button.WARN}
                className={style.delete}
                flat>
                <FontAwesome name='trash' />
              </Button>
            </td>
          ) : null}
        </tr>
      )
    })
  }

  _notAvailable () {
    return (
      <div className={style.notAvailable}>N/A</div>
    )
  }

  render () {
    return (
      <div>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Endpoints</th>
              <th>Configuration</th>
              {this.props.organizationRole === ADMIN_POLICY ? <th /> : null}
              {this.props.organizationRole === ADMIN_POLICY ? <th /> : null}
            </tr>
          </thead>
          <tbody>
            {this.listFilters(this.props.filters.valueSeq())}
          </tbody>
        </table>
      </div>
    )
  }
}

ExternalNotificationFiltersList.propTypes = {
  filters: ImmutablePropTypes.map.isRequired,
  onDelete: PropTypes.func.isRequired,
  onConfirmation: PropTypes.func.isRequired,
  onShowEditModal: PropTypes.func.isRequired,
  organizationRole: PropTypes.number.isRequired
}

export default ExternalNotificationFiltersList
