import PropTypes from 'prop-types'
import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import FontAwesome from 'react-fontawesome'
import { fromJS, List } from 'immutable'

import { ADMIN_POLICY } from 'state/actions'
import { POLICIES } from 'scenes/Manage'
import Button from 'components/Button'

import style from './RolesList.styl'

export class RolesList extends React.Component {
  deleteRole (role) {
    const roleName = role.get('name')

    const confirmation = <span>
      <p>This will permanently delete <b>{roleName}</b>.</p>
      <br />
      <p>Please confirm the name of the role below to continue.</p>
    </span>

    this.props.onConfirmation(
      'Delete Role',
      confirmation,
      () => this.props.onDelete(role.get('id')),
      input => input !== roleName
    )
  }

  listRoles (roles) {
    if (!roles || !roles.size) {
      return (<tr><td>No Roles</td></tr>)
    }

    return roles.map(role => {
      const groups = fromJS(role.get('idp_groups', List())).join(', ')
      return (
        <tr key={role.get('index')}>
          <td>{role.get('name')}</td>
          <td>{POLICIES[role.get('policy')] || 'Invalid'}</td>
          <td>{role.get('auth0_connection')}</td>
          <td>{groups}</td>
          {this.props.organizationRole === ADMIN_POLICY ? (
            <td>
              <Button
                onClick={() => this.props.onShowEditModal(role)}
                className={style.edit}
                flat>
                <FontAwesome
                  name='pencil'
                  className='fa-align-right' />
              </Button>
            </td>
          ) : null}
          {this.props.organizationRole === ADMIN_POLICY ? (
            <td>
              <Button
                onClick={() => this.deleteRole(role)}
                type={Button.WARN}
                className={style.delete}
                flat>
                <FontAwesome name='trash' />
              </Button>
            </td>
          ) : null}
        </tr>
      )
    })
  }

  render () {
    return (
      <div>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Policy</th>
              <th>Auth0 Connection</th>
              <th>IDP Groups</th>
              {this.props.organizationRole === ADMIN_POLICY ? <th /> : null}
              {this.props.organizationRole === ADMIN_POLICY ? <th /> : null}
            </tr>
          </thead>
          <tbody>
            {this.listRoles(this.props.roles.valueSeq().sortBy(v => v.get('name')))}
          </tbody>
        </table>
      </div>
    )
  }
}

RolesList.propTypes = {
  roles: ImmutablePropTypes.map.isRequired,
  onDelete: PropTypes.func.isRequired,
  onConfirmation: PropTypes.func.isRequired,
  onShowEditModal: PropTypes.func.isRequired,
  organizationRole: PropTypes.number.isRequired
}

export default RolesList
