import classNames from 'classnames'
import { List } from 'immutable'
import PropTypes from 'prop-types'
import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import {connect} from 'react-redux'

import style from './QueryContext.styl'

import Dropdown from 'components/Dropdown'
import LoadingIndicator from 'components/LoadingIndicator'

import { setActiveResourceGroup } from 'state/actions'
import { getActiveResourceGroup, getResourceGroupTree } from 'state/selectors'
import { QUERY_BUILDER_RESOURCE_GROUP } from 'refs'

export class QueryContext extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      dropdownActive: false,
      dropdownAnchor: null,
      collapsedGroups: List()
    }
  }

  render () {
    const sectionElements = this.props.resourceGroups && this.props.resourceGroups.size
      ? this._renderSections(this.props.resourceGroups, this.props.activeResourceGroup.get('id'))
      : this._renderLoadingSections()

    const dropdown = this.state.dropdownActive && (<Dropdown
      anchor={this.state.dropdownAnchor}
      onDismiss={() => this._dismissDropdown()}>
      {this._createResourceGroupListElement(this.state.collapsedGroups)}
    </Dropdown>)

    return (
      <div data-ref={QUERY_BUILDER_RESOURCE_GROUP} className={classNames(style.QueryContext, this.props.className)}>
        {sectionElements}
        {dropdown}
      </div>
    )
  }

  _renderSections (resourceGroups, activeResourceGroupId) {
    const resourceGroupsPath = findPath(resourceGroups, resourceGroup => {
      return resourceGroup.get('id') === (activeResourceGroupId || null)
    })

    const collapse = resourceGroupsPath.size > 4
    const collapsedRange = {
      start: 1,
      end: collapse ? resourceGroupsPath.size - 2 : 1
    }

    return resourceGroupsPath
      .reduce((sectionElements, resourceGroup, index) => {
        if (index >= collapsedRange.start && index < collapsedRange.end) {
          if (index === collapsedRange.start) {
            const collapsedResourceGroups = resourceGroupsPath.slice(collapsedRange.start, collapsedRange.end)

            const collapsedSectionElement = (
              <a
                className={classNames(style.section, style.collapsed)}
                key={index}
                onClick={({currentTarget}) => this._activateDropdown(collapsedResourceGroups, currentTarget)}>
                ...
              </a>
            )

            return sectionElements.push(
              collapsedSectionElement
            )
          }

          return sectionElements
        }

        return sectionElements.push(
          <a
            key={index}
            className={classNames(style.section, {
              [style.active]: resourceGroup.get('id') === activeResourceGroupId
            })}
            onClick={() => { this.props.onSelectResourceGroup(resourceGroup) }}>
            {resourceGroup.get('name')}
          </a>
        )
      }, List())
  }

  _renderLoadingSections (resourceGroups, activeResourceGroupId) {
    return [
      <span
        key={0}
        className={style.section}>
        <LoadingIndicator />
      </span>
    ]
  }

  _activateDropdown (resourceGroups, currentTarget) {
    const targetDimensions = currentTarget.getBoundingClientRect()
    const anchor = {
      x: targetDimensions.left + targetDimensions.width / 2,
      y: targetDimensions.bottom + 6
    }

    this.setState({
      dropdownActive: true,
      dropdownAnchor: anchor,
      collapsedGroups: resourceGroups
    })
  }

  _dismissDropdown () {
    this.setState({dropdownActive: false})
  }

  _createResourceGroupListElement (resourceGroups) {
    const listItemElements = resourceGroups.map((resourceGroup, index) => {
      return (
        <a
          key={resourceGroup.get('id')}
          onClick={() => {
            this.props.onSelectResourceGroup(resourceGroup)
            this._dismissDropdown()
          }}>
          {resourceGroup.get('name')}
        </a>
      )
    })

    return <div><div className={style.fullBreadcrumb}><ul>{listItemElements}</ul></div></div>
  }
}

function findPath (node, predicate) {
  if (predicate(node)) {
    return List([node])
  }

  if (node.get('children')) {
    for (const childNode of node.get('children')) {
      const childPath = findPath(childNode, predicate)
      if (childPath.size > 0) {
        return childPath.unshift(node)
      }
    }
  }

  return List()
}

QueryContext.propTypes = {
  resourceGroups: ImmutablePropTypes.map,
  activeResourceGroup: PropTypes.object,
  onSelectResourceGroup: PropTypes.func,
  className: PropTypes.string
}

function mapStateToProps (state) {
  return {
    resourceGroups: getResourceGroupTree(state),
    activeResourceGroup: getActiveResourceGroup(state)
  }
}

function mapDispatchToProps (dispatch) {
  return {
    onSelectResourceGroup: (resourceGroup) => {
      dispatch(setActiveResourceGroup(resourceGroup.get('id')))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QueryContext)
