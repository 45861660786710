import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List, Map } from 'immutable'

import RankedAttributeList from 'components/RankedAttributeList'

import style from './Overview.styl'

const attributeRoot = []

export default function Overview ({ resourceGroup }) {
  const summaryAttributeRanking = List([
    Map({ name: 'Group', path: [ 'name' ] }),
    Map({ name: 'Query', path: [ 'query' ] }),
    Map({ name: 'Group ID', path: [ 'id' ] })
  ])

  return (
    <div className={style.overview}>
      <div className={style.attributeList}>
        <RankedAttributeList
          attributeBase={attributeRoot}
          attributeRanking={summaryAttributeRanking}
          resource={resourceGroup} />
      </div>
    </div>
  )
}

Overview.propTypes = {
  resourceGroup: ImmutablePropTypes.map.isRequired
}
