import React from 'react'
import PropTypes from 'prop-types'
import FontAwesome from 'react-fontawesome'

import Button from 'components/Button'
import Hint from 'components/Hint'

import style from '../../ResourceProviderCreator/ResourceProviderCreator.styl'
import httpStyle from './CredentialsFormHttp.styl'

export class CredentialsFormHttp extends React.Component {
  constructor () {
    super()

    this.addItem = this.addItem.bind(this)
    this.removeItem = this.removeItem.bind(this)
    this._onNameChange = this._onNameChange.bind(this)
    this._onValueChange = this._onValueChange.bind(this)

    this.state = {
      headers: [
        {name: '', value: ''}
      ],
      queryParams: [
        {name: '', value: ''}
      ]
    }
  }

  addItem (collectionName) {
    this.setState({
      [collectionName]: this.state[collectionName].concat([{name: '', value: ''}])
    }, () => {
      this.transformAndSendCredentials()
    })
  }

  removeItem (collectionName, index) {
    this.state[collectionName].splice(index, 1)
    this.setState({
      [collectionName]: this.state[collectionName]
    }, () => {
      this.transformAndSendCredentials()
    })
  }

  _onNameChange (collectionName, index, { target }) {
    this.state[collectionName][index].name = target.value
    this.setState({
      [collectionName]: this.state[collectionName]
    }, () => {
      this.transformAndSendCredentials()
    })
  }

  _onValueChange (collectionName, index, { target }) {
    this.state[collectionName][index].value = target.value
    this.setState({
      [collectionName]: this.state[collectionName]
    }, () => {
      this.transformAndSendCredentials()
    })
  }

  transformAndSendCredentials () {
    const headers = this.state.headers.reduce((acc, header) => {
      return { ...acc, [header.name]: header.value }
    }, {})

    const queryParams = this.state.queryParams.reduce((acc, queryParam) => {
      return { ...acc, [queryParam.name]: queryParam.value }
    }, {})

    const creds = {
      headers: headers,
      query: queryParams
    }

    this.props.onCredentialsChange(creds)
  }

  renderHeaderInputs () {
    return this.state.headers.map((header, index) => {
      return (<div key={index} className={httpStyle.inputContent}>
        <input
          name='header name' type='text'
          placeholder='name'
          pattern='^[a-zA-Z-]+'
          value={header.name || ''}
          onChange={this._onNameChange.bind(null, 'headers', index)} />
        <input
          name='header value' type='text'
          placeholder='value'
          value={header.value || ''}
          onChange={this._onValueChange.bind(null, 'headers', index)} />
        {
          this.state.headers.length > 1 &&
          <Button type={Button.WARN} onClick={this.removeItem.bind(null, 'headers', index)} >
            <FontAwesome name='minus' />
          </Button>
        }
      </div>)
    }).concat([
      <Button key='add' onClick={this.addItem.bind(null, 'headers')} >
        <FontAwesome name='plus' />
      </Button>
    ])
  }

  renderQueryInputs () {
    return this.state.queryParams.map((queryParam, index) => {
      return (<div key={index} className={httpStyle.inputContent}>
        <input
          name='query parameter name' type='text'
          placeholder='name'
          pattern='^[a-zA-Z-]+'
          value={queryParam.name || ''}
          onChange={this._onNameChange.bind(null, 'queryParams', index)} />
        <input
          name='query parameter value' type='text'
          placeholder='value'
          value={queryParam.value || ''}
          onChange={this._onValueChange.bind(null, 'queryParams', index)} />
        {
          this.state.queryParams.length > 1 &&
          <Button type={Button.WARN} onClick={this.removeItem.bind(null, 'queryParams', index)} >
            <FontAwesome name='minus' />
          </Button>
        }
      </div>)
    }).concat([
      <Button key='add' onClick={this.addItem.bind(null, 'queryParams')} >
        <FontAwesome name='plus' />
      </Button>
    ])
  }

  render () {
    return (
      <div>
        <div className={style.description}>
          <p>
            This resource provider type uses HTTP to access an API.
            It requires  one or more HTTP headers, and one or more query parameters.
          </p>
          <p>
            Supply headers names and values, and query parameter names and values, using the form below.
          </p>
        </div>

        <div className={httpStyle.splitField}>
          <label>
            Enter HTTP headers
            <Hint className={style.hint}>
              Enter both the name of each headers and its value
            </Hint>
          </label>
          {this.renderHeaderInputs()}
        </div>

        <div className={httpStyle.splitField}>
          <label>
            Enter Query parameters
            <Hint className={style.hint}>
              Enter both the name of each parameter and its value
            </Hint>
          </label>
          {this.renderQueryInputs()}
        </div>
      </div>
    )
  }
}

CredentialsFormHttp.propTypes = {
  onCredentialsChange: PropTypes.func.isRequired
}

export default CredentialsFormHttp
