import FontAwesome from 'react-fontawesome'
import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { formatPattern } from 'react-router'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List } from 'immutable'

import {
  fetchPolicyTypes,
  updateLocation,
  updateResourcesVisualizationOptions,
  ResourcesVisualizationOptions,
  updateResourceGroupsVisualizationOptions,
  ResourceGroupsVisualizationOptions,
  setResourcesFilter
} from 'state/actions'

import { updateResourceGroup } from 'state/organization/actions'

import {
  selectPolicyType,
  ensureResourceTypes,
  fetchResourceDetails,
  fetchResourceGroupDetails,
  selectCoveragePolicyType
} from 'state/resources/actions'

import {
  getAnnotatedCollectionSet,
  getQueryCollectionFetched
} from 'state/resources/selectors'

import {
  getResourcesVisualizationOptions,
  getResourceGroupsVisualizationOptions,
  getRoleForCurrentOrganization,
  getAvailablePolicyTypes
} from 'state/selectors'

import ModalDialog from 'components/ModalDialog'
import { RadioButton, Item } from 'components/RadioButton'
import ResourceGroupEditor from './components/ResourceGroupEditor'
import ResourceGraph, { ResourceGraphVariants } from './components/ResourceGraph'
import ResourceGroupGraph, { ResourceGroupGraphVariants } from './components/ResourceGroupGraph'
import ResourceGroupTree from './components/ResourceGroupTree'
import ResourcesLayout, { NodeStatus } from './components/ResourcesLayout'
import ResourceDetails, { ResourceDetailsVariants } from './components/ResourceDetails'
import GroupDetails from './components/GroupDetails'
import ResourceList from './components/ResourceList'
import ResourcesFilter from './components/ResourcesFilter'
import { ToolbarSection } from 'components/Toolbar'
import {
  TOGGLE_RESOURCE_SOLUTION_CONTEXT_BUTTON,
  TOGGLE_RELATED_BUTTON
} from 'refs'

import style from './Resources.styl'

const SHOW = true
const HIDE = false

export class Resources extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      resourceDetailsActiveTab: null,
      policiesActiveTab: null,
      editResourceGroup: null
    }

    props.fetchPolicyTypes()
  }

  componentWillMount () {
    if (this.props.activeResourceGroupId) {
      this.props.fetchResourceGroup(this.props.activeResourceGroupId)
    }

    if (this.props.onInitialize) {
      this.props.onInitialize()
    }

    if (this.props.route.path === '/resources/:id') {
      this.props.fetchResource(this.props.params.id)
    }

    if (this.props.route.path === '/resource_groups/:id') {
      this.props.fetchResourceGroup(this.props.params.id)
    }
  }

  componentWillReceiveProps (nextProps, nextState) {
    if (nextProps.route.path === '/resource_groups') {
      this.props.resetResourcesFilter()
    }

    if (nextProps.activeResourceGroupId && this.props.activeResourceGroupId !== nextProps.activeResourceGroupId) {
      this.props.fetchResourceGroup(nextProps.activeResourceGroupId)
    }

    if (this.props.route.path === '/resources/:id' && nextProps.params.id && nextProps.params.id !== this.props.params.id) {
      if (!(this.state.selectedResourceId && nextProps.params.id === this.state.selectedResourceId)) {
        this.props.fetchResource(nextProps.params.id)
      }
    }
  }

  shouldComponentUpdate (nextProps, nextState) {
    return (this.props.location.pathname !== nextProps.location.pathname) ||
      (this.props.activeResourceGroupId !== nextProps.activeResourceGroupId) ||
      (this.state.selectedResourceId !== nextState.selectedResourceId) ||
      (this.props.availableGroupsLength !== nextProps.availableGroupsLength) ||
      this.props.collectionSetFetched !== nextProps.collectionSetFetched ||
      !this.props.collectionSet.equals(nextProps.collectionSet) ||
      !this.props.resourcesVisualizationOptions.equals(nextProps.resourcesVisualizationOptions) ||
      !this.props.resourceGroupsVisualizationOptions.equals(nextProps.resourceGroupsVisualizationOptions) ||
      this.state.editResourceGroup !== nextState.editResourceGroup ||
      this.props.currentCoveragePolicyType !== nextProps.currentCoveragePolicyType ||
      this.state.resourceDetailsActiveTab !== nextState.resourceDetailsActiveTab ||
      this.state.policiesActiveTab !== nextState.policiesActiveTab
  }

  onResourceDetailActiveTabChange = tabTitle => {
    this.setState({resourceDetailsActiveTab: tabTitle})
  }

  onPoliciesActiveTabChange = tabTitle => {
    this.setState({policiesActiveTab: tabTitle})
  }

  selectPolicyType = (policyTypes, policyTypeId) => {
    if (policyTypeId === '' || !policyTypes.find(policyType => policyType.get('id') === policyTypeId)) {
      this._selectFirstPolicyType(policyTypes)
    } else {
      this.props.onSelectPolicyType(policyTypeId)
    }
  }

  _selectFirstPolicyType = (policyTypes) => {
    const availableTypes = policyTypes.map(type => type.get('id')).sort()
    if (availableTypes.size) {
      this.selectPolicyType(policyTypes, availableTypes.get(0))
    }
  }

  render () {
    const {
      resourcesVisualizationOptions,
      resourceGroupsVisualizationOptions
    } = this.props

    const navigation = {
      title: 'Resource Groups',
      route: '/resource_groups',
      content: (status, transitionStatus) => {
        if (resourceGroupsVisualizationOptions.format === 'list') {
          const microClassNames = classNames(style.micro, {
            [style.fadingOut]: transitionStatus === NodeStatus.ACTIVE
          })

          const resourceGroupTreeClassNames = classNames({
            [style.fadingOut]: transitionStatus === NodeStatus.UP
          })

          if (status === NodeStatus.UP) {
            return <div className={microClassNames}>{this.props.availableGroupsLength}</div>
          }

          return (
            <div className={resourceGroupTreeClassNames}>
              <ResourceGroupTree
                onGroupEdit={group => this.setState({ editResourceGroup: group })}
                organizationRole={this.props.organizationRole} />
            </div>
          )
        } else if (resourceGroupsVisualizationOptions.format === 'graph') {
          const variants = {
            [NodeStatus.UP]: ResourceGroupGraphVariants.MICRO,
            [NodeStatus.DOWN]: ResourceGroupGraphVariants.MINI,
            [NodeStatus.ACTIVE]: ResourceGroupGraphVariants.NORMAL
          }
          const variant = variants[transitionStatus || status]

          return (
            <ResourceGroupGraph
              variant={variant}
              activeResourceGroupId={this.props.activeResourceGroupId}
              showLegend={this.props.currentCoveragePolicyType !== 'NONE' && variant === ResourceGroupGraphVariants.NORMAL && !transitionStatus} />
          )
        }
      },
      toolbar: () => {
        let policyTypeSelectorContent = List()
        if (this.props.availablePolicyTypes.size > 1) {
          policyTypeSelectorContent = policyTypeSelectorContent.push(<option key='ALL' value='ALL'>All policy types</option>)
        }
        policyTypeSelectorContent = this.props.availablePolicyTypes.reduce((acc, policyType) => acc.push(
          <option key={policyType.get('id')} value={policyType.get('id')}>{policyType.get('label')}</option>
        ), policyTypeSelectorContent)

        const policyTypeSelector = (
          <div className={style.policyTypeSelector}>
            <select value={this.props.currentCoveragePolicyType} onChange={this.props.onSelectCoveragePolicyType}>
              <option key='NONE' value='NONE'>Show policy coverage for...</option>
              {policyTypeSelectorContent}
            </select>
            <span className={style.icon}><FontAwesome name='chevron-down' /></span>
          </div>
        )

        return [
          <ToolbarSection key=''>
            {policyTypeSelector}
            <div data-ref={TOGGLE_RESOURCE_SOLUTION_CONTEXT_BUTTON}>
              <RadioButton
                value={resourceGroupsVisualizationOptions.format}
                onChange={format => {
                  const newVisualizationOptions = resourceGroupsVisualizationOptions.set('format', format)
                  this.props.onResourceGroupsVisualizationOptionsChange(newVisualizationOptions)
                }}>
                <Item value='graph'><FontAwesome name='pie-chart' /></Item>
                <Item value='list'><FontAwesome name='list' /></Item>
              </RadioButton>
            </div>
          </ToolbarSection>
        ]
      },
      children: [{
        title: 'Resource Group',
        route: '/resource_groups/:id',
        weight: 70,
        content: (status, transitionStatus) => {
          let resourceId = this.props.params.id || this.props.activeResourceGroupId
          if (resourceId) {
            const variants = {
              [NodeStatus.DOWN + null]: ResourceDetailsVariants.MINI,
              [NodeStatus.DOWN + NodeStatus.ACTIVE]: ResourceDetailsVariants.MINI,
              [NodeStatus.ACTIVE + null]: ResourceDetailsVariants.NORMAL,
              [NodeStatus.ACTIVE + NodeStatus.DOWN]: ResourceDetailsVariants.MINI,
              [NodeStatus.UP + null]: ResourceDetailsVariants.MINI,
              [NodeStatus.UP + NodeStatus.ACTIVE]: ResourceDetailsVariants.MINI
            }
            return (
              <GroupDetails
                variant={variants[status + transitionStatus]}
                organizationRole={this.props.organizationRole}
                policiesActiveTab={this.state.policiesActiveTab}
                onSelectPolicyType={this.selectPolicyType}
                onPoliciesActiveTabChange={this.onPoliciesActiveTabChange} />
            )
          }
          return null
        }
      }, {
        title: 'Resources',
        route: '/resources',
        weight: 30,
        offset: 70,
        content: (status, transitionStatus) => {
          if (!this.props.collectionSetFetched) {
            return null
          }

          if (resourcesVisualizationOptions.format === 'list') {
            const microClassNames = classNames(style.micro, {
              [style.fadingOut]: transitionStatus === 'active'
            })

            const resourceListClassNames = classNames({
              [style.fadingOut]: transitionStatus === 'up'
            })

            if (status === NodeStatus.UP) {
              return <div className={microClassNames}>{this.props.collectionSet.get('total_size')}</div>
            }

            return (
              <div className={resourceListClassNames}>
                <ResourceList
                  collectionSet={this.props.collectionSet}
                  onResourceSelect={resourceId => this._setSelectedResourceId(resourceId)}
                />
              </div>
            )
          } else if (resourcesVisualizationOptions.format === 'graph') {
            const variants = {
              [NodeStatus.UP]: ResourceGraphVariants.MICRO,
              [NodeStatus.DOWN]: ResourceGraphVariants.MINI,
              [NodeStatus.ACTIVE]: ResourceGraphVariants.NORMAL
            }

            return (
              <ResourceGraph
                onSelectNode={(id) => this._setSelectedResourceId(id)}
                variant={variants[transitionStatus || status]}
              />
            )
          }
        },
        toolbar: () => [
          <ToolbarSection key=''>
            <ResourcesFilter
              resourcesFilter={this.props.resourcesFilter}
              setResourcesFilter={filter => this.props.onResourcesFilterChange(filter)}
            />
            <div data-ref={TOGGLE_RELATED_BUTTON}>
              <RadioButton
                value={resourcesVisualizationOptions.showRelated}
                onChange={showRelated => {
                  const newVisualizationOptions = resourcesVisualizationOptions.set('showRelated', showRelated)
                  this.props.onResourcesVisualizationOptionsChange(newVisualizationOptions)
                }}>
                <Item value={SHOW}>Show Related</Item>
                <Item value={HIDE}>Hide Related</Item>
              </RadioButton>
            </div>
            <div data-ref={TOGGLE_RESOURCE_SOLUTION_CONTEXT_BUTTON}>
              <RadioButton
                value={resourcesVisualizationOptions.format}
                onChange={format => {
                  const newVisualizationOptions = resourcesVisualizationOptions.set('format', format)
                  this.props.onResourcesVisualizationOptionsChange(newVisualizationOptions)
                }}>
                <Item value='graph'><FontAwesome name='pie-chart' /></Item>
                <Item value='list'><FontAwesome name='list' /></Item>
              </RadioButton>
            </div>
          </ToolbarSection>
        ],
        children: [{
          title: 'Resource',
          route: '/resources/:id',
          content: (status, transitionStatus) => {
            let resourceId = this.props.params.id || this.state.selectedResourceId
            if (resourceId) {
              const variants = {
                [NodeStatus.DOWN + null]: ResourceDetailsVariants.MINI,
                [NodeStatus.DOWN + NodeStatus.ACTIVE]: ResourceDetailsVariants.MINI,
                [NodeStatus.ACTIVE + null]: ResourceDetailsVariants.NORMAL,
                [NodeStatus.ACTIVE + NodeStatus.DOWN]: ResourceDetailsVariants.MINI,
                [NodeStatus.UP + null]: ResourceDetailsVariants.MINI,
                [NodeStatus.UP + NodeStatus.ACTIVE]: ResourceDetailsVariants.MINI
              }
              return (
                <ResourceDetails
                  variant={variants[status + transitionStatus]}
                  organizationRole={this.props.organizationRole}
                  resourceDetailsActiveTab={this.state.resourceDetailsActiveTab}
                  policiesActiveTab={this.state.policiesActiveTab}
                  onResourceDetailActiveTabChange={this.onResourceDetailActiveTabChange}
                  onSelectPolicyType={this.selectPolicyType}
                  onPoliciesActiveTabChange={this.onPoliciesActiveTabChange} />
              )
            }
            return null
          }
        }]
      }]
    }

    const editResourceGroupModal = this.state.editResourceGroup ? (
      <ModalDialog
        onDismiss={() => this._dismissEditResourceGroupModal()}
        title={'Edit Resource Group (' + this.state.editResourceGroup.name + ')'}>
        <ResourceGroupEditor
          resourceGroup={this.state.editResourceGroup}
          onClose={() => this._dismissEditResourceGroupModal()}
          onSave={this.props.onResourceGroupUpdate} />
      </ModalDialog>
    ) : null

    return (
      <div>
        <ResourcesLayout
          navigation={navigation}
          nodeKey={(node) => node.route}
          initialNode={this.props.route.path}
          onNavigate={(node) => this._navigate(node)} />
        {editResourceGroupModal}
      </div>
    )
  }

  _navigate (node) {
    const params = {}
    if (node.route === '/resource_groups/:id') {
      params.id = this.props.activeResourceGroupId
    } else if (node.route === '/resources/:id') {
      params.id = this.state.selectedResourceId
    }

    const pathname = formatPattern(node.route, params)

    this.props.onNavigate({pathname, query: this.props.location.query})
  }

  _setSelectedResourceId (id) {
    this.props.fetchResource(id)
    this.setState({
      selectedResourceId: id
    })
  }

  _dismissEditResourceGroupModal () {
    this.setState({ editResourceGroup: null })
  }
}

Resources.propTypes = {
  onInitialize: PropTypes.func.isRequired,
  onNavigate: PropTypes.func.isRequired,
  fetchPolicyTypes: PropTypes.func.isRequired,
  fetchResource: PropTypes.func.isRequired,
  fetchResourceGroup: PropTypes.func.isRequired,
  collectionSet: ImmutablePropTypes.map.isRequired,
  availableGroupsLength: PropTypes.number.isRequired,
  resourcesFilter: PropTypes.string.isRequired,
  resourcesVisualizationOptions: PropTypes.instanceOf(ResourcesVisualizationOptions),
  resourceGroupsVisualizationOptions: PropTypes.instanceOf(ResourceGroupsVisualizationOptions),
  organizationRole: PropTypes.number.isRequired
}

function mapStateToProps (state) {
  return {
    activeResourceGroupId: state.activeResourceGroupId,
    collectionSet: getAnnotatedCollectionSet(state),
    collectionSetFetched: getQueryCollectionFetched(state),
    availableGroupsLength: state.availableGroups.size,
    resourcesFilter: state.resourcesFilter,
    resourcesVisualizationOptions: getResourcesVisualizationOptions(state),
    resourceGroupsVisualizationOptions: getResourceGroupsVisualizationOptions(state),
    organizationRole: getRoleForCurrentOrganization(state),
    availablePolicyTypes: getAvailablePolicyTypes(state),
    currentCoveragePolicyType: state.currentCoveragePolicyType
  }
}

function mapDispatchToProps (dispatch) {
  return {
    onInitialize: () => {
      dispatch(ensureResourceTypes())
    },
    onNavigate: (route) => {
      dispatch(updateLocation(route))
    },
    fetchPolicyTypes: () => {
      dispatch(fetchPolicyTypes())
    },
    fetchResource: (resourceId) => {
      dispatch(fetchResourceDetails(resourceId))
    },
    fetchResourceGroup: (resourceGroupId) => {
      dispatch(fetchResourceGroupDetails(resourceGroupId))
    },
    resetResourcesFilter: () => dispatch(setResourcesFilter('')),
    onResourcesVisualizationOptionsChange: visualizationOptions => {
      dispatch(updateResourcesVisualizationOptions(visualizationOptions))
    },
    onResourceGroupsVisualizationOptionsChange: visualizationOptions => {
      dispatch(updateResourceGroupsVisualizationOptions(visualizationOptions))
    },
    onResourceGroupUpdate: (id, fields) => dispatch(updateResourceGroup(id, fields)),
    onResourcesFilterChange: filter => dispatch(setResourcesFilter(filter)),
    onSelectCoveragePolicyType: e => { dispatch(selectCoveragePolicyType(e.target.value)) },
    onSelectPolicyType: policyTypeId => dispatch(selectPolicyType(policyTypeId))
  }
}

export default connect(
  mapStateToProps, mapDispatchToProps
)(Resources)
