import PropTypes from 'prop-types'
import React from 'react'

import style from './Badge.styl'

export const Badge = ({ children, content }) => {
  const badge = content
    ? <div className={style.badge}>{content}</div>
    : null

  return (
    <div className={style.Badge}>
      {children}
      {badge}
    </div>
  )
}

Badge.propTypes = {
  content: PropTypes.string
}

export default Badge
