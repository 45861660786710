import PropTypes from 'prop-types'
import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import FontAwesome from 'react-fontawesome'

import { ADMIN_POLICY } from 'state/actions'
import Button from 'components/Button'

import style from './RestrictedCredentialsList.styl'

export class RestrictedCredentialsList extends React.Component {
  _displayProviderInformation (provider) {
    if (provider.get('has_management_credentials')) {
      return `Resource Provider "${provider.get('name')}" is managing these credentials`
    }

    return 'Credentials are managed manually'
  }

  _listRestrictedCredentials (credentials) {
    if (!credentials.size) {
      return (<tr><td>No restricted credentials</td></tr>)
    }

    return credentials.map(c => {
      return (
        <tr key={c.get('policy_type_label')}>
          <td>{c.get('policy_type_label')}</td>
          <td className={c.get('present') ? style.active : style.inactive}>
            <FontAwesome name={c.get('present') ? 'check-square' : 'square'} />
          </td>
          {this.props.organizationRole === ADMIN_POLICY ? (
            <td>
              <Button
                disabled={c.get('is_managed')}
                onClick={() => this.props.onEditCredentials(c)}
                className={style.edit}
                flat>
                <FontAwesome
                  name='pencil'
                  className='fa-align-right' />
              </Button>
            </td>
          ) : null}
          {this.props.organizationRole === ADMIN_POLICY ? (
            <td>
              <Button
                disabled={!c.get('present')}
                onClick={() => this.props.onDeleteCredentials(c)}
                type={Button.WARN}
                className={style.delete}
                flat>
                <FontAwesome name='trash' />
              </Button>
            </td>
          ) : null}
        </tr>
      )
    })
  }

  render () {
    return (
      <div>
        <div className={style.info}>
          {this._displayProviderInformation(this.props.resourceProvider)}
        </div>
        <table>
          <thead>
            <tr>
              <th>Policy type</th>
              <th>Present?</th>
              {this.props.organizationRole === ADMIN_POLICY ? <th /> : null}
              {this.props.organizationRole === ADMIN_POLICY ? <th /> : null}
            </tr>
          </thead>
          <tbody>
            {this._listRestrictedCredentials(this.props.restrictedCredentials)}
          </tbody>
        </table>
      </div>
    )
  }
}

RestrictedCredentialsList.propTypes = {
  resourceProvider: ImmutablePropTypes.map.isRequired,
  restrictedCredentials: ImmutablePropTypes.list.isRequired,
  onEditCredentials: PropTypes.func.isRequired,
  onDeleteCredentials: PropTypes.func.isRequired,
  organizationRole: PropTypes.number.isRequired
}

export default RestrictedCredentialsList
