import React from 'react'
import * as immutable from 'immutable'
import moment from 'moment'

import { convertUTCTimeToLocal } from 'services/time'

import JsonTree from 'components/JsonTree'
import UriMetricButton from 'scenes/Resources/components/ResourceDetails/components/Metrics/components/UriMetric'

import style from './Attribute.styl'

const { Map, OrderedMap, Iterable } = immutable

const defaultFormat = Map({ type: 'default' })

// Convenience export of renderer as a component
export default function Attribute ({ attribute, context }) {
  return render(attribute, context)
}

export function Attributes ({ attributes, context }) {
  const attribute = Map({
    format: Map({
      type: 'entries',
      topLevel: true,
      entries: attributes
    })
  })
  return render(attribute, context)
}

export function render (attribute, context) {
  attribute = normalizeEntry(attribute)
  try {
    return _render(attribute, context)
  } catch (error) {
    if (!(error instanceof FormatError)) {
      throw error
    }

    // Log the formatting error and fall back to the default renderer
    console.warn(`Attribute "${attribute.get('name', attribute)}": ${error.message}`)
    return _render(attribute.delete('format'), context)
  }
}

function _render (attribute, context) {
  const format = attribute.get('format', defaultFormat)
  const renderer = getAttributeRenderer(attribute)
  const nextContext = context && context.getIn(attribute.get('path', []))
  return renderer({ format, context: nextContext })
}

// Renders the context as a string. Adds a style for empty values.
export function Default ({ context }) {
  const content = String(context)
  if (context === undefined || content === '') {
    return <span className={style.notAvailable}>N/A</span>
  } else {
    return <span>{content}</span>
  }
}
Default.compare = (a, b) => {
  const hasValueA = !(a === undefined || String(a) === '')
  const hasValueB = !(b === undefined || String(b) === '')

  if (hasValueA && hasValueB) {
    if (String(a) < String(b)) {
      return -1
    }
    if (String(a) > String(b)) {
      return 1
    }
    return 0
  }

  if (hasValueA && !hasValueB) {
    return -1
  }
  if (!hasValueA && hasValueB) {
    return 1
  }
  return 0
}
Default.isSimple = true

// Renders the size of the context, which is assumed to be an immutable collection.
export function CollectionSize ({ context }) {
  assertIterable('collection-size', context)
  return Default({ context: context && context.size })
}
CollectionSize.compare = (a, b) => {
  if (a.size < b.size) {
    return -1
  }
  if (a.size > b.size) {
    return 1
  }
  return 0
}
CollectionSize.isSimple = true

// Renders a collection as a comma separated sequence.
export function CommaSeparatedSequence ({ format, context }) {
  const _assertIterable = assertIterable.bind(null, 'comma-separated-sequence')

  _assertIterable(context)

  if (context && format.has('path')) {
    context.forEach(e => _assertIterable(e, `given "path" but entry "${e}" was not iterable`))
    context = context.map(e => e.getIn(format.get('path')))
  }
  return Default({ context: context && context.join(', ') })
}

// Parses a comma separated string and renders it using CommaSeparatedSequence
export function CommaSeparatedString ({ format, context }) {
  return CommaSeparatedSequence({ format, context: context && context.split(/\s*,\s*/g) })
}

// Parses a timestamp and renders it in locale format
export function Timestamp ({ context }) {
  const timestamp = context && convertUTCTimeToLocal(context)
  if (!timestamp) {
    throw new FormatError(`invalid context for "timestamp" ("${context} is not a parseable date")`)
  }
  return Default({ context: timestamp })
}
Timestamp.compare = (a, b) => {
  const timestampA = a && Date.parse(a)
  const timestampB = b && Date.parse(b)
  const hasValueA = !isNaN(timestampA)
  const hasValueB = !isNaN(timestampB)

  if (hasValueA && hasValueB) {
    const dateA = new Date(timestampA)
    const dateB = new Date(timestampB)
    if (dateA < dateB) {
      return -1
    }
    if (dateA > dateB) {
      return 1
    }
    return 0
  }

  if (hasValueA && !hasValueB) {
    return -1
  }
  if (!hasValueA && hasValueB) {
    return 1
  }
  return 0
}
Timestamp.isSimple = true

// Parses a timestamp and renders it as a time attribute using relative date
export function RelativeDateTime ({ context }) {
  const timestamp = moment.utc(context)
  if (!context || !timestamp.isValid()) {
    return Default({ context: 'Unknown' })
  }
  return <time dateTime={timestamp.toISOString()} title={timestamp.toString()}>{timestamp.fromNow()}</time>
}
RelativeDateTime.isSimple = true
RelativeDateTime.compare = (a, b) => {
  const timestampA = a && Date.parse(a)
  const timestampB = b && Date.parse(b)
  const hasValueA = !isNaN(timestampA)
  const hasValueB = !isNaN(timestampB)

  if (hasValueA && hasValueB) {
    const dateA = new Date(timestampA)
    const dateB = new Date(timestampB)
    if (dateA < dateB) {
      return -1
    }
    if (dateA > dateB) {
      return 1
    }
    return 0
  }

  if (hasValueA && !hasValueB) {
    return -1
  }
  if (!hasValueA && hasValueB) {
    return 1
  }
  return 0
}

// Renders a map as a list of key: value entries
export function Entries ({ format, context }) {
  assertIterable('entries', context)

  // TODO `topLevel` is a bit of a hack to get the right level of error reporting from `Attributes`,
  // this should have a better API.
  const topLevel = format.get('topLevel', false)

  if (!context || !context.size) {
    return Default({ context })
  }

  const entries = normalizeEntries('entries', 'entries', format.get('entries'))
    .map(entry => (topLevel ? render : _render)(entry, context))
  return Entries.fromMap(entries)
}

// Renders an attribute entries list from a map
// This allows the Entries styling to be used with custom content
Entries.fromMap = function fromMap (entries) {
  const items = entries.entrySeq().flatMap(([ name, content ], i) => {
    return immutable.List.of(
      <dt key={i}>{name}</dt>,
      <dd key={`value-${i}`}>{content}</dd>
    )
  })
  return <dl className={style.entries}>{items}</dl>
}

// Renders a collection of maps as a table
export function Table ({ format, context }) {
  assertIterable('table', context)

  if (!context || !context.size) {
    return Default({})
  }

  const columns = normalizeEntries('table', 'columns', format.get('columns'))

  const headers = Array.from(columns.keys(), name => <th key={name}>{name}</th>)
  const body = Array.from(context, (item, i) => {
    const fields = Array.from(columns, ([ name, column ]) => {
      return <td key={name}>{_render(column, item)}</td>
    })
    return <tr key={i}>{fields}</tr>
  })

  return (
    <table className={style.table}>
      <thead><tr>{headers}</tr></thead>
      <tbody>{body}</tbody>
    </table>
  )
}

// Renders a collection as a list of items
export function List ({ format, context }) {
  if (!context || (!context.length && !context.size)) {
    return Default({})
  }

  if (format.has('path')) {
    context = context && context.map(entry => entry.getIn(format.get('path')))
  }

  const items = Array.from(context, (item, i) => <li key={i}>{item}</li>)
  return <ul>{items}</ul>
}

// Renders a uri metric type
export function UriMetric ({context}) {
  if (!context || !context.get('value')) {
    return Default({})
  }

  return <UriMetricButton value={context.get('value')} />
}
UriMetric.isSimple = true

export function isSimpleAttribute (attribute) {
  const renderer = getAttributeRenderer(attribute)
  return !!renderer.isSimple
}

export function getAttributeRenderer (attribute) {
  attribute = normalizeEntry(attribute)
  const format = attribute.get('format', defaultFormat)
  if (!Map.isMap(format)) {
    throw new FormatError(`invalid format (must be immutable.Map)`)
  }

  const formatType = format.get('type')
  if (!formatType) {
    throw new FormatError('invalid format (missing "type")')
  }

  const formatName = formatType.replace(/(?:^|-)([a-z])/g, (_, char) => char.toUpperCase())

  if (formatName === 'Json') {
    return JsonTree
  }

  const renderer = determineRendererForFormat(formatName)
  if (!renderer) {
    throw new FormatError(`format "${formatType}" does not exist`)
  }

  return renderer
}

function determineRendererForFormat(name) {
  switch (name) {
    case "Attribute":
      return Attribute
      break
    case "Attributes":
      return Attributes
      break
    case "CollectionSize":
      return CollectionSize
      break
    case "CommaSeparatedSequence":
      return CommaSeparatedSequence
      break
    case "CommaSeparatedString":
      return CommaSeparatedString
      break
    case "Timestamp":
      return Timestamp
      break
    case "RelativeDateTime":
      return RelativeDateTime
      break
    case "Entries":
      return Entries
      break
    case "Table":
      return Table
      break
    case "List":
      return List
      break
    case "X":
      return X
      break
    case "X":
      return X
      break
    case "Default":
      return Default
      break
  }
  return null
}

function assertIterable (formatName, context, message = `"${context}" is not iterable`) {
  if (context && !Iterable.isIterable(context)) {
    throw new FormatError(`invalid context for "${formatName}" (${message})`)
  }
}

function normalizeEntries (formatName, fieldName, entries) {
  assertIterable(formatName, entries, `"${fieldName}" is not iterable`)
  return entries.map(normalizeEntry).reduce((entries, entry) => {
    const name = entry.get('name')
    if (!name) {
      throw new FormatError(`invalid format for "entries" (missing "name" in entry ${entry})`)
    }
    if (entries.has(name)) {
      throw new FormatError(`invalid format for "entries" (duplicate column "${name}")`)
    }

    return entries.set(name, entry)
  }, OrderedMap())
}

function normalizeEntry (column) {
  if (Map.isMap(column)) {
    return column
  } else {
    const name = String(column)
    return Map({ name, path: [ name ] })
  }
}

function FormatError (message) {
  this.name = 'FormatError'
  this.message = message
  if (Error.captureStackTrace) {
    Error.captureStackTrace(this, FormatError)
  }
}
FormatError.prototype = Object.create(Error.prototype)
FormatError.prototype.constructor = FormatError
