import classNames from 'classnames'
import { easeCubic } from 'd3-ease'
import { select } from 'd3-selection'
import { Matrix } from 'transformation-matrix-js'

import { initProperty, interpolateProperty } from './interpolate'
import style from '../../ResourceGraph.styl'

const TRANSITION_DURATION = 700

export default function renderLabels (rootNode, parentElement, currentEdges, onSelectLabelNode, enableTransitions, filter, activeItemId) {
  const labelNodes = getAllLabelNodes(rootNode)

  const transformGenerator = (x) => {
    return new Matrix()
      .rotate(x - Math.PI / 2)
      .translate(67, 0)
      .toCSS()
  }

  const updatingLabels = select(parentElement)
    .selectAll(`g.label`)
    .data(labelNodes, getLabelNodeId)

  const enteringLabels = updatingLabels.enter().append('g')
  const currentLabels = updatingLabels.merge(enteringLabels)
  const exitingLabels = updatingLabels.exit()

  selectLabel(currentLabels, currentEdges, activeItemId)

  enteringLabels
    .style('opacity', 0)
    .style('fill', labelNode => labelNode.parent.data.group.get('color'))
    .style('color', labelNode => labelNode.parent.data.group.get('color'))
    .attr('transform', (labelNode, i, elements) => {
      const x = initProperty(elements[i], 'x', labelNode.parent.toggledX)
      return transformGenerator(x)
    })

  enteringLabels
    .append('text')
    .attr('class', 'label')

  currentLabels
    .style('fill', labelNode => {
      return labelNode.active ? 'cyan' : labelNode.parent.data.group.get('color')
    })
    .attr('class', getLabelClassNames)

  currentLabels
    .transition()
    .duration(enableTransitions ? TRANSITION_DURATION : 0)
    .ease(easeCubic)
    .style('opacity', 1)
    .attr('data-group-id', getLabelNodeGroupId)
    .attrTween('transform', (labelNode, i, elements) => {
      return interpolatePosition(labelNode, elements[i], transformGenerator, labelNode.x)
    })

  currentLabels
    .filter(labelNode => labelNode.data.type === 'item')
    .attr('data-item-id', labelNode => labelNode.data.source.id)

  currentLabels
    .select('text.label')
    .style('text-anchor', labelNode => labelNode.x < Math.PI ? 'start' : 'end')
    .attr('transform', labelNode => {
      const hasCircle = labelNode.data.source.mark || labelNode.data.source.warn
      return new Matrix()
        .translate(hasCircle ? 6 : 1, 0)
        .rotate(labelNode.x >= Math.PI ? Math.PI : 0)
        .translate(0, -1)
        .toCSS()
    })
    .on('click', onSelectLabelNode)
    .on('mouseover', (labelNode) => {
      highlightLabel(currentLabels, currentEdges, getLabelNodeId(labelNode))
    })
    .on('mouseout', (labelNode) => resetHighlight(currentLabels, currentEdges))
    .each(labelNode =>
      setText(currentLabels, filter, labelNode)
    )

  currentLabels
    .select('circle')
    .remove()

  currentLabels
    .select('polygon')
    .remove()

  currentLabels
    .filter(labelNode => labelNode.data.source.mark)
    .append('circle')
    .attr('cx', 2)
    .attr('cy', 0)
    .attr('r', 1.5)
    .attr('class', style.nonCompliant)

  currentLabels
    .filter(labelNode => labelNode.data.source.warn)
    .append('polygon')
    .attr('points', '2,-1.5 3.2,1 .8,1')
    .attr('class', style.warning)

  exitingLabels
    .transition()
    .duration(enableTransitions ? TRANSITION_DURATION : 0)
    .ease(easeCubic)
    .style('opacity', 0)
    .attrTween('transform', (labelNode, i, elements) => {
      return interpolatePosition(labelNode, elements[i], transformGenerator, labelNode.parent.toggledX)
    })
    .remove()
}

function getAllLabelNodes (rootNode) {
  return (rootNode.children || []).reduce((labelNodes, collectionNode) => {
    return [...labelNodes, ...collectionNode.children]
  }, [])
}

function getLabelNodeGroupId (labelNode) {
  return labelNode.parent.data.group.id
}

function getLabelNodeId (labelNode) {
  const groupId = labelNode.parent.data.group.id
  const labelSourceId = labelNode.data.source.id
  return `${groupId}${labelNode.data.type === 'item' ? `-${labelSourceId}` : ''}`
}

function getLabelClassNames (labelNode) {
  return classNames(
    'label',
    style.label,
    {
      [style.secondary]: labelNode.data.source.secondary,
      [style.active]: labelNode.active,
      [style.highlight]: labelNode.highlight
    }
  )
}

function interpolatePosition (labelNode, element, transformGenerator, endX) {
  return interpolateProperty(element, 'x', null, endX, x => {
    return transformGenerator(x)
  })
}

function highlightLabel (labels, currentEdges, labelId) {
  labels
    .filter(labelNode => getLabelNodeId(labelNode) === labelId)
    .each(labelNode => { labelNode.highlight = true })
    .attr('class', getLabelClassNames)

  if (!currentEdges) {
    return
  }

  currentEdges
    .filter(edgeNode => edgeNode.targetLabelNode.highlight || edgeNode.sourceLabelNode.highlight)
    .classed(style.highlight, true)
    .raise()
}

function resetHighlight (labels, currentEdges) {
  labels
    .each(labelNode => { labelNode.highlight = false })
    .attr('class', getLabelClassNames)

  if (!currentEdges) {
    return
  }
  currentEdges
    .classed(style.highlight, false)
}

function selectLabel (labels, currentEdges, itemId) {
  labels
    .filter(labelNode => labelNode.data.source.id === itemId)
    .each(labelNode => { labelNode.active = true })
    .attr('class', getLabelClassNames)

  if (!currentEdges) {
    return
  }

  currentEdges
    .filter(edgeNode => edgeNode.targetLabelNode.active || edgeNode.sourceLabelNode.active)
    .classed(style.active, true)
    .raise()
}

function setText (labels, filter, labelNode) {
  filter = filter.replace(/([()])/g, '')

  const label = labels
    .filter(label => label.data.source.id === labelNode.data.source.id)
    .select('text.label')
    .text(null)

  if (filter === '') {
    return label.text(labelNode.data.source.name)
  }

  let labelRe

  try {
    labelRe = new RegExp(`(${filter})`, 'i')
  } catch (err) {
    return label.text(labelNode.data.source.name)
  }

  const labelNameMatches = labelNode.data.source.name.split(labelRe)

  if (labelNameMatches.length > 1) {
    labelNameMatches.forEach((match, index) => {
      if (index % 2 !== 0) {
        label.append('tspan').attr('class', style.match).text(match)
      } else if (match !== '') {
        label.append('tspan').attr('class', style.relatedMatch).text(match)
      }
    })
  } else {
    label.text(labelNode.data.source.name)
  }
}
