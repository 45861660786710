import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import FontAwesome from 'react-fontawesome'

import { debounce } from 'services/debounce'

import style from './ResourcesFilter.styl'

const DEBOUNCE_TIMER = 500

export class ResourcesFilter extends React.Component {
  constructor (props) {
    super()

    this.state = {
      filter: props.resourcesFilter
    }

    this.debounce = debounce(query => {
      props.setResourcesFilter(this.state.filter)
    }, DEBOUNCE_TIMER)
  }

  _onFilterChange = filter => {
    this.debounce(filter)
    this.setState({ filter })
  }

  render () {
    return (<div className={classNames(style.ResourcesFilter, this.props.className)}>
      <FontAwesome name='filter' />
      <input
        onChange={event => { this._onFilterChange(event.target.value) }}
        value={this.state.filter}
        placeholder='Filter...'
      />
    </div>)
  }
}

ResourcesFilter.propTypes = {
  className: PropTypes.string,
  resourcesFilter: PropTypes.string.isRequired,
  setResourcesFilter: PropTypes.func.isRequired
}

export default ResourcesFilter
