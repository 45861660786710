import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Iterable, List, Map } from 'immutable'
import FontAwesome from 'react-fontawesome'

import Accordion from '../../../../../../components/Accordion'

import Attribute, {
  isSimpleAttribute,
  getAttributeRenderer
} from 'components/Attribute'

import style from './CollectionCard.styl'

import { Column, Table, AutoSizer } from 'react-virtualized'
import 'react-virtualized/styles.css'

const SORT_DESCENDING = -1
const SORT_ASCENDING = 1
const COLUMN_WIDTH = 400
const ROW_HEIGHT = 40
const HEADER_HEIGHT = 30
const PADDING = 20
const MAX_TABLE_RESOURCES = 10

const WarnAttribute = Map({
  name: 'Warn',
  path: List(['warn'])
})
const CompliantAttribute = Map({
  name: 'Compliant',
  path: List(['compliant'])
})

export class CollectionCard extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      sortColumn: 1,
      sortDirection: SORT_ASCENDING
    }
  }

  _sortList (column) {
    this.setState({
      sortColumn: column,
      sortDirection: this.state.sortColumn !== column
        ? SORT_ASCENDING
        : (this.state.sortDirection === SORT_ASCENDING
          ? SORT_DESCENDING
          : SORT_ASCENDING)
    })
  }

  _getSortIconName () {
    if (this.state.sortDirection === SORT_ASCENDING) {
      return 'caret-up'
    } else {
      return 'caret-down'
    }
  }

  _sortResources (resources, attributeRanking) {
    if (this.state.sortColumn !== null) {
      let compare
      let path
      if (this.state.sortColumn !== 0) {
        const attribute = attributeRanking.get(this.state.sortColumn - 1)
        compare = getAttributeRenderer(attribute).compare
        if (!compare) {
          return resources
        }
        path = Map.isMap(attribute) ? attribute.get('path') : [ String(attribute) ]
        if (Map.isMap(attribute) && (attribute.get('name') === 'Warn' || attribute.get('name') === 'Compliant')) {
          return resources.sort((resourceA, resourceB) => {
            const attrA = resourceA.get(...path)
            const attrB = resourceB.get(...path)
            return compare(attrA, attrB) * this.state.sortDirection
          })
        }
      } else {
        compare = (displayIdA, displayIdB) => displayIdA.localeCompare(displayIdB)
        path = ['display_id']
      }

      return resources.sort((resourceA, resourceB) => {
        const attrA = resourceA.getIn(['blob', ...path])
        const attrB = resourceB.getIn(['blob', ...path])
        return compare(attrA, attrB) * this.state.sortDirection
      })
    }

    return resources
  }

  _generateResourceItems (attributeRanking, collection, onResourceSelect) {
    const nameColumn = <Column
      key={'Name'}
      dataKey={['blob', 'display_id']}
      label={'Name'}
      width={COLUMN_WIDTH}
      cellDataGetter={({ rowData, dataKey }) => rowData.getIn(dataKey)}
      headerRenderer={({ label }) => {
        return <div
          className={classNames(style.compactableDiv, style.sortableTableHeader)}
          onClick={() => this._sortList(0, attributeRanking)}
        >
          {label}
          {this._getSortIcon(0)}
        </div>
      }}
    />

    const otherColumns = Array.from(attributeRanking, (attr, i) => {
      const name = Iterable.isIterable(attr) ? attr.get('name') : attr

      if (name === 'Name') {
        return
      }

      const column = i + 1
      const sortableColumn = column > 0 && getAttributeRenderer(attributeRanking.get(column - 1)).compare
      const onClickHandler = sortableColumn ? () => this._sortList(column) : undefined

      return <Column
        key={name}
        dataKey={attr}
        label={name}
        width={COLUMN_WIDTH}
        cellDataGetter={({ dataKey, rowData }) => {
          if (dataKey === WarnAttribute || dataKey === CompliantAttribute) {
            return rowData
          }

          return rowData.get('blob')
        }}
        cellRenderer={({ cellData, dataKey }) => {
          return <Attribute
            attribute={dataKey}
            context={cellData}
          />
        }}
        headerRenderer={({ label }) => {
          return <div
            className={classNames(
              style.compactableDiv, style.sortableTableHeader, {
                [style.sortableTableHeader]: sortableColumn
              }
            )}
            onClick={onClickHandler}
          >
            {label}
            {this._getSortIcon(column)}
          </div>
        }}
      />
    })

    const columns = [nameColumn].concat(otherColumns)

    if (collection.get('resources', List()).size) {
      const sortedResources = this._sortResources(
        collection.get('resources'),
        attributeRanking
      ).toList()

      return <div>
        <AutoSizer disableHeight>
          {({ width }) => {
            const height = sortedResources.size < MAX_TABLE_RESOURCES
              ? sortedResources.size * ROW_HEIGHT + HEADER_HEIGHT
              : MAX_TABLE_RESOURCES * ROW_HEIGHT

            return <Table
              className={style.resourceItemsTable}
              height={height}
              headerHeight={HEADER_HEIGHT}
              width={width - PADDING}
              rowHeight={ROW_HEIGHT}
              rowCount={sortedResources.size}
              rowGetter={({ index }) => sortedResources.get(index)}
              rowRenderer={props => {
                const node = props.rowData

                let className = classNames(style.resourceItemRow, {
                  [style.selected]: this.props.currentResourceId === node.get('id'),
                  [style.secondary]: node.get('depth') > 0
                })

                const a11yProps = {'aria-rowindex': props.index + 1}

                return <div
                  {...a11yProps}
                  className={`${className} ${props.className}`}
                  key={props.key}
                  role='row'
                  style={props.style}
                  onClick={() => onResourceSelect(node.get('id'))}
                >
                  {props.columns}
                </div>
              }}
            >
              {columns}
            </Table>
          }}
        </AutoSizer>
      </div>
    }

    return <div className={style.resourceItemsTable}>Loading...</div>
  }

  _getSortIcon (column) {
    if (this.state.sortColumn === column) {
      return <FontAwesome name={this._getSortIconName()} className={style.icon} />
    }
  }
  render () {
    const summaryStyle = {
      upperPanelCollapsed: classNames(style.summaryAccordion),
      upperPanelExpanded: classNames(style.summaryAccordion, style.active),
      lowerPanelCollapsed: classNames(style.summaryPanel),
      lowerPanelExpanded: classNames(style.summaryPanel, style.show)
    }

    const containerClass = classNames({
      [style.cardCompact]: this.props.compact
    })

    const summaryAttributes = this.props.attributeRanking.filter(isSimpleAttribute).take(3).push(WarnAttribute, CompliantAttribute)
    const title = `${this.props.collection.get('id')} - ${this.props.collection.get('name')} (${this.props.collection.get('size')})`

    return <div className={containerClass}>
      <Accordion
        title={title}
        content={this._generateResourceItems(
          summaryAttributes,
          this.props.collection,
          this.props.onResourceSelect
        )}
        style={summaryStyle}
        color={this.props.collection.get('color')}
        open={this.props.open}
        onOpen={() => this.props.onCollectionToggle(this.props.collection.get('id'))} />
    </div>
  }
}

CollectionCard.propTypes = {
  collection: ImmutablePropTypes.map.isRequired,
  currentResourceId: PropTypes.string,
  attributeRanking: ImmutablePropTypes.list.isRequired,
  onResourceSelect: PropTypes.func.isRequired,
  onCollectionToggle: PropTypes.func,
  open: PropTypes.bool,
  compact: PropTypes.bool
}

export default CollectionCard
