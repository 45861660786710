import React from 'react'
import PropTypes from 'prop-types'

import Hint from 'components/Hint'

import style from './CredentialsFormAzure.styl'

export class CredentialsFormAzure extends React.Component {
  constructor () {
    super()

    this.state = {
      tenant: null,
      client_id: null,
      secret: null
    }

    this._onTenantChange = this._onTenantChange.bind(this)
    this._onClientIdChange = this._onClientIdChange.bind(this)
    this._onSecretChange = this._onSecretChange.bind(this)
  }

  componentWillMount () {
    this.props.onCredentialsChange(this.state)
  }

  _onTenantChange ({ target }) {
    this.setState((prevState, props) => {
      return {
        tenant: target.value
      }
    }, () => {
      this.props.onCredentialsChange(this.state)
    })
  }

  _onClientIdChange ({ target }) {
    this.setState((prevState, props) => {
      return {
        client_id: target.value
      }
    }, () => {
      this.props.onCredentialsChange(this.state)
    })
  }

  _onSecretChange ({ target }) {
    this.setState((prevState, props) => {
      return {
        secret: target.value
      }
    }, () => {
      this.props.onCredentialsChange(this.state)
    })
  }

  render () {
    return (
      <div>
        <div className={style.formField}>
          <label>
            Enter the Tenant ID
            <Hint className={style.hint}>
              Must be in a UUID4 format
            </Hint>
          </label>
          <input
            required name='tenant' type='text'
            disabled={!this.props.editable}
            pattern='^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$'
            value={this.state.tenant || ''}
            onChange={this._onTenantChange} />
        </div>
        <div className={style.formField}>
          <label>
            Enter the Client ID (also known as the Application ID)
            <Hint className={style.hint}>
              Must be in a UUID4 format
            </Hint>
          </label>
          <input
            required name='client_id' type='text'
            disabled={!this.props.editable}
            pattern='^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$'
            value={this.state.client_id || ''}
            onChange={this._onClientIdChange} />
        </div>
        <div className={style.formField}>
          <label>
            Enter the Secret (also known as the Application Secret)
            <Hint className={style.hint}>
              Must be in an encoded base64 format
            </Hint>
          </label>
          <input
            required name='secret' type='password'
            disabled={!this.props.editable}
            value={this.state.secret || ''}
            onChange={this._onSecretChange} />
        </div>
      </div>
    )
  }
}

CredentialsFormAzure.defaultProps = {
  editable: true
}

CredentialsFormAzure.propTypes = {
  onCredentialsChange: PropTypes.func.isRequired,
  editable: PropTypes.bool.isRequired
}

export default CredentialsFormAzure
