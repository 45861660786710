import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import Button from 'components/Button'
import ModalDialog from 'components/ModalDialog'
import { hideConfirmationModal } from 'state/actions'
import style from './ConfirmationModal.styl'

export class ConfirmationModal extends React.Component {
  constructor () {
    super()

    this.state = {input: ''}
  }

  componentWillReceiveProps (nextProps) {
    nextProps.visible || this.setState({input: ''})
  }

  render () {
    if (!this.props.visible) {
      return null
    }

    const input = this.props.enableInput ? <div>
      <input
        className={style.inputBox}
        type='text'
        value={this.state.input}
        onChange={(e) => this.setState({input: e.target.value})}
        autoFocus
      />
    </div> : null

    return this.props.visible ? (
      <ModalDialog
        title={this.props.title}
        onDismiss={() => this.props.hideModal()}>
        <div>{this.props.description}</div>
        <form onSubmit={() => {
          this.props.callback(this.state.input)
          this.props.hideModal()
        }}>
          { input }
          <div className={style.controls}>
            <Button onClick={() => this.props.hideModal()} flat>Cancel</Button>
            <Button
              disabled={this.props.disabled(this.state.input)}
              type={Button.WARN}
              submit>
              {this.props.title}
            </Button>
          </div>
        </form>
      </ModalDialog>
    ) : null
  }
}

ConfirmationModal.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]).isRequired,
  callback: PropTypes.func.isRequired,
  disabled: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  enableInput: PropTypes.bool
}

function mapStateToProps (state) {
  return {
    title: state.confirmationModal.title,
    description: state.confirmationModal.description,
    callback: state.confirmationModal.callback,
    disabled: state.confirmationModal.disabled,
    visible: state.confirmationModal.visible,
    enableInput: state.confirmationModal.enableInput
  }
}

function mapDispatchToProps (dispatch) {
  return {
    hideModal: () => { dispatch(hideConfirmationModal()) }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmationModal)
