import PropTypes from 'prop-types'
import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List, OrderedMap } from 'immutable'
import { connect } from 'react-redux'
import FontAwesome from 'react-fontawesome'

import Button from 'components/Button'
import Card, { CardBody, CardHeader } from 'components/Card'
import LoadingIndicator from 'components/LoadingIndicator'
import Overview from './components/Overview'
import GroupPolicyDetails from './components/GroupPolicyDetails'
import PolicyHierarchy from '../PolicyHierarchy'
import { Tabs, Tab } from 'components/Tabs'
import { startFeatureTour } from 'state/actions'
import { getAvailablePolicyTypes } from 'state/selectors'
import { RESOURCE_INFORMATION } from 'refs'
import { ResourceDetailsVariants } from 'scenes/Resources/components/ResourceDetails'

import style from './GroupDetails.styl'

export class GroupDetails extends React.Component {
  _getFeatureTourButton = () => (
    <Button
      className={style.featureTourButton}
      onClick={this.props.onStartFeatureTour}
      flat>
      <FontAwesome name='question' />
    </Button>
  )

  _getMoreTabsAvailable = () => (
    <div
      className={style.tabBarExtraEllipsis}>
      <FontAwesome name='ellipsis-h' />
    </div>
  )

  buildPolicyTabs () {
    const tabBarExtra = (
      this.props.variant === ResourceDetailsVariants.MINI
      ? this._getMoreTabsAvailable()
      : null
    )

    const propagatedPolicies = this.props.resourceGroup.get('propagated_policies') || List()
    return OrderedMap({
      'Group policies': (
        <Tab title='Group policies' key='Group policies' tabBarExtra={tabBarExtra}>
          <CardBody>
            <GroupPolicyDetails
              attachedPolicies={this.props.attachedPolicies}
              policyTypes={this.props.policyTypes}
              organizationRole={this.props.organizationRole}
              onPolicyTypeChanged={this.props.onSelectPolicyType}
              currentPolicyTypeId={this.props.currentPolicyTypeId}
              resourceGroup={this.props.resourceGroup} />
          </CardBody>
        </Tab>
      ),
      ...(propagatedPolicies.size
        ? {'Group policy hierarchy': (
          <Tab title='Group policy hierarchy' key='Group policy hierarchy' tabBarExtra={tabBarExtra}>
            <CardBody>
              <PolicyHierarchy policies={propagatedPolicies} policyTypes={this.props.policyTypes} isResourceGroup />
            </CardBody>
          </Tab>
        )} : {}
      )
    })
  }

  render () {
    if (!this.props.resourceGroup || this.props.resourceGroup.isEmpty()) {
      return <div className={style.loadingContainer}><LoadingIndicator /></div>
    }

    const builtPolicyTabs = this.buildPolicyTabs()
    const activePolicyTab = this.props.policiesActiveTab
    const selectedPolicyTab = activePolicyTab === null || !builtPolicyTabs.get(activePolicyTab)
      ? 'Group policies'
      : activePolicyTab

    const policyTabs = builtPolicyTabs.reduce((tabs, tab, title) => {
      if (this.props.variant === ResourceDetailsVariants.MINI) {
        if (title === selectedPolicyTab) {
          tabs.push(tab)
        }
      } else {
        tabs.push(tab)
      }
      return tabs
    }, [])

    return (
      <div data-ref={RESOURCE_INFORMATION}>
        <Card>
          <CardHeader title='Overview' />
          <CardBody>
            <Overview resourceGroup={this.props.resourceGroup} />
          </CardBody>
        </Card>
        <Card>
          <Tabs onTabChange={this.props.onPoliciesActiveTabChange} selectedTabTitle={selectedPolicyTab}>
            {policyTabs}
          </Tabs>
        </Card>
      </div>
    )
  }
}

GroupDetails.propTypes = {
  resourceGroup: ImmutablePropTypes.map,
  attachedPolicies: ImmutablePropTypes.list.isRequired,
  policyTypes: ImmutablePropTypes.list.isRequired,
  onStartFeatureTour: PropTypes.func,
  organizationRole: PropTypes.number.isRequired,
  currentPolicyTypeId: PropTypes.string.isRequired,
  onPoliciesActiveTabChange: PropTypes.func.isRequired,
  policiesActiveTab: PropTypes.string,
  onSelectPolicyType: PropTypes.func.isRequired
}

function mapDispatchToProps (dispatch) {
  return {
    onStartFeatureTour: () => dispatch(startFeatureTour('resource-view'))
  }
}

function mapStateToProps (state, ownProps) {
  const resourceGroup = state.currentResourceGroup
  const attachedPolicies = state.attachedPolicies.get(resourceGroup.get('id')) || List()
  return {
    policyTypes: getAvailablePolicyTypes(state),
    currentPolicyTypeId: state.currentPolicyTypeId,
    resourceGroup,
    attachedPolicies
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupDetails)
