import { Map, Set, fromJS, List } from 'immutable'

import apiRequest from 'apiRequest'
import { getPendingResourceTypes } from 'state/resources/selectors'
import { getCurrentQuery, getResourcesVisualizationOptions } from 'state/selectors'
import { executeWithProgress, fetchLatestDatapoints, storeResources, storeRelations, notifyUser } from 'state/actions'

export const SET_EXPANDED_COLLECTION_LIMIT = 'SET_EXPANDED_COLLECTION_LIMIT'
export const SET_EXPANDED_COLLECTION_NAMES = 'SET_EXPANDED_COLLECTION_NAMES'
export const RESOURCE_TYPES_REQUEST = 'RESOURCE_TYPES_REQUEST'
export const RESOURCE_TYPES_RESPONSE = 'RESOURCE_TYPES_RESPONSE'
export const STORE_COLLECTION_RESOURCES = 'STORE_COLLECTION_RESOURCES'
export const STORE_COLLECTION_RELATIONS = 'STORE_COLLECTION_RELATIONS'
export const UPDATE_CURRENT_RESOURCE = 'UPDATE_CURRENT_RESOURCE'
export const UPDATE_CURRENT_RESOURCE_GROUP = 'UPDATE_CURRENT_RESOURCE_GROUP'
export const UPDATE_PROPAGATED_POLICIES = 'UPDATE_PROPAGATED_POLICIES'
export const UPDATE_ATTACHED_POLICIES = 'UPDATE_ATTACHED_POLICIES'
export const UPDATE_APPLICABLE_POLICIES = 'UPDATE_APPLICABLE_POLICIES'
export const UPDATE_RELATED_RESOURCES = 'UPDATE_RELATED_RESOURCES'
export const UPDATE_PROPAGATED_POLICY_VERIFICATION = 'UPDATE_PROPAGATED_POLICY_VERIFICATION'
export const HIGHLIGHT_RESOURCE_GROUP = 'HIGHLIGHT_RESOURCE_GROUP'
export const RESET_RESOURCE_GROUP_HIGHLIGHT = 'RESET_RESOURCE_GROUP_HIGHLIGHT'
export const UPDATE_INVOCATIONS = 'UPDATE_INVOCATIONS'
export const POLICY_INVOCATION_IS_FETCHING = 'POLICY_INVOCATION_IS_FETCHING'
export const UPDATE_POLICY_INVOCATIONS = 'UPDATE_POLICY_INVOCATIONS'
export const NOTIFY_COVERAGE_POLICY_TYPE_CHANGED = 'NOTIFY_COVERAGE_POLICY_TYPE_CHANGED'
export const NOTIFY_POLICY_TYPE_CHANGED = 'NOTIFY_POLICY_TYPE_CHANGED'
export const BEGIN_RESOURCE_FETCHING = 'BEGIN_RESOURCE_FETCHING'
export const BEGIN_RESOURCE_GROUP_FETCHING = 'BEGIN_RESOURCE_GROUP_FETCHING'
export const BEGIN_APPLICABLE_POLICIES_DETAILS_FETCHING = 'BEGIN_APPLICABLE_POLICIES_DETAILS_FETCHING'
export const APPLICABLE_POLICIES_DETAILS_FETCHED = 'APPLICABLE_POLICIES_DETAILS_FETCHED'

export function resourceTypesRequest (pendingResourceTypes) {
  return {
    type: RESOURCE_TYPES_REQUEST,
    pendingResourceTypes
  }
}

export function resourceTypesResponse (resourceTypes) {
  return {
    type: RESOURCE_TYPES_RESPONSE,
    resourceTypes
  }
}

export function ensureResourceTypes () {
  return (dispatch, getState) => {
    const state = getState()

    let pendingResourceTypes = getPendingResourceTypes(state)
    if (!pendingResourceTypes) {
      pendingResourceTypes = apiRequest(dispatch, 'GET', 'resource_types/')
        .then(response => {
          return response.body.results.reduce((acc, resourceType) => {
            return acc.set(resourceType.name, fromJS(resourceType))
          }, Map())
        })
        .then(resourceTypes => {
          dispatch(resourceTypesResponse(resourceTypes))
          return resourceTypes
        })
    }

    dispatch(resourceTypesRequest(pendingResourceTypes))
    return pendingResourceTypes
  }
}

export function setExpandedCollectionsWithinLimit (expandedCollectionNames = Set(), prefer = Set()) {
  return (dispatch, getState) => {
    const state = getState()

    if (state.expandedCollectionLimit === null) {
      return dispatch({ type: SET_EXPANDED_COLLECTION_NAMES, expandedCollectionNames })
    }

    const collectionSet = state.normalizedCollectionSets.getIn(['collectionSets', state.queryCollectionSetID])

    if (collectionSet === undefined) {
      return dispatch({ type: SET_EXPANDED_COLLECTION_NAMES, expandedCollectionNames })
    }

    const collectionSizes = collectionSet.get('collections').reduce((acc, col) => {
      return acc.set(col.get('id'), col.get('size'))
    }, Map())

    // We sort the collection names in an order of priority (preferred first, then by size)
    const sortedExpandedCollectionNames = expandedCollectionNames.sortBy(name => {
      if (prefer.has(name)) {
        return -collectionSizes.get(name)
      }
      return collectionSizes.get(name)
    })

    const partitionedCollectionNames = sortedExpandedCollectionNames.reduce((acc, name) => {
      const candidateSize = acc.expandedSize - 1 + collectionSizes.get(name)
      // Decide whether this collection should be expanded or collapsed
      if (candidateSize > state.expandedCollectionLimit) {
        if (state.expandedCollections.includes(name)) {
          return { ...acc, collapse: acc.collapse.add(name) }
        }
        return { ...acc, doNotOpen: acc.doNotOpen.add(name) }
      }
      return { ...acc, expand: acc.expand.add(name), expandedSize: candidateSize }
    }, {
      expand: Set(),
      collapse: Set(),
      doNotOpen: Set(),
      expandedSize: collectionSet.get('collections').size
    })

    if (partitionedCollectionNames.collapse.size > 0) {
      const msg = `To respect view limit of ${state.expandedCollectionLimit} items, collections were collapsed: ${partitionedCollectionNames.collapse.join(', ')}`
      dispatch(notifyUser(msg, 'info'))
    }

    if (partitionedCollectionNames.doNotOpen.size > 0) {
      const msg = `To respect view limit of ${state.expandedCollectionLimit} items, collections could not be opened: ${partitionedCollectionNames.doNotOpen.join(', ')}`
      dispatch(notifyUser(msg, 'info'))
    }

    return dispatch({ type: SET_EXPANDED_COLLECTION_NAMES, expandedCollectionNames: partitionedCollectionNames.expand })
  }
}

export function setExpandedCollectionLimit (limit = null) {
  return (dispatch, getState) => {
    dispatch({ type: SET_EXPANDED_COLLECTION_LIMIT, limit })
    return dispatch(setExpandedCollectionsWithinLimit(getState().expandedCollections))
  }
}

export function toggleCollection (nodeId) {
  return (dispatch, getState) => {
    if (!getState().expandedCollections.has(nodeId)) {
      dispatch(executeWithProgress(fetchCollectionResources(nodeId)))
      return dispatch(setExpandedCollectionsWithinLimit(getState().expandedCollections.add(nodeId), Set([nodeId])))
    }
    return dispatch(setExpandedCollectionsWithinLimit(getState().expandedCollections.delete(nodeId)))
  }
}

export function ensureCollectionResources (force = false) {
  return (dispatch, getState) => {
    const state = getState()

    let collectionsToFetch = state.expandedCollections

    if (!force) {
      const collectionResources = state.collectionResources.get(state.queryCollectionSetID, Map())
      collectionsToFetch = collectionsToFetch.subtract(collectionResources.keys())
    }

    return Promise.all(collectionsToFetch.map(c => {
      return dispatch(executeWithProgress(fetchCollectionResources(c)))
    }))
  }
}

export function fetchCollectionResources (nodeId) {
  return (dispatch, getState) => {
    const state = getState()
    const collectionSetId = state.queryCollectionSetID
    const query = getCurrentQuery(state)
    const groupId = state.activeResourceGroupId
    const viewOptions = getResourcesVisualizationOptions(state)
    const params = {
      q: query,
      depth: viewOptions.showRelated ? 1 : 0,
      resource_group: groupId,
      scope: nodeId
    }

    return apiRequest(dispatch, 'GET', 'query/')
      .query(params)
      .then(resourcesResponse => {
        const response = resourcesResponse.body
        dispatch(storeResources(response.resources))
        dispatch(storeRelations(response.relations))
        dispatch(storeCollectionResources(collectionSetId, nodeId, response.resources.map(r => r.id)))
        dispatch(storeCollectionRelations(collectionSetId, nodeId, response.relations.map(r => r.id)))
        return dispatch(fetchLatestDatapoints(response.resources.map(r => r.id)))
      })
  }
}

export function storeCollectionResources (collectionSetId, collectionId, resourceIds) {
  return {
    type: STORE_COLLECTION_RESOURCES,
    collectionSetId,
    collectionId,
    resourceIds
  }
}

export function storeCollectionRelations (collectionSetId, collectionId, relationIds) {
  return {
    type: STORE_COLLECTION_RELATIONS,
    collectionSetId,
    collectionId,
    relationIds
  }
}

export function resetExpandedCollections () {
  return { type: SET_EXPANDED_COLLECTION_NAMES, expandedCollectionNames: Set() }
}

export function updateCurrentResource (resource) {
  return {
    type: UPDATE_CURRENT_RESOURCE,
    resource
  }
}

export function updateCurrentResourceGroup (resourceGroup) {
  return {
    type: UPDATE_CURRENT_RESOURCE_GROUP,
    resourceGroup
  }
}

export function updatePropagatedPolicies (policies, resourceGroupId) {
  return {
    type: UPDATE_PROPAGATED_POLICIES,
    policies,
    resourceGroupId
  }
}

export function updateAttachedPolicies (policies, resourceGroupId) {
  return {
    type: UPDATE_ATTACHED_POLICIES,
    policies,
    resourceGroupId
  }
}

export function updateApplicablePolicies (policies, resourceId) {
  return {
    type: UPDATE_APPLICABLE_POLICIES,
    policies,
    resourceId
  }
}

export function updateInvocations (invocations, resourceId) {
  return {
    type: UPDATE_INVOCATIONS,
    resourceId,
    invocations
  }
}

export function updatePolicyInvocations (resourceId, policyId, invocations, cursor) {
  return {
    type: UPDATE_POLICY_INVOCATIONS,
    resourceId,
    invocations,
    policyId,
    cursor
  }
}

export function startPolicyInvocationFetchProgress (resourceId, policyId) {
  return {
    type: POLICY_INVOCATION_IS_FETCHING,
    resourceId,
    policyId
  }
}

export function updateRelatedResources (resourceId, relatedResources) {
  return {
    type: UPDATE_RELATED_RESOURCES,
    resourceId,
    relatedResources
  }
}

export function beginResourceGroupFetching () {
  return {
    type: BEGIN_RESOURCE_GROUP_FETCHING
  }
}

export function fetchResourceGroupDetails (resourceId) {
  return dispatch => {
    dispatch(beginResourceGroupFetching())

    return apiRequest(dispatch, 'GET', `resource_groups/${resourceId}/`)
        .then(response => {
          dispatch(updateAttachedPolicies(response.body.attached_policies, resourceId))
          dispatch(updatePropagatedPolicies(response.body.propagated_policies, resourceId))
          return dispatch(updateCurrentResourceGroup(response.body))
        })
  }
}

export function beginResourceFetching () {
  return {
    type: BEGIN_RESOURCE_FETCHING
  }
}

export function fetchResourceDetails (resourceId) {
  return dispatch => {
    const params = {
      id: resourceId,
      depth: 1
    }

    dispatch(beginResourceFetching())

    return apiRequest(dispatch, 'GET', `query/`)
      .query(params)
      .then(resourceResponse => {
        const [currentResource, relatedResources] = addResourceRelationships(resourceId, resourceResponse.body)

        dispatch(beginApplicablePoliciesDetailsFetching())
        dispatch(updateApplicablePolicyDetailsForResource(resourceId))
        dispatch(fetchLatestDatapoints([resourceId]))
        dispatch(updateRelatedResources(resourceId, relatedResources))
        return dispatch(updateCurrentResource(currentResource))
      })
      .then(() => {
        return dispatch(applicablePoliciesDetailsFetched())
      })
      .catch(() => {
        return dispatch(notifyUser('Unable to fetch details for Policies', 'error'))
      })
  }
}

export function beginApplicablePoliciesDetailsFetching () {
  return {
    type: BEGIN_APPLICABLE_POLICIES_DETAILS_FETCHING
  }
}

export function applicablePoliciesDetailsFetched () {
  return {
    type: APPLICABLE_POLICIES_DETAILS_FETCHED
  }
}

export function updateApplicablePolicyDetailsForResource (resourceId) {
  return executeWithProgress((dispatch) => {
    dispatch(beginApplicablePoliciesDetailsFetching())
    return apiRequest(dispatch, 'GET', 'applicable_policies/')
      .query({ queryable_id: resourceId })
      .then(applicablePoliciesResponse => {
        const applicablePolicies = applicablePoliciesResponse.body.results
        dispatch(updateApplicablePolicies(applicablePolicies, resourceId))

        const applicablePolicyIds = applicablePolicies.reduce((acc, policy) => {
          acc.push(policy.id)
          return acc
        }, [])
        if (applicablePolicyIds.length > 0) {
          return dispatch(fetchLatestInvocations(resourceId, applicablePolicyIds, { 'DISCARDED': { excluded: true } }))
            .then(() => {
              return dispatch(applicablePoliciesDetailsFetched())
            }).catch(() => {
              return dispatch(notifyUser('Unable to fetch details for Policies', 'error'))
            })
        }

        return dispatch(applicablePoliciesDetailsFetched())
      })
  })
}

export function fetchPolicyInvocations (resourceId, policyId, cursor, refetch = false) {
  return executeWithProgress((dispatch, getState) => {
    dispatch(startPolicyInvocationFetchProgress(resourceId, policyId))

    return apiRequest(dispatch, 'GET', 'invocations/')
      .query({
        applicable_policy_id: policyId,
        ...(!refetch && cursor && {cursor: cursor})
      })
      .then(invocationsResponse => {
        const cursor = invocationsResponse.body.next
          ? new window.URL(invocationsResponse.body.next).searchParams.get('cursor')
          : null

        const invocations = (!refetch
          ? getState().policyInvocations.getIn([resourceId, policyId, 'invocations'], List())
          : List()
        ).concat(fromJS(invocationsResponse.body.results))

        return dispatch(updatePolicyInvocations(resourceId, policyId, invocations, cursor))
      })
  })
}

export function fetchLatestInvocations (resourceId, applicablePolicyIds, states) {
  return (dispatch, getState) => {
    return apiRequest(dispatch, 'GET', 'invocations/')
      .query({
        applicable_policy_id: applicablePolicyIds.join(),
        latest: 'True',
        state: getStateValues(states)
      })
      .then(invocationsResponse => {
        dispatch(updateInvocations(invocationsResponse.body.results, resourceId))
      })
  }
}

function getStateValues (states) {
  if (!states) {
    return null
  }

  return Object.keys(states).map((stateName) =>
    states[stateName].excluded ? `!${stateName}` : stateName
  ).join()
}

function addResourceRelationships (resourceId, resourceResponse) {
  const resources = resourceResponse.resources.reduce((map, obj) => {
    map[obj.id] = obj
    return map
  }, {})

  const relations = resourceResponse.relations.filter(
    relation => relation.from_queryable === resourceId || relation.to_queryable === resourceId
  )

  const currentResource = resources[resourceId]

  const relatedResources = relations.map(relation => {
    const resource = relation.from_queryable === resourceId
      ? relation.to_queryable
      : relation.from_queryable

    return {
      resource_type: resources[resource].blob.resource_type,
      direction: relation.from_queryable === resourceId ? 'towards' : 'from',
      depth: resources[resource].depth,
      resource: resources[resource].blob.display_id,
      id: resources[resource].id
    }
  })

  return [currentResource, relatedResources]
}

export function invokeApplicablePolicy (applicablePolicy) {
  return dispatch => {
    const body = {
      applicable_policy: applicablePolicy.get('id')
    }

    return apiRequest(dispatch, 'POST', 'invocations/')
      .type('application/json')
      .send(body)
      .then(() => dispatch(fetchLatestInvocations(applicablePolicy.get('queryable_id'), [applicablePolicy.get('id')], { 'DISCARDED': { excluded: true } })))
      .then(() => dispatch(notifyUser('Invocation sent', 'success')))
      .catch((error) => {
        dispatch(notifyUser('Failed to send invocation', 'error'))
        throw error
      })
  }
}

export function fetchAttachedPolicies (resourceGroupId) {
  return dispatch => {
    return apiRequest(dispatch, 'GET', `attached_policies/`)
      .query({resource_group_id: resourceGroupId})
      .then(response => dispatch(updateAttachedPolicies(response.body.results, resourceGroupId)))
  }
}

export function savePolicy (resourceGroupId, policy, policyTypeId, policyId) {
  return (dispatch, getState) => {
    let resourcePath = ''
    let action = 'POST'
    const body = {
      policy_type_id: policyTypeId,
      policy: policy,
      resource_group_id: resourceGroupId
    }

    if (policyId) {
      action = 'PUT'
      resourcePath = `${policyId}/`
    }

    return apiRequest(dispatch, action, `attached_policies/${resourcePath}`)
      .type('application/json')
      .send(body)
      .then(policyResponse => {
        dispatch(notifyUser(`Policy attached`, 'success'))
        return dispatch(fetchAttachedPolicies(resourceGroupId))
      })
      .catch(error => {
        dispatch(notifyUser(`Error saving policy`, 'error'))
        throw error
      })
  }
}

export function deletePolicy (attachedPolicyId, resourceGroupId) {
  return (dispatch, getState) => {
    return apiRequest(dispatch, 'DELETE', `attached_policies/${attachedPolicyId}/`)
      .type('application/json')
      .then(policyResponse => {
        return dispatch(fetchAttachedPolicies(resourceGroupId))
      })
  }
}

export function validatePropagatedPolicy (resourceGroupId, policy, policyTypeId) {
  return dispatch => {
    dispatch(updatePropagatedPolicyValidation({
      'resolution': 'pending',
      'validation': 'pending'
    }))

    return apiRequest(dispatch, 'POST', 'propagated_policies/validate/')
      .type('application/json')
      .send({policy, resource_group_id: resourceGroupId, policy_type_id: policyTypeId})
      .then(response => dispatch(updatePropagatedPolicyValidation(response.body)))
  }
}

export function highlightResourceGroup (resourceGroupId, scroll = true) {
  return {
    type: HIGHLIGHT_RESOURCE_GROUP,
    resourceGroupId,
    scroll
  }
}

export function resetResourceGroupHighlight () {
  return {
    type: RESET_RESOURCE_GROUP_HIGHLIGHT
  }
}

export function selectCoveragePolicyType (policy) {
  return {
    type: NOTIFY_COVERAGE_POLICY_TYPE_CHANGED,
    policy
  }
}

export function selectPolicyType (policyTypeId) {
  return {
    type: NOTIFY_POLICY_TYPE_CHANGED,
    policyTypeId
  }
}

function updatePropagatedPolicyValidation (propagatedPolicyVerification) {
  return {
    type: UPDATE_PROPAGATED_POLICY_VERIFICATION,
    propagatedPolicyVerification
  }
}
