import PropTypes from 'prop-types'
import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'

import PolicyHierarchyList from 'components/PolicyHierarchy'
import style from './PolicyHierarchy.styl'

class PolicyHierarchy extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      policyId: null
    }
  }

  componentWillMount () {
    this.setState({
      policyId: this.props.policies.first().get('id')
    })
  }

  _createPolicyOptions (policies, policyTypes) {
    return policies.map(policy => {
      const policyType = policyTypes.find(policyType => policyType.get('id') === policy.get('policy_type'), Map())

      if (!policyType) {
        return null
      }

      const label = policyType.get('label')
      const value = policy.get('id')

      return <option key={value} value={value}>{label}</option>
    })
  }

  _onSelect = event => {
    const policyId = event.target.value

    this.setState({ policyId })
  }

  render () {
    const policyOptions = this._createPolicyOptions(this.props.policies, this.props.policyTypes)
    const policy = this.props.policies.find(policy => policy.get('id') === this.state.policyId)

    return (<div>
      <select className={style.select} value={this.state.policyId} onChange={this._onSelect}>
        {policyOptions}
      </select>
      <PolicyHierarchyList
        policy={policy}
        isResourceGroup={this.props.isResourceGroup}
        policyTypes={this.props.policyTypes}
      />
    </div>)
  }
}

PolicyHierarchy.propTypes = {
  policies: ImmutablePropTypes.list.isRequired,
  policyTypes: ImmutablePropTypes.list,
  isResourceGroup: PropTypes.bool
}

export default PolicyHierarchy
