import React from 'react'
import { connect } from 'react-redux'

import PropTypes from 'prop-types'
import CopyToClipboard from 'react-copy-to-clipboard'

import { RadioButton, Item } from 'components/RadioButton'
import Button from 'components/Button'
import Hint from 'components/Hint'

import style from './ExternalNotificationEndpointCreator.styl'
import awsSqsLogo from 'scenes/Manage/awsSqsLogo.svg'
import awsSnsLogo from 'scenes/Manage/awsSnsLogo.svg'

import { notifyUser } from 'state/actions'

import ConfigurationFormAwsSqs from '../ConfigurationForms/ConfigurationFormAwsSqs'
import ConfigurationFormAwsSns from '../ConfigurationForms/ConfigurationFormAwsSns'
import CredentialsFormAws from '../CredentialsForms/CredentialsFormAws'

export class ExternalNotificationEndpointCreator extends React.Component {
  constructor () {
    super()

    this._onSubmit = this._onSubmit.bind(this)
    this._setValue = this._setValue.bind(this)
    this._onConfigurationChange = this._onConfigurationChange.bind(this)
    this._onCredentialsChange = this._onCredentialsChange.bind(this)

    this.state = {
      type: 'aws-sqs',
      name: ''
    }

    this.configuration = {}
    this.credentials = {}
  }

  _setValue (key, value) {
    let state = {}
    state[key] = value.target.value
    this.setState(state)
  }

  _onConfigurationChange (configuration) {
    this.configuration = configuration
  }

  _onCredentialsChange (credentials) {
    this.credentials = credentials
  }

  _onSubmit (e) {
    e.preventDefault()

    const fields = {
      endpoint_type: this.state.type,
      name: this.state.name,
      configuration: this.configuration,
      credentials: this.credentials
    }

    this.props.onCreate(fields)
      .then(() => this.props.onClose())
  }

  getConfigurationComponent () {
    switch (this.state.type) {
      case 'aws-sqs':
        return (
          <ConfigurationFormAwsSqs
            initialConfiguration={{}}
            onConfigurationChange={this._onConfigurationChange} />
        )
      case 'aws-sns':
        return (
          <ConfigurationFormAwsSns
            initialConfiguration={{}}
            onConfigurationChange={this._onConfigurationChange} />
        )
      default:
        this.props.onError(this.state.type + ' is not known. Endpoints of this type cannot be created at this time. Please use the "Provide feedback" button to report this problem.')

        // we can't use this.setState() because we're rendering
        this.state.type = ''
        this.forceUpdate()
    }
  }

  getCredentialsComponent () {
    let templateUrl = ''
    switch (this.state.type) {
      case 'aws-sqs':
        templateUrl = this.props.onReturnTemplateUrl('sqs')
        return (
          <div>
            <div className={style.description}>
              <p>
                Conductor uses IAM Roles for access to your AWS SQS queue.
                This requires that an IAM Role is deployed in the AWS account in which the queue exists.
                This can be deployed manually, or more easily via the following CloudFormation template.
                Instantiate a CloudFormation stack by copying the template URL - then copy the IAM Role ARN from the stack outputs into the form below.
              </p>
              <CopyToClipboard text={templateUrl}
                onCopy={() => {}}>
                <Button
                  className={style.copy} >
                  Copy CloudFormation Template URL
                </Button>
              </CopyToClipboard>
            </div>

            <CredentialsFormAws onCredentialsChange={this._onCredentialsChange} />
          </div>
        )
      case 'aws-sns':
        templateUrl = this.props.onReturnTemplateUrl('sns')
        return (
          <div>
            <div className={style.description}>
              <p>
                Conductor uses IAM Roles for access to your AWS SNS topic.
                This requires that an IAM Role is deployed in the AWS account in which the queue exists.
                This can be deployed manually, or more easily via the following CloudFormation template.
                Instantiate a CloudFormation stack by copying the template URL - then copy the IAM Role ARN from the stack outputs into the form below.
              </p>
              <CopyToClipboard text={templateUrl}
                onCopy={() => {}}>
                <Button
                  className={style.copy} >
                  Copy CloudFormation Template URL
                </Button>
              </CopyToClipboard>
            </div>

            <CredentialsFormAws onCredentialsChange={this._onCredentialsChange} />
          </div>
        )
      default:
        this.props.onError(this.state.type + ' is not known. Endpoints of this type cannot be created at this time. Please use the "Provide feedback" button to report this problem.')

        // we can't use this.setState() because we're rendering
        this.state.type = ''
        this.forceUpdate()
    }
  }

  render () {
    const logos =
      <RadioButton
        value={this.state.type}
        filled={false}
        onChange={type => { this.setState({type: type}) }} >
        <Item className={style.item} value='aws-sqs'><img src={awsSqsLogo} className={style.logo} title='AWS SQS' /></Item>
        <Item className={style.item} value='aws-sns'><img src={awsSnsLogo} className={style.logo} title='AWS SNS' /></Item>
      </RadioButton>

    return (
      <div className={style.contents}>
        <form onSubmit={this._onSubmit}>
          <div className={style.formField}>
            <label>
              Select the external notification endpoint type
              <Hint className={style.hint}>
                External Notification Endpoints require configuration in one or more of your public cloud providers.
                Please select an endpoint type from the following supported.
                More information on what permissions are required, and how Conductor uses them is available at <a href='https://docs.conductor.cloudreach.com/' target='_blank'>docs.conductor.cloudreach.com</a>
              </Hint>
            </label>
            {logos}
          </div>
          <div className={style.formField}>
            <label>
              Endpoint Name
              <Hint className={style.hint}>
                Enter a unique name for the external notification endpoint.
              </Hint>
            </label>
            <input
              type='text' name='name' value={this.state.name || ''}
              onChange={this._setValue.bind(this, 'name')} />
          </div>
          {this.getConfigurationComponent()}
          {this.getCredentialsComponent()}
          <div className={style.controls}>
            <Button onClick={() => this.props.onClose()} flat>Cancel</Button>
            <Button type={Button.PRIMARY} submit>Save</Button>
          </div>
        </form>
      </div>
    )
  }
}

ExternalNotificationEndpointCreator.propTypes = {
  onReturnTemplateUrl: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired
}

function mapDispatchToProps (dispatch) {
  return {
    onError: error => dispatch(notifyUser(error, 'error'))
  }
}

export default connect(
  null, mapDispatchToProps
)(ExternalNotificationEndpointCreator)
