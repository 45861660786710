import PropTypes from 'prop-types'
import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'

import { scaleOrdinal } from 'd3-scale'
import colorScheme from '../../services/colorScheme'
import classNames from 'classnames'

import style from './ResourceGroupTable.styl'

export class ResourceGroupTable extends React.Component {
  componentDidUpdate () {
    const highlightedResourceGroupId = this._highlightedResourceGroupId()
    const shouldScroll = this._shouldScrollToHighlightedResourceGroupId()

    if (shouldScroll) {
      const element = document.getElementById(highlightedResourceGroupId)
      if (element) {
        const topPos = element.offsetTop
        document.getElementById('resource-group-table').scrollTop = topPos
      }
    }
  }

  onMouseOver = resourcegroupId => {
    this.props.highlightResourceGroup(resourcegroupId, false)
  }

  onMouseOut = () => {
    this.props.resetResourceGroupHighlight()
  }

  onClick = resourcegroupId => {
    this.props.onSelect(resourcegroupId)
    this.props.resetResourceGroupHighlight()
  }

  _generateProps = node => {
    return {
      onMouseOver: () => this.onMouseOver(node),
      onMouseOut: this.onMouseOut,
      onClick: () => this.onClick(node)
    }
  }

  _locateHighlightedNode = node => {
    if (node.data.id === this._highlightedResourceGroupId()) {
      return node
    } else if (node.children) {
      for (let i = 0; i < node.children.length; i++) {
        const foundNode = this._locateHighlightedNode(node.children[i])
        if (foundNode) {
          return foundNode
        }
      }
    }

    return null
  }

  _highlightedResourceGroupId = () => {
    return (this.props.highlightedResourceGroup && this.props.highlightedResourceGroup.get('id', null)) || null
  }

  _shouldScrollToHighlightedResourceGroupId = () => {
    return this.props.highlightedResourceGroup && this.props.highlightedResourceGroup.get('scroll', false)
  }

  render () {
    if (!this.props.activeResourceGroupNode || this.props.variant !== 'normal') {
      return null
    }

    const colors = scaleOrdinal(colorScheme)

    const parentNodeId = this.props.activeResourceGroupNode.parent && this.props.activeResourceGroupNode.parent.data.id

    const tableClassName = classNames({ [style.disabled]: this.props.isTransitioning })
    const higlightedClassName = node => this._highlightedResourceGroupId() === node ? style.active : style.inactive

    const activeClassName = classNames(style.activeNodeName)

    const color = node => generateColor(node, colors)

    const childrenNodeIds = this.props.activeResourceGroupNode.children ? this.props.activeResourceGroupNode.children.map(node => node.data.id) : []

    let subTree = []
    let subTreeLocation = null
    if (this._highlightedResourceGroupId() && this.props.activeResourceGroupNode.children) {
      if (!childrenNodeIds.includes(this._highlightedResourceGroupId())) {
        for (let i = 0; i < this.props.activeResourceGroupNode.children.length; i++) {
          const node = this._locateHighlightedNode(this.props.activeResourceGroupNode.children[i])

          if (node) {
            let currentNode = node
            while (!childrenNodeIds.includes(currentNode.data.id)) {
              subTree = [currentNode].concat(subTree)
              currentNode = currentNode.parent
            }

            subTreeLocation = i
            break
          }
        }
      }
    }

    const resourceGroupNodes = this.props.activeResourceGroupNode.children
      ? this.props.activeResourceGroupNode.children.map((node, i) =>
        <div id={node.data.id} {...this._generateProps(node.data.id)} key={node.data.id} className={style.topLevelNode}>
          <div style={{ color: color(node) }}>●</div>
          <div className={higlightedClassName(node.data.id)}><span>{node.data.name}</span></div>
          <div className={style.groups}>{node.data.children ? node.data.children.length : 0}</div>
        </div>)
      : null

    const nestedNodes = subTree.map(node =>
      <div id={node.data.id} {...this._generateProps(node.data.id)} key={node.data.id} className={style.nestedNode}>
        <div />
        <div style={{ color: color(node) }}>▸</div>
        <div className={higlightedClassName(node.data.id)}><span>{node.data.name}</span></div>
        <div className={style.groups}>{node.data.children ? node.data.children.length : 0}</div>
      </div>)

    const parentNode = parentNodeId
      ? <div id={parentNodeId} {...this._generateProps(parentNodeId)} className={style.parentNode}>
        <div className={higlightedClassName(parentNodeId)}><span>Back</span></div>
      </div>
      : null

    if (nestedNodes.length > 0) {
      resourceGroupNodes.splice(subTreeLocation + 1, 0, ...nestedNodes)
    }

    return <div id='resource-group-table' className={classNames(style.wrapper, tableClassName)}>
      <div id={this.props.activeResourceGroupId} {...this._generateProps(this.props.activeResourceGroupId)} className={style.topLevelNode}>
        <div className={classNames(style.activeNode)}>●</div>
        <div className={activeClassName}><span>{this.props.activeResourceGroupNode.data.name}</span></div>
        <div className={style.groups}>{this.props.activeResourceGroupNode.children ? this.props.activeResourceGroupNode.children.length : 0}</div>
      </div>
      {resourceGroupNodes}
      {parentNode}
    </div>
  }
}

function generateColor (resourceGroupNode, color) {
  return color(resourceGroupNode.depth)
}

ResourceGroupTable.propTypes = {
  variant: PropTypes.string,
  activeResourceGroupNode: PropTypes.object,
  activeResourceGroupId: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  highlightedResourceGroup: ImmutablePropTypes.contains({
    id: PropTypes.string,
    scroll: PropTypes.bool
  }),
  highlightResourceGroup: PropTypes.func.isRequired,
  resetResourceGroupHighlight: PropTypes.func.isRequired,
  isTransitioning: PropTypes.bool.isRequired
}

export default ResourceGroupTable
