import PropTypes from 'prop-types'
import React from 'react'
import FontAwesome from 'react-fontawesome'

import style from './ConfigurationValidationStatus.styl'

export const ConfigurationValidationStatus = ({ configurationValidation, hidden = false }) => {
  const validationDiv = statusToDiv(
    configurationValidation,
    'Configuration is valid',
    'Configuration is invalid',
    `Schema is ${configurationValidation}`
  )

  return (<div className={hidden ? style.configurationValidationStatusHidden : style.configurationValidationStatusVisible}>
    {validationDiv}
  </div>)
}

ConfigurationValidationStatus.propTypes = {
  configurationValidation: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array
  ]),
  hidden: PropTypes.bool
}

function valid (text) {
  return (<div className={style.validConfiguration}>
    <FontAwesome name='check-square' /> {text}
  </div>)
}

function invalid (text, error) {
  return (<div className={style.invalidConfiguration}>
    <FontAwesome name='exclamation-triangle' /> {text}
    <div className={style.invalidConfigurationDetails}>
      <b>Details:</b>
      <pre>{JSON.stringify(error, null, 2)}</pre>
    </div>
  </div>)
}

function pending (text) {
  return (<div className={style.pendingConfiguration}>
    <FontAwesome name='question-circle' /> {text}
  </div>)
}

function statusToDiv (status, validText, invalidText, pendingText) {
  switch (status) {
    case 'successful':
      return valid(validText)
    case 'pending':
    case 'unknown':
      return pending(pendingText)
    default:
      return invalid(invalidText, status)
  }
}

export default ConfigurationValidationStatus
