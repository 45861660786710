import React from 'react'

import style from './CardBody.styl'

export class CardBody extends React.Component {
  render () {
    return (
      <div className='normal'>
        <div className={style.CardBody}>
          {this.props.children}
        </div>
      </div>
    )
  }
}

export default CardBody
