import PropTypes from 'prop-types'
import React from 'react'
import Ajv from 'ajv'
import ImmutablePropTypes from 'react-immutable-proptypes'

import JsonEditor from 'components/JsonEditor'
import Button from 'components/Button'
import PolicyValidationStatus from './components/PolicyValidationStatus'
import {
  SHOW_SCHEMA_BUTTON,
  EDIT_POLICY_SCHEMA,
  VALIDATE_SAVE_POLICY_BUTTON
} from 'refs'
import style from './PolicyEditor.styl'

class PolicyEditor extends React.Component {
  constructor (props) {
    super(props)
    this.ajv = new Ajv()

    this.state = {
      schemaVisible: false,
      policyValidated: false,
      showValidation: false,
      updatedPolicy: JSON.stringify(props.policy, null, 2),
      schemaValidation: 'pending'
    }
  }

  _validatePolicy () {
    let schemaValidation
    let policyObject

    try {
      policyObject = JSON.parse(this.state.updatedPolicy)
    } catch (err) {
      this.setState({showValidation: true, schemaValidation: String(err)})
      return
    }

    if (!this.props.policyType.isEmpty()) {
      const schema = JSON.parse(JSON.stringify(this.props.policyType.get('schema', '')))
      let valid = this.ajv.validate(schema, policyObject)
      schemaValidation = valid ? 'successful' : this.ajv.errors
    } else {
      schemaValidation = 'schema not found'
    }

    this.setState({
      schemaValidation: schemaValidation,
      showValidation: true
    })

    if (schemaValidation === 'successful') {
      this.props.validatePropagatedPolicy(policyObject, this.props.policyType.get('id'))
    }
  }

  componentWillReceiveProps (nextProps) {
    if (this.props.policy !== nextProps.policy) {
      this.setState({updatedPolicy: JSON.stringify(nextProps.policy, null, 2), policyValidated: false, showValidation: false})
    }

    const resolutionStatus = nextProps.propagatedPolicyValidation.get('resolution')
    const validationStatus = nextProps.propagatedPolicyValidation.get('validation')

    if (resolutionStatus === 'successful' && validationStatus === 'successful' &&
        !this.props.propagatedPolicyValidation.equals(nextProps.propagatedPolicyValidation)) {
      this.setState({ policyValidated: true })
    }
  }

  render () {
    const policyDescription = this.props.policyType.get('description', '').trim()
    const policySchema = this.props.policyType.get('schema', '')

    const validationStatus = <PolicyValidationStatus
      hidden={!this.state.showValidation}
      schemaValidation={this.state.schemaValidation}
      propagatedResolution={this.props.propagatedPolicyValidation.get('resolution', 'unknown')}
      propagatedValidation={this.props.propagatedPolicyValidation.get('validation', 'unknown')}
    />

    const updatePolicy = updatedPolicy => this.setState({updatedPolicy, policyValidated: false, showValidation: false})

    return (<div className={this.props.className}>
      <div className={style.policyView}>
        <div className={style.controls}>
          <div className={style.policyDescription}>
            {policyDescription}
            <Button
              className={style.showPolicyButton}
              onClick={() => this.setState({schemaVisible: !this.state.schemaVisible})}
              flat>
              <div data-ref={SHOW_SCHEMA_BUTTON}>
                {this.state.schemaVisible ? 'Hide Schema' : 'Show Schema'}
              </div>
            </Button>
          </div>
        </div>
        <div className={style.main}>
          <div className={style.textBoxes}>
            <div className={style.editor}>
              <label data-ref={EDIT_POLICY_SCHEMA}>POLICY EDITOR</label>
              <JsonEditor className={style.jsonEditor} jsonObject={this.state.updatedPolicy} showLineNumbering onUpdate={updatePolicy} />
            </div>
            <div className={style.side}>
              { this.state.schemaVisible && (
                <div className={style.policySchema}>
                  <label>POLICY SCHEMA</label>
                  <JsonEditor className={style.jsonEditor} jsonObject={policySchema} readOnly />
                </div>)
              }
              <div className={style.policyInformation} data-inactive={!this.state.policyValidated}>
                <label>PROPAGATED POLICY PRESOLUTION_CONTEXT</label>
                <JsonEditor className={style.jsonEditor} jsonObject={this.props.propagatedPolicyValidation.get('propagated_policy', {})} readOnly />
              </div>
            </div>
          </div>
          <div className={style.validationStatus}>
            { validationStatus }
          </div>
        </div>
        <footer>
          <div data-ref={VALIDATE_SAVE_POLICY_BUTTON}>
            <Button
              onClick={this._validatePolicy.bind(this)}
              disabled={this.state.showValidation}
              flat>
              Validate
            </Button>
          </div>
          <Button
            type={Button.PRIMARY}
            onClick={() => this.props.onSave(JSON.parse(this.state.updatedPolicy))}
            disabled={!this.state.policyValidated}>
            Save
          </Button>
        </footer>
      </div>
    </div>)
  }
}

PolicyEditor.propTypes = {
  policyType: ImmutablePropTypes.map.isRequired,
  className: PropTypes.string,
  policy: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  propagatedPolicyValidation: ImmutablePropTypes.map.isRequired,
  validatePropagatedPolicy: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
}

export default PolicyEditor
