import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import FontAwesome from 'react-fontawesome'
import { connect } from 'react-redux'

import { ADMIN_POLICY } from 'state/actions'
import Dropdown from 'components/Dropdown'
import Button from 'components/Button'
import QueryContext from './components/QueryContext'
import QuerySaver from '../QuerySaver'
import {
        QUERY_BUILDER,
        SAVE_QUERY_BUTTON
} from 'refs'

import style from './QueryBuilder.styl'

export class QueryBuilder extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      formActive: false,
      query: props.currentQuery || '',
      createDropdownActive: false,
      createDropdownAnchor: null,
      submitted: true
    }

    this.formClicked = false
    this.disableCreateDropdown = false
    this.formActiveBeforeDropdown = false
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.currentQuery !== this.state.query && nextProps.currentQuery !== this.props.currentQuery) {
      const query = nextProps.currentQuery || ''
      this.setState({query})
    }
  }

  _dismissDropdown () {
    this.setState({ createDropdownActive: false })
    this._onFormBlur()
  }

  render () {
    const queryBuilderClass = classNames(style.QueryBuilder, {
      [style.expanded]:
        this.state.formActive ||
        (this.state.createDropdownActive && this.formActiveBeforeDropdown) ||
        this.state.query !== ''
    })

    const clearButtonElement = this.state.query !== '' ? (
      <button
        className={style.clearButton}
        onClick={() => {
          this._setQuery('', () => { this._onFormSubmit() })
          this.queryInputElement.focus()
        }}
        onMouseDown={() => this._onFormMouseDown()}
        type='button'>
        <FontAwesome name='times-circle' />
      </button>
    ) : null

    const createDropdown = this.props.organizationRole === ADMIN_POLICY && this.state.createDropdownActive ? (
      <Dropdown
        anchor={this.state.createDropdownAnchor}
        onDismiss={() => this._dismissDropdown()}>
        <QuerySaver
          className={style.querySaver}
          onSave={() => this._dismissDropdown()} />
      </Dropdown>
    ) : null

    const saveQueryButton = this.props.organizationRole === ADMIN_POLICY ? (
      <div data-ref={SAVE_QUERY_BUTTON}>
        <Button
          className={style.createButton}
          onClick={({ currentTarget }) => {
            this._onFormSubmit()
            this._toggleCreateDropdown(currentTarget)
          }}
          onMouseDown={() => {
            this.disableCreateDropdown = this.state.createDropdownActive
            this.formActiveBeforeDropdown = this.state.formActive

            if (!this.state.createDropdownActive) {
              this._onFormMouseDown()
            }
          }}
          flat
          disabled={!this.state.query}>
          <FontAwesome name='plus-circle' />
          <div className={style.tooltip}>
            Create Resource Group
          </div>
        </Button>
      </div>
    ) : null

    return (
      <div className={classNames(this.props.className)}>
        <div className={queryBuilderClass}>
          <QueryContext className={style.context} />
          <form
            ref={element => { this.formElement = element }}
            className={style.queryForm}
            onSubmit={event => {
              event.preventDefault()
              this._onFormSubmit()
            }}
            onBlur={() => this._onFormBlur()}>
            <input
              ref={element => { this.queryInputElement = element }}
              className={classNames({[style.notSubmitted]: !this.state.submitted})}
              onClick={() => this._onFormClick()}
              onMouseDown={() => this._onFormMouseDown()}
              onChange={event => { event.cancelable || this._setQuery(event.target.value) }}
              onBlur={() => {
                this._onNonSubmit()
              }}
              value={this.state.query} />
            {clearButtonElement}
            <button
              className={classNames(style.searchButton, {
                [style.uncommittedSearch]: (this.props.currentQuery !== this.state.query && this.state.query !== '') || (this.props.currentQuery && this.state.query === '')
              })}
              onClick={() => {
                if (this.state.formActive) {
                  this._onFormSubmit()
                } else {
                  this.queryInputElement.focus()
                }
                this._onFormClick()
              }}
              onMouseDown={() => this._onFormMouseDown()}
              type='button'>
              <div data-ref={QUERY_BUILDER}>
                <FontAwesome name='search' />
              </div>
            </button>
            {saveQueryButton}
          </form>
        </div>
        {createDropdown}
      </div>
    )
  }

  _setQuery (query, callback = () => {}) {
    this.setState({ query }, callback)
  }

  _onFormSubmit () {
    this.props.onQuery(this.state.query)
    this.setState({ submitted: true })
  }

  _onFormMouseDown () {
    this.formClicked = true

    setTimeout(() => {
      this.formClicked = false
    }, 0)
  }

  _onFormClick () {
    this.setState({ formActive: true, submitted: true })
  }

  _onFormBlur () {
    if (!this.formClicked) {
      this.setState({ formActive: false })
    }
  }

  _onNonSubmit () {
    if (!this.formClicked && this.props.currentQuery !== this.state.query) {
      this.setState({ submitted: false })
    }
  }

  _toggleCreateDropdown (buttonElement) {
    if (!this.disableCreateDropdown) {
      const buttonDimensions = buttonElement.getBoundingClientRect()

      const dropdownPosition = {
        x: buttonDimensions.left + buttonDimensions.width / 2,
        y: buttonDimensions.bottom + 6
      }

      this.setState({
        createDropdownActive: !this.state.createDropdownActive,
        createDropdownAnchor: dropdownPosition
      })
    }

    this.disableCreateDropdown = false
  }
}

QueryBuilder.defaultProps = {
  organizationRole: 1
}

QueryBuilder.propTypes = {
  className: PropTypes.string,
  onQuery: PropTypes.func,
  organizationRole: PropTypes.number.isRequired
}

function mapStateToProps (state) {
  return {
    currentQuery: state.currentQuery
  }
}

export default connect(
  mapStateToProps
)(QueryBuilder)
