import PropTypes from 'prop-types'
import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import classNames from 'classnames'

import style from './ClickableList.styl'

export default function ClickableList ({ items, selected, clickAction, displayAttribute, selectAttribute, className, selectedClassName }) {
  if (!selectAttribute) {
    selectAttribute = displayAttribute
  }

  const itemList = items
    .map(item => {
      const itemClass = classNames({
        [style.selected]: item.get(selectAttribute) === selected,
        [selectedClassName]: item.get(selectAttribute) === selected
      })
      const itemSelect = item.get(selectAttribute)
      return (
        <li className={itemClass} key={itemSelect} onClick={() => clickAction(itemSelect)}>
          {item.get(displayAttribute)}
        </li>
      )
    })

  return (
    <ul className={classNames(style.clickableList, className)}>
      {itemList}
    </ul>
  )
}

ClickableList.propTypes = {
  items: ImmutablePropTypes.list.isRequired,
  selected: PropTypes.any,
  clickAction: PropTypes.func.isRequired,
  displayAttribute: PropTypes.string.isRequired,
  selectAttribute: PropTypes.string,
  className: PropTypes.string,
  selectedClassName: PropTypes.string
}
